import React from 'react'

import './faqcard.css'

const FaqCard = ({ quetion, answer, checked, onChange, onClickEdit }) => {
    return (
        <div className='faq_card_container'>
            <div className='faq_card_title_view'>
                <p className='form_header_text faq_card_leftview' style={{ color: 'var(--black)' }}>{quetion}</p>
                <div className='faq_card_rightview'>
                    <label className="toggle-switch">
                        <input type="checkbox" checked={checked} onChange={onChange} />
                        <span className="switch" />
                    </label>
                    <img src="/images/editPrimary.png" className="faq_card_editlogo mt-left" onClick={onClickEdit} />
                </div>
            </div>
            <p className='form_description_text mt-1'>{answer}</p>
        </div>
    )
}

export default FaqCard