import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillPlusCircle } from 'react-icons/ai'
import Dropzone from 'react-dropzone'

import Modal from '../../../../components/AppModal'
import AppHeader from '../../../../components/AppHeader'
import HowToCard from '../../../../components/Cards/HowToCard'

import { getColorList } from '../../../../store/colors/colorList'
import { getHowToList } from '../../../../store/howTo/howToList'
import { setEnableDisableHowTo } from '../../../../store/howTo/enableDisableHowTo'

import './howto.css'
import AppButton from '../../../../components/forms/AppButton'
import TextInput from '../../../../components/forms/TextInput'
import ImageUploader from '../../../../components/forms/ImageUploader'
import {
  addHowTo,
  clearAddHowToResponse,
} from '../../../../store/howTo/addHowTo'
import ProgressBar from '../../../../components/forms/ProgressBar'
import { toast } from 'react-toastify'

const HowTo = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { colorList } = useSelector((color) => color.colorList)
  const { howToList, loading } = useSelector((user) => user.howToList)
  const { addhowToResponse } = useSelector((user) => user.addhowToResponse)

  const [addHideShow, setAddHideShow] = useState(false)
  const [search, setSearch] = useState('')
  const [openDropdown, setOpenDropdown] = useState(false)
  const [progress, setProgress] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')
  const [currentColor, setCurrentColor] = useState('select-color')
  const [currentColorError, setCurrentColorError] = useState('')
  const [isUpadate, setIsUpadate] = useState(null)

  const [video, setVideo] = useState('')
  const [videoError, setVideoError] = useState('')
  const [videoURL, setVideoURL] = useState('')

  const [image, setImage] = useState('')
  const [imageError, setImageError] = useState('')
  const [imageURL, setImageURL] = useState('')

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 15

  useEffect(() => {
    dispatch(getHowToList(pageSize, pageNumber))
    dispatch(getColorList())
  }, [dispatch])

  useEffect(() => {
    if (addhowToResponse !== null) {
      if (addhowToResponse.status) {
        onCloseModal()
        toast.success(addhowToResponse.message)
        dispatch(getHowToList(pageSize, pageNumber))
        dispatch(clearAddHowToResponse())
      } else {
        toast.error(addhowToResponse.message)
        dispatch(clearAddHowToResponse())
      }
    }
  }, [addhowToResponse, dispatch])

  const onEnableDisableChangeHandler = async ({ _id, enable }) => {
    setCurrentItem(_id)
    await dispatch(setEnableDisableHowTo({ _id, enable }))
    await dispatch(getHowToList(pageSize, pageNumber))
    setCurrentItem(null)
  }

  const onSearchHandler = (event) => {
    setSearch(event.target.value)
    dispatch(getHowToList(pageSize, pageNumber, event.target.value))
  }

  const onEditHandler = (item) => {
    setAddHideShow(true)
    setIsUpadate(item)
    setTitle(item.title)
    setVideo(item.videoURL)
    setImage(item.imageURL)
    setCurrentColor(item.color)
  }

  const onCloseModal = () => {
    setAddHideShow(false)
    setVideo('')
    setVideoURL('')
    setImage('')
    setImageURL('')
    setTitle('')
    setCurrentColor('select-color')
    setProgress(null)
    setIsUpadate(null)
    setTitleError('')
    setCurrentColorError('')
    setImageError('')
    setVideoError('')
  }

  const onChangeColor = (e) => {
    setOpenDropdown(false)
    const result =
      colorList !== null &&
      colorList.data &&
      colorList.data.filter((colors) => colors.title === e.target.value)
    if (Array.isArray(result) && result.length > 0 && result[0]) {
      setCurrentColor(result[0])
    }
    setCurrentColorError('')
  }

  const onAddHandler = (event) => {
    event.preventDefault()
    const data = new FormData()

    if (isUpadate) {
      if (title !== '') {
        data.append('title', title)
      }

      if (imageURL !== '') {
        data.append('thumbnail', imageURL, imageURL.name)
      }
      if (videoURL !== '') {
        data.append('video', videoURL, videoURL.name)
      }

      if (isUpadate) {
        data.append('id', isUpadate._id)
      }

      data.append('color[primaryColor]', currentColor.primaryColor)
      data.append('color[secondaryColor]', currentColor.secondaryColor)
      data.append('color[darkColor]', currentColor.darkColor)
      data.append('color[title]', currentColor.title)
      dispatch(addHowTo(data, (progress) => setProgress(progress)))
    } else {
      if (title === '') {
        setTitleError('Please enter title')
      } else if (currentColor === 'select-color') {
        setCurrentColorError('Please select color')
      } else if (video === '') {
        setVideoError('Please select video')
      } else if (image === '') {
        setImageError('Please select image')
      } else {
        data.append('title', title)
        data.append('thumbnail', imageURL, imageURL.name)
        data.append('video', videoURL, videoURL.name)
        data.append('color[primaryColor]', currentColor.primaryColor)
        data.append('color[secondaryColor]', currentColor.secondaryColor)
        data.append('color[darkColor]', currentColor.darkColor)
        data.append('color[title]', currentColor.title)
        dispatch(addHowTo(data, (progress) => setProgress(progress)))
      }
    }
  }

  const onUpload = (image) => {
    setVideoURL(image)
    setVideoError('')
  }

  const onImageUpload = (image) => {
    setImageURL(image)
    setImageError('')
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='How To'
        isAdd
        onAdd={() => setAddHideShow(true)}
        isSearch
        searchPlaceholder='Search How To'
        onSearch={onSearchHandler}
        searchValue={search}
      />

      <div className='client__container'>
        <div className='missions_main_content'>
          {howToList &&
          howToList.data &&
          howToList.data.result &&
          howToList.data.result.length > 0 ? (
            howToList.data.result.map((item, index) => {
              return (
                <HowToCard
                  item={item}
                  key={index}
                  onChange={() => onEnableDisableChangeHandler(item)}
                  onClickEdit={onEditHandler}
                  loading={loading}
                  currentItem={currentItem}
                />
              )
            })
          ) : (
            <p className='nodata_message'>{howToList && howToList.message}</p>
          )}
        </div>
      </div>

      <Modal show={addHideShow}>
        <p className='modal_heading'>Add How To Video</p>
        <div className='howto_edit_modal mt-2'>
          <form className='howto_edit_modal_left' onSubmit={onAddHandler}>
            <TextInput
              placeholder='Title'
              src='/images/title.png'
              onChange={(title) => {
                setTitleError('')
                setTitle(title.target.value)
              }}
              value={title}
              error={titleError}
            />
            <div className='mission_dropdown'>
              <div className='text_input_image_container'>
                <img
                  src='/images/color-input.png'
                  alt='color'
                  className='text_input_image'
                />
              </div>
              <select
                name='color-select'
                id='color-select'
                value={
                  currentColor === 'select-color'
                    ? 'select-color'
                    : currentColor.title
                }
                onChange={onChangeColor}
                className='mission_dropdown_select'>
                <option value='select-color' disabled>
                  Select color
                </option>
                {colorList !== null &&
                colorList.data &&
                colorList.data.length > 0 ? (
                  colorList.data.map((item, index) => (
                    <option key={index} value={item.title}>
                      {item.title}
                    </option>
                  ))
                ) : (
                  <option value='no mission'>No colors found</option>
                )}
              </select>
              <div
                style={{
                  backgroundColor:
                    currentColor !== 'select-color' &&
                    currentColor.primaryColor,
                }}
                className='dropdown_selectedColor'
              />
            </div>
            {currentColorError && (
              <p className='input_error_text'>{currentColorError}</p>
            )}

            <div className='modal_buttons'>
              <AppButton
                title='add'
                type='submit'
                icon={<AiFillPlusCircle className='app_button_image' />}
                onClick={onAddHandler}
              />
              <AppButton
                title='cancel'
                type='button'
                buttonContainer={{
                  backgroundColor: 'var(--transparent)',
                  marginLeft: '0.5rem',
                }}
                textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                onClick={onCloseModal}
              />
            </div>
          </form>
          <div className='clinic_form_image_upload_container'>
            <ImageUploader
              onUpload={onUpload}
              video={video}
              setURL={(video) => setVideo(video)}
              errorMessage={videoError}
              containerStyles={{ width: '15vw', height: '9vw' }}
              videoTitle='Upload video'
            />
          </div>
          <div className='clinic_form_image_upload_container'>
            <ImageUploader
              onUpload={onImageUpload}
              image={image}
              setURL={(image) => setImage(image)}
              errorMessage={imageError}
              containerStyles={{ width: '15vw', height: '9vw' }}
              imageTitle='Upload image'
            />
          </div>
        </div>
        <ProgressBar progress={progress * 100} />
      </Modal>
      {/* <AppLoader loader={loading} /> */}
    </div>
  )
}

export default HowTo
