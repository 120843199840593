import React from 'react';
import Button from './Button';
import classes from './Components.module.css';

const Plan = ({ plan, onSelectAnnually, onSelectMonthly, active, activeAnnually, activeMonthly }) => {
  return (
    <div className={active ? classes.plan_active : classes.plan}>
      <h4 className={classes.plan_heading}>{plan.name}</h4>
      <p className={classes.plan_price}>
        {plan?.details[0]?.unit_amount === 'Free'
          ? plan?.details[0]?.unit_amount
          : `${plan?.currency}${plan?.details[1]?.unit_amount / 100} / ${plan?.details[1]?.recurring?.interval}`}
      </p>
      {plan.description && <p className={classes.plan_desc}>{plan.description}</p>}
      <p className={classes.plan_text_bold}>Your plan includes:</p>

      <ul className={classes.plan_texts}>
        {plan.features.map((item, i) => (
          <li
            key={item + i}
            style={{ color: plan?.details[0]?.unit_amount === 'Free' ? (i + 1 === 4 || i + 1 === 5 ? 'var(--error2)' : 'var(--dark-blue3)') : 'var(--dark-blue3)' }}
          >
            {item.name}
          </li>
        ))}
      </ul>

      <div className='flex'>
        <Button onClick={onSelectAnnually} active={activeAnnually} style={{ maxWidth: '25rem' }}>
          {plan.buttonText ? plan.buttonText : 'Annually'}
        </Button>
        {plan?.details[0]?.unit_amount !== 'Free' && (
          <Button onClick={onSelectMonthly} style={{ maxWidth: '25rem' }} active={activeMonthly}>
            Monthly
          </Button>
        )}
      </div>
    </div>
  );
};

export default Plan;
