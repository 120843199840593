import React from 'react'
import { useSelector } from 'react-redux'
import { BigHead } from '@bigheads/core'

const ProcessCard = ({ item, index }) => {
  const { credentials, userInfo } = useSelector((user) => user.credentials)
  const { moduleNames } = useSelector((user) => user.moduleNames)

  return (
    <div className='big_heades_main_card_container'>
      <div className='big_heades_card_container'>
        {/* <div className='kid_avatar_container'>
          {item.kidAvatar && item.kidAvatar.background && (
            <img
              alt='avatar'
              src={item.kidAvatar.background}
              className='kid_avatar_background_image'
            />
          )}
          <div className='big_heads_container'>
            {item.kidAvatar && (
              <BigHead
                accessory={
                  item?.kidAvatar?.accessory
                    ? item?.kidAvatar?.accessory
                    : 'none'
                }
                clothing={item.kidAvatar.clothing}
                clothingColor={item.kidAvatar.clothingColor}
                eyebrows={item.kidAvatar.eyebrows}
                eyes={item.kidAvatar.eyes}
                facialHair={item.kidAvatar.facialHair}
                hair={item.kidAvatar.hair}
                hairColor={item.kidAvatar.hairColor}
                hat={item.kidAvatar.hat}
                hatColor={item.kidAvatar.hatColor}
                lipColor={item.kidAvatar.lipColor}
                mouth={item.kidAvatar.mouth}
                skinTone={item.kidAvatar.skinTone}
              />
            )}
          </div>
          {item.kidAvatar && item.kidAvatar.tag && (
            <img
              alt='avatar'
              src={item.kidAvatar.tag}
              className='kid_avatar_tag_image'
            />
          )}
        </div> */}
        <div className='big_heades_card_view'>
          <div className='big_heades_card_view_container'>
            <div className='client_card_detail_container'>
              <p className='big_heades_card_title'>
                {item.kidName}{' '}
                {userInfo && userInfo.clientType !== 'lab' ? ' | Age:' : null}{' '}
                {item.kidAge && userInfo && userInfo.clientType !== 'lab'
                  ? item.kidAge
                  : null}
              </p>
              <div className='flex'>
                {userInfo && userInfo.clientType !== 'lab' ? (
                  <p
                    className='client_card_count'
                    style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>
                    Parent Name: {item.userName}{' '}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <p className='right_wrong_answered'>
            {moduleNames && moduleNames.data && moduleNames.data.stageOne}:{' '}
            {item.mission_count} |{' '}
            {moduleNames && moduleNames.data && moduleNames.data.stageTwo}:{' '}
            {item.space_count}{' '}
          </p>
          <p className='right_wrong_answered'>
            {moduleNames && moduleNames.data && moduleNames.data.stageThree}:{' '}
            {item.properties_count} | Questions: {item.questions_count}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProcessCard
