import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enable-disable-question',
    initialState: {
        questionStatus: null,
        loading: false,
    },
    reducers: {
        requested: (question) => {
            question.loading = true
        },
        success: (question, action) => {
            question.questionStatus = action.payload;
            question.loading = false
        },
        failed: (question, action) => {
            question.questionStatus = action.payload;
            question.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const setEnableDisableQuestion = data => apiCallBegan({
    url: '/space/enable-disable-questions',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})