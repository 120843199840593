import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import SmallChartSkeleton from '../Skeletons/SmallChartSkeleton'
import { Bar } from 'react-chartjs-2'
import {
  clearCompletedSpaceCountResponse,
  getCompletedSpaceCount,
} from '../../store/dashboard/spaceCounts'
import { logoutUser } from '../../store/login'
import './charts.css'

const SpaceChart = () => {
  const dispatch = useDispatch()
  const { spaceCountList, loading } = useSelector((list) => list.spaceCountList)

  const { enabledSpaceList } = useSelector((list) => list.enabledSpaceList)
  const { enabledMissionList } = useSelector((list) => list.enabledMissionList)

  const [largestCounts, setLargestCounts] = useState(0)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [currentMissionItem, setCurrentMissionItem] = useState('')

  const getSpaceCounts = async () =>
    await enabledSpaceList.data.result.forEach((item) =>
      dispatch(
        getCompletedSpaceCount(
          currentMissionItem === '' ? 'all' : currentMissionItem._id,
          item._id,
          item.title
        )
      )
    )
  useEffect(() => {
    if (enabledSpaceList && enabledSpaceList.status) {
      getSpaceCounts()
    } else {
      setCurrentMissionItem('')
    }
  }, [dispatch, enabledSpaceList.status, currentMissionItem])

  useEffect(() => {
    Array.isArray(spaceCountList) &&
      spaceCountList.length > 0 &&
      spaceCountList.map((space) => {
        if (space.count > largestCounts) setLargestCounts(space.count)
      })
  }, [spaceCountList, largestCounts])

  useEffect(() => {
    if (enabledMissionList === null) {
      if (
        enabledMissionList &&
        enabledMissionList.data &&
        enabledMissionList.data.result
      ) {
        dispatch(setCurrentMissionItem(enabledMissionList.data.result[0]))
      }
    }
  }, [dispatch, enabledMissionList, currentMissionItem])

  const selectItem = async (item) => {
    await dispatch(clearCompletedSpaceCountResponse())
    await setOpenDropdown(false)
    await setCurrentMissionItem(item)
    await getSpaceCounts()
  }

  const backgroundColor = [
    'rgb(75, 192, 192)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(255, 99, 132)',
  ]
  const options = {
    animation: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      bar: {
        borderRadius: 10,
      },
    },
  }

  try {
    return (
      <div style={{ marginTop: '2rem' }}>
        <div className='date_main_contaner'>
          {loading ? (
            <SmallChartSkeleton from='spaceChart' />
          ) : (
            <Fragment>
              {enabledMissionList &&
                enabledMissionList.data &&
                enabledMissionList.data.result &&
                enabledMissionList.data.result.length > 0 && (
                  <div className='date_container'>
                    <div
                      className='dropdown_container'
                      onBlur={() => setOpenDropdown(false)}
                      tabIndex='0'>
                      {currentMissionItem !== null && (
                        <div
                          className='dropdown date__input'
                          onClick={() => setOpenDropdown(!openDropdown)}>
                          <p className='role_title'>
                            {currentMissionItem !== ''
                              ? currentMissionItem.title
                              : 'all'}
                          </p>
                          {openDropdown ? (
                            <IoIosArrowUp
                              color='#E56E00'
                              className='arrow_up_down'
                            />
                          ) : (
                            <IoIosArrowDown
                              color='#E56E00'
                              className='arrow_up_down'
                            />
                          )}
                        </div>
                      )}
                      {openDropdown && (
                        <div
                          className='list_container'
                          style={{ marginRight: 0 }}>
                          {enabledMissionList.data.result.map((item, index) => {
                            return (
                              <div
                                className={
                                  currentMissionItem &&
                                  currentMissionItem._id === item._id
                                    ? 'selected_dropdown_item'
                                    : 'dropdown_item'
                                }
                                key={index}
                                onClick={() => selectItem(item)}>
                                <p className='dropdown_item_userName'>
                                  {' '}
                                  {item.title}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              <div className='chartContainer'>
                <div className='chart'>
                  {(spaceCountList && spaceCountList.loading) ||
                  (enabledSpaceList && enabledSpaceList.loading) ? (
                    <SmallChartSkeleton />
                  ) : spaceCountList && spaceCountList.length > 0 ? (
                    <Bar
                      data={{
                        labels: spaceCountList.map((item) => item.label),
                        datasets: [
                          {
                            data: spaceCountList.map((item) => item.count),
                            backgroundColor,
                            responsive: true,
                          },
                        ],
                      }}
                      height={55}
                      options={options}
                    />
                  ) : (
                    <div className='chart_no_data'>
                      <h1>No Data</h1>
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  } catch (e) {
    dispatch(logoutUser())
  }
}

export default SpaceChart
