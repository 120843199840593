import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'add-mission',
    initialState: {
        addMissionResponse: null,
        loading: false,
    },
    reducers: {
        requested: (mission) => {
            mission.loading = true
        },
        success: (mission, action) => {
            mission.addMissionResponse = action.payload
            mission.loading = false
        },
        failed: (mission, action) => {
            mission.addMissionResponse = action.payload;
            mission.loading = false
        },
        reset: (mission, action) => {
            mission.addMissionResponse = action.payload;
            mission.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addMission = (data, onUploadProgress) => apiCallBegan({
    url: '/mission/create-and-update-mission',
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearAddMissionResponse = () => apiCallBegan({
    onReset: reset.type,
})