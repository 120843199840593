import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'delete-response-video',
  initialState: {
    deleteResponseVideo: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.deleteResponseVideo = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.deleteResponseVideo = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.deleteResponseVideo = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteResponseVideo = (id) =>
  apiCallBegan({
    url: `/response-content/delete-response-video/${id}/true`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearDeleteResponseVideo = () =>
  apiCallBegan({
    onReset: reset.type,
  })
