import React from 'react';
import SkeletonStyles from './Skeletons.module.css';

const PrivacySkeleton = () => {
	const items = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ];
	return (
		items &&
		items.map((item) => (
			<div
				key={item}
				className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
				style={{ width: item < 5 ? '80vw' : item < 8 ? '78vw' : '75vw' }}
			/>
		))
	);
};

export default PrivacySkeleton;
