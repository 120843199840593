import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'completed-and-incompleted-mission-count',
    initialState: {
        missionCountList: [],
        loading: false,
    },
    reducers: {
        requested: (count) => {
            count.loading = true
        },
        success: (count, action) => {
            var result = [];
            if (action.payload && action.payload.status) {
                var dataArr = count.missionCountList.concat(action.payload.data).map(item => {
                    if (item && item.label) {
                        return [item.label, item]
                    }
                });

                var maparr;
                if (dataArr && dataArr.length > 0) {
                    maparr = new Map(dataArr);
                }
                if (maparr) {
                    result = [...maparr.values()];
                }
            }
            count.missionCountList = result && result.length > 0 ? result : []
            count.loading = false
        },
        failed: (count, action) => {
            count.missionCountList = action.payload;
            count.loading = false
        },
        reset: (count, action) => {
            count.missionCountList = []
            count.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getCompletedAndIncompletedMissionCount = (mission, missionName) => apiCallBegan({
    url: `/generic/get-total-mission-completed-and-incomplete-count/${mission}/${missionName}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})


export const clearMissionCountResponse = () => apiCallBegan({
    onReset: reset.type,
})