import React from 'react';
import AppSwitchSmall from '../../AppSwitchSmall';

const VideoCard = ({ item, loading, onEditClick, onChangeSwitch, currentItem, onDelete }) => {
	return (
		<div className='client_card_container'>
			<div className='client_card_view_container' style={{ flexDirection: 'column', width: '18rem' }}>
				<img
					alt='mission'
					src='/images/question_category.png'
					className='client_card_image'
					style={{ backgroundColor: 'var(--primary)', padding: '1rem' }}
				/>
				<div className='client_card_detail_container' style={{ width: '100%' }}>
					<div
						className='client_card_action_container'
						style={{ gap: '.5rem', alignItems: 'flex-start', justifyContent: 'center' }}>
						<p className='client_card_title'>{item.title}</p>
					</div>
				</div>
			</div>
			<div className='client_card_action_container_left'>
				<AppSwitchSmall
					checked={item.enable}
					onChange={() => onChangeSwitch(item)}
					loading={currentItem === item._id && loading}
				/>
				<img src='/images/editPrimary.png' className='client_card_edit_image' onClick={() => onEditClick(item)} />
			</div>
		</div>
	);
};

export default VideoCard;
