import React, { Fragment, useState } from 'react'
import EmogiPicker from '../../../../../components/EmogiPicker'

const Options = ({
  emogi,
  setEmogi,
  onClose,
  setEmogis,
  question,
  index,
  onEdit,
  answer,
  onDelete,
  onChange,
  setIndex,
  isEdit,
  onSetEdit,
  optionValue,
  onCancelEdit,
}) => {
  console.log(' answer === question.value', answer, question.value)
  return (
    <Fragment>
      <div className='answer'>
        <div className='answer_textBox'>
          {!isEdit ? (
            <Fragment>
              <p className='answer_text'>{question.optionTitle}</p>
              <div className='flex'>
                <img
                  src='/images/icon_edit.png'
                  alt='right tick'
                  onClick={() => onEdit(question, index)}
                  className='answer_text_icon'
                />
                <img
                  src='/images/delete.png'
                  alt='right tick'
                  className='answer_text_icon'
                  onClick={() => onDelete(index)}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <input
                type='text'
                className='answer_text_input'
                autoFocus
                value={optionValue}
                onChange={(option) => onChange(option.target.value, index)}
              />
              <div className='flex'>
                <div className='edit_emogi' onClick={setEmogi}>
                  <p>😊</p>
                </div>
                <img
                  src='/images/icon_tick.png'
                  alt='right tick'
                  className='answer_text_icon'
                  onClick={() => onSetEdit(index)}
                />
                <img
                  src='/images/icon_delete.png'
                  alt='right tick'
                  onClick={() => onCancelEdit(false)}
                  className='answer_text_icon'
                />
              </div>
            </Fragment>
          )}
        </div>
        <div className='answer_right' onClick={() => setIndex(question, index)}>
          {answer === question.value ? (
            <>
              <img
                src='/images/icon_tick.png'
                alt='right tick'
                className='answer_right_icon'
              />
              <p className='answer_right_text'>Right Answer</p>
            </>
          ) : (
            <p className='answer_makeright_text'>Make as right answer</p>
          )}
        </div>
      </div>
      {isEdit && emogi && (
        <EmogiPicker onClose={onClose} setEmogi={setEmogis} />
      )}
    </Fragment>
  )
}

export default Options
