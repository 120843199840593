import React from 'react'
import './QuestionCard.css'

const QuestionCard = ({ index, item, onEditClick, onDelete, onAddClick }) => {
  const typeTitle =
    item.questionType === 'singleOption'
      ? 'Single Option'
      : item.questionType === 'multipleOption'
      ? 'Multiple Option'
      : item.questionType === 'matchPair'
      ? 'Match Pair'
      : item.questionType === 'rightWrongOption'
      ? 'Right Wrong Option'
      : item.questionType === 'fillInTheBlanks'
      ? 'Fill in the Blanks'
      : ''

  return (
    <div className='properties_card_container' style={{ width: '35rem' }}>
      <div className='properties_card_head'>
        <div className='properties_card_header'>
          <div className='client_card_detail_container'>
            <p className='client_card_title'>
              {index + 1}. {item.question}
            </p>
            <p className='client_card_questionType'>
              Question Type: {typeTitle}
            </p>
          </div>
        </div>
        <div className='client_card_action_container'>
          <div className='client_card_action_container_left'>
            <img
              src='/images/baseline-video-settings.png'
              className='client_card_edit_image'
              onClick={() => onAddClick(item)}
            />
            <img
              src='/images/editPrimary.png'
              className='client_card_edit_image'
              onClick={() => onEditClick(item)}
            />
          </div>
          <img
            src='/images/delete.png'
            className='client_card_delete_image'
            onClick={() => onDelete(item)}
          />
        </div>
      </div>
      <div className='properties_card_answers'>
        {item.questionType === 'singleOption' &&
          item.options &&
          Array.isArray(item.options) &&
          item.options.length > 0 &&
          item.options.map((option, index) => (
            <div className='flex'>
              <p key={index} className='properties_card_answer'>
                • {option.optionTitle}
              </p>
              {option.value === item.answer && (
                <p className='properties_card_answer_right'>Right Answer</p>
              )}
            </div>
          ))}
        {item.questionType === 'multipleOption' &&
          item.options &&
          Array.isArray(item.options) &&
          item.options.length > 0 &&
          item.options.map((option, index) => (
            <div className='flex'>
              <p key={index} className='properties_card_answer'>
                • {option.optionTitle}
              </p>
              {option.isChecked && (
                <p className='properties_card_answer_right'>Right Answer</p>
              )}
            </div>
          ))}
        {item.questionType === 'fillInTheBlanks' &&
          item.options &&
          Array.isArray(item.options) &&
          item.options.length > 0 &&
          item.options.map((option, index) => (
            <div className='flex'>
              <p key={index} className='properties_card_answer'>
                • {option.optionTitle}
              </p>
              {option.isChecked && (
                <p className='properties_card_answer_right'>Right Answer</p>
              )}
            </div>
          ))}
        {item.questionType === 'rightWrongOption' &&
          item.rightWrongOptions &&
          Array.isArray(item.rightWrongOptions) &&
          item.rightWrongOptions.length > 0 &&
          item.rightWrongOptions.map((option, index) => (
            <div className='flex'>
              <p key={index} className='properties_card_answer'>
                • {option.optionTitle}
              </p>
              {option.answerType === 'red' ? (
                <p className='properties_card_answer_right properties_card_answer_right_red'>
                  Red
                </p>
              ) : option.answerType === 'green' ? (
                <p className='properties_card_answer_right properties_card_answer_right_green'>
                  Green
                </p>
              ) : (
                ''
              )}
            </div>
          ))}
        {item.questionType === 'matchPair' &&
          item.options &&
          Array.isArray(item.options) &&
          item.options.length > 0 &&
          item.options.map((option, index) => (
            <div key={index} className='flex'>
              <div className='match_pair_left'>
                {item.options[0] &&
                  Array.isArray(item.options[0].PairA) &&
                  item.options[0].PairA.length > 0 &&
                  item.options[0].PairA.map((a, aI) => (
                    <p className='properties_card_answer' key={aI}>
                      {a} &rarr;
                    </p>
                  ))}
              </div>
              <div>
                {item.options[0] &&
                  Array.isArray(item.options[0].PairB) &&
                  item.options[0].PairB.length > 0 &&
                  item.options[0].PairB.map((b, bI) => (
                    <p className='properties_card_answer' key={bI}>
                      {b}
                    </p>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default QuestionCard
