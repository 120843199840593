import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-success-videos',
  initialState: {
    successVideoList: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.successVideoList = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.successVideoList = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.successVideoList = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getSuccessVideoList = (data) =>
  apiCallBegan({
    url: `/general/get-general-video`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearSuccessVideoList = () =>
  apiCallBegan({
    onReset: reset.type,
  })
