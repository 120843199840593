import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'user-count-by-date',
    initialState: {
        userCount: {},
        userCountList: [],
        loading: false,
    },
    reducers: {
        requested: (count) => {
            count.loading = true
        },
        success: (count, action) => {

            if (action.payload && action.payload.data && action.payload.data.result) {
                if (count && count.userCountList) {
                    var dataArr = count.userCountList.concat(action.payload.data.result).map(item => {
                        if (item && item.label) {
                            return [item.label, item]
                        }
                    });

                    var maparr;
                    var result;
                    if (dataArr && dataArr.length !== 0) {
                        maparr = new Map(dataArr);
                    }
                    if (maparr) {
                        result = [...maparr.values()];
                    }

                    count.userCountList = result && result.length !== 0 ? result : []
                }
            }


            var maparr = new Map(dataArr && dataArr);
            var result = maparr && [...maparr.values()];

            count.userCount = action.payload

            count.loading = false
        },
        failed: (count, action) => {
            count.userCount = action.payload;
            count.userCountList = action.payload
            count.loading = false
        },
        reset: (count, action) => {
            count.userCount = action.payload;
            count.userCountList = []
            count.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getUserCount = date => apiCallBegan({
    url: `/generic/get-total-user-count-by-date/${date}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearUserCountResponse = () => apiCallBegan({
    onReset: reset.type,
})