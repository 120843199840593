import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'enable-disable-client',
  initialState: {
    clientStatus: null,
    loading: false,
  },
  reducers: {
    requested: (clients) => {
      clients.loading = true
    },
    success: (clients, action) => {
      clients.clientStatus = action.payload
      clients.loading = false
    },
    failed: (clients, action) => {
      clients.clientStatus = action.payload
      clients.loading = false
    },
    reset: (clients, action) => {
      clients.clientStatus = action.payload
      clients.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const setEnableDisableClient = (data) =>
  apiCallBegan({
    url: '/user/enable-disable-client',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type,
  })

export const clearEnableDisableClient = () =>
  apiCallBegan({
    onReset: reset.type,
  })
