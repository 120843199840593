import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'send-notification',
    initialState: {
        sendNotificationResponse: null,
        loading: false,
    },
    reducers: {
        requested: (notification) => {
            notification.loading = true
        },
        success: (notification, action) => {
            notification.sendNotificationResponse = action.payload
            notification.loading = false
        },
        failed: (notification, action) => {
            notification.sendNotificationResponse = action.payload;
            notification.loading = false
        },
        reset: (notification, action) => {
            notification.sendNotificationResponse = action.payload;
            notification.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const sendNotification = (data, onUploadProgress) => apiCallBegan({
    url: '/notification/send-generic-notification',
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearSendNotificationResponse = () => apiCallBegan({
    onReset: reset.type,
})