import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'delete-mission',
    initialState: {
        deleteMissionResponse: null,
        loading: false,
    },
    reducers: {
        requested: (mission) => {
            mission.loading = true
        },
        success: (mission, action) => {
            mission.deleteMissionResponse = action.payload
            mission.loading = false
        },
        failed: (mission, action) => {
            mission.deleteMissionResponse = action.payload;
            mission.loading = false
        },
        reset: (mission, action) => {
            mission.deleteMissionResponse = action.payload;
            mission.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteMission = (id, status) => apiCallBegan({
    url: `/mission/delete-mission/${id}/${status}`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearDeleteMissionResponse = () => apiCallBegan({
    onReset: reset.type,
})