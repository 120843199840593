import React, { useState, useEffect, useRef } from 'react'
import AppHeader from '../../../../components/AppHeader'
import AppLoader from '../../../../components/AppLoader'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  getCoupon,
  clearGetCouponResponse,
} from '../../../../store/Coupon/getCoupons'
import {
  addCoupon,
  clearAddCouponResponse,
} from '../../../../store/Coupon/addCoupons'
import AppButton from '../../../../components/forms/AppButton'
import TextInput from '../../../../components/forms/TextInput'
import AppModal2 from '../../../../components/AppModal2'
import AppSwitchSmall from '../../../../components/AppSwitchSmall'
import {
  deleteCoupon,
  clearDeleteCouponResponse,
} from '../../../../store/Coupon/deleteCoupon'
import {
  enableDisableCoupon,
  clearEnableDisableCouponCouponResponse,
} from '../../../../store/Coupon/enableCoupon'
import styles from './Coupon.module.css'
import Pagination from '../../../../components/Pagination'

const Coupon = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [showAddModal, setShowAddModal] = useState(false)
  const [percentOff, setPercentOff] = useState('')
  const [name, setName] = useState('')
  const [duration, setDuration] = useState('')
  const [durationType, setDurationType] = useState('')
  const [errorCoupon, setErrorCoupon] = useState({
    percentOff: '',
    name: '',
    duration: '',
    durationType: '',
  })

  const next = useRef(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [startId, setStartId] = useState(null)
  const [endId, setEndId] = useState(null)
  const [initialPage, setInitialPage] = useState(true)
  const [lastPage, setLastPage] = useState(false)
  const [isNextPressed, setIsNextPressed] = useState(false)
  const [isPrevPressed, setIsPrevPressed] = useState(false)

  const pageSize = 12

  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { couponList, loading } = useSelector((coupon) => coupon.getCoupon)
  const { addCouponResponse } = useSelector((coupon) => coupon.addCoupon)
  const { deleteCouponResponse } = useSelector((coupon) => coupon.deleteCoupon)
  const { enableCouponResponse } = useSelector((coupon) => coupon.enableCoupon)

  const nextStyle =
    couponList?.data?.length < pageSize ? styles.true : styles.false

  const previousStyle = initialPage ? styles.true : styles.false

  useEffect(() => {
    if (couponList && couponList.data && Array.isArray(couponList.data)) {
      setStartId(couponList?.data[pageSize - 1]?.id)
      setEndId(couponList?.data[0]?.id)
    }
  }, [couponList])

  useEffect(() => {
    if (couponList && couponList.data && Array.isArray(couponList.data)) {
      if (!couponList.has_more && isNextPressed) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }

      if (!couponList.has_more && isPrevPressed && !lastPage) {
        setInitialPage(true)
      }
    }
  }, [isPrevPressed, couponList, isNextPressed, loading])

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value)
  }

  const onAddCoupon = () => {
    setShowAddModal(true)
  }

  const onCloseModal = () => {
    setShowAddModal(false)
    setName('')
    setDuration('')
    setDurationType('')
    setPercentOff('')
  }

  const onSubmitHandler = () => {
    let validate = true
    let error = {}

    if (percentOff === '' && percentOff.trim() === '') {
      error = {
        ...error,
        percentOff: 'Please enter the percentage off',
      }
      validate = false
    } else if (!isNumeric(percentOff)) {
      error = {
        ...error,
        percentOff: 'Percentage must be a number',
      }
      validate = false
    }

    if (name === '' && name.trim() === '') {
      error = {
        ...error,
        name: 'Please enter coupon code',
      }
      validate = false
    }

    if (
      durationType !== 'forever' &&
      durationType !== 'once' &&
      duration === '' &&
      duration.trim() === ''
    ) {
      error = {
        ...error,
        duration: 'Please enter the duration in months',
      }
      validate = false
    } else if (
      durationType !== 'forever' &&
      durationType !== 'once' &&
      !isNumeric(duration)
    ) {
      error = {
        ...error,
        duration: 'Duration must be a number',
      }
      validate = false
    }

    if (durationType === '' && durationType.trim() === '') {
      error = {
        ...error,
        durationType: 'Please select duration type',
      }
      validate = false
    }

    setErrorCoupon({
      ...errorCoupon,
      ...error,
    })

    let data

    if (validate) {
      data = {
        percent_off: Number(percentOff),
        name: name,
        duration: durationType,
      }

      if (durationType === 'repeating') {
        data = {
          ...data,
          duration_in_months: Number(duration),
        }
      }

      dispatch(addCoupon(data))
    }
  }

  const onPageChange = (type) => {
    if (type === 'next') {
      setInitialPage(false)
      setIsNextPressed(true)
      setIsPrevPressed(false)
      dispatch(getCoupon(pageSize, startId))
    } else {
      setIsNextPressed(false)
      setIsPrevPressed(true)
      dispatch(getCoupon(pageSize, null, endId))
    }
  }

  useEffect(() => {
    dispatch(getCoupon(pageSize))
  }, [])

  useEffect(() => {
    if (addCouponResponse) {
      if (addCouponResponse.status) {
        toast.success(addCouponResponse.message)
        dispatch(getCoupon(pageSize))
        onCloseModal()
        dispatch(clearAddCouponResponse())
        setInitialPage(true)
      } else if (!addCouponResponse.status) {
        toast.error(addCouponResponse.message)
      }
    }
  }, [addCouponResponse])

  const showDeleteModalHandler = (item) => {
    setShowDeleteModal(true)
    setCurrentItem(item)
  }

  const closeDeleteModalHandler = () => {
    setShowDeleteModal(false)
    setCurrentItem(null)
  }

  const deleteSubmitHandler = () => {
    if (currentItem?.id) {
      dispatch(deleteCoupon(currentItem?.id.toString()))
    }
  }

  useEffect(() => {
    if (deleteCouponResponse) {
      if (deleteCouponResponse.status) {
        toast.success(deleteCouponResponse.message)
        dispatch(getCoupon(pageSize))
        dispatch(clearDeleteCouponResponse())
        closeDeleteModalHandler()
        setInitialPage(true)
      } else {
        toast.error(deleteCouponResponse.message)
        dispatch(clearDeleteCouponResponse())
      }
    }
  }, [deleteCouponResponse])

  useEffect(() => {
    if (enableCouponResponse) {
      if (enableCouponResponse.status) {
        toast.success(enableCouponResponse.message)
        dispatch(getCoupon(pageSize))
        dispatch(clearEnableDisableCouponCouponResponse())
        setInitialPage(true)
      } else {
        toast.error(enableCouponResponse.message)
        dispatch(clearEnableDisableCouponCouponResponse())
      }
    }
  }, [enableCouponResponse])

  const enableCouponHandler = (item) => {
    if (item) {
      const data = {
        enable: !item.valid,
        id: item.id,
      }

      dispatch(enableDisableCoupon(data))
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Coupons' isAdd={true} onAdd={onAddCoupon} />
      <div className='client__container'>
        <div className='missions_main_content'>
          {couponList &&
            couponList.data &&
            Array.isArray(couponList.data) &&
            couponList.data.map((item, i) => {
              return (
                <div className='client_card_container' key={i}>
                  <div className='coupon_card_container'>
                    <div className='coupon_card_text'>{item.name}</div>
                    <div className='coupon_card_text2'>{item.percent_off}%</div>
                  </div>
                  <div className='coupon_card_duration'>
                    Duration:{` `}
                    {item.duration === 'repeating' &&
                      item.duration_in_months && (
                        <span>{`${item.duration_in_months} Month`}</span>
                      )}
                    {item.duration === 'forever' && (
                      <span>{`${item.duration}`}</span>
                    )}
                    {item.duration === 'once' && (
                      <span>{`${item.duration}`}</span>
                    )}
                  </div>
                  <div className='coupon_card_options'>
                    {/* <AppSwitchSmall
                      checked={item.valid}
                      onChange={enableCouponHandler.bind(this, item)}
                    /> */}
                    <img
                      src='/images/delete.png'
                      style={{ marginLeft: '1rem' }}
                      className='client_card_delete_image'
                      onClick={showDeleteModalHandler.bind(this, item)}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <AppModal2 show={showAddModal}>
        <div
          className={styles.modalContainer}
          style={{ height: '44rem', width: '40rem' }}>
          <div className={styles.modalSection}>
            <p className='form_header_text'>Add Coupon</p>

            <TextInput
              placeholder='Coupon Code'
              src='/images/clinic.png'
              onChange={(event) => {
                setErrorCoupon({
                  ...errorCoupon,
                  name: '',
                })
                setName(event.target.value)
              }}
              value={name}
              error={errorCoupon.name}
            />
            <div className='mission_dropdown'>
              <div className='text_input_image_container'>
                <img
                  src='/images/spaces.png'
                  alt='color'
                  className='text_input_image'
                />
              </div>
              <select
                name='space-type'
                id='space-type'
                value={durationType}
                onChange={(e) => {
                  setErrorCoupon({
                    ...errorCoupon,
                    durationType: '',
                  })
                  setDurationType(e.target.value)
                }}
                className='mission_dropdown_select dropdown_smallfont '>
                <option value=''>Select Duration type</option>
                <option value='repeating'>Repeating</option>
                <option value='once'>Once</option>
                <option value='forever'>Forever</option>
              </select>
            </div>
            {errorCoupon.durationType !== '' ? (
              <p className='input_error_text'>{errorCoupon.durationType}</p>
            ) : null}

            <TextInput
              placeholder='Percent Off'
              src='/images/email.png'
              onChange={(event) => {
                setErrorCoupon({
                  ...errorCoupon,
                  percentOff: '',
                })
                setPercentOff(event.target.value)
              }}
              value={percentOff}
              error={errorCoupon.percentOff}
            />

            {durationType === 'repeating' && (
              <TextInput
                placeholder='Duration'
                src='/images/clinic-phone.png'
                onChange={(event) => {
                  setErrorCoupon({
                    ...errorCoupon,
                    duration: '',
                  })
                  setDuration(event.target.value)
                }}
                value={duration}
                error={errorCoupon.duration}
              />
            )}
          </div>

          <div className='modal_buttons'>
            <AppButton
              title='Add'
              type='submit'
              icon={
                <img
                  src='/images/add-clinic.png'
                  className='app_button_image'
                />
              }
              onClick={onSubmitHandler}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={onCloseModal}
            />
          </div>
        </div>
      </AppModal2>

      <AppModal2 show={showDeleteModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Delete Coupon</p>
          <p className='form_header_delete_text'>
            Are you sure you want to delete {` `}
            <span>{currentItem?.name ? currentItem.name : ''}</span>
            {` `}coupon ?
          </p>
          <p className='form_header_delete_text'>
            <span style={{ color: 'red', fontSize: '1.4rem' }}>Note:</span>{' '}
            Deleting this coupon will also delete all connected promo codes
          </p>

          <div className='modal_buttons'>
            <AppButton
              title='delete'
              type='submit'
              onClick={deleteSubmitHandler}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={closeDeleteModalHandler}
            />
          </div>
        </div>
      </AppModal2>

      <AppLoader loader={loading} />

      {couponList && couponList.data && couponList.data.length > 0 && (
        <div className={styles.paginationContainer}>
          <div
            className={[styles.paginationBtn, previousStyle].join(' ')}
            onClick={onPageChange.bind(this, 'previous')}>
            Previous
          </div>
          <div
            ref={next}
            // style={{
            //   pointerEvents: couponList.data.length < pageSize ? styles.true : styles.false,
            //   backgroundColor:
            //     couponList.data.length < pageSize ? '#9e9e9e' : '#404f90',
            // }}
            className={[styles.paginationBtn, nextStyle].join(' ')}
            onClick={onPageChange.bind(this, 'next')}>
            Next
          </div>
        </div>
      )}
    </div>
  )
}

export default Coupon
