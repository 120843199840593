import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-all-promocode',
  initialState: {
    promoList: null,
    loading: false,
  },
  reducers: {
    requested: (promo) => {
      promo.loading = true
    },
    success: (promo, action) => {
      promo.promoList = action.payload
      promo.loading = false
    },
    failed: (promo, action) => {
      promo.promoList = action.payload
      promo.loading = false
    },
    reset: (promo, action) => {
      promo.promoList = action.payload
      promo.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getPromo = (pageSize, startId, endId) =>
  apiCallBegan({
    url: startId
      ? `payment/get-all-promo-code?limit=${pageSize}&starting_after=${startId} `
      : endId
      ? `payment/get-all-promo-code?limit=${pageSize}&ending_before=${endId}`
      : pageSize
      ? `payment/get-all-promo-code?limit=${pageSize}`
      : `payment/get-all-promo-code`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearGetPromoResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
