import React from 'react'
import './Skeleton.css'

function TableSkeleton({ style }) {
  return (
    <div className={`client__container`} style={style ? style : {}}>
      <div className='missions_main_content'>
        <div className='table_skeleton_container'>
          <div
            className='flex table_skeleton_header'
            style={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}>
            {Array(5)
              .fill(null)
              .map((u, i) => {
                return (
                  <div
                    className='table_skeleton_header_item skeleton'
                    key={i}></div>
                )
              })}

            <div className='table_skeleton_header_item skeleton table_top_border'></div>
          </div>
          {Array(15)
            .fill(null)
            .map((u, i) => {
              return (
                <div className='table_skeleton_content skeleton' key={i}></div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default TableSkeleton
