import React, { Fragment, useState } from 'react'
import EmogiPicker from '../../../../../components/EmogiPicker';
import TextInput from '../../../../../components/forms/TextInput';

const RightWrong = ({ rightWrongOptions, setRightWrongOptions, errorMessage, onRemoveError }) => {

    const [rightWrongOptionTitle, setRightWrongOptionTitle] = useState("")
    const [rightWrongOptionTitleError, setRightWrongOptionTitleError] = useState("")

    const [isEdit, setIsEdit] = useState(null)
    const [rightWrongOptionEdit, setRightWrongOptionsEdit] = useState("")

    const [displayEmogies, setDisplayEmogies] = useState(false);
    const [emogi, setEmogi] = useState(false)

    const addRightWrongOptionHandler = () => {
        onRemoveError()
        if (rightWrongOptionTitle === "") {
            setRightWrongOptionTitleError("Please enter Question option")
        } else {
            setRightWrongOptions(prev => [...prev, {
                optionTitle: rightWrongOptionTitle,
                value: rightWrongOptionTitle,
                answerType: "",
            }])
            setRightWrongOptionTitle("")
        }
    }

    const onSetTrueHandler = (i) => {
        onRemoveError()
        let setData = JSON.parse(JSON.stringify(rightWrongOptions))
        setData.forEach((is, index) => {
            if (i === index) {
                if (is.answerType === "" || is.answerType === "red") {
                    is.answerType = "green"
                }
            }
        })
        setRightWrongOptions(setData)
    }

    const onSetFalseHandler = (i) => {
        onRemoveError()
        let setData = JSON.parse(JSON.stringify(rightWrongOptions))
        setData.forEach((is, index) => {
            if (i === index) {
                if (is.answerType === "" || is.answerType === "green") {
                    is.answerType = "red"
                }
            }
        })
        setRightWrongOptions(setData)
    }

    const onDeleteRightWrongOptionHandler = (index) => setRightWrongOptions(rightWrongOptions.filter((i, ii) => ii !== index))

    const onEditCancelHandler = () => {
        setIsEdit(null)
    }

    const onEditHandler = index => {
        const editable = rightWrongOptions.find((_, i) => i === index)
        setIsEdit(index)
        setRightWrongOptionsEdit(editable.optionTitle)
    }

    const onSetEdit = index => {
        setIsEdit(null)
        const data = JSON.parse(JSON.stringify(rightWrongOptions))
        data.forEach((_, inx) => {
            if (index === inx) {
                _.optionTitle = rightWrongOptionEdit
                _.value = rightWrongOptionEdit
            }
        })
        setRightWrongOptions(data)
    }

    return (
        <Fragment>
            <div className='flex' style={{ gap: '2rem' }}>
                <TextInput
                    placeholder='Answer'
                    src='/images/question_answer.png'
                    onChange={(options) => {
                        setRightWrongOptionTitleError('');
                        onRemoveError()
                        setRightWrongOptionTitle(options.target.value);
                    }}
                    value={rightWrongOptionTitle}
                    error={rightWrongOptionTitleError}
                />
                <div className="emogi_wrapper" onClick={() => setDisplayEmogies(!displayEmogies)}>
                    <p>😊</p>
                </div>
                <div className='addBtn' onClick={addRightWrongOptionHandler}>
                    <img src='/images/add_blue.png' alt='add icon' className='addBtnIcon' />
                </div>
            </div>
            <div className='answers'>
                {
                    displayEmogies &&
                    <EmogiPicker onClose={() => setDisplayEmogies(false)} setEmogi={emogi => setRightWrongOptionTitle(prev => prev + emogi)} />
                }
                {

                    rightWrongOptions &&
                    rightWrongOptions.length > 0 &&
                    rightWrongOptions.map((question, qIndex) => (
                        <Fragment>

                            <div className='answer' key={qIndex}>
                                <div className='answer_textBox'>
                                    {
                                        isEdit !== null && isEdit === qIndex ?
                                            <Fragment>
                                                <input
                                                    type='text'
                                                    className='answer_text_input'
                                                    autoFocus
                                                    value={rightWrongOptionEdit}
                                                    onChange={option => setRightWrongOptionsEdit(option.target.value)}
                                                />
                                                <div className='flex'>
                                                    <div className="edit_emogi" onClick={() => setEmogi(!emogi)}>
                                                        <p>😊</p>
                                                    </div>
                                                    <img src='/images/icon_tick.png' alt='right tick' className='answer_text_icon' onClick={() => onSetEdit(qIndex)} />
                                                    <img
                                                        src='/images/icon_delete.png'
                                                        alt='right tick'
                                                        className='answer_text_icon'
                                                        onClick={onEditCancelHandler}
                                                    />
                                                </div>
                                            </Fragment> :
                                            <Fragment>
                                                <p className='answer_text'>{question.optionTitle}</p>
                                                <div className='flex'>
                                                    <img src='/images/icon_edit.png' alt='right tick' className='answer_text_icon' onClick={() => onEditHandler(qIndex)} />
                                                    <img src='/images/delete.png' alt='right tick' className='answer_text_icon' onClick={() => onDeleteRightWrongOptionHandler(qIndex)} />
                                                </div>
                                            </Fragment>
                                    }

                                </div>
                                <div className={`right_wrong_answer_textBox btn ${question.answerType === "green" && "green-bg"}`}
                                    onClick={() => onSetTrueHandler(qIndex)}
                                >
                                    <p className='rightWrongText'>G</p>
                                </div>
                                <div className={`right_wrong_answer_textBox btn ${question.answerType === "red" && "red-bg"}`}
                                    onClick={() => onSetFalseHandler(qIndex)}
                                >
                                    <p className='rightWrongText'>R</p>
                                </div>
                            </div>
                            {
                                isEdit === qIndex && emogi &&
                                <EmogiPicker onClose={() => setEmogi(false)} setEmogi={emogi => setRightWrongOptionsEdit(prev => prev + emogi)} />
                            }
                        </Fragment>
                    ))
                }
            </div>
            <p className='input_error_text'>{errorMessage}</p>
        </Fragment>
    )
}

export default RightWrong