import React, { useState } from 'react'
import ClinicIntroRegistration from '../../../src2/screens/ClinicIntroRegistration'
import { useSelector, useDispatch } from 'react-redux'
import Dashboard from '../../app/Dashboard'
import Sidebar from '../../../components/Sidebar'
import MobileSidebar from '../../../components/Sidebar/MobileSidebar'

//for old dashboard

const ClinicIntro = () => {
  const [completed, setCompleted] = useState(false)
  const { credentials, userInfo } = useSelector((user) => user.credentials)

  const completeHandler = () => {
    setCompleted(true)
  }

  const MainScreen = () => {
    if (!completed) {
      if (
        userInfo &&
        !userInfo.isOnboardProcessCompleted &&
        userInfo.clientType === 'lab'
      ) {
        return (
          <ClinicIntroRegistration
            type='Lab'
            processComplete={completeHandler}
          />
        )
      } else if (
        userInfo &&
        !userInfo.isOnboardProcessCompleted &&
        userInfo.clientType === 'clinic'
      ) {
        return (
          <ClinicIntroRegistration
            type='Therapist'
            processComplete={completeHandler}
          />
        )
      } else {
        return <Dashboard />
      }
    } else {
      return <Dashboard />
    }
  }

  return <MainScreen />
}

export default ClinicIntro
