import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import './dashboard.css'

import AppModal3 from '../../../components/AppModal3'
import AppHeader from '../../../components/AppHeader'
import MissionChart from '../../../components/charts/MissionChart'
import SpaceChart from '../../../components/charts/SpaceChart'
import UserChart from '../../../components/charts/UserChart'

import { setLoggedIn } from '../../../store/localStates/auth'
import { getEnabledClientList } from '../../../store/Clients/enabledClientsList'
import { getEnabledMissionList } from '../../../store/missions/enabledMissionList'
import { getEnabledSpaceList } from '../../../store/spaces/enabledSpaceList'
import { logoutUser } from '../../../store/login'

import {
  changeClientPassword,
  clearChangeClientPasswordResponse,
} from '../../../store/Clients/changePassword'
import TextInput from '../../../components/forms/TextInput'
import AppButton from '../../../components/forms/AppButton'

const Dashboard = () => {
  const [showPassModal, setShowPassModal] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { isLoggedIn } = useSelector((bar) => bar.isLoggedIn)
  const { credentials } = useSelector((bar) => bar.credentials)

  const { passwordChangeResponse, loading: passLoading } = useSelector(
    (client) => client.changePassword
  )

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      const initialAPIs = async () => {
        await dispatch(getEnabledClientList())
        await dispatch(getEnabledMissionList())
        await dispatch(getEnabledSpaceList())
      }

      initialAPIs()
    }

    return () => (isSubscribed = false)
  }, [dispatch])

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      if (!isLoggedIn && credentials !== null) {
        dispatch(setLoggedIn(true))
        toast.success(credentials.message)
      }
    }

    return () => (isSubscribed = false)
  }, [isLoggedIn, credentials])

  const options = {
    onClose: (props) => dispatch(logoutUser()),
  }

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      if (passwordChangeResponse) {
        if (passwordChangeResponse.status) {
          toast.success(passwordChangeResponse.message, options)
          closePasswordModalHandler()
          dispatch(clearChangeClientPasswordResponse())
        } else {
          toast.error(passwordChangeResponse.message)
          dispatch(clearChangeClientPasswordResponse())
        }
      }
    }

    return () => (isSubscribed = false)
  }, [passwordChangeResponse, dispatch])

  const openPasswordModalHandler = () => {
    setShowPassModal(true)
  }

  const closePasswordModalHandler = () => {
    setShowPassModal(false)
    setPassword('')
    setConfirmPassword('')
  }

  const passwordSubmitHandler = () => {
    if (password === '') {
      setPasswordError('Password must not be empty')
      return
    } else if (password.length < 2) {
      setPasswordError('Password must be at least 2 characters')
      return
    }

    if (confirmPassword === '') {
      setPasswordError('Confirm Password must not be empty')
      return
    } else if (confirmPassword.length < 2) {
      setPasswordError('Confirm Password must be at least 2 characters')
      return
    }

    if (password !== confirmPassword) {
      setPasswordError('Password do not match try again')
      return
    }

    const data = {
      userPassword: password,
      type: 'bySelf',
    }

    dispatch(changeClientPassword(data))
  }

  const { moduleNames } = useSelector((user) => user.moduleNames)
  const [missionModuleName, setMissionModuleName] = useState('Missions')
  const [spaceModuleName, setSpaceModuleName] = useState('Spaces')

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      if (moduleNames && moduleNames.data && moduleNames.data.stageOne) {
        setMissionModuleName(moduleNames.data.stageOne)
        setSpaceModuleName(moduleNames.data.stageTwo)
      }
    }

    return () => (isSubscribed = false)
  }, [moduleNames])

  const onClientChange = (item) => {
    const initialAPIs = async () => {
      await dispatch(getEnabledClientList())
      await dispatch(getEnabledMissionList())
      await dispatch(getEnabledSpaceList())
    }

    initialAPIs()
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='dashboard'
        showChangePassword={true}
        changePassword={openPasswordModalHandler}
        onClientChange={onClientChange}
      />
      <div className='main_content'>
        <div className='mission_graph_container'>
          <p className='graph_title_text'>
            Complete and Incomplete {missionModuleName.toLowerCase()} counts
          </p>
          <MissionChart />
        </div>
        <div className='space_graph_container'>
          <p className='graph_title_text'>{spaceModuleName} counts</p>
          <SpaceChart />
        </div>
        <div className='user_graph_container'>
          <p className='graph_title_text'>Users counts</p>
          <UserChart />
        </div>
        <AppModal3 show={showPassModal}>
          <div className='clinic_add_modal_container'>
            <p className='form_header_text'>Change Password</p>
          </div>
          <div>
            <TextInput
              placeholder='New Password'
              type='password'
              src='/images/key.png'
              onChange={(event) => {
                setPassword(event.target.value)
                setPasswordError('')
              }}
              value={password}
            />
            <TextInput
              placeholder='Confirm Password'
              type='password'
              src='/images/key.png'
              onChange={(event) => {
                setConfirmPassword(event.target.value)
                setPasswordError('')
              }}
              value={confirmPassword}
              error={passwordError}
            />
          </div>

          <div className='modal_buttons'>
            <AppButton
              title='Submit'
              type='submit'
              // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
              onClick={passwordSubmitHandler}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={closePasswordModalHandler}
            />
          </div>
        </AppModal3>
      </div>
    </div>
  )
}

export default Dashboard
