import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'change-password',
    initialState: {
        changePasswordResponse: null,
        loading: false,
    },
    reducers: {
        requested: (user) => {
            user.loading = true
        },
        success: (user, action) => {
            user.changePasswordResponse = action.payload
            user.loading = false
        },
        failed: (user, action) => {
            user.changePasswordResponse = action.payload;
            user.loading = false
        },
        reset: (user, action) => {
            user.changePasswordResponse = action.payload;
            user.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const changePassword = (data, onUploadProgress) => apiCallBegan({
    url: '/auth/change-password',
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})
export const clearChangePasswordResponse = () => apiCallBegan({
    onReset: reset.type,
})