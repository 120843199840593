import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import './missions.css';

import AppHeader from '../../../../components/AppHeader';
import MissionCard from '../../../../components/Cards/MissionCard';
import AppModal from '../../../../components/AppModal';
import AppModal2 from '../../../../components/AppModal2';
import ImageUploader from '../../../../components/forms/ImageUploader';
import AppButton from '../../../../components/forms/AppButton';
import ProgressBar from '../../../../components/forms/ProgressBar';
import TextInput from '../../../../components/forms/TextInput';
import AppSwitch from '../../../../components/AppSwitch';
import Pagination from '../../../../components/Pagination';

import { getMissionList } from '../../../../store/missions/missionList';
import { setEnableDisableMission } from '../../../../store/missions/enableDisableMission';
import { getColorList } from '../../../../store/colors/colorList';
import { addMission, clearAddMissionResponse } from '../../../../store/missions/addMission';
import { clearDeleteMissionResponse, deleteMission } from '../../../../store/missions/deleteMission';

import { validateOnlyChacters } from '../../../../utils/validators';
import AllMissionsSkeleton from '../../../../components/Skeletons/AllMissionsSkeleton';

import axios from 'axios';
import { baseUrl } from '../../../../middleware/url';

const Mission = () => {
  const dispatch = useDispatch();
  const { sidebar } = useSelector(bar => bar.sidebar);
  const { missionList, loading } = useSelector(list => list.missionList);
  const { addMissionResponse } = useSelector(list => list.addMissionResponse);
  const { deleteMissionResponse } = useSelector(deleted => deleted.deleteMissionResponse);
  const { colorList } = useSelector(color => color.colorList);
  const credentials = useSelector(state => state.credentials);

  const [search, setSearch] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [progress, setProgress] = useState(null);
  const [isUpadate, setIsUpadate] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentColor, setCurrentColor] = useState('select-color');
  const [currentColorError, setCurrentColorError] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [deleteWithReference, setDeleteWithReference] = useState(true);

  const [missionName, setMissionName] = useState('');
  const [missionNameError, setMissionNameError] = useState('');
  const [status, setStatus] = useState(false);

  const [image, setImage] = useState('');
  const [imageError, setImageError] = useState('');
  const [imageURL, setImageURL] = useState('');

  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 15;

  useEffect(() => {
    dispatch(getMissionList({ pageSize, pageNumber, filterValue: '' }));
    dispatch(getColorList());
  }, [dispatch]);

  useEffect(() => {
    if (addMissionResponse !== null) {
      if (addMissionResponse.status) {
        toast.success(addMissionResponse.message);
        dispatch(getMissionList({ pageSize, pageNumber, filterValue: '' }));
        dispatch(clearAddMissionResponse());
        setProgress(null);
        onCloseModal();
      } else {
        toast.error(addMissionResponse.message);
        dispatch(clearAddMissionResponse());
      }
    }
  }, [addMissionResponse, dispatch]);

  useEffect(() => {
    if (deleteMissionResponse !== null) {
      if (deleteMissionResponse.status) {
        closeDeleteModal();
        toast.success(deleteMissionResponse.message);
        dispatch(getMissionList({ pageSize, pageNumber, filterValue: '' }));
        dispatch(clearDeleteMissionResponse());
      } else {
        toast.error(deleteMissionResponse.message);
        dispatch(clearDeleteMissionResponse());
      }
    }
  }, [deleteMissionResponse, dispatch]);

  const onSearchMission = event => {
    setSearch(event.target.value);
    dispatch(getMissionList({ pageSize, pageNumber, filterValue: event.target.value }));
  };

  const onAddMissionHAndler = () => {
    setShowAddModal(true);
  };

  const enableDisableHandler = async ({ _id, enable }) => {
    setCurrentItem(_id);
    await dispatch(setEnableDisableMission({ _id, enable }));
    await dispatch(getMissionList({ pageSize, pageNumber, filterValue: '' }));
    setCurrentItem(null);
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    const data = new FormData();

    if (isUpadate !== null) {
      if (imageURL !== '') {
        data.append('thumbnail', imageURL, imageURL.name);
      }

      if (isUpadate._id) {
        data.append('id', isUpadate._id);
      }

      data.append('title', missionName);
      data.append('isLive', status);
      data.append('color[primaryColor]', currentColor.primaryColor);
      data.append('color[secondaryColor]', currentColor.secondaryColor);
      data.append('color[darkColor]', currentColor.darkColor);
      data.append('color[title]', currentColor.title);
      dispatch(addMission(data, progress => setProgress(progress)));
    } else {
      if (missionName === '') {
        setMissionNameError('Please enter Mission title');
      } else if (missionName.length < 2) {
        setMissionNameError('Mission title must be two character long');
      } else if (currentColor === 'select-color') {
        // 	else if (validateOnlyChacters(missionName)) {
        // 	setMissionNameError('Please enter valid Mission title');
        // }
        setCurrentColorError('Please select color');
      } else if (image === '') {
        setImageError('Please select image');
      } else {
        data.append('title', missionName);
        data.append('thumbnail', imageURL, imageURL.name);
        data.append('isLive', status);
        data.append('color[primaryColor]', currentColor.primaryColor);
        data.append('color[secondaryColor]', currentColor.secondaryColor);
        data.append('color[darkColor]', currentColor.darkColor);
        data.append('color[title]', currentColor.title);
        dispatch(addMission(data, progress => setProgress(progress)));
      }
    }
  };

  const onUpload = image => {
    setImageURL(image);
    setImageError('');
  };

  const onCloseModal = () => {
    setIsUpadate(null);
    setShowAddModal(false);
    setMissionName('');
    setCurrentColor('select-color');
    setStatus(false);
    setImage('');
    setImageURL('');
  };

  const onEditHandler = item => {
    setIsUpadate(item);
    setShowAddModal(true);
    setMissionName(item.title);
    setCurrentColor(item.color);
    setImage(item.imageURL);
    setStatus(item.isLive);
  };

  const onDeleteHandler = item => {
    setShowDeleteModal(true);
    setDeletedItem(item);
  };

  const onDeleteMission = () => {
    if (deletedItem !== null) {
      dispatch(deleteMission(deletedItem._id, deleteWithReference));
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeletedItem(null);
    setDeleteWithReference(false);
  };

  const onStatusChange = () => {
    setStatus(!status);
  };

  const onChangeColor = e => {
    const result = colorList !== null && colorList.data && colorList.data.filter(colors => colors.title === e.target.value);
    if (Array.isArray(result) && result.length > 0 && result[0]) {
      setCurrentColor(result[0]);
    }
    setCurrentColorError('');
  };

  const onPageChange = async page => {
    const selectedPage = page.selected;
    dispatch(
      getMissionList({
        pageSize,
        pageNumber: selectedPage + 1,
        filterValue: '',
      })
    );
    setPageNumber(selectedPage + 1);
  };

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference);
  };

  const handleOrderChange = (value, orderNumber) => {
    var data = JSON.stringify({
      currentOrderNumber: Number(value),
      previousOrderNumber: Number(orderNumber),
    });

    var config = {
      method: 'patch',
      url: `${baseUrl}/mission/change-mission-order`,
      headers: {
        token: credentials && credentials.credentials && credentials.credentials.data && credentials.credentials.data.token,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data && response.data.status) {
          toast.success(response.data.message);
          dispatch(getMissionList({ pageSize, pageNumber, filterValue: '' }));
        }
      })
      .catch(function (error) {});
  };

  const { moduleNames } = useSelector(user => user.moduleNames);
  const [missionModuleName, setMissionModuleName] = useState('Missions');
  const [spaceName, setSpaceName] = useState('Spaces');

  useEffect(() => {
    if (moduleNames && moduleNames.data && moduleNames.data.stageOne) {
      setMissionModuleName(moduleNames.data.stageOne);
      setSpaceName(moduleNames.data.stageTwo);
    }
  }, [moduleNames]);

  const onClientChange = item => {
    dispatch(getMissionList({ pageSize, pageNumber, filterValue: '' }));
    dispatch(getColorList());
    setSearch('');
  };

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading={missionModuleName}
        isAdd
        onAdd={onAddMissionHAndler}
        isSearch
        searchPlaceholder={`Search ${missionModuleName}`}
        onSearch={onSearchMission}
        searchValue={search}
        onClientChange={onClientChange}
      />
      {currentItem === null && loading ? (
        <AllMissionsSkeleton />
      ) : (
        <Fragment>
          <div className='client__container'>
            <div className='missions_main_content'>
              {missionList !== null && missionList.data && missionList.data.result && missionList.data.result.length > 0 ? (
                missionList.data.result.map((item, index) => (
                  <MissionCard
                    key={index}
                    item={item}
                    loading={loading}
                    currentItem={currentItem}
                    onEdit={onEditHandler}
                    onDelete={onDeleteHandler}
                    onChangeSwitch={enableDisableHandler}
                    totalMissions={missionList.data.result.length}
                    handleOrderChange={handleOrderChange}
                    spaceName={spaceName}
                  />
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== '' ? 'Search result not found' : `You haven't created any ${missionModuleName} yet. Please create one`}
                  </p>
                </div>
              )}
            </div>
          </div>
          {missionList !== null && missionList.data && missionList.data.result && missionList.data.result.length > 0 && missionList.data.totalPageSize > 15 && (
            <Pagination list={missionList} onPageChange={onPageChange} rowsPerPage={pageSize} />
          )}
          <AppModal show={showAddModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>{isUpadate ? `edit ${missionModuleName}` : `Add ${missionModuleName}`}</p>
              <form className='clinic_form_container' onSubmit={onSubmitHandler}>
                <div className='clinic_form_top_container'>
                  <div className='clinic_form_top_left_container'>
                    <TextInput
                      placeholder={`${missionModuleName} name`}
                      src='/images/missions-input.png'
                      onChange={mission => {
                        setMissionNameError('');
                        setMissionName(mission.target.value);
                      }}
                      value={missionName}
                      // maxLength={20}
                      error={missionNameError}
                    />
                    <div className='mission_dropdown'>
                      <div className='text_input_image_container'>
                        <img src='/images/color-input.png' alt='color' className='text_input_image' />
                      </div>
                      <select
                        name='color-select'
                        id='color-select'
                        value={currentColor === 'select-color' ? 'select-color' : currentColor.title}
                        onChange={onChangeColor}
                        className='mission_dropdown_select'
                      >
                        <option value='select-color' disabled>
                          Select color
                        </option>
                        {colorList !== null && colorList.data && colorList.data.length > 0 ? (
                          colorList.data.map((item, index) => {
                            // console.log('item', item)
                            return (
                              <option key={index} value={item.title}>
                                {item.title}
                              </option>
                            );
                          })
                        ) : (
                          <option value='no mission'>No colors found</option>
                        )}
                      </select>
                      <div
                        style={{
                          backgroundColor: currentColor !== 'select-color' && currentColor.primaryColor,
                        }}
                        className='dropdown_selectedColor'
                      />
                    </div>
                    {currentColorError && <p className='input_error_text'>{currentColorError}</p>}

                    <p className='active_status_text'>active status</p>
                    <AppSwitch checked={status} onChange={() => onStatusChange()} />
                    {isUpadate && isUpadate.isLive ? (
                      <div className='mission_card_message'>This mission is active</div>
                    ) : (
                      <>{isUpadate ? <div className='mission_card_message2'>This mission is inactive</div> : null}</>
                    )}
                  </div>

                  <div className='clinic_form_image_upload_container'>
                    <ImageUploader
                      onUpload={onUpload}
                      image={image}
                      setURL={image => setImage(image)}
                      errorMessage={imageError}
                      containerStyles={{ borderRadius: '50%' }}
                      imageTitle='Upload thumbnail'
                      showMessage={true}
                    />
                  </div>
                </div>

                <div className='modal_buttons'>
                  <AppButton
                    title={isUpadate ? 'update' : 'add'}
                    type='submit'
                    icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                    onClick={onSubmitHandler}
                  />
                  <AppButton
                    title='cancel'
                    type='button'
                    buttonContainer={{
                      backgroundColor: 'var(--transparent)',
                      marginLeft: '0.5rem',
                    }}
                    textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                    onClick={onCloseModal}
                  />
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
          <AppModal2 show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete {missionModuleName}</p>

              <p className='form_header_delete_text'>
                Are you sure you want to delete <span>{deletedItem !== null && deletedItem.title}</span>?
              </p>

              {/* <div className='form_header_delete_ref'>
                <p className='form_header_delete_ref_text'>
                  Delete all it's reference
                </p>
                <AppSwitch
                  checked={deleteWithReference}
                  onChange={onDeleteStatusChange}
                />
              </div> */}

              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteMission}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal2>
        </Fragment>
      )}
    </div>
  );
};

export default Mission;
