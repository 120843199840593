import React from 'react'
import SkeletonStyles from './Skeletons.module.css'
import '../../screens/FAQ/FAQ.css'

const FaqSkeleton = () => {
  return (
    <div className='LAB_FAQ_container'>
      {Array(4)
        .fill(null)
        .map((u, i) => {
          return (
            <div className={['LAB_FAQ_card_skeleton'].join(' ')} key={i}>
              <div
                className={[
                  'LAB_FAQ_collapse_header_skeleton',
                  SkeletonStyles.skeleton,
                ].join(' ')}>
                <div
                  className={[
                    'LAB_FAQ_collapse_header_left_skeleton',
                    SkeletonStyles.skeleton,
                  ].join(' ')}></div>
                <div
                  className={[
                    'LAB_FAQ_collapse_header_right_skeleton',
                    SkeletonStyles.skeleton2,
                  ].join(' ')}></div>
              </div>

              <div
                className={[
                  'LAB_FAQ_collpase_content_skeleton',
                  SkeletonStyles.skeleton,
                ].join(' ')}></div>
            </div>
          )
        })}
    </div>
  )
}

export default FaqSkeleton
