import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAboutDataAction } from '../../redux/actions/aboutActions'

import Content from '../../components/Content'
import AboutScreenStyles from './About.module.css'
import AboutSkeleton from '../../components/Skeletons/AboutSkeleton'

const About = () => {
  const dispatch = useDispatch()
  const about = useSelector((state) => state.about)
  const { configurationList, loading: configLoading } = useSelector(
    (config) => config.getConfig
  )

  React.useEffect(() => {
    dispatch(getAboutDataAction('bx'))
  }, [dispatch])

  return (
    <Content currentMenu='about' headerTitle='About Bx'>
      {about && about.loading ? (
        <AboutSkeleton />
      ) : (
        about &&
        about.data &&
        about.data.data && (
          <div className={AboutScreenStyles.about_container}>
            <img
              src={about.data.data.aboutImage}
              alt={about.data.data.type}
              className={AboutScreenStyles.about_container_image}
            />
            <div>
              <h2 className={AboutScreenStyles.about_title}>
                {about.data.data.aboutDescription}
              </h2>
              <h2 className={AboutScreenStyles.about_container_short}>
                {about.data.data.shortDescription}
              </h2>
            </div>
          </div>
        )
      )}

      <div className={AboutScreenStyles.aboutLinks}>
        <Link to='/about-algo' className={AboutScreenStyles.aboutLink}>
          Developer Details
        </Link>
        {configurationList &&
          configurationList.functionSetting &&
          configurationList.functionSetting.privacyPolicy && (
            <Link to='/privacy-policy' className={AboutScreenStyles.aboutLink}>
              Privacy Policy
            </Link>
          )}
      </div>
    </Content>
  )
}

export default About
