import {
	GET_PROPERTIES_REQUEST,
	GET_PROPERTIES_SUCCESS,
	GET_PROPERTIES_FAIL,
	GET_PROPERTIES_RESET,
	GET_MEDIA_DETAILS_REQUEST,
	GET_MEDIA_DETAILS_SUCCESS,
	GET_MEDIA_DETAILS_FAIL,
	GET_MEDIA_DETAILS_RESET,
	GET_PROPERTY_QUESTIONS_REQUEST,
	GET_PROPERTY_QUESTIONS_SUCCESS,
	GET_PROPERTY_QUESTIONS_FAIL,
	GET_PROPERTY_QUESTIONS_RESET,
	GET_QUESTION_PROGRESS_REQUEST,
	GET_QUESTION_PROGRESS_SUCCESS,
	GET_QUESTION_PROGRESS_FAIL,
	GET_QUESTION_PROGRESS_RESET,
	GET_RESPONSE_VIDEO_REQUEST,
	GET_RESPONSE_VIDEO_SUCCESS,
	GET_RESPONSE_VIDEO_FAIL,
	GET_RESPONSE_VIDEO_RESET
} from '../constants/propertyConstants';

export const allPropertiesReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROPERTIES_REQUEST:
			return { loading: true };

		case GET_PROPERTIES_SUCCESS:
			return { properties: action.payload, loading: false };

		case GET_PROPERTIES_FAIL:
			return { loading: false, error: action.payload };

		case GET_PROPERTIES_RESET:
			return {};

		default:
			return state;
	}
};

export const getMediaDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_MEDIA_DETAILS_REQUEST:
			return { loading: true };

		case GET_MEDIA_DETAILS_SUCCESS:
			return { medias: action.payload, loading: false };

		case GET_MEDIA_DETAILS_FAIL:
			return { loading: false, error: action.payload };

		case GET_MEDIA_DETAILS_RESET:
			return {};

		default:
			return state;
	}
};

export const getPropertyQuestionsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROPERTY_QUESTIONS_REQUEST:
			return { loading: true };

		case GET_PROPERTY_QUESTIONS_SUCCESS:
			return { questions: action.payload, loading: false };

		case GET_PROPERTY_QUESTIONS_FAIL:
			return { loading: false, error: action.payload };

		case GET_PROPERTY_QUESTIONS_RESET:
			return {};

		default:
			return state;
	}
};

export const getQuestionProgressReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_QUESTION_PROGRESS_REQUEST:
			return { loading: true };

		case GET_QUESTION_PROGRESS_SUCCESS:
			return { progress: action.payload, loading: false };

		case GET_QUESTION_PROGRESS_FAIL:
			return { loading: false, error: action.payload };

		case GET_QUESTION_PROGRESS_RESET:
			return {};

		default:
			return state;
	}
};

export const getResponseVideosReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_RESPONSE_VIDEO_REQUEST:
			return { loading: true };

		case GET_RESPONSE_VIDEO_SUCCESS:
			return { videos: action.payload, loading: false };

		case GET_RESPONSE_VIDEO_FAIL:
			return { loading: false, error: action.payload };

		case GET_RESPONSE_VIDEO_RESET:
			return {};

		default:
			return state;
	}
};
