import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'aap-loader',
    initialState: {
        isLoading: false
    },
    reducers: {
        onChnageLoading: (loading, action) => {
            loading.isLoading = action.payload;
        }
    }
})

const { onChnageLoading } = slice.actions
export default slice.reducer

export const setAppLoading = data => apiCallBegan({ data, onChnageLoading: onChnageLoading.type, })