import React from 'react';

const ChartSkeleton = () => {
	const count = [ 7, 2, 5, 8, 9, 4, 1, 10, 11 ];

	return (
		<div className='chartContainer'>
			<div className='chart'>
				{count &&
					count.map((item) => (
						<div className='chart__stack' key={item}>
							<div className='chart__bar'>
								<div className='incomplete_stack_sleleton2 skeleton' style={{ height: `${item + 10}vh` }}>
									<div className='complete_stack_sleleton2' style={{ height: `${item + 3}vh` }} />
								</div>
							</div>
							<div className='chart__bar_label_skeleton2 skeleton' />
						</div>
					))}
			</div>
			<div className='date_container'>
				<div className='flex'>
					<div className='date__input_skeleton2 skeleton' />
					<div className='date__input_skeleton2 skeleton' />
					<div className='date_input_filter_container_btn_skeleton skeleton' />
				</div>
			</div>
			<div id='ticks'>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
			</div>
		</div>
	);
};

export default ChartSkeleton;
