import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'add-coupon',
  initialState: {
    addCouponResponse: null,
    loading: false,
  },
  reducers: {
    requested: (Coupon) => {
      Coupon.loading = true
    },
    success: (Coupon, action) => {
      Coupon.addCouponResponse = action.payload
      Coupon.loading = false
    },
    failed: (Coupon, action) => {
      Coupon.addCouponResponse = action.payload
      Coupon.loading = false
    },
    reset: (Coupon, action) => {
      Coupon.addCouponResponse = action.payload
      Coupon.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addCoupon = (data) =>
  apiCallBegan({
    url: `payment/add-coupon`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearAddCouponResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
