import React, {
  useRef,
  useState, useLayoutEffect
} from 'react'
import styles from '../OnBoard/Register.module.css'
import { gsap } from 'gsap'
import { validateEmail } from '../../../utils/validators'
import { useHistory } from 'react-router-dom'
import Support from '../../../components/Support'
const Register = ({ onPageChange, data, isback }) => {
  const [activeFocus, setActiveFocus] = useState(null)
  const [isActive, setIsActive] = useState(
    data && data.clientType ? data.clientType : null
  )
  const [userName, setUserName] = useState({
    value: data && data.userName ? data.userName : '',
    error: '',
  })
  const [userMobile, setUserMobile] = useState({
    value: data && data.userMobile ? data.userMobile : '',
    error: '',
  })
  const [userEmail, setUserEmail] = useState({
    value: data && data.userEmail ? data.userEmail : '',
    error: '',
  })
  const [allchecked, setAllChecked] = useState(true);
  // userEmail: userEmail.value,
  // userMobile: userMobile.value,
  // userName: userName.value,
  // clientType: isActive,

  const history = useHistory()

  const container = useRef(null)
  const heading = useRef(null)
  const subheading = useRef(null)
  const paragraph = useRef(null)
  const desc = useRef(null)
  const cardContainer = useRef(null)
  const bottomContainer = useRef(null)
  const input1 = useRef(null)
  const input2 = useRef(null)
  const input3 = useRef(null)

  useLayoutEffect(() => {
    // gsap.fromTo(container.current, { opacity: 0 }, { opacity: 1 })

    let isSubscribed = true

    if (isSubscribed) {
      gsap.set(container.current, { opacity: 0, overflowX: 'hidden' })

      let timeline = gsap.timeline({ defaults: { opacity: 0 } })

      timeline
        .to(container.current, { opacity: 1 })
        .from(heading.current, {
          xPercent: -100,
          ease: 'power3.out',
          duration: 0.5,
        })
        .from(subheading.current, {
          xPercent: -100,
          ease: 'power3.out',
          duration: 0.5,
        })
        .from(paragraph.current, {
          xPercent: -100,
          ease: 'power3.out',
          duration: 0.5,
        })
        .from(desc.current, {
          xPercent: -100,
          ease: 'power3.out',
          duration: 1,
        })
        .from(
          cardContainer.current,
          {
            xPercent: 100,
            ease: 'power3.out',
            duration: 1,
          },
          '<'
        )
    }

    return () => (isSubscribed = false)
  }, [])

  const handleChange = () => {
    setAllChecked(prevVal => !prevVal)
  }
  useLayoutEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      if (isback) {
        gsap.fromTo(
          bottomContainer.current,
          {
            opacity: 0,
            yPercent: 100,
            ease: 'back',
            duration: 0.5,
            display: 'none',
            delay: 2,
          },
          {
            opacity: 1,
            yPercent: 0,
            ease: 'back',
            display: 'block',
            duration: 0.5,
            delay: 2.5,
          }
        )
        return
      }
      gsap.fromTo(
        bottomContainer.current,
        {
          opacity: 0,
          yPercent: 100,
          ease: 'back',
          duration: 0.5,
          display: 'none',
          delay: 2,
        },
        {
          opacity: 1,
          yPercent: 0,
          ease: 'back',
          display: 'block',
          duration: 0.5,
          delay: 2,
        }
      )
    }

    return () => (isSubscribed = false)
  }, [isActive, isback])

  useLayoutEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      if (activeFocus === 'Name') {
        gsap.to(input1.current, {
          border: '1px solid white',
          ease: 'power3.out',
          duration: 0.5,
        })
      } else if (activeFocus === 'Phone') {
        gsap.to(input2.current, {
          border: '1px solid white',
          ease: 'power3.out',
          duration: 0.5,
        })
      } else if (activeFocus === 'Email') {
        gsap.to(input3.current, {
          border: '1px solid white',
          ease: 'power3.out',
          duration: 0.5,
        })
      }
      if (activeFocus === null) {
        gsap.to(input1.current, {
          border: 'none',
          ease: 'power3.out',
          duration: 0.5,
        })
        gsap.to(input2.current, {
          border: 'none',
          ease: 'power3.out',
          duration: 0.5,
        })
        gsap.to(input3.current, {
          border: 'none',
          ease: 'power3.out',
          duration: 0.5,
        })
      }
    }

    return () => (isSubscribed = false)
  }, [activeFocus])

  const cardActiveHandler = (user) => {
    setIsActive(user)
  }
  const handleCheckMobile = async (e) => {
    e.preventDefault()
    let validate = true

    // changed it after testing

    // onPageChange(2)

    if (userName.value === '' || userName.value.trim() === '') {
      setUserName({
        ...userName,
        error: `Please enter ${isActive} name`,
      })
    } else {
      if (userMobile.value === '' || userMobile.value.trim() === '') {
        setUserMobile({
          ...userMobile,
          error: 'Please enter your mobile number',
        })
      } else if (userMobile.value.length !== 10) {
        setUserMobile({
          ...userMobile,
          error: 'Mobile number must have 10 digits',
        })
      }
      //  else if (!validatePhone(userMobile.value)) {
      //   setUserMobile({
      //     ...userMobile,
      //     error: 'Mobile number should be in digits',
      //   })
      // }
      else {
        if (userEmail.value === '' || userEmail.value.trim() === '') {
          setUserEmail({
            ...userEmail,
            error: 'Please enter email',
          })
        } else if (!validateEmail(userEmail.value)) {
          setUserEmail({
            ...userEmail,
            error: 'Please enter valid email',
          })
        } else {
          onPageChange(2, {
            userEmail: userEmail.value,
            userMobile: userMobile.value,
            userName: userName.value,
            clientType: isActive,
          })
        }
      }
    }
  }
  const activeFocusHandler = (type) => {
    setActiveFocus(type)
  }

  const logoutHandler = () => {
    history.push('/')
  }

  return (
    <div
      style={{
        backgroundImage:
          'linear-gradient(to bottom, rgba(11, 26, 91, .58), rgba(11, 26, 91, .58)), url(/assets/child_background.png)',
      }}
      className={styles.container}
      ref={container}>
      <div className={styles.topContainer}>
        <div className={styles.headerContainer}>
          <h1 className={styles.heading} ref={heading}>
            Welcome to your Bx interactive learning experience
          </h1>
          <div className={styles.logout} onClick={logoutHandler}>
            <svg className={styles.logouticon} style={{ cursor: 'pointer' }}>
              <use xlinkHref={`/images/sprite.svg#icon-logout3`} />
            </svg>
          </div>
        </div>

        <div className={styles.subheading} ref={subheading}>
          First step towards understanding , learning and empowering through Bx
        </div>
        {/* <div className={styles.paragraph} ref={paragraph}>
          Welcome to the one stop solution for everything you will ever need to
          learn quick with fun and in intearctive way
        </div> */}
        {/* <div className={styles.desc} ref={desc}>
          Register your Bx account by selecting from the following:
        </div> */}

      </div>

      {isActive && (
        <div className={[styles.bottomContainer]} ref={bottomContainer}>
          <div className={styles.bottom_title}>
            {isActive ? (isActive === 'user' ? 'Account' : isActive) : 'User'}{' '}
            INFORMATION
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>
                {isActive
                  ? isActive === 'user'
                    ? ''
                    : isActive
                  : 'User'}{' '}
                Name
              </label>
              <div className={styles.input} ref={input1}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-person2`} />
                </svg>
                <input
                  type='text'
                  placeholder='Ex. John Doe'
                  name='Name'
                  value={userName.value}
                  onChange={(e) =>
                    setUserName({
                      value: e.target.value,
                      error: '',
                    })
                  }
                  onFocus={activeFocusHandler.bind(this, 'Name')}
                  onBlur={activeFocusHandler.bind(this, null)}
                  className={styles.inputText}
                />
              </div>
              {userName.error && (
                <p className={styles.login_container_loginBox_inputBoxError}>
                  {userName.error}
                </p>
              )}
            </div>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>Phone Number</label>
              <div className={styles.input} ref={input2}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-phone4`} />
                </svg>
                <input
                  type='number'
                  placeholder='Ex. 123 456 7890'
                  name='Phone'
                  maxLength={10}
                  value={userMobile.value}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setUserMobile({
                        value: e.target.value,
                        error: '',
                      })
                    }
                  }}
                  onFocus={activeFocusHandler.bind(this, 'Phone')}
                  onBlur={activeFocusHandler.bind(this, null)}
                  className={styles.inputText}
                />
              </div>
              {userMobile.error && (
                <p className={styles.login_container_loginBox_inputBoxError}>
                  {userMobile.error}
                </p>
              )}
            </div>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>Email</label>
              <div className={styles.input} ref={input3}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-email`} />
                </svg>
                <input
                  type='text'
                  placeholder='Ex. abc@xyz.com'
                  name='Email'
                  value={userEmail.value}
                  onChange={(e) =>
                    setUserEmail({
                      value: e.target.value,
                      error: '',
                    })
                  }
                  onFocus={activeFocusHandler.bind(this, 'Email')}
                  onBlur={activeFocusHandler.bind(this, null)}
                  className={styles.inputText}
                />
              </div>
              {userEmail.error && (
                <p className={styles.login_container_loginBox_inputBoxError}>
                  {userEmail.error}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputSubContainerCheckbox}>
            <button onClick={handleChange} className='modalAnswers_btn'>
              <svg className='modalAnswers_icon'>
                <use
                  xlinkHref={`/assets/sprite.svg#icon-${allchecked ? 'check' : 'check-blank'}`}
                />
              </svg>
              <span> <label className={styles.label}>
                By registering, I agree to receive text messages in relation to my account login. I understand that standard text and data rates may apply.
              </label> </span>
            </button>

          </div>
          <div className={styles.buttonContainer}>
            {allchecked ? (<button
              type='submit'
              className={styles.button}
              onClick={handleCheckMobile}
            >
              <p className={styles.buttonText}>NEXT</p>
              <div className={styles.line}></div>
            </button>) : (
              <button
                className={styles.button_unselected}
              >
                <p className={styles.buttonText}>NEXT</p>
                <div className={styles.line}></div>
              </button>)}
          </div>
        </div>
      )}
      <Support />
    </div>
  )
}

export default Register


// Cards 

{/* <div className={styles.cardContainer} ref={cardContainer}>
          <div
            className={[
              styles.card,
              `${isActive === 'user' ? styles.active : null}`,
            ].join(' ')}
            onClick={cardActiveHandler.bind(this, 'user')}>
            <p className={styles.p1}>I am a</p>
            <p className={styles.p2}>USER / LEARNER</p>
            {/* <p className={styles.p3}>LEARNER</p> */}
// </div>
// <div className={styles.comingsoonCard}>
//   <div
//     className={[
//       styles.card,
//       `${isActive === 'clinic' ? styles.active : null}`,
//       styles.disable,
//     ].join(' ')}
//     // onClick={cardActiveHandler.bind(this, 'clinic')}
//   >
//     <p className={[styles.p1, styles.disable2].join(' ')}>I am a</p>
//     <p className={[styles.p2, styles.disable2].join(' ')}>
//       professional / THERAPIST
//     </p>
//   </div>
//   <div className={styles.comingsoon}>Coming Soon...</div>
// </div>
{/* <div
            className={[
              styles.card,
              `${isActive === 'lab' ? styles.active : null}`,
            ].join(' ')}
            onClick={cardActiveHandler.bind(this, 'lab')}>
            <p className={styles.p1}>I am a</p>
            <p className={styles.p2}>specialty group / LAB</p>
          </div> */}
// </div> */}