import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'enable-disable-success-videos',
  initialState: {
    enableSuccessVideo: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.enableSuccessVideo = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.enableSuccessVideo = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.enableSuccessVideo = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const enableSuccessVideos = (data) =>
  apiCallBegan({
    url: `general/enable-disable-general-video`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearEnableSuccessVideosResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
