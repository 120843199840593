import React from 'react'
import styles from './CheckBox.module.css'
const CheckBox = ({ onClick = () => {}, isSelected = false }) => {
  return (
    <div className={styles.checkBox} onClick={onClick}>
      {isSelected ? (
        <svg className={styles.icon}>
          <use xlinkHref={`/images/sprite.svg#icon-checkmark`} />
        </svg>
      ) : null}
    </div>
  )
}

export default CheckBox
