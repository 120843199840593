export const GET_PRETEST_REPORT_REQUEST = 'GET_PRETEST_REPORT_REQUEST'
export const GET_PRETEST_REPORT_SUCCESS = 'GET_PRETEST_REPORT_SUCCESS'
export const GET_PRETEST_REPORT_FAIL = 'GET_PRETEST_REPORT_FAIL'
export const GET_PRETEST_REPORT_RESET = 'GET_PRETEST_REPORT_RESET'

export const GET_USER_INFO_BY_TOKEN_REQUEST = 'GET_USER_INFO_BY_TOKEN_REQUEST'
export const GET_USER_INFO_BY_TOKEN_SUCCESS = 'GET_USER_INFO_BY_TOKEN_SUCCESS'
export const GET_USER_INFO_BY_TOKEN_FAIL = 'GET_USER_INFO_BY_TOKEN_FAIL'
export const GET_USER_INFO_BY_TOKEN_RESET = 'GET_USER_INFO_BY_TOKEN_RESET'

export const UPDATE_USERS_PROFILE_REQUEST = 'UPDATE_USERS_PROFILE_REQUEST'
export const UPDATE_USERS_PROFILE_SUCCESS = 'UPDATE_USERS_PROFILE_SUCCESS'
export const UPDATE_USERS_PROFILE_FAIL = 'UPDATE_USERS_PROFILE_FAIL'
export const UPDATE_USERS_PROFILE_RESET = 'UPDATE_USERS_PROFILE_RESET'
