import React from 'react';

import './Skeleton.css';
const AllQuestionsSkeleton = () => {
	const count = [ 1, 2, 3, 4 ];
	return (
		<div className='client__container'>
			<div className='missions_main_content'>
				{count &&
					count.map((item) => (
						<div key={item} className='client_card_skeleton' style={{ width: '35rem' }}>
							<div className='flex' style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
								<div className='flex' style={{ alignItems: 'flex-start' }}>
									<div>
										<div className='client_card_heading_skeleton skeleton' />
										<div className='client_card_spaces_skeleton skeleton' />
									</div>
								</div>
								<div className='flex'>
									<div className='client_card_edit_skeleton skeleton' />
									<div className='clinet_card_delete_skeleton skeleton' />
								</div>
							</div>
							<div className='clinet_card_videos'>
								<div className='question_answer_skeleton skeleton' style={{ width: '18rem' }} />
								<div className='question_answer_skeleton skeleton' style={{ width: '10rem' }} />
								<div className='question_answer_skeleton skeleton' style={{ width: '13rem' }} />
								<div className='question_answer_skeleton skeleton' style={{ width: '5rem' }} />
								<div className='question_answer_skeleton skeleton' style={{ width: '8rem' }} />
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default AllQuestionsSkeleton;
