import React, { useState, useEffect } from 'react'
import Styles from './AddResponseVideo.module.css'
import AppButton from '../../../../../components/forms/AppButton'
import { useSelector, useDispatch } from 'react-redux'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import {
  questionResponseContent,
  clearQuestionResponseContent,
} from '../../../../../store/questions/questionResponseContent'
import { toast } from 'react-toastify'

const AddresponseVideo = ({ onClose, questionId, currentItem, reRender }) => {
  const [successVideoFilter, setSuccessVideoFilter] = useState(true)
  const [failVideoFilter, setfailVideoFilter] = useState(true)

  const [successSearch, setSuccessSearch] = useState('')
  const [failSearch, setFailSearch] = useState('')

  const [successSelected, setSuccessSelected] = useState()
  const [successSelectedError, setSuccessSelectedError] = useState('')
  const [failSelected, setFailSelected] = useState()
  const [failSelectedError, setfailSelectedError] = useState('')
  const dispatch = useDispatch()

  const { videoId } = useParams()

  useEffect(() => {
    if (currentItem) {
      setSuccessSelected(currentItem.successResponseID)
      setFailSelected(currentItem.failedResponseID)
    }
  }, [currentItem, reRender])

  const { responseContentList } = useSelector(
    (state) => state.getResponseContent
  )

  const { failResponseContentList } = useSelector(
    (state) => state.getFailResponseContent
  )

  const { message } = useSelector((state) => state.questionResponseContent)

  let successData
  let failData
  let title

  if (currentItem) {
    title = currentItem.question
  }

  if (
    responseContentList &&
    responseContentList.data &&
    responseContentList.data.result &&
    successVideoFilter
  ) {
    successData = responseContentList.data.result.filter(
      (item) =>
        item.responseFileType === 'video' && item.title.includes(successSearch)
    )
  } else if (
    !successVideoFilter &&
    responseContentList &&
    responseContentList.data &&
    responseContentList.data.result
  ) {
    successData = responseContentList.data.result.filter(
      (item) =>
        item.responseFileType === 'doc' && item.title.includes(successSearch)
    )
  } else {
    successData = []
  }

  if (
    failResponseContentList &&
    failResponseContentList.data &&
    failResponseContentList.data.result &&
    failVideoFilter
  ) {
    failData = failResponseContentList.data.result.filter(
      (item) =>
        item.responseFileType === 'video' && item.title.includes(failSearch)
    )
  } else if (
    !failVideoFilter &&
    failResponseContentList &&
    failResponseContentList.data &&
    failResponseContentList.data.result
  ) {
    failData = failResponseContentList.data.result.filter(
      (item) =>
        item.responseFileType === 'doc' && item.title.includes(failSearch)
    )
  } else {
    failData = []
  }

  useEffect(() => {
    if (message !== null) {
      if (message.status) {
        toast.success(message.message)
        dispatch(clearQuestionResponseContent())
        setFailSearch('')
        setSuccessSearch('')
        setSuccessSelected(null)
        setFailSelected(null)
        onClose()
      } else {
        toast.error(message.message)
        setSuccessSearch('')
        setFailSearch('')
        setSuccessSelected()
        setFailSelected()
        dispatch(clearQuestionResponseContent())
      }
    }
  }, [message, dispatch, onClose])

  const successFilterHandler = () => {
    setSuccessVideoFilter(!successVideoFilter)
  }
  const failFilterHandler = () => {
    setfailVideoFilter(!failVideoFilter)
  }

  const onSearchSuccess = (event) => {
    setSuccessSearch(event.target.value)
  }
  const onFailSuccess = (event) => {
    setFailSearch(event.target.value)
  }

  const selectHandler = (item) => {
    setSuccessSelected(item._id)
  }

  const failHandler = (item) => {
    setFailSelected(item._id)
  }

  const submitHandler = async (event) => {
    event.preventDefault()

    if (!successSelected) {
      setSuccessSelectedError('Please select success content')
      return
    } else {
      setSuccessSelectedError('')
    }

    if (!failSelected) {
      setfailSelectedError('Please select fail content')
      return
    } else {
      setfailSelectedError('')
    }

    const data = {
      media_id: videoId,
      questions_id: questionId,
      successResponseID: successSelected,
      failedResponseID: failSelected,
    }

    await dispatch(questionResponseContent(data))
  }

  const closeModalHandler = () => {
    setFailSearch('')
    setSuccessSearch('')
    setSuccessSelectedError('')
    setfailSelectedError('')
    setSuccessSelected(null)
    setFailSelected(null)
    onClose()
  }

  return (
    <div className={Styles.addResponseVideo}>
      <div className={Styles.title}>
        Change Response Content for {title} question
      </div>
      <div className={Styles.container}>
        <div className={Styles.container_left}>
          <div className={Styles.container_left_header}>
            <div className={Styles.text1}>On Success</div>
            <div className='search_container'>
              <img
                className='howto_search_logo'
                alt='search'
                src='/images/search.png'
              />
              <input
                className='background_container_header_input mt-left'
                placeholder='Search contents'
                onChange={onSearchSuccess}
                value={successSearch}
              />
            </div>
            <div className={Styles.filter} onClick={successFilterHandler}>
              <div className={Styles.text2}>
                {successVideoFilter ? 'Video' : 'Pdf'}
              </div>
              <div>
                <svg className={Styles.container_icon_arrow}>
                  <use xlinkHref={`/images/sprite.svg#icon-double-arrow`} />
                </svg>
              </div>
            </div>
          </div>
          <div className={Styles.container_left_content}>
            {successData.map((item) => {
              return (
                <div className={Styles.boxContainer} key={item._id}>
                  <div className={Styles.box}>
                    {item.responseFileType === 'video' ? (
                      <video width='100%' height='110' controls>
                        <source src={item.videoURL} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div className={Styles.pdf}>
                        <a
                          href={item.videoURL}
                          target='_blank'
                          rel='noreferrer'>
                          <img
                            src='/images/pdf.png'
                            alt='pdf'
                            className={Styles.img}
                          />
                        </a>
                      </div>
                    )}

                    <div className={Styles.box_bottom}>
                      <div className={Styles.box_title}> {item.title}</div>
                    </div>
                  </div>
                  <div className={Styles.selected}>
                    {successSelected === item._id ? (
                      <div
                        className={Styles.activeCustomButton}
                        onClick={() => {
                          selectHandler(item)
                        }}>
                        Selected
                      </div>
                    ) : (
                      <div
                        className={Styles.customButton}
                        onClick={() => {
                          selectHandler(item)
                        }}>
                        Not Selected
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          {successSelectedError !== '' ? (
            <p className={Styles.danger}>{successSelectedError}</p>
          ) : null}
        </div>

        <div className={Styles.container_right}>
          <div className={Styles.container_right_header}>
            <div className={Styles.text1}>On fail</div>
            <div className='search_container'>
              <img
                className='howto_search_logo'
                alt='search'
                src='/images/search.png'
              />
              <input
                className='background_container_header_input mt-left'
                placeholder='Search contents'
                onChange={onFailSuccess}
                value={failSearch}
              />
            </div>
            <div className={Styles.filter} onClick={failFilterHandler}>
              <div className={Styles.text2}>
                {failVideoFilter ? 'Video' : 'Pdf'}{' '}
              </div>
              <div>
                <svg className={Styles.container_icon_arrow}>
                  <use xlinkHref={`/images/sprite.svg#icon-double-arrow`} />
                </svg>
              </div>
            </div>
          </div>
          <div className={Styles.container_right_content}>
            {failData.map((item) => {
              return (
                <div className={Styles.boxContainer} key={item._id}>
                  <div className={Styles.box}>
                    {item.responseFileType === 'video' ? (
                      <video width='100%' height='110' controls>
                        <source src={item.videoURL} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div className={Styles.pdf}>
                        <a
                          href={item.videoURL}
                          target='_blank'
                          rel='noreferrer'>
                          <img
                            src='/images/pdf.png'
                            alt='pdf'
                            className={Styles.img}
                          />
                        </a>
                      </div>
                    )}

                    <div className={Styles.box_bottom}>
                      <div className={Styles.box_title}> {item.title}</div>
                    </div>
                  </div>
                  <div className={Styles.selected}>
                    {failSelected === item._id ? (
                      <div
                        className={Styles.activeCustomButton}
                        onClick={() => {
                          failHandler(item)
                        }}>
                        Selected
                      </div>
                    ) : (
                      <div
                        className={Styles.customButton}
                        onClick={() => {
                          failHandler(item)
                        }}>
                        Not Selected
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          {failSelectedError !== '' ? (
            <p className={Styles.danger}>{failSelectedError}</p>
          ) : null}
        </div>
      </div>
      <div className='modal_buttons'>
        <AppButton
          title='add'
          type='submit'
          icon={
            <img
              alt='Add'
              src='/images/add-clinic.png'
              className='app_button_image'
            />
          }
          onClick={submitHandler}
        />
        <AppButton
          title='cancel'
          type='button'
          buttonContainer={{
            backgroundColor: 'var(--transparent)',
            marginLeft: '0.5rem',
          }}
          textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
          onClick={closeModalHandler}
        />
      </div>
    </div>
  )
}

export default AddresponseVideo
