import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'

import AppHeader from '../../../../components/AppHeader'
import ProcessCard from '../../../../components/Cards/ReportsCard/ProcessCard'
import AllMissionsSkeleton from '../../../../components/Skeletons/AllMissionsSkeleton'

import { getProcessChartReport } from '../../../../store/reports/processChartReport'
import { getProcessReport } from '../../../../store/reports/processCount'

import { options } from './chartOptions'
import ReportToggle from './ReportToggle'
import { useHistory } from 'react-router'

const ProcessReport = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { processReport, loading } = useSelector(
    (report) => report.processReport
  )
  const { processChartReport } = useSelector(
    (report) => report.processChartReport
  )

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const [ischarts, setISCharts] = useState(false)

  useEffect(() => {
    dispatch(getProcessReport())
    dispatch(getProcessChartReport())
  }, [dispatch])

  const onClientChange = (item) => {
    if (item && item.clientType === 'lab') {
      history.push('/')
    } else {
      dispatch(getProcessReport())
      dispatch(getProcessChartReport())
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Progress Reports' onClientChange={onClientChange} />
      <ReportToggle onChange={(status) => setISCharts(status)} />
      <div className='client__container'>
        {ischarts ? (
          processReport !== null &&
          processReport.data &&
          processReport.data.length > 0 ? (
            processChartReport &&
            processChartReport.data && (
              <Bar
                data={processChartReport.data}
                options={options}
                className='report_chart_container'
              />
            )
          ) : (
            <div className='data_not_found_container'>
              <p className='data_not_found_message'>
                {processReport !== null && processReport.message}
              </p>
            </div>
          )
        ) : (
          <div className='missions_main_content'>
            {loading ? (
              <AllMissionsSkeleton />
            ) : processReport !== null &&
              processReport.data &&
              processReport.data.length > 0 ? (
              processReport.data.map((item, index) => (
                <ProcessCard item={item} key={index} index={index} />
              ))
            ) : (
              <div className='data_not_found_container'>
                <p className='data_not_found_message'>
                  {processReport !== null && processReport.message}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProcessReport
