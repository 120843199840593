import React from 'react';
import classes from './Components.module.css';

const Button = ({ icon, type, onClick, disabled, children, style, active }) => {
  return (
    <button type={type} className={active ? classes.button_active : classes.button} style={style} onClick={onClick} disabled={disabled || active}>
      {children}

      {icon && (
        <svg className={classes.button_icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
        </svg>
      )}
    </button>
  );
};

export default Button;
