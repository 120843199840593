import React from 'react'
import './loader.css'

const AppLoader = ({ loader = false }) => {
  if (!loader) return null
  return (
    <div className='app_loader'>
      <div className='app_loader_subcontainer'>
        <img src='/images/loader.gif' className='loader' alt='loader' />
      </div>
    </div>
  )
}

export default AppLoader
