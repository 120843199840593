import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { logout } from '../../redux/actions/userActions';
import { logoutUser } from '../../../store/login'

import './SideBar.css'
import {
  GET_TILES_RESET,
  GET_USER_DETAILS_BY_TOKEN_RESET,
} from '../../redux/constants/userConstants'
import Modal from '../Modal'
import { GET_MISSIONS_RESET } from '../../redux/constants/missionConstants'
import { GET_SPACES_REQUEST } from '../../redux/constants/spaceConstants'
import { GET_PROPERTIES_RESET } from '../../redux/constants/propertyConstants'
import Avatar, { Piece } from 'avataaars'

const Sidebar = ({ currentMenu }) => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  const { credentials, userInfo } = useSelector((user) => user.credentials)
  const userDetails = useSelector((state) => state.userDetails)
  const { configurationList, loading: configLoading } = useSelector(
    (config) => config.getConfig
  )

  const handleLogout = () => {
    // dispatch(logout());
    dispatch(logoutUser())
    dispatch({ type: GET_USER_DETAILS_BY_TOKEN_RESET })
    dispatch({ type: GET_TILES_RESET })
    dispatch({ type: GET_MISSIONS_RESET })
    dispatch({ type: GET_SPACES_REQUEST })
    dispatch({ type: GET_PROPERTIES_RESET })
  }

  return (
    <Fragment>
      <nav className='navigation'>
        <ul className='navigation__list'>
          <li className='navigation__list--link'>
            <img
              src='/assets/bx_black_logo.png'
              alt='logo'
              className='navigation__logo'
            />
          </li>
          <li className='navigation__list--item'>
            <Link
              to='/'
              className={
                currentMenu === 'home'
                  ? 'navigation__list--link-active'
                  : 'navigation__list--link'
              }>
              <svg
                className={
                  currentMenu === 'home'
                    ? 'navigation__icon-active'
                    : 'navigation__icon'
                }>
                <use xlinkHref={`/assets/sprite.svg#icon-home`} />
              </svg>
              <span>Home</span>
            </Link>
          </li>
          {userInfo.client.clientType !== 'lab' && (
            <li className='navigation__list--item'>
              <Link
                to='/student'
                className={
                  currentMenu === 'student'
                    ? 'navigation__list--link-active'
                    : 'navigation__list--link'
                }>
                <svg
                  className={
                    currentMenu === 'student'
                      ? 'navigation__icon-active'
                      : 'navigation__icon'
                  }>
                  <use xlinkHref={`/assets/sprite.svg#icon-users`} />
                </svg>
                <span>students</span>
              </Link>
            </li>
          )}

          {userInfo.client.clientType === 'clinic' &&
            configurationList &&
            configurationList.functionSetting &&
            configurationList.functionSetting.performanceReport && (
              <li className='navigation__list--item'>
                <Link
                  to='/performance'
                  className={
                    currentMenu === 'performance'
                      ? 'navigation__list--link-active'
                      : 'navigation__list--link'
                  }>
                  <svg
                    className={
                      currentMenu === 'performance'
                        ? 'navigation__icon-active'
                        : 'navigation__icon'
                    }>
                    {currentMenu === 'performance' ? (
                      <use xlinkHref={`/assets/sprite.svg#icon-performance2`} />
                    ) : (
                      <use xlinkHref={`/assets/sprite.svg#icon-performance`} />
                    )}
                  </svg>
                  <span>Performance</span>
                </Link>
              </li>
            )}

          {/* <li className='navigation__list--item'>
						<Link
							to='/stats'
							className={currentMenu === 'stats' ? 'navigation__list--link-active' : 'navigation__list--link'}>
							<svg className={currentMenu === 'stats' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-chart`} />
							</svg>
							<span>Stats</span>
						</Link>
					</li>
					<li className='navigation__list--item'>
						<Link
							to='/users'
							className={currentMenu === 'users' ? 'navigation__list--link-active' : 'navigation__list--link'}>
							<svg className={currentMenu === 'users' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-users`} />
							</svg>
							<span>Users</span>
						</Link>
					</li>
					<li className='navigation__list--item'>
						<Link
							to='/settings'
							className={currentMenu === 'settings' ? 'navigation__list--link-active' : 'navigation__list--link'}>
							<svg className={currentMenu === 'settings' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-settings`} />
							</svg>
							<span>Settings</span>
						</Link>
					</li> */}
          {configurationList &&
            configurationList.functionSetting &&
            configurationList.functionSetting.aboutUs && (
              <li className='navigation__list--item'>
                <Link
                  to='/about-bx'
                  className={
                    currentMenu === 'about'
                      ? 'navigation__list--link-active'
                      : 'navigation__list--link'
                  }>
                  <img
                    src='/assets/bx2.png'
                    alt='About'
                    className='navigation__icon_image'
                  />

                  <span>About</span>
                </Link>
              </li>
            )}

          {userInfo.client.clientType === 'clinic' &&
            configurationList &&
            configurationList.functionSetting &&
            configurationList.functionSetting.leaderBoard && (
              <li className='navigation__list--item'>
                <Link
                  to='/leaderboard'
                  className={
                    currentMenu === 'leaderboard'
                      ? 'navigation__list--link-active'
                      : 'navigation__list--link'
                  }>
                  <svg
                    className={
                      currentMenu === 'leaderboard'
                        ? 'navigation__icon-active'
                        : 'navigation__icon'
                    }>
                    <use xlinkHref={`/assets/sprite.svg#icon-badge`} />
                  </svg>
                  <span>Leaderboard</span>
                </Link>
              </li>
            )}

          {userInfo.client &&
            userInfo.client.clientType !== 'lab' &&
            configurationList &&
            configurationList.functionSetting &&
            configurationList.functionSetting.faq && (
              <li className='navigation__list--item'>
                <Link
                  to='/lab-FAQ'
                  className={
                    currentMenu === 'lab-FAQ'
                      ? 'navigation__list--link-active'
                      : 'navigation__list--link'
                  }>
                  {currentMenu === 'lab-FAQ' ? (
                    <svg
                      className={
                        currentMenu === 'lab-FAQ'
                          ? 'navigation__icon-active'
                          : 'navigation__icon'
                      }>
                      <use xlinkHref={`/assets/sprite.svg#icon-FAQ2`} />
                    </svg>
                  ) : (
                    <svg
                      className={
                        currentMenu === 'lab-FAQ'
                          ? 'navigation__icon-active'
                          : 'navigation__icon'
                      }>
                      <use xlinkHref={`/assets/sprite.svg#icon-FAQ`} />
                    </svg>
                  )}

                  <span>FAQs</span>
                </Link>
              </li>
            )}
        </ul>
        <ul className='navigation__list'>
          <li className='navigation__list--item'>
            <Link
              to='/my-profile'
              className={
                currentMenu === 'my-profile'
                  ? 'navigation__list--link-active'
                  : 'navigation__list--link'
              }>
              {/* <Avatar
                style={{ width: '2rem', height: '2rem' }}
                avatarStyle='Transparent'
                topType='LongHairCurvy'
                accessoriesType='Blank'
                hairColor='Black'
                facialHairType='Blank'
                clotheType='BlazerSweater'
                eyeType='Happy'
                eyebrowType='Default'
                mouthType='Default'
                skinColor='Tanned'
              /> */}
              {/* <img
                src='/assets/my-profile.png'
                alt='About'
                className='navigation__icon_image'
              /> */}

              {currentMenu === 'my-profile' ? (
                <svg
                  className={
                    currentMenu === 'profile'
                      ? 'navigation__icon-active'
                      : 'navigation__icon'
                  }>
                  <use xlinkHref={`/assets/sprite.svg#icon-myProfile2`} />
                </svg>
              ) : (
                <svg
                  className={
                    currentMenu === 'my-profile'
                      ? 'navigation__icon-active'
                      : 'navigation__icon'
                  }>
                  <use xlinkHref={`/assets/sprite.svg#icon-myProfile`} />
                </svg>
              )}
              <div className='navigation__list__my_profile'>
                {userInfo.userName ? `${userInfo.userName}'s` : 'My'} Profile
              </div>
            </Link>
          </li>
          <li className='navigation__list--item'>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowModal(true)
              }}
              className='navigation__list--link'>
              <svg
                className={
                  currentMenu === 'logout'
                    ? 'navigation__icon-active'
                    : 'navigation__icon'
                }>
                <use xlinkHref={`/assets/sprite.svg#icon-logout`} />
              </svg>
              <span>Logout</span>
            </div>
          </li>
        </ul>

        {/* <div>
					<Link
						to='/my-profile'
						className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
						<img src='/assets/my-profile.png' alt='About' className='navigation__icon_image' />
						<span>My Profile</span>
					</Link>
					<Link
						to='/login'
						className={currentMenu === 'logout' ? 'navigation__list--link-active' : 'navigation__list--link'}>
						<svg className={currentMenu === 'logout' ? 'navigation__icon-active' : 'navigation__icon'}>
							<use xlinkHref={`/assets/sprite.svg#icon-logout`} />
						</svg>
						<span>Logout</span>
					</Link>
				</div> */}
      </nav>

      {/* {userDetails &&
        userDetails.userInfo &&
        userDetails.userInfo.data &&
        userDetails.userInfo.data.client &&
        userDetails.userInfo.data.client.clientType !== 'lab'  (
          <Modal show={true} style={{ width: '25vw' }}>
            <p className='modalQuestion'>
              We are sorry for not letting you in.
            </p>
            <p className='modal__text'>
              This web experience is currently for 'pre-test' users. <br />
              We are continuously working for better web experience. Kindly
              visit again later.
            </p>
            <div className='modalAnswerNext'>
              <div className='modalAnswerNext_again_btns'>
                <button
                  className='modalAnswerNext_next_btn'
                  onClick={handleLogout}>
                  Logout <span />
                </button>
              </div>
            </div>
          </Modal>
        )} */}

      <Modal show={showModal} style={{ width: '25vw' }}>
        <p className='modalQuestion' style={{ textAlign: 'center' }}>
          Are you sure you want to logout ?
        </p>

        <div className='logout_btn_container'>
          <button
            className='modalAnswerNext_next_btn2'
            onClick={() => {
              setShowModal(false)
            }}>
            Cancel <span />
          </button>
          <button className='modalAnswerNext_next_btn3' onClick={handleLogout}>
            Confirm <span />
          </button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default Sidebar
