import React, { useState } from 'react'

import { VscEye, VscEyeClosed } from 'react-icons/vsc'
import './text_input.css'

const TextInput = ({
  placeholder,
  type = 'text',
  src,
  icon,
  secure = false,
  onChange,
  value,
  error,
  maxLength,
  onFocus,
  inputStyles,
  imageStyles,
  inputImageStyles,
  disabled = false,
}) => {
  const [showPassword, setshowPassword] = useState(false)

  return (
    <div className='input_container'>
      <div className='text_input_container'>
        {src && (
          <div className='text_input_image_container' style={inputImageStyles}>
            <img src={src} className='text_input_image' style={imageStyles} />
          </div>
        )}
        {icon && icon}
        <input
          placeholder={placeholder}
          className='text_input'
          type={showPassword ? 'text' : type}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          onFocus={onFocus}
          style={inputStyles}
          disabled={disabled}
        />
        {secure === true ? (
          showPassword ? (
            <VscEyeClosed
              className='text_input_password'
              onClick={() => setshowPassword(!showPassword)}
            />
          ) : (
            <VscEye
              className='text_input_password'
              onClick={() => setshowPassword(!showPassword)}
            />
          )
        ) : null}
      </div>
      <p className='input_error_text'>{error}</p>
    </div>
  )
}

export default TextInput
