import React, { useEffect, useState, useRef, useContext } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import Content from '../../components/Content'
import styles from './Performance.module.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMissionGraphData
} from '../../../store/performance/getGraphData'
import {
  getSkillGraphData
} from '../../../store/performance/getSkillGraphData'
import {
  getPlayGraphData
} from '../../../store/performance/getPlayGraphData'
import { ErrorBoundary } from 'react-error-boundary'
import { getEnabledMissionList } from '../../../store/missions/enabledMissionList'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import CustomBar from '../../components/Graph/Bar'
import { LeftArrow, RightArrow } from './Arrows'
import StackedBar from '../../components/Graph/StackedBar'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

function createGradient(ctx, area) {
  const colorStart = '#FFFFFF'
  const colorEnd = '#6977B1'

  const gradient = ctx.createLinearGradient(0, area.top, 0, area.bottom)

  gradient.addColorStop(0, colorStart)
  gradient.addColorStop(1, colorEnd)

  return gradient
}

const easingFunctions = {
  noEasing: undefined,
  // no easing, no acceleration
  linear: (t) => t,
  // accelerating from zero velocity
  easeInQuad: (t) => t * t,
  // decelerating to zero velocity
  easeOutQuad: (t) => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  // accelerating from zero velocity
  easeInCubic: (t) => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: (t) => --t * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: (t) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // accelerating from zero velocity
  easeInQuart: (t) => t * t * t * t,
  // decelerating to zero velocity
  easeOutQuart: (t) => 1 - --t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t) =>
    t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
  // accelerating from zero velocity
  easeInQuint: (t) => t * t * t * t * t,
  // decelerating to zero velocity
  easeOutQuint: (t) => 1 + --t * t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: (t) =>
    t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t,
  // Source https://gist.github.com/gre/1650294#file-easing-js
}

const Performance = () => {
  const chartSkillPerformanceRef = useRef(null)
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete,
    isLastItemVisible,
    scrollNext,
  } = useContext(VisibilityContext)

  const [chartSkillPerformanceData, setChartSkillPerformanceData] = useState({
    datasets: [],
  })

  const chartMissionPerformanceRef = useRef(null)
  const [chartMissionPerformanceData, setChartMissionPerformanceData] =
    useState({
      datasets: [],
    })

  const chartPlayPerformanceRef = useRef(null)
  const [chartPlayPerformanceData, setChartPlayPerformanceData] = useState({
    datasets: [],
  })

  const { graphData, loading: missionGraphLoading } = useSelector(
    (graph) => graph.getGraphData
  )
  const { skillGraphData, loading: skillGraphLoading } = useSelector(
    (graph) => graph.getSkillGraphData
  )
  console.log({ skillGraphData })
  const { playGraphData, loading: playGraphLoading } = useSelector(
    (graph) => graph.getPlayGraphData
  )
  const [missionId, setMissionId] = useState('')
  const [currentMission, setCurrentMission] = useState('all')
  const { enabledMissionList } = useSelector((list) => list.enabledMissionList)
  const [missionPlayId, setMissionPlayId] = useState('')
  const [currentMissionPlay, setCurrentMissionPlay] = useState('all')
  const { userInfo } = useSelector((state) => state.credentials)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getEnabledMissionList())
  }, [dispatch])
  useEffect(() => {
    /*Mission PERFORMANCE */

    dispatch(getMissionGraphData())

    /*SKILL PERFORMANCE */

    dispatch(getSkillGraphData('all'))
    setSkillData()

    /*PLAY PERFORMANCE */

    // dispatch(getPlayGraphData('all'))
    // setPlayData()
  }, [])
  const onChangeMissionSkill = (e) => {
    setCurrentMission(e.target.value)
    dispatch(getSkillGraphData(e.target.value))
    setSkillData()
  }
  const onChangeMissionPlay = (e) => {
    setCurrentMissionPlay(e.target.value)
    dispatch(getPlayGraphData(e.target.value))
    setPlayData()
  }
  function setSkillData() {
    const chartSkillPerformance = chartSkillPerformanceRef.current
    if (!chartSkillPerformance) {
      return
    }
    const bgSkillColor = createGradient(
      chartSkillPerformance.ctx,
      chartSkillPerformance.chartArea
    )
    const chartSkillData = {
      ...dataSample,
      labels: skillGraphData.data.labels,
      datasets: skillGraphData.data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: bgSkillColor,
        borderRadius: 20,
        datalabels: {
          align: 'center',
          anchor: 'center',
        },
      })),
    }
    setChartSkillPerformanceData(chartSkillData)
  }
  function setPlayData() {
    const chartPlayPerformance = chartPlayPerformanceRef.current
    if (!chartPlayPerformance) {
      return
    }
    const bgPlayColor = createGradient(
      chartPlayPerformance.ctx,
      chartPlayPerformance.chartArea
    )
    const chartPlayData = {
      ...dataSample,
      labels: playGraphData.data.labels,
      datasets: playGraphData.data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: bgPlayColor,
      })),
    }
    setChartPlayPerformanceData(chartPlayData)
  }

  const dataSample = {
    datasets: [
      {
        fill: false,
        label: 'Sideeffects',
        data: [16, 25, 52, 45, 26, 30, 27],
        // borderColor: 'rgba(53, 162, 235,0)',
        borderWidth: 2,
        tension: 0.5,
        pointBackgroundColor: '#FF7B01',
        pointBorderColor: '#FF7B01',
        datalabels: {
          align: 'center',
          anchor: 'center',
        },
      },
    ],
  }
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: '#27273f',
        display: (context) => {
          return context.dataset.data[context.dataIndex]
        },
        formatter: function (value, context) {
          return context.dataset.data[context.dataIndex] + ' %'
        },
        font: {
          weight: 'bold',
          size: 20,
        },
      },
    },
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        top: 24,
        right: 16,
        bottom: 0,
        left: 8,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        fill: false,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
      },
    },
  }
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <div>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

    if (isThouchpad) {
      ev.stopPropagation()
      return
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext()
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev()
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { }}>
      <Content currentMenu='performance' headerTitle='Performance'>
        <div className={styles.container}>
          {/* <div className={styles.hint}>
            Graph data is representing all the correct responses in percentage
            (%).{' '}
          </div> */}
          <div className={styles.header}>{userInfo &&
            userInfo.client &&
            userInfo.client.renameModule &&
            userInfo.client.renameModule.stageOne?userInfo.client.renameModule.stageOne:'Mission'} Performance</div>

          <div className={styles.graph}>
            {!missionGraphLoading ? (
              <ScrollMenu
                transitionDuration={500} // NOTE: for transitions
                transitionEase={easingFunctions.easeOutQuad}
                onWheel={onWheel}
                LeftArrow={LeftArrow}
                RightArrow={RightArrow}>
                {graphData && graphData.data && Array.isArray(graphData.data)
                  ? graphData?.data?.map((item, i) => {
                    return (
                      <StackedBar key={i} data={item} itemId={i} title={i} />
                    )
                  })
                  : null}
              </ScrollMenu>
            ) : null}
          </div>
          <div className={styles.header}>Skill Performance</div>
          <div
            className={styles.graph}
            style={{
              marginBottom: '4rem',
            }}>
            {!skillGraphLoading ? (
              <ScrollMenu
                transitionDuration={500} // NOTE: for transitions
                transitionEase={easingFunctions.easeOutQuad}
                onWheel={onWheel}
                LeftArrow={LeftArrow}
                RightArrow={RightArrow}>
                {skillGraphData &&
                  skillGraphData.data &&
                  Array.isArray(skillGraphData.data)
                  ? skillGraphData?.data?.map((item, i) => {
                    return (
                      <div>
                        <CustomBar
                          key={i}
                          data={item.data}
                          itemId={i}
                          title={i}
                        />
                        <div className={styles.title}>{item?.mission}</div>
                      </div>
                    )
                  })
                  : null}
              </ScrollMenu>
            ) : null}
          </div>

          {/* <div className={styles.graph}>
            {!skillGraphLoading &&
              skillGraphData &&
              skillGraphData.status &&
              skillGraphData.data ? (
              <Bar
                ref={chartSkillPerformanceRef}
                data={chartSkillPerformanceData}
                options={options}
              />
            ) : (
              <>
                {!skillGraphLoading &&
                  skillGraphData &&
                  !skillGraphData.status ? (
                  <div className={styles.errorContainer}>
                    <p className={styles.errorMessage}>
                      {' '}
                      {skillGraphData?.message}
                    </p>
                  </div>
                ) : (
                  <div className={styles.loaderContainer}>
                    <Loader
                      type='spinner-circle'
                      bgColor={'#FFFFFF'}
                      color={'#FFFFFF'}
                      size={100}
                    />
                  </div>
                )}
              </>
            )}
          </div> */}
        </div>
      </Content>
    </ErrorBoundary>
  )
}

export default React.memo(Performance)
