import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'statistics-chart-report',
    initialState: {
        statisticsChartReport: [],
        loading: false,
    },
    reducers: {
        requested: (report) => {
            report.loading = true
        },
        success: (report, action) => {
            report.statisticsChartReport = action.payload;
            report.loading = false
        },
        failed: (report, action) => {
            report.statisticsChartReport = action.payload;
            report.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getStatisticsChartReport = () => apiCallBegan({
    url: `/report/get-statistics-report-for-chart`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})