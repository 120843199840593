import React from 'react';

const ProgressBarSkeleton = ({ title }) => {
	const data = [{ _id: 1, width: 70 }, { _id: 2, width: 50 }, { _id: 3, width: 40 }, { _id: 4, width: 30 }];
	return (
		<div style={{ marginTop: '3rem', marginBottom: '3rem', width: '30rem' }}>
			<h3 className='date__subheading'>{title}</h3>
			<div className='topDataSkeleton__col'>
				{data.map((d) => (
					<div key={d._id} className='topDataSkeleton'>
						<div className='topDataSkeleton__name' />
						<div className='topDataSkeleton__bar' style={{ width: `${d.width}%` }} />
						<p className='topDataSkeleton__view' />
					</div>
				))}
			</div>
		</div>
	);
};

export default ProgressBarSkeleton;
