import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'selected-client',
    initialState: {
        currentClient: null
    },
    reducers: {
        changeClient: (current, action) => {
            current.currentClient = action.payload;
        }
    }
})

const { changeClient } = slice.actions
export default slice.reducer

export const setClient = data => apiCallBegan({
    data,
    onChangeClient: changeClient.type,
})