import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AppSwitchSmall from '../../AppSwitchSmall'

import './PropertyCard.css'
const PropertyCard = ({
  item,
  loading,
  onEditClick,
  onChangeSwitch,
  currentItem,
  onDelete,
  totalProperties,
  handleOrderChange,
}) => {
  const { enabledVideoList, loading: videoLoading } = useSelector(
    (list) => list.enabledVideoList
  )
  const [videoList, setVideoList] = useState([])

  useEffect(() => {
    if (
      enabledVideoList &&
      enabledVideoList.data &&
      enabledVideoList.data.result &&
      Array.isArray(enabledVideoList.data.result) &&
      enabledVideoList.data.result.length > 0
    ) {
      if (item.media && Array.isArray(item.media) && item.media.length > 0) {
        const videos = enabledVideoList.data.result.filter((video) => {
          const result = item.media.filter((media) => media === video._id)
          if (result && Array.isArray(result) && result.length > 0) {
            return video
          }
        })
        setVideoList(videos)
      }
    }
  }, [item.media, enabledVideoList])

  const [items, setItems] = React.useState([])
  const [selectedOrder, setSelectedOrder] = React.useState(item.orderNumber)

  React.useState(() => {
    const data = []
    for (let i = 0; i < totalProperties; i++) {
      data.push(i)
    }
    setItems(data)
  })

  return (
    <div className='properties_card_container'>
      <div className='properties_card_head'>
        <div className='properties_card_header'>
          <img
            alt={item.title}
            src={item.imageURL}
            className='client_card_image'
          />
          <div className='client_card_detail_container'>
            <p className='client_card_title'>{item.propertyName}</p>
            <div
              className='client_card_color'
              style={{ color: `var(--primary)` }}>
              <p>Color</p>
              <div
                className='circularColor'
                style={{
                  backgroundColor: item.color
                    ? item.color.primaryColor
                    : `var(--primary)`,
                }}
              />
            </div>
            <div className='client_card_view_space_container'>
              {totalProperties > 0 && items.length > 0 && (
                <select
                  value={selectedOrder}
                  onChange={(e) => {
                    handleOrderChange(e.target.value, item.orderNumber)
                    setSelectedOrder(e.target.value)
                  }}>
                  {/* <option>Select Order</option> */}
                  {items.map((item) => (
                    <option key={item} value={item + 1}>
                      Order {item + 1}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
        <div className='client_card_action_container'>
          <div className='client_card_action_container_left'>
            <AppSwitchSmall
              checked={item.enable}
              onChange={() => onChangeSwitch(item)}
              loading={currentItem === item._id && loading}
            />
            <img
              src='/images/editPrimary.png'
              className='client_card_edit_image'
              onClick={() => onEditClick(item)}
            />
          </div>
          <img
            src='/images/delete.png'
            className='client_card_delete_image'
            onClick={() => onDelete(item)}
            style={{ marginLeft: '.5rem' }}
          />
        </div>
      </div>
      <div className='properties_card_videos'>
        <h3 className='properties_card_videos__title'>Selected Videos</h3>
        <div className='properties_card_videos__list'>
          {videoList &&
            videoList.length > 0 &&
            videoList.map((video, vIndex) => (
              <div className='properties_card_video' key={vIndex}>
                {video.fileType === 'doc' ? (
                  <div className='properties_card_pdf_container'>
                    <img
                      className='properties_card_pdf'
                      src='/images/pdf.png'
                    />
                  </div>
                ) : (
                  <>
                    {video.fileType === 'audio' ? (
                      <div className='video_card_audio_container'>
                        <img
                          src='/images/audio_icon.png'
                          className='client_card_pdf_image'
                          style={{ width: '9rem', height: '9rem' }}
                        />
                        <audio
                          controls
                          style={{ width: '200px' }}
                          controlsList='nodownload'>
                          <source src={video.videoURL} type='audio/mpeg' />
                          <source src={video.videoURL} type='audio/ogg' />
                          Your browser does not support HTML video.
                        </audio>
                      </div>
                    ) : (
                      <>
                        {video.fileType === 'image' ? (
                          <img
                            alt='logo3'
                            src={video.videoURL}
                            style={{
                              width: '17rem',
                              height: '10rem',
                            }}
                          />
                        ) : (
                          <video controls width='185'>
                            <source src={video.videoURL} type='video/mp4' />
                            Your browser does not support HTML video.
                          </video>
                        )}
                      </>
                    )}
                  </>
                )}
                <p className='properties_card_video__title'>
                  {video.title.slice(0, 20)}...
                </p>
                <Link
                  to={`/videos/questions/${video._id}/${video.title}`}
                  className='properties_card_video__button'>
                  {video.questionsCount} Questions
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default PropertyCard
