import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const ImageUploader = ({
  onUpload,
  containerStyles,
  image = '',
  setURL,
  errorMessage,
  video,
  imageTitle = '',
  videoTitle = '',
  fileType = null,
  isUpadate,
  showMessage = false,
  style,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setURL(URL.createObjectURL(acceptedFiles[0]))
    // console.log('file', URL.createObjectURL(acceptedFiles[0]))
    onUpload(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <>
      {video !== undefined ? (
        <div
          {...getRootProps()}
          className='modal_drag_and_drop_view'
          style={containerStyles}>
          <input
            {...getInputProps()}
            accept={
              fileType === null || fileType === 'video'
                ? 'video/*'
                : fileType === 'image'
                ? '.doc, image/*'
                : 'application/pdf'
            }
          />
          {video !== '' ? (
            <>
              {fileType === 'image' ? (
                <img
                  alt='logo1'
                  src={video}
                  className='modal_actual_logo_image'
                />
              ) : (
                <video controls width='250'>
                  <source src={video} type='video/mp4' />
                  Your browser does not support HTML video.
                </video>
              )}
            </>
          ) : (
            <>
              <img
                className='modal_logo_image'
                src={
                  fileType === null ||
                  fileType === 'video' ||
                  fileType === 'image'
                    ? '/images/select_file.png'
                    : '/images/pdf.png'
                }
                alt='logo'
              />
              <p className='form_description_text'>
                {videoTitle !== '' ? videoTitle : 'Select video to upload'}
              </p>
            </>
          )}
        </div>
      ) : (
        <div
          {...getRootProps()}
          className='modal_drag_and_drop_view'
          style={containerStyles}>
          <input {...getInputProps()} accept='.pdf,.doc, image/*, video/*' />
          {image !== '' ? (
            <img alt='logo1' src={image} className='modal_actual_logo_image' />
          ) : (
            <>
              <img
                className='modal_logo_image'
                src='/images/select_file.png'
                alt='logo2'
              />
              <p className='form_description_text'>
                {imageTitle !== '' ? imageTitle : 'Select file to upload'}
              </p>
            </>
          )}
        </div>
      )}
      {errorMessage !== '' && (
        <p className='error_message smallFontMessage'>{errorMessage}</p>
      )}
      {fileType === 'image' && (
        <p className='form_description_text smallFontMessage'>
          Note: Image resolution must be larger than 1920 * 1080
        </p>
      )}
      {showMessage && (
        <p className='form_description_text smallFontMessage' style={style}>
          Note: Use transparent image for better visualization
        </p>
      )}
    </>
  )
}

export default ImageUploader
