import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'
import { generatePassword } from '../../components/passwordGenerator'

const slice = createSlice({
    name: 'add-background-avatar',
    initialState: {
        addBackgroundResponse: null,
        loading: false,
    },
    reducers: {
        requested: (users, action) => {
            users.loading = true
        },
        success: (users, action) => {
            users.addBackgroundResponse = action.payload
            users.loading = false
        },
        failed: (users, action) => {
            users.addBackgroundResponse = action.payload;
            users.loading = false
        },
        reset: (users, action) => {
            users.addBackgroundResponse = action.payload;
            users.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addBackgroundAvatar = (data, onUploadProgress) => apiCallBegan({
    url: '/avatar-properties/create-and-update-avatar-properties',
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearAddBackgroundAvatar = () => apiCallBegan({
    onReset: reset.type,
})