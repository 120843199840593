import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'category-report',
  initialState: {
    categoryReport: null,
    loading: false,
  },
  reducers: {
    requested: (report) => {
      report.loading = true
    },
    success: (report, action) => {
      report.categoryReport = action.payload
      report.loading = false
    },
    failed: (report, action) => {
      report.categoryReport = action.payload
      report.loading = false
    },
  },
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getCategoryReport = () =>
  apiCallBegan({
    url: `/report/get-categories-wise-process-report`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type,
  })
