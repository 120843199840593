import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'page-title',
    initialState: {
        pageTitle: ""
    },
    reducers: {
        changePageHeading: (page, action) => {
            page.pageTitle = action.payload;
        }
    }
})

const { changePageHeading } = slice.actions
export default slice.reducer

export const setPageHeading = data => apiCallBegan({
    data,
    onChangePageHeading: changePageHeading.type,
})