import React from 'react'
import AppSwitchSmall from '../../AppSwitchSmall'

import './howToCard.css'

const HowToCard = ({ item, onChange, onClickEdit, currentItem, loading }) => {
    return (
        <div className='client_card_container' style={{ width: "28rem" }}>
            <div className='client_card_view_container'>
                <img src={item.imageURL} alt={item.imageURL} className="client_card_image" />
                <div className='client_card_detail_container'>
                    <p className='client_card_title'>{item.title}</p>
                    <div className="flex">
                        <p className='client_card_count' style={{ color: item.color && item.color.primaryColor }}>Color</p>
                        <div className='dropdown_selectedColor' style={{ backgroundColor: item.color && item.color.primaryColor }} />
                    </div>
                </div>
            </div>
            <div className='client_card_action_container' style={{ justifyContent: "flex-start" }}>
                <AppSwitchSmall
                    checked={item.enable}
                    onChange={() => onChange(item)}
                    loading={currentItem === item._id && loading}
                />
                <img src="/images/editPrimary.png" className="howto_card_editlogo mt-left" onClick={() => onClickEdit(item)} />
            </div>
        </div>
    )
}

export default HowToCard