import React, { Fragment } from 'react';
import SkeletonStyles from './Skeletons.module.css';
import AboutScreenStyles from '../../screens/About/About.module.css';

const AboutSkeleton = () => {
	return (
		<Fragment>
			<div className={AboutScreenStyles.about_container}>
				<div className={[ SkeletonStyles.aboutSkeletonImage, SkeletonStyles.skeleton ].join(' ')} />
				<div>
					<div
						className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '70vw' }}
					/>
					<div
						className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '68vw' }}
					/>
					<div
						className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '65vw' }}
					/>
					<div
						className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '65vw' }}
					/>
					<div
						className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '60vw' }}
					/>
					<div
						className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '60vw' }}
					/>
				</div>
			</div>
			<div
				className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
				style={{ width: '80vw', marginTop: '2rem' }}
			/>
			<div
				className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
				style={{ width: '80vw' }}
			/>
			<div
				className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
				style={{ width: '75vw' }}
			/>
			<div
				className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
				style={{ width: '75vw' }}
			/>
			<div
				className={[ SkeletonStyles.aboutSkeletonText, SkeletonStyles.skeleton ].join(' ')}
				style={{ width: '75vw' }}
			/>
		</Fragment>
	);
};

export default AboutSkeleton;
