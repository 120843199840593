import React, { Fragment } from 'react'
import './MatchPairDraggable.css'

const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: [],
}

const MatchPairDraggable2 = ({ items, list, setList }) => {

  const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState)
  const [equal, setEqual] = React.useState(false)

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  React.useEffect(() => {
    const result = JSON.stringify(items) == JSON.stringify(list)
    if (!result && !equal) {
      setEqual(true)
      setList(shuffleArray(items))
    }

    // eslint-disable-next-line
  }, [items])

  // onDragStart fires when an element
  // starts being dragged
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position)

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list,
    })

    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData('text/html', '')
  }

  // onDragOver fires when an element being dragged
  // enters a droppable area.
  // In this case, any of the items on the list
  const onDragOver = (event) => {
    // in order for the onDrop
    // event to fire, we have
    // to cancel out this one
    event.preventDefault()

    let newList = dragAndDrop.originalOrder

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position)

    const itemDragged = newList[draggedFrom]
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    )

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ]

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      })
    }
  }

  const onDrop = (event) => {
    setList(dragAndDrop.updatedOrder)

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    })
  }

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    })
  }

  return (
    <section>
      <ul className='drag_ul'>
        {list.map((item, index) => {
          return (
            <li
              key={index}
              data-position={index}
              draggable
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragLeave={onDragLeave}
              className={
                dragAndDrop && dragAndDrop.draggedTo === Number(index)
                  ? 'drag_placeholder'
                  : 'drag_li'
              }>
              {/* <span>{item}</span> */}
              {dragAndDrop && dragAndDrop.draggedTo !== Number(index) && (
                <Fragment>
                  <svg className='drag_li_icon'>
                    <use xlinkHref={`/assets/sprite.svg#icon-drag_handle`} />
                  </svg>
                  <p className='modalAnswer_pair_option_B'>{item}</p>
                </Fragment>
              )}
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default MatchPairDraggable2
