import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enabled-mission-list',
    initialState: {
        enabledMissionList: [],
        loading: false,
    },
    reducers: {
        requested: (clients, action) => {
            clients.loading = true
        },
        success: (clients, action) => {
            clients.enabledMissionList = action.payload;
            clients.loading = false
        },
        failed: (clients, action) => {
            clients.enabledMissionList = action.payload;
            clients.loading = false
        },
        reset: (count, action) => {
            count.enabledMissionList = [];
            count.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getEnabledMissionList = data => apiCallBegan({
    url: '/mission/get-enabled-mission',
    method: 'GET',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})

export const clearEnabledMissionList = () => apiCallBegan({
    onReset: reset.type,
})