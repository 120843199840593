import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AppHeader from '../../../../components/AppHeader'
import AppModal3 from '../../../../components/AppModal3'
import AppModal2 from '../../../../components/AppModal2'
import AppButton from '../../../../components/forms/AppButton'
import TextInput from '../../../../components/forms/TextInput'
import TextArea from '../../../../components/forms/TextArea'
import AppSwitch from '../../../../components/AppSwitchSmall'
import VersionCard from '../../../../components/Cards/VersionCard/VersionCard'
import AllUsersSkeleton from '../../../../components/Skeletons/AllUsersSkeleton'
import {
  addVersion,
  changeVersionStatus,
  clearAddVersionResponse,
} from '../../../../store/Versions/AddVersion'
import {
  deleteVersion,
  clearDeleteVersionResponse,
} from '../../../../store/Versions/deleteVersion'
import { getVersionList } from '../../../../store/Versions/getVersion'
import {
  updateVersion,
  clearUpdateVersionResponse,
} from '../../../../store/Versions/updateVersion'

import Styles from './AppVersion.module.css'

const pageSize = 9

let data

const AppVersion = () => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedDeleteId, setSelectedDeleteId] = useState(null)
  const [androidVersion, setAndroidVersion] = useState('')
  const [iosVersion, setIOSVersion] = useState('')
  const [compulsory, setCompulsory] = useState(false)
  const [description, setDescription] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [enableVersion, setEnableVersion] = useState(false)
  const [mounted, setMounted] = useState(false);
  
  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)

  const { addVersionInfo } = useSelector((add) => add.addVersion)
  const { versionList, loading: versionListLoading } = useSelector(
    (list) => list.getVersionList
  )

  const { deleteVersionInfo } = useSelector((del) => del.deleteVersion)

  const { updateVersionResponse } = useSelector(
    (version) => version.updateVersion
  )

  useEffect(() => {
    dispatch(getVersionList(pageNumber, pageSize))
  }, [dispatch])

  const modalOpenHandler = () => {
    setShowModal(true)
  }
  const modalCloseHandler = () => {
    setAndroidVersion('')
    setIOSVersion('')
    setDescription('')
    setCompulsory(false)
    setShowModal(false)
  }
  const androidVersionHandler = (event) => {
    setAndroidVersion(event.target.value)
  }
  const iosVersionHandler = (event) => {
    setIOSVersion(event.target.value)
  }

  const descriptionHandler = (event) => {
    setDescription(event.target.value)
  }

  const compulsoryHandler = () => {
    setCompulsory(v => !v)
  }

  const openDeleteModal = (id) => {
    setSelectedDeleteId(id)
    setShowDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedDeleteId(null)
  }

  const submitHandler = (event) => {
    if (iosVersion !== '' && androidVersion !== '') {
      data = {
        versionTitleIOS: iosVersion,
        versionTitleAndroid: androidVersion,
        versionDescription: description,
        isCompulsory: compulsory,
      }

      dispatch(addVersion(data))
    }
  }

  const onDeleteVersion = () => {
    if (selectedDeleteId) {
      dispatch(deleteVersion(selectedDeleteId))
    }
  }

  //   const onPageChange = async (page) => {
  //     const selectedPage = page.selected
  //     // await dispatch(
  //     //   getUserList({ pageSize, pageNumber: selectedPage + 1, filterValue: '' })
  //     // )
  //     await dispatch(getVersionList(pageNumber, pageSize))
  //     setPageNumber(selectedPage + 1)
  //   }

  useEffect(() => {
    if (addVersionInfo !== null) {
      if (addVersionInfo.status) {
        toast.success(addVersionInfo.message)
        dispatch(getVersionList(pageNumber, pageSize))
        dispatch(clearAddVersionResponse())
        modalCloseHandler()
      } else {
        toast.error(addVersionInfo.message)
        dispatch(getVersionList(pageNumber, pageSize))
        dispatch(clearAddVersionResponse())
        modalCloseHandler()
      }
    }
  }, [addVersionInfo, dispatch])

  useEffect(() => {
    if (deleteVersionInfo !== null) {
      if (deleteVersionInfo.status) {
        toast.success(deleteVersionInfo.message)
        dispatch(getVersionList(pageNumber, pageSize))
        dispatch(clearDeleteVersionResponse())
        closeDeleteModal()
      } else {
        toast.error(deleteVersionInfo.message)
        dispatch(getVersionList(pageNumber, pageSize))
        dispatch(clearDeleteVersionResponse())
        closeDeleteModal()
      }
    }
  }, [deleteVersionInfo, dispatch])

  // const { updateDialogueBox } = versionList;
  useEffect(() => {
    if (versionList?.updateDialogueBox !== undefined) {
      setEnableVersion(versionList?.updateDialogueBox)
    }
  }, [versionList?.updateDialogueBox])

  const enableVersionHandler = (status) => {
    dispatch(updateVersion({ status }))
    dispatch(getVersionList(pageNumber, pageSize))
  }
  
  useEffect(() => {
    let isActive = true
    if (isActive) {
      if (updateVersionResponse) {
        if (updateVersionResponse.status) {
          toast.success(updateVersionResponse.message)
          dispatch(getVersionList(pageNumber, pageSize))
          setEnableVersion(versionList.updateDialogueBox)
          dispatch(clearUpdateVersionResponse())
        } else if (!updateVersionResponse.status) {
          toast.error(updateVersionResponse.message)
          dispatch(clearUpdateVersionResponse())
        }
      }
    }

    return () => (isActive = false)
  }, [updateVersionResponse])

  const enableDisableVersion = (id, status) => {
    dispatch(changeVersionStatus(id, {
      enable: status
    }));
    dispatch(getVersionList(pageNumber, pageSize))
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='App Versions'
        isAdd={true}
        onAdd={modalOpenHandler}
        isVersionToggle={true}
        versionChecked={enableVersion}
        onVersionChange={enableVersionHandler}
      />
      {versionListLoading ? (
        <AllUsersSkeleton />
      ) : (
        <>
          <div className='client__container'>
            <div className='missions_main_content'>
              {versionList &&
              versionList.data &&
              Array.isArray(versionList.data) &&
              versionList.data.length > 0 ? (
                versionList.data.map((item, index) => (
                  <VersionCard
                    key={item._id}
                    androidVersion={item.versionTitleAndroid}
                    iosVersion={item.versionTitleIOS}
                    description={item.versionDescription}
                    isCompulsory={item.enable}
                    onDelete={openDeleteModal}
                    deleteId={item._id}
                    onChange={enableDisableVersion}
                  />
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    You havn't added any App Versions yet
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <AppModal3 show={showModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Add New Version</p>
          <div className={Styles.container}>
            <div className={Styles.inputContainer}>
              <div className={Styles.input}>
                <TextInput
                  placeholder='Android Version'
                  src='/images/missions-input.png'
                  onChange={androidVersionHandler}
                  value={androidVersion}
                />
              </div>
              <div className={Styles.input}>
                <TextInput
                  placeholder='iOS Version'
                  src='/images/missions-input.png'
                  onChange={iosVersionHandler}
                  value={iosVersion}
                />
              </div>
            </div>

            <div className={Styles.textArea}>
              <TextArea
                placeholder='App version description'
                onChange={descriptionHandler}
                value={description}
              />
            </div>
            <div className={Styles.compulsory}>
              <div className={Styles.compulsoryTitle}>Compulsory</div>
              <div className={Styles.switch}>
                <AppSwitch checked={compulsory} onChange={compulsoryHandler} />
              </div>
            </div>
          </div>
        </div>
        <div className='modal_buttons'>
          <AppButton
            title='Add'
            type='submit'
            // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
            onClick={submitHandler}
          />
          <AppButton
            title='cancel'
            type='button'
            buttonContainer={{
              backgroundColor: 'var(--transparent)',
              marginLeft: '0.5rem',
            }}
            textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
            onClick={modalCloseHandler}
          />
        </div>
      </AppModal3>
      {/* {versionList &&
        versionList.data &&
        versionList.data.length > 0 &&
        versionList.data.length > 9 && (
          <Pagination
            list={versionList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
            page={pageNumber}
          />
        )} */}
      <AppModal2 show={showDeleteModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Delete Version</p>
          <p className='form_header_delete_text'>
            Are you sure you want to delete selected Version?
          </p>

          <div className='modal_buttons'>
            <AppButton
              title='delete'
              type='submit'
              // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
              onClick={onDeleteVersion}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={closeDeleteModal}
            />
          </div>
        </div>
      </AppModal2>
    </div>
  )
}

export default AppVersion
