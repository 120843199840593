import React from 'react';
import { useSelector } from 'react-redux';
import { Button, HeaderTitle, Plan } from './components';
import classes from './Onboard.module.css';

const Step2 = ({ userState, setUserState, handleStep2, onBackPress }) => {
  const { plansList, loading: planLoading } = useSelector(plans => plans.getPlans);

  const staticPlan = {
    id: 'bx-basic',
    name: 'Bx Basic',
    details: [{ unit_amount: 'Free' }],
    features: [
      { name: 'Access to preview the Bx Resource Center.' },
      { name: 'Paid downloads.' },
      { name: 'Limited free lessons and worksheets.' },
      { name: 'No access to interactive BX App and Bx Dashboard.' },
      { name: 'No data collection on students.' },
    ],
    buttonText: 'Select',
  };

  return (
    <div className={classes.content}>
      <HeaderTitle text='Choose your plan' onBackPress={onBackPress} />

      <div className={classes.plans}>
        <Plan plan={staticPlan} active={false} onSelectAnnually={() => window.open('https://www.bxbuilders.com/resource-center', '_blank')} />
        {plansList &&
          plansList.data &&
          plansList.data.length &&
          plansList.data.map(plan => (
            <Plan
              key={plan.id}
              plan={plan}
              active={userState.step2.plan.value && userState.step2.plan.value.id === plan.id}
              activeAnnually={userState.step2.plan.type && userState.step2.plan.type === 'annually'}
              activeMonthly={userState.step2.plan.type && userState.step2.plan.type === 'monthly'}
              onSelectAnnually={() => setUserState(prevVal => ({ ...prevVal, step2: { ...prevVal.step2, plan: { value: plan, type: 'annually', error: '' } } }))}
              onSelectMonthly={() => setUserState(prevVal => ({ ...prevVal, step2: { ...prevVal.step2, plan: { value: plan, type: 'monthly', error: '' } } }))}
            />
          ))}
      </div>

      <Button type='submit' disabled={!(userState.step2.plan.value && userState.step2.plan.value.id) || planLoading} onClick={handleStep2}>
        Next
      </Button>
    </div>
  );
};

export default Step2;
