import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppSwitchSmall from '../../../../components/AppSwitchSmall'
import AppHeader from '../../../../components/AppHeader'
import AllUsersSkeleton from '../../../../components/Skeletons/AllUsersSkeleton'
import AppSwitch from '../../../../components/AppSwitch'
import AppModal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'
import AppButton from '../../../../components/forms/AppButton'
import { toast } from 'react-toastify'
import { getUserList } from '../../../../store/users/userList'
import {
  deleteUser,
  clearDeleteUserResponse,
} from '../../../../store/users/DeleteUser'

import './Table.css'
import './Users.css'
import Pagination from '../../../../components/Pagination'
import { setEnableDisableUser } from '../../../../store/users/enableDisableUser'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import TextInput from '../../../../components/forms/TextInput'
import { addUser, clearAddUserResponse } from '../../../../store/users/addUser'

const Users = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState('')
  const [currentItem, setCurrentItem] = useState(null)
  const pageSize = 9
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [deleteWithReference, setDeleteWithReference] = useState(false)
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [isUpadate, setIsUpadate] = useState(false)
  const [userName, setUserName] = useState('')
  const [userMobile, setUserMobile] = useState('')
  const [userNameError, setUserNameError] = useState('')
  const [userMobileError, setUserMobileError] = useState('')

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { userList, loading } = useSelector((bar) => bar.userList)
  const { userStatus, loading: statusLoading } = useSelector(
    (bar) => bar.userStatus
  )

  const { credentials, userInfo } = useSelector((state) => state.credentials)
  const { deleteUserResponse } = useSelector((state) => state.deleteUser)
  const { currentClient } = useSelector((user) => user.currentClient)
  const { addUserResponse, loading: addLoading } = useSelector(
    (state) => state.addUser
  )

  const onCloseModal = () => {
    setShowAddUserModal(false)
    setUserMobile('')
    setUserMobileError('')
    setUserName('')
    setUserNameError('')
    setIsUpadate(false)
    setCurrentItem(null)
  }

  useEffect(() => {
    const initialAPIs = async () => {
      await dispatch(getUserList({ pageSize, pageNumber, filterValue: '' }))
    }
    initialAPIs()
  }, [dispatch, credentials])

  useEffect(() => {
    if (deleteUserResponse !== null) {
      if (deleteUserResponse.status) {
        toast.success(deleteUserResponse.message)
        dispatch(getUserList({ pageSize, pageNumber, filterValue: '' }))
        dispatch(clearDeleteUserResponse())
        closeDeleteModal()
      } else {
        toast.error(deleteUserResponse.message)
        dispatch(clearDeleteUserResponse())
        closeDeleteModal()
      }
    }
  }, [deleteUserResponse, dispatch])

  useEffect(() => {
    if (addUserResponse) {
      if (addUserResponse.status) {
        toast.success(addUserResponse?.message)

        dispatch(clearAddUserResponse())
        dispatch(getUserList({ pageSize, pageNumber, filterValue: '' }))
        onCloseModal()
      } else if (!addUserResponse.status) {
        toast.error(addUserResponse?.message)
      }
    }
  }, [addUserResponse])
  const onDeleteHandler = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }
  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
    setDeleteWithReference(false)
  }
  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference)
  }
  const onDeleteUseData = () => {
    if (
      credentials &&
      credentials.data &&
      credentials.data.token &&
      deletedItem !== null
    ) {
      //   dispatch(deleteMission(deletedItem._id, deleteWithReference))
      dispatch(deleteUser(credentials.data.token, deletedItem._id))
    }
  }
  const onPageChange = async (page) => {
    const selectedPage = page.selected
    await dispatch(
      getUserList({ pageSize, pageNumber: selectedPage + 1, filterValue: '' })
    )
    setPageNumber(selectedPage + 1)
  }

  const onSearchSpaces = (event) => {
    setSearch(event.target.value)
    dispatch(
      getUserList({ pageSize, pageNumber, filterValue: event.target.value })
    )
  }

  const enableDisableHandler = async ({ _id, userIsActiveByAdmin }) => {
    setCurrentItem(_id)
    await dispatch(setEnableDisableUser({ _id, userIsActiveByAdmin }))
    await dispatch(getUserList({ pageSize, pageNumber, filterValue: '' }))
    setCurrentItem(null)
  }

  const addUserHandler = () => {
    setShowAddUserModal(true)
  }

  const onSubmitHandler = () => {
    let data
    if (userName === '' || userName.trim() === '') {
      setUserNameError('Please enter a user name')
      return
    } else if (userMobile === '' || userMobile.trim() === '') {
      setUserMobileError('Please enter a user phone number')
      return
    } else if (userMobile.length < 10) {
      setUserMobileError('Phone number must be of 10 characters')
      return
    }

    if (isUpadate) {
      data = {
        userMobile: userMobile,
        userName: userName,
        id: currentItem?._id ? currentItem._id : null,
      }
    } else {
      data = {
        userMobile: userMobile,
        userName: userName,
      }
    }

    dispatch(addUser(data))
  }

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value)
  }

  const onUpdateHandler = (item) => {
    setCurrentItem(item)
    setUserName(item.userName)
    setUserMobile(item.userMobile)
    setIsUpadate(true)
    setShowAddUserModal(true)
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Users'
        searchPlaceholder='Search Users'
        isSearch
        onSearch={onSearchSpaces}
        searchValue={search}
        isAdd={false}
        // onAdd={addUserHandler}
      />
      {loading ? (
        <AllUsersSkeleton />
      ) : (
        <Fragment>
          <div className='client__container'>
            <div className='missions_main_content'>
              {userList &&
              userList.data &&
              userList.data.result &&
              userList.data.result.length > 0 ? (
                userList.data.result.map((item) => (
                  <div
                    className='client_card_container'
                    style={{ width: '30rem' }}
                    key={item._id}>
                    <div className='client_card_header'>
                      <div>
                        <h3 className='user_card_name'>{item.userName}</h3>
                        {currentClient !== null &&
                          currentClient.clientType !== 'lab' && (
                            <Link
                              to={`/users/kids/${item?._id}`}
                              className='properties_card_video__button'>
                              View Children
                            </Link>
                          )}
                        <div>
                          <Link
                            to={`/users/audit/${item?._id}`}
                            className='properties_card_video__button'>
                            View Audit
                          </Link>
                        </div>
                      </div>
                      <div className='user_card_actions'>
                        <div
                          className='client_card_action_container_left'
                          style={{ marginTop: 0 }}>
                          <AppSwitchSmall
                            onChange={() => enableDisableHandler(item)}
                            checked={item.userIsActiveByAdmin}
                            loading={currentItem === item._id && statusLoading}
                          />
                          <img
                            src='/images/editPrimary.png'
                            className='client_card_edit_image'
                            onClick={() => onUpdateHandler(item)}
                          />
                          <img
                            src='/images/clean.png'
                            className='client_card_delete_image'
                            onClick={() => onDeleteHandler(item)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='user_card_mobile_email'>
                      <svg className='user_card_mobile_icon'>
                        <use xlinkHref={`/images/sprite.svg#icon-phone`} />
                      </svg>
                      <p className='user_card_mobile_number'>
                        {item.countryCode + ' ' + item.userMobile}
                      </p>
                    </div>
                    <div className='user_card_mobile_email'>
                      <svg className='user_card_email_icon'>
                        <use xlinkHref={`/images/sprite.svg#icon-email`} />
                      </svg>
                      <p className='user_card_email_number'>
                        {item.userEmail ? item.userEmail : '-'}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== ''
                      ? 'Search result not found'
                      : "You havn't created any users yet. Please create one"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <AppModal2 show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete user data</p>
              <p className='form_header_delete_text'>
                Are you sure you want to delete all the data related to{' '}
                <span>{deletedItem !== null && deletedItem.userName}</span>?
              </p>

              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteUseData}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal2>
        </Fragment>
      )}

      <AppModal2 show={showAddUserModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>
            {isUpadate ? 'Update user' : 'Add user'}
          </p>
          <form className='clinic_form_container'>
            <div>
              <TextInput
                placeholder='User Name'
                src='/images/missions-input.png'
                onChange={(user) => {
                  setUserNameError('')
                  setUserName(user.target.value)
                }}
                value={userName}
                error={userNameError}
              />
              <TextInput
                placeholder='Phone number'
                src='/images/missions-input.png'
                disabled={true}
                onChange={(user) => {
                  setUserMobileError('')
                  if (
                    user.target.value.length <= 10 &&
                    (isNumeric(user.target.value) ||
                      user.target.value.trim() === '')
                  ) {
                    setUserMobile(user.target.value)
                  }
                }}
                value={userMobile}
                error={userMobileError}
              />
            </div>

            <div className='modal_buttons'>
              <AppButton
                title={isUpadate ? 'update' : 'add'}
                type='submit'
                icon={
                  <img
                    src='/images/add-clinic.png'
                    className='app_button_image'
                  />
                }
                onClick={onSubmitHandler}
              />
              <AppButton
                title='cancel'
                type='button'
                buttonContainer={{
                  backgroundColor: 'var(--transparent)',
                  marginLeft: '0.5rem',
                }}
                textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                onClick={onCloseModal}
              />
            </div>
          </form>
        </div>
      </AppModal2>

      {userList !== null &&
        userList.data &&
        userList.data.result &&
        userList.data.result.length > 0 &&
        userList.data.totalPageSize > 9 && (
          <Pagination
            list={userList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
    </div>
  )
}

export default Users
