import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IoChevronBackSharp } from 'react-icons/io5'

import AppHeader from '../../../../components/AppHeader';
import KidCard from '../../../../components/Cards/KidCard';

import { getUserKidList } from '../../../../store/users/usersKidList';

const Kids = ({ match, history }) => {

    const { id } = match.params;

    const dispatch = useDispatch()
    const { sidebar } = useSelector((bar) => bar.sidebar);
    const { userKidList } = useSelector(list => list.userKidList);

    useEffect(
        () => {
            const initialAPIs = async () => {
                await dispatch(getUserKidList({ userId: id }));
            };
            initialAPIs();
        },
        [dispatch]
    );

    return (
        <div className={`main_container ${!sidebar && 'main_container_active'}`}>
            <AppHeader
                heading='Kids'
                searchPlaceholder='Search Kid'
            />
            <div className="back_button" onClick={() => history.goBack()}>
                <IoChevronBackSharp />
                <p className="label">back</p>
            </div>
            <div className='client__container'>
                <div className='missions_main_content'>
                    {
                        userKidList?.data?.result?.length ?
                            userKidList?.data?.result?.map((item, index) => <KidCard key={index} item={item} />) :
                            <div className='data_not_found_container'>
                                <p className='data_not_found_message'>{userKidList?.data?.messgae}</p>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Kids;