import React from 'react';
import { CardElement, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { confirmPayment } from '../../../store/onBoarding/paymentConfirmation';

import { Button, HeaderTitle, PaymentConfirmationModal, TextBox } from './components';
import InputClasses from './components/Components.module.css';
import classes from './Onboard.module.css';

const Step3 = ({ onBackPress, userState, setUserState, handleCouponVerify, handlePayment, onNextStep }) => {
  const [showConfModal, setShowConfModal] = React.useState(false);
  const [paymentLoading, setPaymentLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { verfiyCouponResponse, loading: couponLoading } = useSelector(coupon => coupon.verifyCoupon);

  const stripeInputOptions = {
    style: {
      base: {
        iconColor: '#292929',
        color: '#292929',
        fontWeight: '500',
        fontFamily: 'inherit',
        fontSize: '15px',
        fontSmoothing: 'antialiased',
        // padding: '10px',
        ':-webkit-autofill': {
          color: '#aa9999',
        },
        '::placeholder': {
          color: '#aa9999',
          fontWeight: '500',
        },
      },
    },
  };

  React.useEffect(() => {
    if (verfiyCouponResponse && verfiyCouponResponse.status && verfiyCouponResponse.message) {
      handleConfirmPayment();
    }
  }, [verfiyCouponResponse]);

  const handleConfirmPayment = async () => {
    if (!stripe || !elements) return;

    setPaymentLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    setPaymentLoading(false);

    if (!error) {
      try {
        setUserState(prevval => ({ ...prevval, step3: { ...prevval.step3, paymentId: paymentMethod.id } }));
        setShowConfModal(true);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error(error.message);
    }
  };

  const handleFinalPayment = async event => {
    event.preventDefault();
    try {
      setPaymentLoading(true);
      const response = await handlePayment();

      setPaymentLoading(false);
      setShowConfModal(false);

      if (response.data && response.data.status) {
        const { client_secret, paymentStatus, status, message } = response.data;

        if (paymentStatus === 'requires_action') {
          stripe.confirmCardPayment(client_secret).then(function (result) {
            if (result.error) {
              setShowConfModal(false);

              dispatch(confirmPayment(userState.step1.phone.value, 'fail'));

              // The card was declined (i.e. insufficient funds, card has expired, etc)
              toast.error(result.error.message);
              setShowConfModal(false);
            } else {
              setShowConfModal(false);
              toast.success('Your payment is successful');

              dispatch(confirmPayment(userState.step1.phone.value, 'success'));
              onNextStep();
            }
          });
        } else if (paymentStatus === 'fail') {
          // console.log('There was an issue!')
          setShowConfModal(false);
        } else {
          setShowConfModal(false);
          toast.success('Your payment is successful');
          onNextStep();
        }
      } else {
        toast.error(response.data.message);
        setShowConfModal(false);
      }
    } catch (error) {
      setShowConfModal(false);
    }
  };

  return (
    <div className={classes.content}>
      <HeaderTitle text='Card Information' onBackPress={onBackPress} />

      <div className={classes.content_box}>
        <div className={classes.content_box_cards}>
          <img src='/assets/cards/visa.png' alt='Visa Card' />
          <img src='/assets/cards/mastercard.png' alt='Master Card' />
          <img src='/assets/cards/discover.png' alt='Discover Card' />
          <img src='/assets/cards/american-express.png' alt='American Express Card' />
        </div>

        <div className={InputClasses.input_container}>
          <label htmlFor='card-number' className={InputClasses.input_container_label}>
            Card Number
          </label>
          <div className={InputClasses.input_box_stripe}>
            <CardNumberElement options={{ ...stripeInputOptions, showIcon: true }} />
          </div>
        </div>

        <div className={InputClasses.input_container}>
          <label htmlFor='card-number' className={InputClasses.input_container_label}>
            Month and Year
          </label>
          <div className={InputClasses.input_box_stripe}>
            <CardExpiryElement options={stripeInputOptions} />
          </div>
        </div>
        <div className={InputClasses.input_container}>
          <label htmlFor='card-number' className={InputClasses.input_container_label}>
            CVC
          </label>
          <div className={InputClasses.input_box_stripe}>
            <CardCvcElement options={stripeInputOptions} />
          </div>
        </div>
      </div>

      <form className={classes.content_box_wo_bg} onSubmit={handleCouponVerify} style={{ alignItems: userState.step3.coupon.error ? 'center' : 'flex-end' }}>
        <TextBox
          label='Have a coupon code?'
          placeholder='Enter it here'
          name='coupon_code'
          value={userState.step3.coupon.value}
          onChange={e => setUserState(prevVal => ({ ...prevVal, step3: { ...prevVal.step1, coupon: { value: e.target.value, error: '' } } }))}
          errorMessage={userState.step3.coupon.error}
          style={{ width: '100%' }}
        />
        <Button type='submit' disabled={!userState.step3.coupon.value || couponLoading}>
          Apply
        </Button>
      </form>
      <Button type='button' disabled={!stripe || !elements || paymentLoading} onClick={handleConfirmPayment}>
        {paymentLoading ? 'Please wait' : 'Subscribe'}
      </Button>

      {showConfModal && (
        <PaymentConfirmationModal
          loading={paymentLoading}
          selected={userState.step2.plan}
          onConfirm={handleFinalPayment}
          closePayHandler={() => setShowConfModal(false)}
          verfiyCouponResponse={verfiyCouponResponse}
        />
      )}
    </div>
  );
};

export default Step3;
