import React, { useState, useEffect, useRef } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import HomeScreenStyles from '../../screens/Home/Home.module.css'

const PdfViewer = ({ url, onEnded = () => {} }) => {
  console.log('source', url)

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const pageRef = useRef(null)
  const container = useRef(null)
  const button = useRef(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const previousHandler = (e) => {
    setPageNumber((prev) => prev - 1)
    e.preventDefault()
  }

  const nextHandler = (e) => {
    setPageNumber((prev) => prev + 1)
    e.preventDefault()
  }

  useEffect(() => {
    // button.current?.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }, [pageNumber, numPages])

  return (
    <div className={HomeScreenStyles.pdfContainer} ref={container}>
      <div className={HomeScreenStyles.pdfbuttonSuperContainer}>
        <div></div>
        <div className={HomeScreenStyles.pdfButtonContainer} ref={button}>
          <button
            onClick={previousHandler}
            className={HomeScreenStyles.button}
            style={{
              pointerEvents: pageNumber === 1 ? 'none' : 'auto',
            }}>
            <svg className={HomeScreenStyles.prviousButton}>
              <use xlinkHref={`/images/sprite.svg#icon-previous`} />
            </svg>
          </button>
          <div className={HomeScreenStyles.pdfButtonLabel}>
            {pageNumber} of {numPages}
          </div>
          <button
            onClick={nextHandler}
            className={HomeScreenStyles.button}
            style={{
              pointerEvents: pageNumber === numPages ? 'none' : 'auto',
            }}>
            <svg className={HomeScreenStyles.nextButton}>
              <use xlinkHref={`/images/sprite.svg#icon-previous`} />
            </svg>
          </button>
        </div>
        <div className={HomeScreenStyles.nextButtonContainer}>
          {pageNumber === numPages ? (
            <button className='modalAnswerNext_next_btn' onClick={onEnded}>
              Next <span />
            </button>
          ) : null}
        </div>
      </div>
      <Document
        loading={<div>Please wait loading .....</div>}
        file={url}
        className={HomeScreenStyles.pdfDocument}
        onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          inputRef={pageRef}
          pageNumber={pageNumber}
          className={HomeScreenStyles.pdfPages}
          height={500}
        />
      </Document>
    </div>
  )
}

export default PdfViewer
