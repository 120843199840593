import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enable-disable-properties',
    initialState: {
        propertiesStatus: null,
        loading: false,
    },
    reducers: {
        requested: (properties) => {
            properties.loading = true
        },
        success: (properties, action) => {
            properties.propertiesStatus = action.payload;
            properties.loading = false
        },
        failed: (properties, action) => {
            properties.propertiesStatus = action.payload;
            properties.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const setEnableDisableproperty = data => apiCallBegan({
    url: '/space/enable-disable-properties',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})