import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../../middleware/apis';

const slice = createSlice({
	name: 'get-module-name',
	initialState: {
		moduleNames: null,
		loading: false
	},
	reducers: {
		requested: (module) => {
			module.loading = true;
		},
		success: (module, action) => {
			module.moduleNames = action.payload;
			module.loading = false;
		},
		failed: (module, action) => {
			module.moduleNames = action.payload;
			module.loading = false;
		}
	}
});

const { requested, success, failed } = slice.actions;
export default slice.reducer;

export const getModuleNames = (clientId) =>
	apiCallBegan({
		url: `/user/get-rename-module?userId=${clientId}`,
		method: 'GET',
		onStart: requested.type,
		onSuccess: success.type,
		onFailed: failed.type
	});
