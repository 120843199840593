import React from 'react';

import './Skeleton.css';
const AllVideosSkeleton = () => {
	const count = [ 1, 2, 3, 4, 5, 6, 7, 8 ];
	return (
		<div className='client__container'>
			<div className='missions_main_content'>
				{count &&
					count.map((item) => (
						<div key={item} className='client_card_skeleton'>
							<div className='flex' style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
								<div>
									<div className='client_card_heading_skeleton skeleton' />
									<div className='client_card_view_btn_skeleton skeleton' />
								</div>
								<div className='flex'>
									<div className='client_card_edit_skeleton skeleton' />
									<div className='clinet_card_delete_skeleton skeleton' />
								</div>
							</div>
							<div className='client_card_actions'>
								<div className='clinet_card_video_skeleton2 skeleton' />
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default AllVideosSkeleton;
