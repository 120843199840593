import {
	GET_ABOUT_DATA_REQUEST,
	GET_ABOUT_DATA_SUCCESS,
	GET_ABOUT_DATA_FAIL,
	GET_ABOUT_DATA_RESET,
	GET_PRIVACY_POLICY_REQUEST,
	GET_PRIVACY_POLICY_SUCCESS,
	GET_PRIVACY_POLICY_FAIL,
	GET_PRIVACY_POLICY_RESET
} from '../constants/aboutConstants';

export const getAboutReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ABOUT_DATA_REQUEST:
			return { loading: true };

		case GET_ABOUT_DATA_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_ABOUT_DATA_FAIL:
			return { loading: false, error: action.payload };

		case GET_ABOUT_DATA_RESET:
			return {};

		default:
			return state;
	}
};

export const getPrivacyPolicyReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PRIVACY_POLICY_REQUEST:
			return { loading: true };

		case GET_PRIVACY_POLICY_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_PRIVACY_POLICY_FAIL:
			return { loading: false, error: action.payload };

		case GET_PRIVACY_POLICY_RESET:
			return {};

		default:
			return state;
	}
};
