import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../../middleware/apis';

const slice = createSlice({
    name: 'user-kid-list',
    initialState: {
        userKidList: [],
        loading: false
    },
    reducers: {
        requested: users => {
            users.loading = true;
        },
        success: (users, action) => {
            users.userKidList = action.payload;
            users.loading = false;
        },
        failed: (users, action) => {
            users.userKidList = action.payload;
            users.loading = false;
        }
    }
});

const { requested, success, failed } = slice.actions;
export default slice.reducer;

export const getUserKidList = data => apiCallBegan({
    url: `/user/get-kid-list-with-detail`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
});
