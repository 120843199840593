import * as actions from "./apis";

const localStates = ({ dispatch }) => next => async action => {

    if (action.type !== actions.apiCallBegan.type) return next(action)

    const {
        data,
        onChangeSidebar,
        onChangeToaster,
        onAuthentication,
        onChangeClient,
        onChangePageHeading,
        onChangeCurrentTab,
        onChnageLoading,
        onChangePage
    } = action.payload

    if (onChangeSidebar) dispatch({ type: onChangeSidebar, payload: data })
    if (onChangeToaster) dispatch({ type: onChangeToaster, payload: data })
    if (onAuthentication) dispatch({ type: onAuthentication, payload: data })
    if (onChangeClient) dispatch({ type: onChangeClient, payload: data })
    if (onChangePageHeading) dispatch({ type: onChangePageHeading, payload: data })
    if (onChangeCurrentTab) dispatch({ type: onChangeCurrentTab, payload: data })
    if (onChnageLoading) dispatch({ type: onChnageLoading, payload: data })
    if (onChangePage) dispatch({ type: onChangePage, payload: data })

}

export default localStates