import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ContentStyles from './Content.module.css';
import { useSelector, useDispatch } from 'react-redux';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import AppSwitch from '../../../components/AppSwitch';

import { getExpiredTokenInfo, replaceToken, clearExpiredTokenInfo, logoutUser } from '../../../store/login';
import { toast } from 'react-toastify';

const Header = ({
  backBtn = true,
  noHeader,
  headerTitle,
  showAdd = false,
  therapistView = false,
  add = () => {},
  onChangeTherapistView = () => {},
  therapistViewValue,
}) => {
  const { credentials, userInfo, isExpiredInfo } = useSelector(user => user.credentials);
  const history = useHistory();
  const dispatch = useDispatch();

  const random = Math.random();

  const handleLogout = () => {
    // dispatch(logout());
    dispatch(logoutUser());
    dispatch({ type: GET_USER_DETAILS_BY_TOKEN_RESET });
    dispatch({ type: GET_TILES_RESET });
    dispatch({ type: GET_MISSIONS_RESET });
    dispatch({ type: GET_SPACES_REQUEST });
    dispatch({ type: GET_PROPERTIES_RESET });
  };

  useEffect(() => {
    if (credentials && credentials.data && credentials.data.token) {
      dispatch(getExpiredTokenInfo(credentials.data.token));
    }
  }, [credentials]);

  useEffect(() => {
    if (credentials && credentials.data && credentials.data.token) {
      if (!isExpiredInfo && isExpiredInfo !== null && isExpiredInfo !== undefined) {
        toast.success('Your session has expired. Please login again');

        dispatch(clearExpiredTokenInfo());
        handleLogout();
      } else {
      }
    }
  }, [isExpiredInfo]);

  if (noHeader) return <></>;

  return (
    <div className={ContentStyles.header}>
      {backBtn && (
        <button onClick={() => history.goBack()} className={ContentStyles.goBackBtn}>
          <svg className={ContentStyles.goBackBtn_icon}>
            <use xlinkHref={`/assets/sprite.svg#icon-west`} />
          </svg>
        </button>
      )}
      <h1 className={ContentStyles.header__title}>{headerTitle}</h1>

      {showAdd && (
        <div className={ContentStyles.add}>
          <svg className={ContentStyles.addIcon} onClick={add}>
            <use xlinkHref={`/images/sprite.svg#icon-add3`} />
          </svg>
        </div>
      )}

      {therapistView && (
        <div className={ContentStyles.therapistView}>
          <span>Therapist View</span>
          <AppSwitch checked={therapistViewValue} onChange={onChangeTherapistView} loading={false} />
          {/* <Toggle
            className='custom-classname'
            defaultChecked={therapistViewValue}
            icons={false}
            value={therapistViewValue}
            onChange={onChangeTherapistView}
          /> */}
        </div>
      )}
    </div>
  );
};

export default Header;
