import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'add-faq',
    initialState: {
        addFAQResponse: null,
        loading: false,
    },
    reducers: {
        requested: (users, action) => {
            users.loading = true
        },
        success: (users, action) => {
            users.addFAQResponse = action.payload
            users.loading = false
        },
        failed: (users, action) => {
            users.addFAQResponse = action.payload;
            users.loading = false
        },
        reset: (users, action) => {
            users.addFAQResponse = action.payload;
            users.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addFAQ = (data) => apiCallBegan({
    url: '/faq/add-update-question',
    method: 'POST',
    data: data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearAddFAQtResponse = () => apiCallBegan({
    onReset: reset.type,
})