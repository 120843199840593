import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-versionList',
  initialState: {
    versionList: null,
    loading: false,
  },
  reducers: {
    requested: (user) => {
      user.loading = true
    },
    success: (user, action) => {
      user.versionList = action.payload
      user.loading = false
    },
    failed: (user, action) => {
      user.versionList = action.payload
      user.loading = false
    },
    reset: (user, action) => {
      user.versionList = action.payload
      user.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getVersionList = (pageNumber, pageSize) =>
  apiCallBegan({
    url: `/version/get-version?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearVersionList = () =>
  apiCallBegan({
    onReset: reset.type,
  })
