import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import api from '../middleware/api'
import localStates from '../middleware/localStates';
import auth from '../middleware/auth';

import reducer from './reducer'

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default () => configureStore({
    reducer: persistedReducer,
    middleware: [
        ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(auth, api, localStates),
    ],
});