import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const VideoUploader = ({
  onUpload,
  containerStyles,
  setURL,
  errorMessage,
  video,
  videoTitle = '',
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setURL(URL.createObjectURL(acceptedFiles[0]))
    onUpload(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div
      {...getRootProps()}
      className='modal_drag_and_drop_view'
      style={containerStyles}>
      <input {...getInputProps()} accept='video/mp4' />
      {video !== '' ? (
        <video controls width='250'>
          <source src={video} type='video/mp4' />
          Your browser does not support HTML video.
        </video>
      ) : (
        <>
          <img
            className='modal_logo_image'
            src='/images/select_file.png'
            alt='logo'
          />
          <p className='form_description_text'>
            {videoTitle !== '' ? videoTitle : 'Select video to upload'}
          </p>
        </>
      )}
    </div>
  )
}

export default VideoUploader
