import React, { useRef, useState, useEffect, useCallback } from 'react'

import './onboard.css'
import { useSelector, useDispatch } from 'react-redux'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Register from './Register'
import Payment from './Payment'
import Congrats from './Congrats'
import { STRIPE_PUBLISHABLE_KEY } from '../../../middleware/url'

// import OnBoardForm from './OnBoardForm'

// console.log(
//   'REACT_APP_STRIPE_PUBLISHABLE_KEY',
//   `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
// )

// const stripePromise = loadStripe(
//   'pk_live_51KRQfSEEHMP6rc33N4qSqgIRk4XrKEwX0N99oMoupUQWe8k1f1xlYoPN33rn4kL1Uc9xCJKOqXFvxQbZ5HoH5yq200RmQNc29U'
// )

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const OnBoard = () => {
  const [activePage, setActivePage] = useState(1)
  const [primaryData, setPrimaryData] = useState({clientType: 'user'})
  const [isback, setIsback] = useState(false)

  const pageChangeHandler = (step, data) => {
    setPrimaryData(data)
    setActivePage(step)
  }

  const goBackhandler = () => {
    setActivePage(1)
    setIsback(true)
  }

  const goToCongratulationsPage = () => {
    setActivePage(3)
  }

  return (
    <Elements stripe={stripePromise}>
      {activePage === 1 && (
        <Register
          onPageChange={pageChangeHandler}
          data={primaryData}
          isback={isback}
        />
      )}
      {activePage === 2 && (
        <Payment
          data={primaryData}
          goback={goBackhandler}
          onPageChange={pageChangeHandler}
          gotToCongrats={goToCongratulationsPage}
        />
      )}
      {activePage === 3 && <Congrats data={primaryData} />}
      {activePage !== 3 && (
        <div className='onboard_cirlce_progress_container'>
          <div
            className={`onboard_cirlce_progress_circle ${
              activePage === 1 ? `circle_active` : null
            }`}></div>
          <div
            className={`onboard_cirlce_progress_circle ${
              activePage === 2 ? `circle_active` : null
            }`}></div>
        </div>
      )}
    </Elements>
  )
}

export default OnBoard
