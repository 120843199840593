import axios from 'axios';
import { GET_MISSIONS_REQUEST, GET_MISSIONS_SUCCESS, GET_MISSIONS_FAIL } from '../constants/missionConstants';
import { baseUrl } from '../../../middleware/url';

export const getAllMissionsAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_MISSIONS_REQUEST });
		const { credentials: { credentials }, userDetails } = getState();
		const clientType =
			userDetails &&
			userDetails.userInfo &&
			userDetails.userInfo.data &&
			userDetails.userInfo.data.client &&
			userDetails.userInfo.data.client.clientType;

		const config = {
			headers: {
				token: credentials.data.token,
				'Content-Type': 'application/json'
			}
		};

		const uri =
			clientType === 'lab'
				? `${baseUrl}/lab/mission/get-enabled-mission-mobile-for-lab`
				: `${baseUrl}/mission/get-enabled-mission-mobile`;

		const { data } = await axios.get(uri, config);

		dispatch({
			type: GET_MISSIONS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_MISSIONS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
