import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-progress-for-user-dashboard',
  initialState: {
    progressData: null,
    loading: false,
  },
  reducers: {
    requested: (progress) => {
      progress.loading = true
    },
    success: (progress, action) => {
      progress.progressData = action.payload
      progress.loading = false
    },
    failed: (progress, action) => {
      progress.progressData = action.payload
      progress.loading = false
    },
    reset: (progress, action) => {
      progress.progressData = action.payload
      progress.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getProgressData = (missionId, spaceId, propertyID) =>
  apiCallBegan({
    url: `mission/get-data-by-id-with-progress?mission=${missionId}&space=${spaceId}&property=${propertyID}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearProgressData = () =>
  apiCallBegan({
    onReset: reset.type,
  })
