export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_USER_DETAILS_BY_TOKEN_REQUEST = 'GET_USER_DETAILS_BY_TOKEN_REQUEST';
export const GET_USER_DETAILS_BY_TOKEN_SUCCESS = 'GET_USER_DETAILS_BY_TOKEN_SUCCESS';
export const GET_USER_DETAILS_BY_TOKEN_FAIL = 'GET_USER_DETAILS_BY_TOKEN_FAIL';
export const GET_USER_DETAILS_BY_TOKEN_RESET = 'GET_USER_DETAILS_BY_TOKEN_RESET';

export const GET_TILES_REQUEST = 'GET_TILES_REQUEST';
export const GET_TILES_SUCCESS = 'GET_TILES_SUCCESS';
export const GET_TILES_FAIL = 'GET_TILES_FAIL';
export const GET_TILES_RESET = 'GET_TILES_RESET';
