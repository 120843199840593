import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './app_header.css';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { CgMenuGridR } from 'react-icons/cg';

import { getExpiredTokenInfo, replaceToken, clearExpiredTokenInfo, logoutUser } from '../../store/login';
import { setSidebar } from '../../store/localStates/sidebar';
import { setClient } from '../../store/localStates/selectedClient';
import { clearMissionCountResponse } from '../../store/dashboard/missionCount';
import { clearCompletedSpaceCountResponse } from '../../store/dashboard/spaceCounts';
import { clearUserCountResponse, getUserCount } from '../../store/dashboard/userCount';
import { setVideoPageNumber } from '../../store/localStates/paginations/videoPagination';
import { clearEnabledSpaceList, getEnabledSpaceList } from '../../store/spaces/enabledSpaceList';
import { clearEnabledMissionList, getEnabledMissionList } from '../../store/missions/enabledMissionList';

import AppLoader from '../AppLoader';
import AppSwitchSmall from '../AppSwitchSmall';
import { toast } from 'react-toastify';

var enumerateDaysBetweenDates = function (startDate, endDate) {
  var dates = [];

  for (var m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
    dates.push(m.format('YYYY-MM-DD'));
  }
  return dates;
};

const AppHeader = ({
  heading,
  isAdd,
  onAdd,
  isSearch,
  onSearch,
  searchValue,
  searchPlaceholder,
  showChangePassword,
  changePassword,
  isVersionToggle,
  versionChecked,
  showRefresh = false,
  onRefresh = () => {},
  onClientChange = () => {},
  onVersionChange = () => {},
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sidebar } = useSelector(bar => bar.sidebar);
  const { enabledClientList } = useSelector(list => list.enabledClientList);
  const { currentClient } = useSelector(client => client.currentClient);
  const { credentials, userInfo, loading: TokenLoading, isExpiredInfo } = useSelector(user => user.credentials);

  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = credentials;
  const { token } = data;

  const random = Math.random();

  const fromDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
  const toDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (enabledClientList !== null && enabledClientList.data && enabledClientList.data.result) {
        if (currentClient === null) {
          const result = enabledClientList.data.result ? enabledClientList.data.result[0] : null;
          dispatch(setClient(result));
        }
      }
    }

    return () => (isSubscribed = false);
  }, [enabledClientList, dispatch, userInfo]);

  const toggleMenu = () => {
    dispatch(setSidebar(!sidebar));
  };

  const onChangeClient = async item => {
    setLoading(true);
    setOpenDropdown(false);

    await dispatch(setClient(item));
    await dispatch(replaceToken(item._id));
    await dispatch(clearEnabledMissionList());
    await dispatch(clearMissionCountResponse());
    await dispatch(clearUserCountResponse());
    await dispatch(clearEnabledSpaceList());
    await dispatch(clearCompletedSpaceCountResponse());
    await dispatch(getEnabledMissionList());
    await dispatch(getEnabledSpaceList());
    await dispatch(setVideoPageNumber(1));
    const dateList = enumerateDaysBetweenDates(fromDate, toDate);
    dateList.forEach(item => dispatch(getUserCount(item)));
    onClientChange(item);
    setLoading(false);
    // history.replace('/')
  };

  useEffect(() => {
    if (credentials && credentials.data && credentials.data.token) {
      dispatch(getExpiredTokenInfo(credentials.data.token));
    }
  }, [credentials]);

  useEffect(() => {
    if (credentials && credentials.data && credentials.data.token) {
      if (!isExpiredInfo && isExpiredInfo !== null && isExpiredInfo !== undefined) {
        toast.success('Your session has expired. Please login again');

        dispatch(clearExpiredTokenInfo());
        dispatch(logoutUser());
      } else {
      }
    }
  }, [isExpiredInfo]);

  return (
    <div className='header_container'>
      <div className='main_header'>
        <div className='header_left_content'>
          <CgMenuGridR className='main_header_menu_icon' onClick={toggleMenu} />
          <p className='main_header_heading'>{heading}</p>
          {isAdd && <img src='/images/add_faq.png' alt='add' className='howto_editlogo mt-left' style={{ cursor: 'pointer' }} onClick={onAdd} />}
          {isSearch && (
            <form autoComplete='false' className='search_container'>
              <img className='howto_search_logo' src='/images/search.png' />
              <input className='background_container_header_input mt-left' placeholder={searchPlaceholder} onChange={onSearch} value={searchValue} autoComplete='false' />
            </form>
          )}
          {isVersionToggle && (
            <div className='version_toggle_conatainer'>
              <AppSwitchSmall checked={versionChecked} onChange={() => onVersionChange(!versionChecked)} />
            </div>
          )}
          {showRefresh && <img src='/images/refresh.png' alt='add' className='howto_editlogo mt-left' style={{ cursor: 'pointer' }} onClick={onRefresh} />}
        </div>

        <div className='change_password_container'>
          {/* {showChangePassword && userInfo && userInfo.userRole !== 'Admin' && (
            <div className='change_password'>
              <img
                src='/images/passkey.png'
                className='howto_card_editlogo mt-left'
                onClick={changePassword}
              />
            </div>
          )} */}
          {(userInfo && userInfo.userRole === 'Admin') || userInfo.isTemporaryLogin ? (
            <div className='dropdown_container' onBlur={() => setOpenDropdown(false)} tabIndex='0' style={{ zIndex: 102 }}>
              {currentClient !== null && (
                <div className='dropdown' onClick={() => setOpenDropdown(!openDropdown)}>
                  <img src='/images/admin.png' alt='role' className='role_image' />
                  <p className='role_title'>{currentClient && currentClient.userName}</p>
                  {openDropdown ? <IoIosArrowUp color='#E56E00' className='arrow_up_down' /> : <IoIosArrowDown color='#E56E00' className='arrow_up_down' />}
                </div>
              )}
              {openDropdown && (
                <div className='list_container'>
                  {enabledClientList &&
                    enabledClientList.data &&
                    enabledClientList.data.result &&
                    enabledClientList.data.result.map((item, index) => {
                      return (
                        <div
                          className={currentClient && currentClient._id === item._id ? 'selected_dropdown_item' : 'dropdown_item'}
                          key={index}
                          onClick={() => onChangeClient(item)}
                        >
                          <p className='dropdown_item_userName'> {item.userName}</p>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <AppLoader loader={loading} />
    </div>
  );
};

export default AppHeader;
