import React, { useEffect, useState } from 'react'
import Content from '../../components/Content'
import styles from './Student.module.css'
import { BigHead } from '@bigheads/core'
import {
  getStudentList,
  clearStudentList,
} from '../../../store/students/getStudentList'
import { useSelector, useDispatch } from 'react-redux'
import {
  changeStudent,
  clearChangeStudent,
} from '../../../store/students/changeStudent'
import toast from 'react-hot-toast'
import Modal from '../../components/Modal'
import { addStudent, clearAddStudent } from '../../../store/students/addStudent'
import { updateToken } from '../../../store/login'
import InputMask from 'react-input-mask'
import AddStudentSkeleton from '../../../src2/components/Skeletons/AddStudentSkeleton'
import { useHistory } from 'react-router-dom'
import Avatar, { Piece } from 'avataaars'

const DEFAULT = {
  accessory: 'none',
  bgColor: 'blue',
  clothing: 'vneck',
  clothingColor: 'black',
  eyebrows: 'concerned',
  eyes: 'simple',
  facialHair: 'none',
  hair: 'short',
  hairColor: 'black',
  hat: 'none',
  hatColor: 'blue',
  lipColor: 'purple',
  mouth: 'openSmile',
  skinTone: 'light',
}

const Student = () => {
  const [showModal, setShowModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [firstKid, setFirstKid] = useState(false)
  const [studentData, setStudentData] = useState({
    name: '',
    age: '',
    gender: 'male',
  })
  const [studentDataError, setStudentDataError] = useState({
    name: '',
    age: '',
    gender: '',
  })
  const [currentSelected, setCurrentSelected] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()

  const { credentials, userInfo } = useSelector((user) => user.credentials)

  const { studentList, loading } = useSelector(
    (student) => student.getStudentList
  )

  const { changeStudentReponse, loading: changeReponseLoading } = useSelector(
    (student) => student.changeStudent
  )

  const { addStudentResponse, loading: addStudentLoading } = useSelector(
    (student) => student.addStudent
  )

  useEffect(() => {
    if (addStudentResponse) {
      if (addStudentResponse.status) {
        toast.success(addStudentResponse.message)
        dispatch(getStudentList())
        dispatch(clearChangeStudent())
        dispatch(clearAddStudent())
        closeModal()
      } else if (!addStudentResponse.status) {
        toast.error(addStudentResponse.message)
        dispatch(clearChangeStudent())
        dispatch(clearAddStudent())
      }
    }
  }, [addStudentResponse])

  useEffect(() => {
    dispatch(getStudentList())
  }, [])

  useEffect(() => {
    if (
      studentList &&
      studentList.data &&
      studentList.data.result &&
      !firstKid
    ) {
      if (studentList.data.result.length === 1) {
        dispatch(changeStudent(studentList.data.result[0]._id))
        setFirstKid(true)
      } else {
        setFirstKid(false)
      }
    }
  }, [studentList, firstKid])

  useEffect(() => {
    if (changeStudentReponse) {
      if (changeStudentReponse.status && userInfo._id) {
        if (!firstKid) {
          toast.success(changeStudentReponse.message)
        }

        dispatch(updateToken(changeStudentReponse))
        dispatch(getStudentList())
        dispatch(clearChangeStudent())
      } else if (!changeStudentReponse.status) {
        toast.error(changeStudentReponse.message)
        dispatch(clearChangeStudent())
      }
    }
  }, [changeStudentReponse, userInfo])

  const changeDefaultStudentHandler = (item) => {
    if (item && item._id) {
      dispatch(changeStudent(item._id))
    }
  }

  const addStudentModal = () => {
    setShowModal(true)
  }

  const onChangeTextHandler = (event) => {
    setStudentData({
      ...studentData,
      [event.target.name]: event.target.value,
    })
    setStudentDataError({
      ...studentDataError,
      [event.target.name]: '',
    })
  }

  const submitHandler = () => {
    let validate = true
    let errors = {}

    if (studentData.name === '') {
      errors = {
        ...errors,
        name: 'Please enter Name',
      }
      validate = false
    }
    if (studentData.age == '') {
      errors = {
        ...errors,
        age: 'Please enter age',
      }
      validate = false
    } else if (Number(studentData.age) <= 0 || Number(studentData.age) > 60) {
      errors = {
        ...errors,
        age: 'age must be between 1 and 60',
      }

      validate = false
    }

    if (studentData.gender === '') {
      errors = {
        ...errors,
        gender: 'Please select gender',
      }
    }

    setStudentDataError(errors)

    if (validate) {
      const data = {
        kidName: studentData.name,
        kidGender: studentData.gender,
        kidAge: studentData.age,
        kidAvatar: {
          ...DEFAULT,
          hair: studentData.gender === 'male' ? 'short' : 'long',
        },
        _id:
          currentSelected && currentSelected._id
            ? currentSelected._id
            : undefined,
      }

      dispatch(addStudent(data))
      closeModal()
    }
  }

  const updateStudentHandler = (item) => {
    setShowModal(true)
    setIsUpdate(true)
    setCurrentSelected(item)
    setStudentData({
      name: item.kidName !== '' ? item.kidName : '',
      age: item.kidAge !== '' ? item.kidAge : '',
      gender: item.kidGender !== '' ? item.kidGender : 'male',
    })
  }

  const closeModal = () => {
    setShowModal(false)
    setStudentData({
      name: '',
      age: '',
      gender: 'male',
    })
    setIsUpdate(false)
  }

  const editAvatarHandler = (item) => {
    history.push(`/student/editAvatar/${item._id}`)
  }

  return (
    <Content
      currentMenu='student'
      headerTitle='Students'
      showAdd={true}
      add={addStudentModal}>
      {loading || changeReponseLoading ? (
        <AddStudentSkeleton />
      ) : (
        <div className={styles.container}>
          {studentList &&
            studentList.data &&
            studentList.data.result &&
            Array.isArray(studentList.data.result) &&
            studentList.data.result.map((item, index) => {
              return (
                <div className={styles.studentcard} key={index}>
                  <div
                    className={styles.selectBox}
                    onClick={changeDefaultStudentHandler.bind(this, item)}>
                    {item.isDefault ? (
                      <svg className={styles.checkmark2}>
                        <use xlinkHref={`/images/sprite.svg#icon-checkmark3`} />
                      </svg>
                    ) : null}
                  </div>
                  <div
                    className={styles.editBox}
                    onClick={updateStudentHandler.bind(this, item)}>
                    <svg className={styles.icon}>
                      <use xlinkHref={`/images/sprite.svg#icon-pencil2`} />
                    </svg>
                  </div>
                  <div
                    className={styles.avatarBox}
                    onClick={editAvatarHandler.bind(this, item)}>
                    <svg className={styles.icon}>
                      <use xlinkHref={`/images/sprite.svg#icon-pencil3`} />
                    </svg>
                  </div>
                  <div className={styles.bigheadsContainer}>
                    {item.kidAvatar && (
                      <Avatar
                        style={{ width: '10rem', height: '10rem' }}
                        avatarStyle={item.kidAvatar.avatarStyle}
                        topType={item.kidAvatar.topType}
                        accessoriesType={item.kidAvatar.accessoriesType}
                        hairColor={item.kidAvatar.hairColor}
                        facialHairType={item.kidAvatar.facialHairType}
                        clotheType={item.kidAvatar.clotheType}
                        clotheColor={item.kidAvatar.clotheColor}
                        eyeType={item.kidAvatar.eyeType}
                        eyebrowType={item.kidAvatar.eyebrowType}
                        mouthType={item.kidAvatar.mouthType}
                        skinColor={item.kidAvatar.skinColor}
                      />
                    )}
                  </div>
                  {item.kidAvatar && item.kidAvatar.background && (
                    <div className={styles.backgroungContainer}>
                      <img
                        src={item.kidAvatar.background}
                        className={styles.background}
                      />
                    </div>
                  )}

                  {item.kidAvatar && item.kidAvatar.tag && (
                    <div className={styles.tagContainer}>
                      <img src={item.kidAvatar.tag} className={styles.tag} />
                    </div>
                  )}

                  <div className={styles.studentName}>{item?.kidName}</div>
                  <div className={styles.coinContaier}>
                    <img src='/images/BagOfCoins.png' className={styles.coin} />
                    <div className={styles.points}>
                      {item?.point % 1 != 0
                        ? Number(item.point).toFixed(2)
                        : ~~item.point}
                    </div>
                  </div>
                </div>
              )
            })}

          {studentList && !studentList.status && (
            <div className={styles.containerError}>
              <p className={styles.message}>{studentList.message}</p>
            </div>
          )}
          <Modal show={showModal}>
            <div className={styles.modalContainer}>
              <div className={styles.modalHeader}>
                {isUpdate ? 'Update Student' : 'Add  Student'}
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.inputSubContainer}>
                  {/* <label className={styles.label}>Student Name</label> */}
                  <div className={styles.input}>
                    <svg className={styles.modalIcon}>
                      <use xlinkHref={`/images/sprite.svg#icon-person2`} />
                    </svg>
                    <input
                      type='text'
                      placeholder='Student Name'
                      name='name'
                      value={studentData.name}
                      className={styles.inputText}
                      onChange={onChangeTextHandler}
                    />
                  </div>
                  {studentDataError.name !== '' ? (
                    <p className={styles.errorMessage}>
                      {studentDataError.name}
                    </p>
                  ) : null}
                </div>
                <div className={styles.inputSubContainer}>
                  {/* <label className={styles.label}>Student Name</label> */}
                  <div className={styles.input}>
                    <svg className={styles.modalIcon}>
                      <use xlinkHref={`/images/sprite.svg#icon-age`} />
                    </svg>
                    <InputMask
                      placeholder='Student Age'
                      name='age'
                      mask='99'
                      maskChar=' '
                      value={studentData.age}
                      className={styles.inputText}
                      onChange={onChangeTextHandler}
                    />
                  </div>
                  {studentDataError.age !== '' ? (
                    <p className={styles.errorMessage}>
                      {studentDataError.age}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.genderContainer}>
                {/* <label className={styles.label}>Student Gender</label> */}
                <div className={styles.chooseContainer}>
                  <label className={styles.radioContainer}>
                    Male
                    <input
                      type='radio'
                      name='gender'
                      value='male'
                      checked={studentData.gender === 'male' ? true : false}
                      onChange={onChangeTextHandler}
                    />
                    <span className={styles.checkmark}></span>
                  </label>
                  <label className={styles.radioContainer}>
                    Female
                    <input
                      type='radio'
                      name='gender'
                      value='female'
                      checked={studentData.gender === 'female' ? true : false}
                      onChange={onChangeTextHandler}
                    />
                    <span className={styles.checkmark}></span>
                  </label>
                  {studentDataError.gender !== '' ? (
                    <p className={styles.errorMessage}>
                      {studentDataError.gender}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.modalButtonContainer}>
                <div
                  className={[styles.button].join(' ')}
                  onClick={submitHandler}>
                  <div className={styles.text}>Save</div>
                  <div className={styles.line}></div>
                </div>

                <div
                  className={[styles.text, styles.button2].join(' ')}
                  onClick={closeModal}>
                  <span className={styles.textButton}>Close</span>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </Content>
  )
}

export default Student
