import {
	ADD_STATISTICS_REQUEST,
	ADD_STATISTICS_SUCCESS,
	ADD_STATISTICS_FAIL,
	ADD_STATISTICS_RESET
} from '../constants/statisticsConstants';

export const addStatisticsReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_STATISTICS_REQUEST:
			return { loading: true };

		case ADD_STATISTICS_SUCCESS:
			return { stats: action.payload, loading: false };

		case ADD_STATISTICS_FAIL:
			return { loading: false, error: action.payload };

		case ADD_STATISTICS_RESET:
			return {};

		default:
			return state;
	}
};
