import React from 'react'
import Styles from './ResponseVideoCard.module.css'

const ResponseVideoCard = ({ data, onEdit, onDelete }) => {
  let details

  let description
  if (data.type === 'success') {
    details = null
    description = 'for success response'
  } else {
    details = Styles.danger
    description = 'for failure response'
  }

  return (
    <div className={Styles.cardContainer}>
      <div className={Styles.container_title}>
        <div className={Styles.container_title_left}>{data.title}</div>
        <div className={Styles.container_title_right}>
          <img
            src='/images/editPrimary.png'
            alt='editvideos'
            className='client_card_edit_image'
            onClick={onEdit}
          />
          <img
            src='/images/delete.png'
            alt='deleteVideos'
            className='client_card_delete_image'
            onClick={onDelete}
          />
        </div>
      </div>
      <div className={Styles.descriptionContainer}>
        <span className={Styles.description + ' ' + details}>
          {description}
        </span>
      </div>

      <div className={Styles.line}></div>
      {data.responseFileType === 'doc' ? (
        <div className={Styles.pdfContainer}>
          <a href={data.videoURL} target='_blank' rel='noreferrer'>
            <img src='/images/pdf.png' alt='pdf' className={Styles.pdfImage} />
          </a>
        </div>
      ) : (
        <div className={Styles.videoContainer}>
          <video controls width='250px' height='120px'>
            <source src={data.videoURL} type='video/mp4' />
            Your browser does not support HTML video.
          </video>
        </div>
      )}
    </div>
  )
}

export default ResponseVideoCard
