import React from 'react'
import { BigHead } from '@bigheads/core'
import { useSelector, useDispatch } from 'react-redux'
import './point-card.css'

const PointsCard = ({ item, index }) => {
  const { credentials, userInfo } = useSelector((user) => user.credentials)

  return (
    <div className='big_heades_main_card_container'>
      <div className='big_heades_card_container'>
        {/* <div className='kid_avatar_container'>
          {item.kidAvatar && item.kidAvatar.background && (
            <img
              alt='avatar'
              src={item.kidAvatar.background}
              className='kid_avatar_background_image'
            />
          )}
          <div className='big_heads_container'>
            {item && item.kidAvatar && (
              <BigHead
                // accessory={item.kidAvatar.accessory}
                clothing={item.kidAvatar.clothing}
                clothingColor={item.kidAvatar.clothingColor}
                eyebrows={item.kidAvatar.eyebrows}
                // eyes={item.kidAvatar.eyes}
                facialHair={item.kidAvatar.facialHair}
                // hair={item.kidAvatar.hair}
                hairColor={item.kidAvatar.hairColor}
                // hat={item.kidAvatar.hat}
                hatColor={item.kidAvatar.hatColor}
                lipColor={item.kidAvatar.lipColor}
                mouth={item.kidAvatar.mouth}
                skinTone={item.kidAvatar.skinTone}
              />
            )}
          </div>
          {item.kidAvatar && item.kidAvatar.tag && (
            <img
              alt='avatar'
              src={item.kidAvatar.tag}
              className='kid_avatar_tag_image'
            />
          )}
        </div> */}
        <div className='big_heades_card_view'>
          <div className='big_heades_card_view_container'>
            <div className='client_card_detail_container'>
              <p className='big_heades_card_title'>
                {item.kidName}
                {userInfo && userInfo.clientType !== 'lab'
                  ? ' | Age:'
                  : null}{' '}
                {item.kidAge && userInfo && userInfo.clientType !== 'lab'
                  ? item.kidAge
                  : null}
              </p>
              <div className='flex'>
                {userInfo && userInfo.clientType !== 'lab' ? (
                  <p
                    className='client_card_count'
                    style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>
                    Parent Name: {item.userName}{' '}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <p className='pointsEarned'>
            Earned Points: {Math.floor(item.total)}
          </p>
        </div>
      </div>
      {index < 3 && (
        <div
          className='rank_container'
          style={{
            backgroundColor:
              index === 0
                ? 'var(--primary)'
                : index === 1
                ? 'var(--secondary)'
                : 'var(--red)',
          }}>
          <p>rank {index === 0 ? '1' : index === 1 ? '2' : '3'}</p>
        </div>
      )}
    </div>
  )
}

export default PointsCard
