import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  getOnboard,
  clearGetOnBoardList,
} from '../../../../store/onBoarding/getOnBoarding'

import AppHeader from '../../../../components/AppHeader'
import ClinicCard from '../../../../components/Cards/ClinicCard'
import Pagination from '../../../../components/Pagination'
import {
  confirmApproval,
  clearConfirmApproval,
} from '../../../../store/onBoarding/updateApproval'
import AppLoader from '../../../../components/AppLoader'

const OnBoard = () => {
  const [search, setSearch] = useState('')

  const [progress, setProgress] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 8

  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { getOnboardList, loading } = useSelector(
    (state) => state.getOnBoarding
  )
  const { approvalResponse } = useSelector((state) => state.updateApproval)

  useEffect(() => {
    dispatch(getOnboard({ filter: '', pageNumber, pageSize }))
  }, [])

  useEffect(() => {
    if (approvalResponse) {
      if (approvalResponse.status) {
        toast.success(approvalResponse.message)
        dispatch(getOnboard({ filter: '', pageNumber, pageSize }))
        dispatch(clearConfirmApproval())
      } else {
        toast.error(approvalResponse.message)
        dispatch(clearConfirmApproval())
      }
    }
  }, [approvalResponse])

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(
      getOnboard({ pageSize, pageNumber: selectedPage + 1, filterValue: '' })
    )
    setPageNumber(selectedPage + 1)
  }

  const onApprovalHandler = (item) => {
    dispatch(confirmApproval(item._id))
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='On Board' />
      <div className='clinic_main_content'>
        {getOnboardList &&
          getOnboardList.data &&
          getOnboardList.data.result &&
          getOnboardList.data.result.map((item, index) => {
            return (
              <ClinicCard
                key={index}
                item={item}
                showSwitch={false}
                showPassword={false}
                showModule={false}
                showEdit={false}
                showApproval={true}
                onApproval={onApprovalHandler.bind(this, item)}
              />
            )
          })}
      </div>
      {getOnboardList !== null &&
        getOnboardList.data &&
        getOnboardList.data.result &&
        getOnboardList.data.result.length > 0 &&
        getOnboardList.data.totalPageSize > 8 && (
          <Pagination
            list={getOnboardList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
      <AppLoader loader={loading} />
    </div>
  )
}

export default OnBoard
