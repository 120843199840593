import React, { Fragment, useState } from 'react'
import EmogiPicker from '../../../../../components/EmogiPicker';
import TextInput from '../../../../../components/forms/TextInput';

const MultipleOptions = ({ multipleOptions, setMultipleOptions, multipleAnswers, setMultipleAnswers, onRemoveError, errorMessage }) => {

    const [multipleOptionTitle, setMultipleOptionTitle] = useState("")
    const [multipleOptionTitleError, setMultipleOptionTitleError] = useState("")

    const [isEdit, setIsEdit] = useState(null)
    const [multipleOptionEdit, setMultipleOptionEdit] = useState("")

    const [displayEmogies, setDisplayEmogies] = useState(false);
    const [emogi, setEmogi] = useState(false)

    const onAddMultipleOptionHandler = () => {
        // onRemoveError()
        if (multipleOptionTitle === "") {
            setMultipleOptionTitleError("Please enter Question option")
        } else {
            setMultipleOptions([
                ...multipleOptions,
                {
                    isSelected: false,
                    optionTitle: multipleOptionTitle,
                    value: multipleOptionTitle,
                    isChecked: false
                }
            ])
            setMultipleOptionTitle("")
        }
    }

    const onEditHandler = index => {
        const editable = multipleOptions.find((_, i) => i === index)
        setIsEdit(index)
        setMultipleOptionEdit(editable.optionTitle)
    }

    const onEditCancelHandler = () => {
        setIsEdit(null)
    }

    const onOptionDeleteHandler = i => {
        setMultipleOptions(multipleOptions.filter((_, index) => index !== i))
    }

    const onSetEdit = index => {
        setIsEdit(null)
        const data = JSON.parse(JSON.stringify(multipleOptions))
        data.forEach((_, inx) => {
            if (index === inx) {
                _.optionTitle = multipleOptionEdit
                _.value = multipleOptionEdit
            }
        })
        setMultipleOptions(data)
    }

    const setMultipleAnswer = (item, index) => {
        const data = JSON.parse(JSON.stringify(multipleOptions))
        data.forEach((is, i) => {
            if (index === i) {
                is.isChecked = !is.isChecked
            }
        })
        setMultipleOptions(data)

        if (multipleAnswers && Array.isArray(multipleAnswers)) {
            if (multipleAnswers.length === 0) {
                setMultipleAnswers([{ value: item.value }])
            } else {
                const id = multipleAnswers.some((_, id) => _.value === item.value)
                if (id) {
                    setMultipleAnswers(multipleAnswers.filter((i, _) => i.value !== item.value))
                } else {
                    setMultipleAnswers([...multipleAnswers, { value: item.value }])
                }
            }
        }
    }

    return (
        <Fragment>
            <div className='flex' style={{ gap: '2rem' }}>
                <TextInput
                    placeholder='Answer'
                    src='/images/question_answer.png'
                    onChange={(option) => {
                        setMultipleOptionTitleError('');
                        setMultipleOptionTitle(option.target.value);
                    }}
                    value={multipleOptionTitle}
                    error={multipleOptionTitleError}
                />
                <div className="emogi_wrapper" onClick={() => setDisplayEmogies(!displayEmogies)}>
                    <p>😊</p>
                </div>
                <div className='addBtn' onClick={onAddMultipleOptionHandler}>
                    <img src='/images/add_blue.png' alt='add icon' className='addBtnIcon' />
                </div>
            </div>
            <div className='answers'>
                {
                    displayEmogies &&
                    <EmogiPicker onClose={() => setDisplayEmogies(false)} setEmogi={emogi => setMultipleOptionTitle(prev => prev + emogi)} />
                }
                {
                    multipleOptions &&
                    multipleOptions.length > 0 &&
                    multipleOptions.map((question, qIndex) => (
                        <Fragment>
                            <div className='answer' key={qIndex}>
                                <div className='answer_textBox'>
                                    {
                                        isEdit !== null && isEdit === qIndex ?
                                            <Fragment>
                                                <input
                                                    type='text'
                                                    className='answer_text_input'
                                                    autoFocus
                                                    value={multipleOptionEdit}
                                                    onChange={option => setMultipleOptionEdit(option.target.value)}
                                                />
                                                <div className='flex'>
                                                    <div className="edit_emogi" onClick={() => setEmogi(!emogi)}>
                                                        <p>😊</p>
                                                    </div>
                                                    <img src='/images/icon_tick.png' alt='right tick' className='answer_text_icon' onClick={() => onSetEdit(qIndex)} />
                                                    <img
                                                        src='/images/icon_delete.png'
                                                        alt='right tick'
                                                        className='answer_text_icon'
                                                        onClick={onEditCancelHandler}
                                                    />
                                                </div>
                                            </Fragment> :
                                            <Fragment>
                                                <p className='answer_text'>{question.optionTitle}</p>
                                                <div className='flex'>
                                                    <img src='/images/icon_edit.png' alt='right tick' className='answer_text_icon' onClick={() => onEditHandler(qIndex)} />
                                                    <img src='/images/delete.png' alt='right tick' className='answer_text_icon' onClick={() => onOptionDeleteHandler(qIndex)} />
                                                </div>
                                            </Fragment>
                                    }

                                </div>
                                <div className='answer_right' onClick={() => setMultipleAnswer(question, qIndex)}>
                                    {
                                        question.isChecked ?
                                            <>
                                                <img src='/images/icon_tick.png' alt='right tick' className='answer_right_icon' />
                                                <p className='answer_right_text'>Right Answer</p>
                                            </> :
                                            <p className='answer_makeright_text'>Make as right answer</p>
                                    }

                                </div>
                            </div>
                            {
                                isEdit === qIndex && emogi &&
                                <EmogiPicker onClose={() => setEmogi(false)} setEmogi={emogi => setMultipleOptionEdit(prev => prev + emogi)} />
                            }
                        </Fragment>
                    ))
                }
            </div>
            <p className='input_error_text'>{errorMessage}</p>
        </Fragment>
    )
}

export default MultipleOptions