import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import AppHeader from '../../../../components/AppHeader'
import CategoryCard from '../../../../components/Cards/CategoryCard'
import AppModal2 from '../../../../components/AppModal2'
import AppModal from '../../../../components/AppModal'
import ProgressBar from '../../../../components/forms/ProgressBar'
import AppButton from '../../../../components/forms/AppButton'
import AllQuestionCategoriesSkeleton from '../../../../components/Skeletons/AllQuestionCategoriesSkeleton'
import TextInput from '../../../../components/forms/TextInput'
import Pagination from '../../../../components/Pagination'
import AppSwitch from '../../../../components/AppSwitch'

import { getSpaceList } from '../../../../store/spaces/SpaceList'
import {
  clearDeleteSpaceResponse,
  deleteSpace,
} from '../../../../store/spaces/deleteSpace'

import { validateOnlyChacters } from '../../../../utils/validators'
import { getCetegoryList } from '../../../../store/cetegory/cetegoryList'
import { setEnableDisableCategory } from '../../../../store/cetegory/enableDisableClient'
import {
  addCetegory,
  clearAddCetegoryResponse,
} from '../../../../store/cetegory/addCetegory'
import EditInputData from '../../../../components/forms/EditInputData'

const QuestionCategories = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { addCetegoryResponse } = useSelector(
    (list) => list.addCetegoryResponse
  )
  const { deleteSpaceResponse } = useSelector(
    (list) => list.deleteSpaceResponse
  )
  const { cetegoryList, loading } = useSelector((list) => list.cetegoryList)

  const [search, setSearch] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [progress, setProgress] = useState(null)
  const [isUpadate, setIsUpadate] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [deleteWithReference, setDeleteWithReference] = useState(false)

  const [metaData, setMetaData] = useState([])
  const [data, setData] = useState({
    value: '',
    error: '',
  })

  const [categoryName, setCategoryName] = useState('')
  const [categoryNameError, setCategoryNameError] = useState('')

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 15

  useEffect(() => {
    const initialAPIs = async () => {
      await dispatch(getCetegoryList({ pageSize, pageNumber, filterValue: '' }))
    }
    initialAPIs()
  }, [dispatch])

  useEffect(() => {
    if (addCetegoryResponse !== null) {
      if (addCetegoryResponse.status) {
        toast.success(addCetegoryResponse.message)
        dispatch(getCetegoryList({ pageSize, pageNumber, filterValue: '' }))
        dispatch(clearAddCetegoryResponse())
        setProgress(null)
        onCloseModal()
      } else {
        toast.error(addCetegoryResponse.message)
        dispatch(clearAddCetegoryResponse())
      }
    }
  }, [addCetegoryResponse, dispatch])

  useEffect(() => {
    if (deleteSpaceResponse !== null) {
      if (deleteSpaceResponse.status) {
        closeDeleteModal()
        toast.success(deleteSpaceResponse.message)
        dispatch(getSpaceList({ pageSize, pageNumber, filterValue: '' }))
        dispatch(clearDeleteSpaceResponse())
      } else {
        toast.error(deleteSpaceResponse.message)
        dispatch(clearDeleteSpaceResponse())
      }
    }
  }, [deleteSpaceResponse, dispatch])

  const onSearchCategory = (event) => {
    setSearch(event.target.value)
    dispatch(
      getCetegoryList({ pageSize, pageNumber, filterValue: event.target.value })
    )
  }

  const handleAddSpace = () => {
    setShowAddModal(true)
  }

  const enableDisableHandler = async ({ _id, enable }) => {
    setCurrentItem(_id)
    await dispatch(setEnableDisableCategory({ _id, enable }))
    await dispatch(getCetegoryList({ pageSize, pageNumber, filterValue: '' }))
    setCurrentItem(null)
  }

  const onCloseModal = () => {
    setIsUpadate(null)
    setShowAddModal(false)
    setCategoryName('')
    setData({
      value: '',
      error: '',
    })
    setMetaData([])
  }

  const onEditHandler = (item) => {
    setShowAddModal(true)
    setIsUpadate(item)
    setCategoryName(item.title)
    if (item.metadata) {
      setMetaData(item.metadata)
    }
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault()

    if (categoryName === '') {
      setCategoryNameError('Please enter Category title')
    } else if (categoryName.length < 2) {
      setCategoryNameError('Category title must be two character long')
    } else if (validateOnlyChacters(categoryName)) {
      setCategoryNameError('Please enter valid Category title')
    } else if (metaData.length === 0) {
      setData({
        value: '',
        error: 'Please enter at least one meta data',
      })
    } else {
      if (isUpadate) {
        await dispatch(
          addCetegory(
            { title: categoryName, metadata: metaData, id: isUpadate._id },
            (progress) => setProgress(progress)
          )
        )
      } else {
        await dispatch(
          addCetegory({ title: categoryName, metadata: metaData }, (progress) =>
            setProgress(progress)
          )
        )
      }
    }
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(
      getSpaceList({ pageSize, pageNumber: selectedPage + 1, filterValue: '' })
    )
    setPageNumber(selectedPage + 1)
  }

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference)
  }

  const onDeleteHandler = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }

  const onDeleteMission = () => {
    if (deletedItem !== null) {
      dispatch(deleteSpace(deletedItem._id, deleteWithReference))
    }
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
    setDeleteWithReference(false)
  }

  const onClientChange = (item) => {
    const initialAPIs = async () => {
      await dispatch(getCetegoryList({ pageSize, pageNumber, filterValue: '' }))
    }
    initialAPIs()
  }

  const addMetaData = () => {
    const arr = [...metaData]

    if (data.value === '' && data.value.trim() === '') {
      setData({
        ...data,
        error: 'Please enter metadata',
      })
      return
    }

    arr.push(data.value)

    setMetaData([...arr])
    setData({
      value: '',
      error: '',
    })
  }

  const changeMetaData = (data, index) => {
    const arr = [...metaData]

    arr[index] = data

    setMetaData([...arr])
  }

  const deleteMetaData = (data, index) => {
    const arr = [...metaData]
    arr.splice(index, 1)
    setMetaData([...arr])
  }

  console.log('metadata', metaData)

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Question Categories'
        isAdd
        onAdd={handleAddSpace}
        isSearch
        searchPlaceholder='Search Categories'
        onSearch={onSearchCategory}
        searchValue={search}
        onClientChange={onClientChange}
      />
      {currentItem === null && loading ? (
        <AllQuestionCategoriesSkeleton />
      ) : (
        <Fragment>
          <div className='client__container'>
            <div className='missions_main_content'>
              {cetegoryList !== null &&
              cetegoryList.data &&
              cetegoryList.data.result &&
              cetegoryList.data.result.length > 0 ? (
                cetegoryList.data.result.map((item, index) => (
                  <CategoryCard
                    key={index}
                    item={item}
                    loading={loading}
                    onEditClick={onEditHandler}
                    currentItem={currentItem}
                    onChangeSwitch={enableDisableHandler}
                    onDelete={onDeleteHandler}
                  />
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== ''
                      ? 'No search results found'
                      : "You havn't created any categories yet. Please create one"}
                  </p>
                </div>
              )}
            </div>
          </div>
          {cetegoryList !== null &&
            cetegoryList.data &&
            cetegoryList.data.result &&
            cetegoryList.data.result.length > 0 &&
            cetegoryList.data.totalPageSize > 15 && (
              <Pagination
                list={cetegoryList}
                onPageChange={onPageChange}
                rowsPerPage={pageSize}
              />
            )}
          <AppModal show={showAddModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>
                {isUpadate ? 'Edit Category' : 'Add Category'}
              </p>
              <form
                className='clinic_form_container'
                onSubmit={onSubmitHandler}>
                <div className='clinic_form_top_container'>
                  <div className='clinic_form_top_left_container'>
                    <TextInput
                      placeholder='Category Name'
                      src='/images/missions-input.png'
                      onChange={(mission) => {
                        setCategoryNameError('')
                        setCategoryName(mission.target.value)
                      }}
                      value={categoryName}
                      error={categoryNameError}
                      maxLength={20}
                    />
                    <div className='flex' style={{ gap: '2rem' }}>
                      <TextInput
                        placeholder='Meta Data'
                        src='/images/question_answer.png'
                        onChange={(event) => {
                          setData({
                            value: event.target.value,
                            error: '',
                          })
                        }}
                        value={data.value}
                        error={data.error}
                      />

                      <div className='addBtn' onClick={addMetaData}>
                        <img
                          src='/images/add_blue.png'
                          alt='add icon'
                          className='addBtnIcon'
                        />
                      </div>
                    </div>
                    {metaData.map((item, i) => {
                      return (
                        <EditInputData
                          data={item}
                          key={i}
                          index={i}
                          changeData={changeMetaData}
                          deleteMetaData={deleteMetaData}
                        />
                      )
                    })}
                    <div className='modal_buttons'>
                      <AppButton
                        title={isUpadate ? 'update' : 'add'}
                        type='submit'
                        icon={
                          <img
                            src='/images/add-clinic.png'
                            className='app_button_image'
                          />
                        }
                        onClick={onSubmitHandler}
                      />
                      <AppButton
                        title='cancel'
                        type='button'
                        buttonContainer={{
                          backgroundColor: 'var(--transparent)',
                          marginLeft: '0.5rem',
                        }}
                        textStyle={{
                          color: 'var(--black)',
                          fontWeight: 'bold',
                        }}
                        onClick={onCloseModal}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
          <AppModal show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete mission</p>
              <p>{deletedItem !== null && deletedItem.title}</p>
              <AppSwitch
                checked={deleteWithReference}
                onChange={onDeleteStatusChange}
              />
              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteMission}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal>
        </Fragment>
      )}
    </div>
  )
}

export default QuestionCategories
