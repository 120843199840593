import React from 'react';
import ProgressBarSkeleton from './ProgressBarSkeleton';

import './Skeleton.css';
const CategorySkeleton = () => {
	const count = [1, 2, 3, 4, 5, 6, 7, 8];
	return (
		<div className='client__container'>
			<div className='missions_main_content'>
				{count &&
					count.map((item) => (
						<div key={item} className='client_card_skeleton'>
							<div className='flex'>
								<div className='client_card_image_skeleton' />
								<div>
									<div className='client_card_heading_skeleton skeleton' />
									<div className='client_card_spaces_skeleton skeleton' />
									<div className='client_card_view_btn_skeleton skeleton' />
								</div>
							</div>
							<div className='client_card_actions'>
								<ProgressBarSkeleton />
								{/* <div className='flex'>
									<div className='client_card_toggle_skeleton skeleton' />
									<div className='client_card_edit_skeleton skeleton' />
								</div>
								<div className='clinet_card_delete_skeleton skeleton' /> */}
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default CategorySkeleton;
