import React from 'react'
import SkeletonStyles from './Skeletons.module.css'
import styles from '../../screens/MyProfile/MyProfile.module.css'

const MyProfileSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.subContainer}>
          <div
            className={[styles.bigheadsContainer, SkeletonStyles.skeleton].join(
              ' '
            )}></div>
          <div className={styles.detailsContainer}>
            <div
              className={[styles.skeletonTitle, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonName, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonPhone, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonPhone, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonPhone, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonButton, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
          </div>
        </div>
        <div className={styles.subContainer}>
          <div
            className={[styles.bigheadsContainer, SkeletonStyles.skeleton].join(
              ' '
            )}></div>
          <div className={styles.detailsContainer}>
            <div
              className={[styles.skeletonTitle, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonName, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonPhone, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonPhone, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonPhone, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
            <div
              className={[styles.skeletonButton, SkeletonStyles.skeleton].join(
                ' '
              )}></div>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}></div>
    </div>
  )
}

export default MyProfileSkeleton
