import axios from 'axios'
import {
  GET_PROPERTIES_REQUEST,
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTIES_FAIL,
  GET_MEDIA_DETAILS_REQUEST,
  GET_MEDIA_DETAILS_SUCCESS,
  GET_MEDIA_DETAILS_FAIL,
  GET_PROPERTY_QUESTIONS_REQUEST,
  GET_PROPERTY_QUESTIONS_SUCCESS,
  GET_PROPERTY_QUESTIONS_FAIL,
  GET_QUESTION_PROGRESS_REQUEST,
  GET_QUESTION_PROGRESS_SUCCESS,
  GET_QUESTION_PROGRESS_FAIL,
  GET_RESPONSE_VIDEO_REQUEST,
  GET_RESPONSE_VIDEO_SUCCESS,
  GET_RESPONSE_VIDEO_FAIL,
} from '../constants/propertyConstants'
import { baseUrl } from '../../../middleware/url'

export const getAllPropertiesAction =
  (spaceId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_PROPERTIES_REQUEST })
      const {
        credentials: { credentials },
      } = getState()
      const config = {
        headers: {
          token: credentials.data.token,
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.get(
        `${baseUrl}/space/get-properties-by-space-id-mobile?spaceId=${spaceId}`,
        config
      )

      dispatch({
        type: GET_PROPERTIES_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: GET_PROPERTIES_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      })
    }
  }

export const getMediaDetailsAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_MEDIA_DETAILS_REQUEST })
      const {
        credentials: { credentials },
      } = getState()
      const config = {
        headers: {
          token: credentials.data.token,
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${baseUrl}/media/get-media-details-by-id-for-mobile`,
        { media: formData },
        config
      )

      dispatch({
        type: GET_MEDIA_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: GET_MEDIA_DETAILS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      })
    }
  }

export const getPropertyQuestionsAction =
  (mediaId, propertyId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_PROPERTY_QUESTIONS_REQUEST })
      const {
        credentials: { credentials },
      } = getState()
      const config = {
        headers: {
          token: credentials.data.token,
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.get(
        `${baseUrl}/media/get-enabled-questions-for-mobile?id=${mediaId}&property=${propertyId}`,
        config
      )

      dispatch({
        type: GET_PROPERTY_QUESTIONS_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: GET_PROPERTY_QUESTIONS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      })
    }
  }

export const getQuestionProgressAction =
  (missionId, spaceId, propertyId, mediaId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_QUESTION_PROGRESS_REQUEST })
      const {
        credentials: { credentials },
        userDetails,
      } = getState()
      const clientType =
        userDetails &&
        userDetails.userInfo &&
        userDetails.userInfo.data &&
        userDetails.userInfo.data.client &&
        userDetails.userInfo.data.client.clientType

      const config = {
        headers: {
          token: credentials.data.token,
          'Content-Type': 'application/json',
        },
      }
      console.log("missionId, spaceId, propertyId, mediaId", missionId, spaceId, propertyId, mediaId);
      const { data } =
        clientType === 'lab'
          ? await axios.post(
              `${baseUrl}/statistics/get-user-statistics?offset=-360`,
              {
                mission: missionId,
                space: spaceId,
                properties: propertyId,
                media: mediaId,
              },
              config
            )
          : await axios.get(
              `${baseUrl}/progress/get-completed-question-count-for-user-space?offset=-360&space=${spaceId}`,
              config
            )

      dispatch({
        type: GET_QUESTION_PROGRESS_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: GET_QUESTION_PROGRESS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      })
    }
  }

export const getResponseVideosAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_RESPONSE_VIDEO_REQUEST })
    const {
      credentials: { credentials },
    } = getState()
    const config = {
      headers: {
        token: credentials.data.token,
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.get(
      `${baseUrl}/generic/get-response-content-video-data`,
      config
    )

    dispatch({
      type: GET_RESPONSE_VIDEO_SUCCESS,
      payload: data,
    })
  } catch (err) {
    dispatch({
      type: GET_RESPONSE_VIDEO_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    })
  }
}
