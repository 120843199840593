import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPrivacyPolicyAction } from '../../redux/actions/aboutActions'

import Content from '../../components/Content'
import AboutScreenStyles from './About.module.css'
import PrivacySkeleton from '../../components/Skeletons/PrivacySkeleton'

const PrivacyPolicy = () => {
  const dispatch = useDispatch()

  const privacyPolicy = useSelector((state) => state.privacyPolicy)

  React.useEffect(() => {
    dispatch(getPrivacyPolicyAction())
  }, [dispatch])

  return (
    <Content currentMenu='about' headerTitle='Privacy Policy'>
      {privacyPolicy && privacyPolicy.loading ? (
        <PrivacySkeleton />
      ) : (
        privacyPolicy &&
        privacyPolicy.data &&
        privacyPolicy.data.data &&
        privacyPolicy.data.data.result && (
          //   <div className={AboutScreenStyles.about_container_short}>
          //     {privacyPolicy.data.data.result.content}
          //   </div>
          <textarea
            spellCheck={false}
            autoFocus
            rows={17}
            className='textarea textAreaColor'
            style={{ borderBottom: 'none' }}
            value={privacyPolicy.data.data.result.content}
          />
        )
      )}

      <div className={AboutScreenStyles.aboutLinks}>
        <Link to='/about-bx' className={AboutScreenStyles.aboutLink}>
          About Bx
        </Link>
        <Link to='/about-algo' className={AboutScreenStyles.aboutLink}>
          Developer Details
        </Link>
      </div>
    </Content>
  )
}

export default PrivacyPolicy
