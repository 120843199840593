import moment from 'moment';

export const validateOnlyChacters = name => {
  const re = /^[a-zA-Z\ \s]+$/;
  return !re.test(name);
};

export const validatePassword = password => {
  const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/;
  return re.test(password);
};

export const validateEmail = email => {
  const re = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(email);
};

export const validatePhone = phone => {
  // const re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
  const re = /^\d{10}$/
  return re.test(phone);
};

export const validateFillInTheBlank = title => {
  var count = 0;
  for (var i = 0; i < title.length; i++) {
    if (title[i] === '*') {
      count = count + 1;
    }
  }
  if (count === 1) {
    return false;
  } else if (count !== 1) {
    return true;
  }
};
export const getCountdown = (week, year) => {
  const source = new Date().getTime();
  const destination = new Date(
    moment()
      .day(0)
      .week(week + 2)
      .year(year)
      .hour(12)
      .minute(0)
      .second(0)
  ).getTime();
  const diffrence = destination - source;
  const days = Math.floor(diffrence / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffrence % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffrence % (1000 * 60 * 60)) / (1000 * 60));
  return `${days}d ${hours}h ${minutes}m`;
};

export const inputPhoneMasking = e => {
  let value = e;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');
  value = value.substring(0, 12);

  return value;
};

export const unMasking = e => {
  let value = e;
  value = value.replaceAll('-', '');

  return value;
};

export const returnOnlyNumber = data => {
  let value = data;
  value = value.replace(/\D/g, '');
  return value;
};
