import React from 'react';
import ModalButtonSkeleton from './ModalButtonSkeleton';
import SkeletonStyles from './Skeletons.module.css';

const PropertyAnswerSkeleton = () => {
	return (
		<div className={SkeletonStyles.flexColumn} style={{ alignItems: 'flex-start' }}>
			<div className={[ SkeletonStyles.questionTitle, SkeletonStyles.skeleton2 ].join(' ')} style={{ width: '100%' }} />
			<div className={[ SkeletonStyles.questionTitle, SkeletonStyles.skeleton2 ].join(' ')} />
			<div
				className={[ SkeletonStyles.questionTitle, SkeletonStyles.skeleton2 ].join(' ')}
				style={{ marginTop: '1rem', width: '20%' }}
			/>

			<div
				className={[ SkeletonStyles.questionOptions, SkeletonStyles.skeleton2 ].join(' ')}
				style={{ marginTop: '2rem', width: '25%' }}
			/>
			<div
				className={[ SkeletonStyles.questionOptions, SkeletonStyles.skeleton2 ].join(' ')}
				style={{ width: '15%' }}
			/>
			<div
				className={[ SkeletonStyles.questionOptions, SkeletonStyles.skeleton2 ].join(' ')}
				style={{ width: '25%' }}
			/>
			<div
				className={[ SkeletonStyles.questionOptions, SkeletonStyles.skeleton2 ].join(' ')}
				style={{ width: '15%' }}
			/>
			<div
				className={[ SkeletonStyles.questionOptions, SkeletonStyles.skeleton2 ].join(' ')}
				style={{ width: '25%' }}
			/>
			<div
				className={[ SkeletonStyles.questionOptions, SkeletonStyles.skeleton2 ].join(' ')}
				style={{ width: '10%', marginBottom: '2rem' }}
			/>

			<ModalButtonSkeleton />
		</div>
	);
};

export default PropertyAnswerSkeleton;
