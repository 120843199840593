export const GET_PROPERTIES_REQUEST = 'GET_PROPERTIES_REQUEST';
export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS';
export const GET_PROPERTIES_FAIL = 'GET_PROPERTIES_FAIL';
export const GET_PROPERTIES_RESET = 'GET_PROPERTIES_RESET';

export const GET_MEDIA_DETAILS_REQUEST = 'GET_MEDIA_DETAILS_REQUEST';
export const GET_MEDIA_DETAILS_SUCCESS = 'GET_MEDIA_DETAILS_SUCCESS';
export const GET_MEDIA_DETAILS_FAIL = 'GET_MEDIA_DETAILS_FAIL';
export const GET_MEDIA_DETAILS_RESET = 'GET_MEDIA_DETAILS_RESET';

export const GET_PROPERTY_QUESTIONS_REQUEST = 'GET_PROPERTY_QUESTIONS_REQUEST';
export const GET_PROPERTY_QUESTIONS_SUCCESS = 'GET_PROPERTY_QUESTIONS_SUCCESS';
export const GET_PROPERTY_QUESTIONS_FAIL = 'GET_PROPERTY_QUESTIONS_FAIL';
export const GET_PROPERTY_QUESTIONS_RESET = 'GET_PROPERTY_QUESTIONS_RESET';

export const GET_QUESTION_PROGRESS_REQUEST = 'GET_QUESTION_PROGRESS_REQUEST';
export const GET_QUESTION_PROGRESS_SUCCESS = 'GET_QUESTION_PROGRESS_SUCCESS';
export const GET_QUESTION_PROGRESS_FAIL = 'GET_QUESTION_PROGRESS_FAIL';
export const GET_QUESTION_PROGRESS_RESET = 'GET_QUESTION_PROGRESS_RESET';

export const GET_RESPONSE_VIDEO_REQUEST = 'GET_RESPONSE_VIDEO_REQUEST';
export const GET_RESPONSE_VIDEO_SUCCESS = 'GET_RESPONSE_VIDEO_SUCCESS';
export const GET_RESPONSE_VIDEO_FAIL = 'GET_RESPONSE_VIDEO_FAIL';
export const GET_RESPONSE_VIDEO_RESET = 'GET_RESPONSE_VIDEO_RESET';
