import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'update-user',
    initialState: {
        updateUserResponse: null,
        loading: false,
    },
    reducers: {
        requested: (user) => {
            user.loading = true
        },
        success: (user, action) => {
            user.updateUserResponse = action.payload
            user.loading = false
        },
        failed: (user, action) => {
            user.updateUserResponse = action.payload;
            user.loading = false
        },
        reset: (user, action) => {
            user.updateUserResponse = action.payload;
            user.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const updateUser = (token, data) => apiCallBegan({
    url: '/user/update-user',
    method: 'POST',
    token,
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})
export const clearUpdateUserResponse = () => apiCallBegan({
    onReset: reset.type,
})