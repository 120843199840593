import React from 'react'
import '../VideoPlayer/VideoPlayer.css'
import Lottie from 'react-lottie'
import animationData from './audio.json'

const AudioPlayer = ({ controls = false, videoSource, onEnded }) => {
  console.log('source', videoSource)

  const propVideo = document.getElementById('propertyAudio')
  const playBtn = document.querySelector('.play-btn')

  const [totalDuration, setTotalDuration] = React.useState(0)
  const [currentDuration, setCurrentDuration] = React.useState(0)
  const [play, setPlay] = React.useState(false)

  const neatTime = (time) => {
    var hours = Math.floor((time % 86400) / 3600)
    var minutes = Math.floor((time % 3600) / 60)
    var seconds = Math.floor(time % 60)
    seconds = seconds > 9 ? seconds : `0${seconds}`
    return `${hours}:${minutes}:${seconds}`
  }

  const togglePlay = () => {
    if (propVideo.paused) {
      propVideo.play()
      setPlay(true)
    } else {
      propVideo.pause()
      setPlay(false)
    }

    playBtn.classList.toggle('paused')
  }

  const togglePlayBtn = () => {
    playBtn.classList.toggle('playing')
  }

  const showPlayBtn = () => {
    const playBtnContainer = document.querySelector('.playBtnContainer')
    playBtnContainer.classList.add('playBtnContainer_visible')
  }
  const removePlayBtn = () => {
    const playBtnContainer = document.querySelector('.playBtnContainer')
    playBtnContainer.classList.remove('playBtnContainer_visible')
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  }

  return (
    <div className='videoContainer'>
      {!controls && (
        <div
          className='playBtnContainer'
          onMouseOver={showPlayBtn}
          onMouseEnter={showPlayBtn}
          onMouseLeave={removePlayBtn}
          onClick={togglePlay}>
          <div className='playBtnContainerText'>
            {play ? 'Pause Audio' : 'Play Audio'}
          </div>
          <div className='play-btn' />
        </div>
      )}

      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        isPaused={play ? false : true}
        isClickToPauseDisabled={true}
      />

      <audio
        controls={controls}
        onEnded={() => {
          onEnded()
          setPlay(false)

          playBtn.classList.remove('paused')

          playBtn.classList.remove('playing')
        }}
        id='propertyAudio'
        onClick={togglePlay}
        onPlay={togglePlayBtn}
        onPause={togglePlayBtn}
        onMouseEnter={showPlayBtn}
        onLoadedData={(e) => setTotalDuration(e.target.duration)}
        // onCanPlay={() => setCanPlay(true)}
        onTimeUpdate={(e) => setCurrentDuration(e.target.currentTime)}
        onMouseLeave={showPlayBtn}>
        <source src={videoSource} type='audio/mpeg' />
        <source src={videoSource} type='audio/ogg' />
        Your browser does not support the video tag.
      </audio>

      <div className='seekBar'>
        <p>{neatTime(currentDuration)}</p>
        <div
          className='currentPosition'
          style={{
            width: `${(currentDuration / totalDuration) * 100}%`,
          }}
        />
        <p>{neatTime(totalDuration)}</p>
      </div>
    </div>
  )
}

export default AudioPlayer
