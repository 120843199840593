import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enabled-space-list',
    initialState: {
        enabledSpaceList: [],
        loading: false,
    },
    reducers: {
        requested: space => {
            space.loading = true
        },
        success: (space, action) => {
            space.enabledSpaceList = action.payload;
            space.loading = false
        },
        failed: (space, action) => {
            space.enabledSpaceList = action.payload;
            space.loading = false
        },
        reset: count => {
            count.enabledSpaceList = []
            count.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getEnabledSpaceList = token => apiCallBegan({
    url: '/space/get-enabled-space',
    method: 'GET',
    token,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})

export const clearEnabledSpaceList = () => apiCallBegan({
    onReset: reset.type,
})