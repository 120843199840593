import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react'
import { gsap } from 'gsap'
import styles from './ClinicIntroRegistration.module.css'
import { useDropzone } from 'react-dropzone'

import { setSidebar } from '../../../store/localStates/sidebar'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import toast from 'react-hot-toast'
import { introReplaceToken, logoutUser } from '../../../store/login'

import { baseUrl } from '../../../middleware/url'
const ClinicIntroRegistration = ({ type, processComplete = () => {} }) => {
  const [next, setNext] = useState(false)
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const { credentials, userInfo } = useSelector((user) => user.credentials)

  const dispatch = useDispatch()

  useEffect(() => {
    const sideBar = document.querySelector('.sidebar_container')

    sideBar.style.display = 'none'

    return () => {
      sideBar.style.display = 'unset'
    }
  }, [])

  const [data, setData] = useState({
    address: '',
    missions: '',
    spaces: '',
    properties: '',
  })
  const [dataError, setDataError] = useState({
    address: '',
    missions: '',
    spaces: '',
    properties: '',
  })

  const container = useRef(null)
  const heading = useRef(null)
  const subheading = useRef(null)
  const paragraph = useRef(null)
  const paragraph2 = useRef(null)
  const inputContainer = useRef(null)
  const inputContainer2 = useRef(null)
  const button = useRef(null)
  const button2 = useRef(null)
  const input1 = useRef(null)
  const input2 = useRef(null)
  const input3 = useRef(null)
  const modal = useRef(null)

  useLayoutEffect(() => {
    gsap.set(container.current, { opacity: 0, overflowX: 'hidden' })

    let timeline = gsap.timeline({ defaults: { opacity: 0 } })

    timeline
      .to(container.current, { opacity: 1 })
      .from(heading.current, {
        xPercent: -100,
        ease: 'power3.out',
        duration: 0.5,
      })
      .from(subheading.current, {
        xPercent: -100,
        ease: 'power3.out',
        duration: 0.5,
      })
      .from(paragraph.current, {
        xPercent: -100,
        ease: 'power3.out',
        duration: 0.5,
      })
      .from(
        inputContainer.current,
        { yPercent: 100, ease: 'power3.out', duration: 0.5 },
        '<'
      )
      .from(
        button.current,
        { yPercent: 100, ease: 'power3.out', duration: 0.5 },
        '<'
      )
  }, [])

  useLayoutEffect(() => {
    let timeline = gsap.timeline({ defaults: { opacity: 0 } })

    timeline
      .from(paragraph2.current, {
        xPercent: -100,
        opacity: 0,
        ease: 'power3.out',
        duration: 0.8,
      })
      .from(
        inputContainer2.current,
        { yPercent: 100, opacity: 0, ease: 'power3.out', duration: 0.5 },
        '<'
      )
      .from(button2.current, {
        yPercent: 100,
        opacity: 0,
        ease: 'power3.out',
        duration: 0.5,
      })
  }, [next])

  const onDrop = useCallback((acceptedFiles) => {
    setImage(URL.createObjectURL(acceptedFiles[0]))
    setImageUrl(acceptedFiles[0])
    // setURL(URL.createObjectURL(acceptedFiles[0]))
    // onUpload(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const nextHandler = () => {
    let validate = true
    let errors = {}

    if (data.address === '') {
      errors = { ...errors, address: 'Please enter address' }
      validate = false
    } else if (data.address.length <= 2) {
      errors = { ...errors, address: 'Address must be at least 3 characters' }
      validate = false
    }

    setDataError({
      ...dataError,
      ...errors,
    })

    if (validate) {
      setNext(true)
    }
  }

  const onChangeTextHandler = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
    setDataError({
      ...dataError,
      [event.target.name]: '',
    })
  }

  const submitHandler = () => {
    let validate = true
    let errors = {}

    if (data.missions === '') {
      errors = { ...errors, missions: 'Please enter missions' }
      validate = false
    }
    if (data.spaces === '') {
      errors = { ...errors, spaces: 'Please enter spaces' }
      validate = false
    }
    if (data.properties === '') {
      errors = { ...errors, properties: 'Please enter properties' }
      validate = false
    }

    setDataError({
      ...dataError,
      ...errors,
    })

    if (validate) {
      try {
        const newData = new FormData()

        newData.append('userAddress', data.address)
        newData.append(
          'renameModule',
          JSON.stringify({
            stageOne: data.missions,
            stageTwo: data.spaces,
            stageThree: data.properties,
          })
        )
        if (imageUrl) {
          newData.append('thumbnail', imageUrl, imageUrl.name)
        } else {
          newData.append('thumbnail', '')
        }

        var config = {
          method: 'patch',
          url: `${baseUrl}/user/update-onboard-data?offset=-360`,
          headers: {
            token: credentials && credentials.data && credentials.data.token,
            'Content-Type': 'multipart/form-data;',
          },
          data: newData,
        }

        axios(config)
          .then(function (response) {
            if (response.data && response.data.status) {
              toast.success(response.data.message)
              //save token
              //navigate  to new dashboard
              dispatch(introReplaceToken(response.data))
              processComplete()
            } else {
              toast.error(response.data.message)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    }
  }

  const logoutHandler = () => {
    dispatch(logoutUser())
    dispatch({ type: GET_USER_DETAILS_BY_TOKEN_RESET })
    dispatch({ type: GET_TILES_RESET })
    dispatch({ type: GET_MISSIONS_RESET })
    dispatch({ type: GET_SPACES_REQUEST })
    dispatch({ type: GET_PROPERTIES_RESET })
  }

  const openModalHandler = () => {
    modal.current.style.display = 'block'
  }

  const closeModalHandler = () => {
    modal.current.style.display = 'none'
  }

  return (
    <div
      style={{
        backgroundImage:
          'linear-gradient(to bottom, rgba(11, 26, 91, .58), rgba(11, 26, 91, .58)), url(/assets/child_background.png)',
      }}
      className={styles.container}
      ref={container}>
      <div className={styles.topContainer}>
        <div className={styles.topSubContainer}>
          <h1 className={styles.heading} ref={heading}>
            {` Welcome ${
              userInfo && userInfo.userName != '' ? userInfo.userName : ``
            }`}
          </h1>
          <div className={styles.subheading} ref={subheading}>
            Please complete your profile
          </div>
          <div className={styles.paragraph} ref={paragraph}>
            Enter the following information to continue
          </div>
          <div className={styles.inputContainer} ref={inputContainer}>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>Address</label>
              <div
                className={[styles.input2, styles.address].join(' ')}
                ref={input1}>
                <svg className={styles.icon} style={{ marginTop: '0.8rem' }}>
                  <use xlinkHref={`/images/sprite.svg#icon-location4`} />
                </svg>
                <textarea
                  type='text'
                  placeholder='Address'
                  name='address'
                  className={[styles.inputText, styles.textarea].join(' ')}
                  onChange={onChangeTextHandler}
                />
              </div>
              {dataError.address !== '' ? (
                <p className={styles.error}>{dataError.address}</p>
              ) : null}
            </div>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>Upload Logo</label>
              <div
                {...getRootProps()}
                className={[styles.input2, styles.upload].join(' ')}
                ref={input2}
                style={{
                  cursor: 'pointer',
                  // backgroundColor: image !== null ? 'white' : '#2c355c82',
                }}>
                <input
                  {...getInputProps()}
                  accept='.pdf,.doc, image/*, video/*'
                />
                {image !== null ? (
                  <img alt='logo1' src={image} className={styles.img} />
                ) : (
                  <>
                    <svg className={styles.icon2}>
                      <use xlinkHref={`/images/sprite.svg#icon-upload2`} />
                    </svg>

                    <div className={styles.uploadText}>Upload your Logo</div>
                  </>
                )}
              </div>
            </div>
          </div>
          {!next && (
            <div className={styles.buttonContainer}>
              <button
                type='submit'
                className={styles.button}
                ref={button}
                onClick={nextHandler}>
                <p>Next</p>
                <div className={styles.line}></div>
              </button>
            </div>
          )}
        </div>
        <div className={styles.topSubContainer2}>
          <div className={styles.logout} onClick={logoutHandler}>
            <svg className={styles.logouticon} style={{ cursor: 'pointer' }}>
              <use xlinkHref={`/images/sprite.svg#icon-logout3`} />
            </svg>
            {/* <span style={{ cursor: 'pointer' }}>Logout</span> */}
          </div>
        </div>
      </div>
      {next && (
        <div className={styles.bottomContainer}>
          <div className={styles.paragraph} ref={paragraph2}>
            What would you like to call “Missions” , “Spaces”,”Properties” ?
          </div>
          <div className={styles.inputContainer} ref={inputContainer2}>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>Missions</label>
              <div className={styles.input} ref={input1}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-mission2`} />
                </svg>
                <input
                  type='text'
                  placeholder='Missions'
                  name='missions'
                  className={styles.inputText}
                  onChange={onChangeTextHandler}
                />
              </div>
              {dataError.missions !== '' ? (
                <p className={styles.error}>{dataError.missions}</p>
              ) : null}
            </div>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>Spaces</label>
              <div className={styles.input} ref={input2}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-spaces2`} />
                </svg>
                <input
                  type='text'
                  placeholder='Spaces'
                  name='spaces'
                  className={styles.inputText}
                  onChange={onChangeTextHandler}
                />
              </div>
              {dataError.spaces !== '' ? (
                <p className={styles.error}>{dataError.spaces}</p>
              ) : null}
            </div>
            <div className={styles.inputSubContainer}>
              <label className={styles.label}>Properties</label>
              <div className={styles.input} ref={input2}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-properties2`} />
                </svg>
                <input
                  type='text'
                  placeholder='Properties'
                  name='properties'
                  className={styles.inputText}
                  onChange={onChangeTextHandler}
                />
              </div>
              {dataError.properties !== '' ? (
                <p className={styles.error}>{dataError.properties}</p>
              ) : null}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              type='submit'
              className={styles.button}
              ref={button2}
              onClick={openModalHandler}>
              <p>Submit</p>
              <div className={styles.line}></div>
            </button>
          </div>
        </div>
      )}
      <div className={styles.modalContainer} ref={modal}>
        <div className={styles.modal}>
          <div className={styles.confirmCardContainer}>
            <div className={styles.confirmCard}>
              <div className={styles.confirmCardHeader}>Welcome to Bx</div>
              <div className={styles.confirmCardcontent}>
                <div className={styles.tiles}>
                  <div className={styles.circle}></div>
                  <div className={styles.modalText}>
                    Your data will be updated
                  </div>
                </div>
                <div className={styles.tiles}>
                  <div className={styles.circle}></div>
                  <div className={styles.modalText}>
                    You will be redirected to dashboard
                  </div>
                </div>
                <div className={styles.tiles}>
                  <div className={styles.circle}></div>
                  <div className={styles.modalText}>
                    For any querys please contact
                  </div>
                </div>
                <div className={styles.modalEmail}>
                  <svg className={styles.modalIcon}>
                    <use xlinkHref={`/images/sprite.svg#icon-email`} />
                  </svg>
                  <div>support@bxbuilders.com</div>
                </div>

                <div
                  className={styles.confirmCardButton}
                  onClick={submitHandler}>
                  Confirm
                </div>
                <div className={styles.confirmCardButtonClose}>
                  <span className={styles.close} onClick={closeModalHandler}>
                    Close
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClinicIntroRegistration
