import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppHeader from '../../../../components/AppHeader'
import BackgroundCard from '../../../../components/Cards/BackgroundCard'
import AppModal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'

import { getAvatarBackgroundList } from '../../../../store/avatar/avatarBackgroundList'
import { setEnableDisableBackground } from '../../../../store/avatar/enableDisableBackground'
import AppButton from '../../../../components/forms/AppButton'
import AppSwitch from '../../../../components/AppSwitch'
import TextInput from '../../../../components/forms/TextInput'
import ImageUploader from '../../../../components/forms/ImageUploader'
import {
  addBackgroundAvatar,
  clearAddBackgroundAvatar,
} from '../../../../store/avatar/addBackground'
import ProgressBar from '../../../../components/forms/ProgressBar'
import { toast } from 'react-toastify'
import {
  clearDeleteAvatarPropsResponse,
  deleteAvatarProps,
} from '../../../../store/avatar/deleteAvatarProps'
import Pagination from '../../../../components/Pagination'

const Background = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { avatarBackgroundList, loading } = useSelector(
    (bar) => bar.avatarBackgroundList
  )
  const { addBackgroundResponse } = useSelector(
    (bar) => bar.addBackgroundResponse
  )
  const { deleteAvatarPropsResponse } = useSelector(
    (bar) => bar.deleteAvatarPropsResponse
  )

  const [currentItem, setCurrentItem] = useState(null)
  const [search, setSearch] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [progress, setProgress] = useState(null)

  const [backgroundTitle, setBackgroundTitle] = useState('')
  const [backgroundTitleError, setBackgroundTitleError] = useState('')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [deleteWithReference, setDeleteWithReference] = useState(false)

  const [image, setImage] = useState('')
  const [imageError, setImageError] = useState('')
  const [imageURL, setImageURL] = useState('')

  const [isUpadate, setIsUpadate] = useState(null)

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 9

  useEffect(() => {
    dispatch(
      getAvatarBackgroundList(
        { filterValue: '', pageNumber, pageSize },
        'background'
      )
    )
  }, [dispatch])

  useEffect(() => {
    if (addBackgroundResponse !== null) {
      if (addBackgroundResponse.status) {
        toast.success(addBackgroundResponse.message)
        dispatch(
          getAvatarBackgroundList(
            { filterValue: '', pageNumber, pageSize },
            'background'
          )
        )
        dispatch(clearAddBackgroundAvatar())
        setProgress(null)
        onCloseModal()
      } else {
        toast.error(addBackgroundResponse.message)
        dispatch(clearAddBackgroundAvatar())
      }
    }
  }, [addBackgroundResponse, dispatch])
  useEffect(() => {
    if (deleteAvatarPropsResponse !== null) {
      if (deleteAvatarPropsResponse.status) {
        toast.success(deleteAvatarPropsResponse.message)
        dispatch(
          getAvatarBackgroundList(
            { filterValue: '', pageNumber, pageSize },
            'background'
          )
        )
        dispatch(clearDeleteAvatarPropsResponse())
        setProgress(null)
        closeDeleteModal()
      } else {
        toast.error(deleteAvatarPropsResponse.message)
        dispatch(clearDeleteAvatarPropsResponse())
      }
    }
  }, [deleteAvatarPropsResponse, dispatch])

  const enableDisableHandler = async ({ _id, enable }) => {
    setCurrentItem(_id)
    await dispatch(setEnableDisableBackground({ _id, enable }))
    await dispatch(
      getAvatarBackgroundList(
        { filterValue: '', pageNumber, pageSize },
        'background'
      )
    )
    setCurrentItem(null)
  }

  const onDeleteHandler = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference)
  }

  const onDeleteMission = () => {
    if (deletedItem !== null) {
      dispatch(deleteAvatarProps({ _id: deletedItem._id }))
    }
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
    setDeleteWithReference(false)
  }

  const onEditHandler = (item) => {
    setIsUpadate(item)
    setShowAddModal(true)
    setImage(item.imageURL)
    setBackgroundTitle(item.title)
  }

  const onUpload = (image) => {
    setImageURL(image)
    setImageError('')
  }

  const onCloseModal = () => {
    setIsUpadate(null)
    setShowAddModal(false)
    setImage('')
    setImageURL('')
    setBackgroundTitle('')
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault()

    const data = new FormData()

    if (image === '') {
      setImageError('Please select image')
    } else if (backgroundTitle.trim() === '') {
      setBackgroundTitleError('Please enter title')
    } else {
      data.append('type', 'background')
      if (isUpadate !== null) {
        if (imageURL !== '') {
          data.append('thumbnail', imageURL, imageURL.name)
        }
        if (isUpadate._id) {
          data.append('id', isUpadate._id)
        }
        data.append('title', backgroundTitle)
        await dispatch(
          addBackgroundAvatar(data, (progress) => setProgress(progress))
        )
      } else {
        data.append('title', backgroundTitle)
        data.append('thumbnail', imageURL, imageURL.name)
        await dispatch(
          addBackgroundAvatar(data, (progress) => setProgress(progress))
        )
      }
    }
  }

  const onSearchBackground = (event) => {
    setSearch(event.target.value)
    dispatch(
      getAvatarBackgroundList(
        { filterValue: event.target.value, pageNumber, pageSize },
        'background'
      )
    )
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(
      getAvatarBackgroundList(
        { filterValue: '', pageNumber: selectedPage + 1, pageSize },
        'background'
      )
    )
    setPageNumber(selectedPage + 1)
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Backgrounds'
        isAdd
        onAdd={() => setShowAddModal(true)}
        isSearch
        searchPlaceholder='Search Backgrounds'
        onSearch={onSearchBackground}
        searchValue={search}
      />
      <div className='client__container'>
        <div className='missions_main_content'>
          {avatarBackgroundList !== null &&
          avatarBackgroundList.data &&
          avatarBackgroundList.data.result &&
          avatarBackgroundList.data.result.length > 0 ? (
            avatarBackgroundList.data.result.map((item, index) => (
              <BackgroundCard
                key={index}
                item={item}
                loading={loading}
                currentItem={currentItem}
                onEdit={onEditHandler}
                onDelete={onDeleteHandler}
                onChangeSwitch={enableDisableHandler}
              />
            ))
          ) : (
            <div className='data_not_found_container'>
              <p className='data_not_found_message'>
                {search !== ''
                  ? 'Search result not found'
                  : "You havn't created any missions yet. Please create one"}
              </p>
            </div>
          )}
        </div>
      </div>

      {avatarBackgroundList !== null &&
        avatarBackgroundList.data &&
        avatarBackgroundList.data.result &&
        avatarBackgroundList.data.result.length > 0 &&
        avatarBackgroundList.data.totalPageSize > 9 && (
          <Pagination
            list={avatarBackgroundList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
      <AppModal2 show={showAddModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>
            {isUpadate ? 'edit Background' : 'Add Background'}
          </p>
          <form className='clinic_form_container' onSubmit={onSubmitHandler}>
            <div className='clinic_form_image_upload_container'>
              <ImageUploader
                onUpload={onUpload}
                image={image}
                setURL={(image) => setImage(image)}
                errorMessage={imageError}
                containerStyles={{ borderRadius: '50%' }}
                imageTitle='Upload background image'
              />
            </div>
            <TextInput
              placeholder='Title'
              src='/images/missions-input.png'
              onChange={(mission) => {
                setBackgroundTitleError('')
                setBackgroundTitle(mission.target.value)
              }}
              value={backgroundTitle}
              error={backgroundTitleError}
              maxLength={20}
            />

            <div className='modal_buttons'>
              <AppButton
                title={isUpadate ? 'update' : 'add'}
                type='submit'
                icon={
                  <img
                    src='/images/add-clinic.png'
                    className='app_button_image'
                  />
                }
                onClick={onSubmitHandler}
              />
              <AppButton
                title='cancel'
                type='button'
                buttonContainer={{
                  backgroundColor: 'var(--transparent)',
                  marginLeft: '0.5rem',
                }}
                textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                onClick={onCloseModal}
              />
            </div>
          </form>
        </div>
        <ProgressBar progress={progress * 100} />
      </AppModal2>
      <AppModal2 show={showDeleteModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Delete mission</p>
          <p className='form_header_delete_text'>
            Are you sure you want to delete mission name{' '}
            <span>{deletedItem !== null && deletedItem.title}</span>?
          </p>
          <div className='form_header_delete_ref'>
            <p className='form_header_delete_ref_text'>
              Delete all it's reference
            </p>
            <AppSwitch
              checked={deleteWithReference}
              onChange={onDeleteStatusChange}
            />
          </div>

          <div className='modal_buttons'>
            <AppButton
              title='delete'
              type='submit'
              // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
              onClick={onDeleteMission}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={closeDeleteModal}
            />
          </div>
        </div>
      </AppModal2>
    </div>
  )
}

export default Background
