import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'delete-properties',
    initialState: {
        deletePropertyResponse: null,
        loading: false,
    },
    reducers: {
        requested: (properties) => {
            properties.loading = true
        },
        success: (properties, action) => {
            properties.deletePropertyResponse = action.payload
            properties.loading = false
        },
        failed: (properties, action) => {
            properties.deletePropertyResponse = action.payload;
            properties.loading = false
        },
        reset: (properties, action) => {
            properties.deletePropertyResponse = action.payload;
            properties.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteProperty = (id, status) => apiCallBegan({
    url: `/space/delete-properties/${id}/${status}`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearDeletePropertyResponse = () => apiCallBegan({
    onReset: reset.type,
})