import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'property-list',
    initialState: {
        propertyList: [],
        loading: false,
    },
    reducers: {
        requested: (property, action) => {
            property.loading = true
        },
        success: (property, action) => {
            property.propertyList = action.payload;
            property.loading = false
        },
        failed: (property, action) => {
            property.propertyList = action.payload;
            property.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getPropertyList = id => apiCallBegan({
    url: `/space/get-properties-by-space-id?spaceId=${id}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})