import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'add-video',
  initialState: {
    addVideoResponse: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.addVideoResponse = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.addVideoResponse = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.addVideoResponse = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addVideo = (data, fileType, onUploadProgress, onCancel) =>
  apiCallBegan({
    url: `/media/create-and-update-media?fileType=${fileType}`,
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
    onCancel,
  })

export const clearAddVideoResponse = () =>
  apiCallBegan({
    onLogout: reset.type,
  })
