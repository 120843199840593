import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'current-tab',
    initialState: {
        currentTab: 1
    },
    reducers: {
        changeCurrentTab: (page, action) => {
            page.currentTab = action.payload;
        }
    }
})

const { changeCurrentTab } = slice.actions
export default slice.reducer

export const setCurrentTab = data => apiCallBegan({
    data,
    onChangeCurrentTab: changeCurrentTab.type,
})