import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'add-success-videos',
  initialState: {
    addSuccessVideoResponse: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.addSuccessVideoResponse = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.addSuccessVideoResponse = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.addSuccessVideoResponse = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addSuccessVideo = (data, onUploadProgress) =>
  apiCallBegan({
    url: `general/create-and-update-general-video`,
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearAddSuccessVideoResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
