import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'add-privacy',
    initialState: {
        addPrivacyResponse: null,
        loading: false,
    },
    reducers: {
        requested: (privacy) => {
            privacy.loading = true
        },
        success: (privacy, action) => {
            privacy.addPrivacyResponse = action.payload
            privacy.loading = false
        },
        failed: (privacy, action) => {
            privacy.addPrivacyResponse = action.payload;
            privacy.loading = false
        },
        reset: (privacy, action) => {
            privacy.addPrivacyResponse = action.payload;
            privacy.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addPrivacy = (data) => apiCallBegan({
    url: '/privacy/add-privacy-content',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearaddPrivacyResponse = () => apiCallBegan({
    onReset: reset.type,
})