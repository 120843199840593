import React from 'react';

import Sidebar from '../Sidebar';
import Header from './Header';
import ContentStyles from './Content.module.css';

const Content = ({ children, headerTitle, currentMenu, backBtn, noHeader, showAdd, add, therapistView, onChangeTherapistView, therapistViewValue }) => {
  return (
    <main className={ContentStyles.container}>
      <Sidebar currentMenu={currentMenu} />

      <div className={ContentStyles.mainContent}>
        <Header
          noHeader={noHeader}
          headerTitle={headerTitle}
          backBtn={backBtn}
          showAdd={showAdd}
          add={add}
          therapistView={therapistView}
          onChangeTherapistView={onChangeTherapistView}
          therapistViewValue={therapistViewValue}
        />
        <div className={ContentStyles.content}>{children}</div>
      </div>
    </main>
  );
};

export default Content;
