import React, { useEffect, useRef, useState } from "react";
import { Bar, Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const dataSample = {
  datasets: [
    {
      fill: false,
      label: "Sideeffects",
      data: [16, 25, 52, 45, 26, 30, 27],
      // borderColor: 'rgba(53, 162, 235,0)',
      borderWidth: 2,
      tension: 0.5,
      datalabels: {
        align: "center",
        anchor: "center",
      },
    },
  ],
  labels: ["Categories", "Functional Skills", "Labeling", "Effect", "Intent"],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: "#27273f",
      display: (context) => {
        return context.dataset.data[context.dataIndex];
      },
      formatter: function (value, context) {
        return context.dataset.data[context.dataIndex] + " %";
      },
      font: {
        weight: "bold",
        size: 8,
      },
    },
  },
  aspectRatio: 5 / 3,
  layout: {
    padding: {
      top: 24,
      right: 16,
      bottom: 0,
      left: 8,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      fill: false,
    },
    point: {
      hoverRadius: 7,
      radius: 5,
    },
  },
  scales: {
    x: {
      ticks: {
        color: "white",
      },
    },
    y: {
      ticks: {
        color: "white",
      },
    },
  },
};

function createGradient(ctx, area) {
  const colorStart = "#FFFFFF";
  const colorEnd = "#6977B1";

  const gradient = ctx.createLinearGradient(0, area.top, 0, area.bottom);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const CustomBar = ({ data, itemId, title }) => {
  const [backgroundColor, setBackgroundColor] = useState("white");

  const chartMissionPerformanceRef = useRef(null);
  const visibility = React.useContext(VisibilityContext);
  useEffect(() => {
    /*Mission PERFORMANCE */

    const chartMissionPerformance = chartMissionPerformanceRef.current;
    if (!chartMissionPerformance) {
      return;
    }
    const bgMissionColor = createGradient(
      chartMissionPerformance.ctx,
      chartMissionPerformance.chartArea
    );

    setBackgroundColor(bgMissionColor);

    /*SKILL PERFORMANCE */
  }, []);

  return data ? (
    <div
      style={{
        width: "35vw",
        height: "27rem",
      }}
      tabIndex={0}
    >
      {data && data.datasets && Array.isArray(data.datasets) ? (
        <Bar
          ref={chartMissionPerformanceRef}
          data={{
            ...dataSample,
            labels: data?.labels,
            datasets: data?.datasets?.map((dataset) => ({
              ...dataset,
              backgroundColor: backgroundColor,
              borderRadius: 20,
            })),
          }}
          options={options}
        />
      ) : (
          <Bar
            style={{
              filter: "blur(8px)",
              webkitFilter: "blur(8px)"
            }}
            ref={chartMissionPerformanceRef}
            data={dataSample}
            options={options}
          />
      )}
    </div>
  ) : null;
};

export default CustomBar;
