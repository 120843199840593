import React from 'react'
import AppLoader from '../AppLoader'

import './switch.css'

const AppSwitchSmall = ({ loading, checked, onChange }) => {
  return (
    <div
      className={`small_custom_switch_container ${
        checked && 'small_custom_switch_container_checked'
      }`}
      onClick={onChange}>
      {loading ? (
        <div className='swicth_loader_container'>
          <img
            src='/images/loader.gif'
            className='swicth_loader'
            alt='loader'
          />
        </div>
      ) : (
        <div
          className={`small_custom_switch_dot ${
            checked && 'small_custom_switch_dot_checked'
          }`}
        />
      )}
    </div>
  )
}

export default AppSwitchSmall
