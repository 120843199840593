import React, { Fragment } from 'react';

import './Skeleton.css';
const PreviousNotificationsSkeleton = () => {
	const count = [1, 2, 3];
	return (
		<Fragment>
			{/* <div className='previous_notifications_search'>
				<label htmlFor='search-text' className='previous_notifications_search_label'>
					<img src='/images/search.png' alt='search icon' className='previous_notifications_search_icon' />
					<div
						className='client_card_heading_skeleton skeleton'
						style={{ width: '10vw', height: '1rem', marginLeft: '1rem' }}
					/>
				</label>
			</div> */}
			<div className='previous_notifications_cards'>
				{count &&
					count.map((item) => (
						<div key={item} className='previous_notifications_card ' style={{ width: '40vw' }}>
							<div className='flex' style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
								<div>
									<div className='client_card_heading_skeleton skeleton' style={{ width: '20vw' }} />
									<div className='client_card_view_btn_skeleton skeleton' style={{ width: '30vw' }} />
									<div className='client_card_view_btn_skeleton skeleton' style={{ width: '15vw' }} />
								</div>
							</div>
							<div className='client_card_actions' style={{ justifyContent: 'flex-end' }}>
								<div className='client_card_view_btn_skeleton skeleton' style={{ width: '10vw' }} />
							</div>
						</div>
					))}
			</div>
		</Fragment>
	);
};

export default PreviousNotificationsSkeleton;
