import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'sidebar',
    initialState: {
        sidebar: true,
    },
    reducers: {
        changeSidebar: (bar, action) => {
            bar.sidebar = action.payload;
        }
    }
})

const { changeSidebar } = slice.actions
export default slice.reducer

export const setSidebar = data => apiCallBegan({
    data,
    onChangeSidebar: changeSidebar.type,
})