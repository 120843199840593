import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'total-space-completed-count',
    initialState: {
        spaceCountList: [],
        loading: false,
    },
    reducers: {
        requested: (count) => {
            count.loading = true
        },
        success: (count, action) => {
            var result = [];
            if (action.payload && action.payload.status) {
                var dataArr = count.spaceCountList.concat(action.payload.data).map(item => {
                    if (item && item.label) {
                        return [item.label, item]
                    }
                });

                var maparr;
                if (dataArr && dataArr.length > 0) {
                    maparr = new Map(dataArr);
                }
                if (maparr) {
                    result = [...maparr.values()];
                }
            }
            count.spaceCountList = result && result.length > 0 ? result : []
            count.loading = false
        },
        failed: (count, action) => {
            count.spaceCountList = action.payload;
            count.loading = false
        },
        reset: (count, action) => {
            count.spaceCountList = []
            count.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getCompletedSpaceCount = (mission, spaceID, spaceName) => apiCallBegan({
    url: `/generic/get-total-space-completed-count/${mission}/${spaceID}/${spaceName}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})


export const clearCompletedSpaceCountResponse = () => apiCallBegan({
    onReset: reset.type,
})