import React, { Fragment, useState } from 'react'
import EmogiPicker from '../../../../../components/EmogiPicker'
import TextInput from '../../../../../components/forms/TextInput'
import Options from './Options'

const SingleOption = ({
  singleOptions,
  setFinalAnswer,
  errorMessage,
  onRemoveError,
  correctAnswer,
}) => {
  const [singleOptionTitle, setSingleOptionTitle] = useState('')
  const [singleOptionTitleError, setSingleOptionTitleError] = useState('')

  const [currentIndex, setCurrentIndex] = useState(null)
  const [answer, setAnswer] = useState(correctAnswer ? correctAnswer : '')
  const [answerEdit, setAnswerEdit] = useState(false)
  const [singleOptionEdit, setSingleOptionEdit] = useState('')
  const [editIndex, setEditIndex] = useState(null)

  const [displayEmogies, setDisplayEmogies] = useState(false)
  const [emogi, setEmogi] = useState(false)

  const onAddSingleOptionHandler = () => {
    onRemoveError()

    if (singleOptionTitle === '') {
      setSingleOptionTitleError('Please enter Question option')
    } else {
      setFinalAnswer(
        [
          ...singleOptions,
          {
            isSelected: false,
            optionTitle: singleOptionTitle,
            value: singleOptionTitle,
          },
        ],
        answer
      )
      setSingleOptionTitle('')
    }
  }

  const onOptionDeleteHandler = (index) =>
    setFinalAnswer(
      singleOptions.filter((i, ii) => ii !== index),
      answer
    )

  const singleOptionEditHandler = (question, index) => {
    singleOptions.forEach((_, inx) => {
      if (index === inx) {
        setAnswerEdit(true)
        setEditIndex(index)
        setSingleOptionEdit(question.optionTitle)
      }
    })
  }

  const onEditChange = (option, index) => {
    singleOptions.forEach((_, inx) => {
      if (index === inx) {
        setSingleOptionEdit(option)
      }
    })
    setAnswer('')

    // const newFinalAnswer = singleOptions.map((option, index) => {
    //   if (index === inx) {
    //     return {
    //       isSelected: false,
    //       optionTitle: option,
    //       value: option,
    //     }
    //   } else {
    //     return option
    //   }
    // })

    // setFinalAnswer(newFinalAnswer)
  }

  const onSetEdit = (index) => {
    setAnswerEdit(false)
    setEditIndex(null)
    const data = JSON.parse(JSON.stringify(singleOptions))
    data.forEach((_, inx) => {
      if (index === inx) {
        _.optionTitle = singleOptionEdit
        _.value = singleOptionEdit
      }
    })
    setFinalAnswer(data, answer)
  }

  const onCancelEdit = () => {
    setAnswerEdit(false)
    setEditIndex(null)
    setDisplayEmogies(false)
  }

  const setIndex = (question, index) => {
    setAnswer(question.value)
    setCurrentIndex(index)
    setFinalAnswer(singleOptions, question.value)
    setDisplayEmogies(false)
  }

  return (
    <Fragment>
      <div className='flex' style={{ gap: '2rem' }}>
        <TextInput
          placeholder='Answer'
          src='/images/question_answer.png'
          onChange={(option) => {
            setSingleOptionTitleError('')
            onRemoveError()
            setSingleOptionTitle(option.target.value)
          }}
          value={singleOptionTitle}
          error={singleOptionTitleError}
        />
        <div
          className='emogi_wrapper'
          onClick={() => setDisplayEmogies(!displayEmogies)}>
          <p>😊</p>
        </div>
        <div className='addBtn' onClick={onAddSingleOptionHandler}>
          <img
            src='/images/add_blue.png'
            alt='add icon'
            className='addBtnIcon'
          />
        </div>
      </div>
      <div className='answers'>
        {displayEmogies && (
          <EmogiPicker
            onClose={() => setDisplayEmogies(false)}
            setEmogi={(emogi) => setSingleOptionTitle((prev) => prev + emogi)}
          />
        )}
        {singleOptions &&
          singleOptions.length > 0 &&
          singleOptions.map((question, qIndex) => (
            <Options
              key={qIndex}
              question={question}
              index={qIndex}
              onEdit={singleOptionEditHandler}
              onDelete={onOptionDeleteHandler}
              onChange={onEditChange}
              setIndex={setIndex}
              answer={answer}
              currentIndex={currentIndex}
              isEdit={qIndex === editIndex ? answerEdit : false}
              onSetEdit={onSetEdit}
              optionValue={singleOptionEdit}
              onCancelEdit={onCancelEdit}
              onClose={() => setDisplayEmogies(false)}
              setEmogis={(emogi) => setSingleOptionEdit((prev) => prev + emogi)}
              setEmogi={() => setEmogi(!emogi)}
              emogi={emogi}
            />
          ))}
      </div>
      <p className='input_error_text'>{errorMessage}</p>
    </Fragment>
  )
}

export default SingleOption
