import React from 'react';
import classes from './Components.module.css';

const TextBox = ({ type = 'text', style, label, placeholder, name, value, onChange, errorMessage }) => {
  return (
    <div className={classes.input_container} style={style}>
      {label && (
        <label htmlFor={name} className={classes.input_container_label}>
          {label}
        </label>
      )}
      <div className={classes.input_box}>
        <input type={type} placeholder={placeholder} id={name} name={name} value={value} onChange={onChange} className={classes.input_type} />
      </div>
      {errorMessage && <p className={classes.input_error}>{errorMessage}</p>}
    </div>
  );
};

export default TextBox;
