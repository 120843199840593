import React from 'react'
import { BigHead } from '@bigheads/core'

const KidCard = ({ item }) => {
  return (
    <div className='big_heades_main_card_container'>
      <div className='big_heades_card_container'>
        <div className='kid_avatar_container'>
          {/* {item?.kidAvatar?.background && (
            <img
              alt='avatar'
              src={item?.kidAvatar?.background}
              className='kid_avatar_background_image'
            />
          )}
          <div className='big_heads_container'>
            {
                            item?.kidAvatar &&
                            <BigHead
                                accessory={item?.kidAvatar?.accessory}
                                clothing={item?.kidAvatar?.clothing}
                                clothingColor={item?.kidAvatar?.clothingColor}
                                eyebrows={item?.kidAvatar?.eyebrows}
                                eyes={item?.kidAvatar?.eyes}
                                facialHair={item?.kidAvatar?.facialHair}
                                hair={item?.kidAvatar?.hair}
                                hairColor={item?.kidAvatar?.hairColor}
                                hat={item?.kidAvatar?.hat}
                                hatColor={item?.kidAvatar?.hatColor}
                                lipColor={item?.kidAvatar?.lipColor}
                                mouth={item?.kidAvatar?.mouth}
                                skinTone={item?.kidAvatar?.skinTone}
                            />
                        }
          </div>
          {item?.kidAvatar?.tag && (
            <img
              alt='avatar'
              src={item?.kidAvatar?.tag}
              className='kid_avatar_tag_image'
            />
          )} */}
        </div>
        <div className='big_heades_card_view'>
          <div className='big_heades_card_view_container'>
            <div className='client_card_detail_container'>
              <p className='big_heades_card_title'>
                {item?.kidName} | {item?.kidAge} years | {item.kidGender}
              </p>
              <div className='flex'>
                <p
                  className='client_card_count'
                  style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>
                  Total Points earned: {Math.round(item.totalPoints)}{' '}
                </p>
              </div>
            </div>
          </div>
          <p className='total_answered'>
            Missions: {item.mission} | Spaces: {item.space}
          </p>
          <p className='total_answered'>
            Properties: {item.properties} | Questions: {item.questions}
          </p>
        </div>
      </div>
    </div>
  )
}

export default KidCard
