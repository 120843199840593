import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'delete-version',
  initialState: {
    deleteVersionInfo: null,
    loading: false,
  },
  reducers: {
    requested: (state, action) => {
      state.loading = true
    },
    success: (state, action) => {
      state.deleteVersionInfo = action.payload
      state.loading = false
    },
    failed: (state, action) => {
      state.deleteVersionInfo = action.payload
      state.loading = false
    },
    reset: (state, action) => {
      state.deleteVersionInfo = action.payload
      state.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteVersion = (Id) =>
  apiCallBegan({
    url: `version/delete-version?id=${Id}`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type,
  })

export const clearDeleteVersionResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
