import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './clinic.css'
import { toast } from 'react-toastify'

import AppHeader from '../../../../components/AppHeader'
import ClinicCard from '../../../../components/Cards/ClinicCard'
import AppModal from '../../../../components/AppModal'
import AppModal3 from '../../../../components/AppModal3'
import AppButton from '../../../../components/forms/AppButton'
import TextInput from '../../../../components/forms/TextInput'
import ImageUploader from '../../../../components/forms/ImageUploader'
import { generatePassword } from '../../../../components/passwordGenerator'
import Pagination from '../../../../components/Pagination'
import ProgressBar from '../../../../components/forms/ProgressBar'
import {
  validateEmail,
  validateOnlyChacters,
  validatePhone,
} from '../../../../utils/validators'
import {
  changeClientPassword,
  clearChangeClientPasswordResponse,
} from '../../../../store/Clients/changePassword'
import { getClientList } from '../../../../store/Clients/clientsList'
import {
  addClient,
  clearAddClientResponse,
} from '../../../../store/Clients/addClient'
import {
  setEnableDisableClient,
  clearEnableDisableClient,
} from '../../../../store/Clients/enableDisableClient'
import { getEnabledClientList } from '../../../../store/Clients/enabledClientsList'
import {
  getConfigList
} from '../../../../store/configuration/getConfig'
import {
  updateConfig,
  clearUpdateConfig,
} from '../../../../store/configuration/patchConfig'
import AppLoader from '../../../../components/AppLoader'

import axios from 'axios'
import { baseUrl } from '../../../../middleware/url'
import AppSwitchSmall from '../../../../components/AppSwitchSmall'
import Loader from 'react-js-loader'
import Tooltip from '@material-ui/core/Tooltip'

const Clinic = () => {
  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { clientList, loading } = useSelector((bar) => bar.clientList)
  const { addClientResponse, loading: addLoading } = useSelector(
    (bar) => bar.addClientResponse
  )
  const { clientStatus } = useSelector((state) => state.clientStatus)
  const { passwordChangeResponse, loading: passLoading } = useSelector(
    (client) => client.changePassword
  )
  const { credentials } = useSelector((response) => response.credentials)

  const { configurationList, loading: configLoading } = useSelector(
    (config) => config.getConfig
  )

  const { updateConfigRseponse, loading: updateLoading } = useSelector(
    (config) => config.patchConfig
  )

  const [search, setSearch] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [showPassModal, setShowPassModal] = useState(false)
  const [progress, setProgress] = useState(null)
  const [isUpadate, setIsUpadate] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [clientType, setClientType] = useState('')
  const [address, setAddress] = useState('')

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [numberError, setNumberError] = useState('')
  const [clientTypeError, setClientTypeError] = useState('')
  const [addressError, setAddressError] = useState('')

  const [image, setImage] = useState('')
  const [imageError, setImageError] = useState('')
  const [imageURL, setImageURL] = useState('')

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 10

  const [showModuleModal, setShowModuleModal] = useState(false)
  const [selectedClient, setSelectedClient] = useState({})
  const [missionsText, setMissionsText] = useState({ value: '', error: '' })
  const [spacesText, setSpacesText] = useState({ value: '', error: '' })
  const [propertiesText, setPropertiesText] = useState({ value: '', error: '' })

  const [showConfigModal, setShowConfigModal] = useState(false)

  const [admin, setAdmin] = useState({
    pretestModule: true, // In admin dashboard
    categoryReport: true, // In admin dashboard
    statisticsReport: true, //In admin dashboard
    progressReport: true, //In admin dashboard
    pointReport: true, // In admin report
    responseVideo: true, // in admin dashboard
    successVideo: true, // in admin dashboard
    renameModule: true, // in admin dashboard
  })

  const [app, setApp] = useState({
    secureCode: true, //if false then secureCode will be 1234. In app
    therapistView: true, //In app
    performanceReport: true, // In app and user dashboard
    genericVideo: true, //in app and user dashboard
    faq: true, // in user dashboard and app
    aboutUs: true, // in user dashboard and app
    aboutAlgo: true, // in user dashboard and app
    privacyPolicy: true, // in user dashboard and app
    leaderBoard: true, // in app and web dashboard
    updateDialogueBox: true, // in app and web dashboard
    report: true, // in app and web dashboard
  })

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value)
  }

  const onCloseModal = () => {
    setIsUpadate(null)
    setShowAddModal(false)
    setName('')
    setNameError('')
    setNumber('')
    setNumberError('')
    setEmail('')
    setEmailError('')
    setAddress('')
    setAddressError('')
    setClientType('')
    setImage('')
    setImageURL('')
    setImageError('')
  }

  useEffect(() => {
    dispatch(getClientList({ pageSize, pageNumber, filterValue: '' }))
  }, [dispatch])

  useEffect(() => {
    if (clientStatus) {
      if (clientStatus.status) {
        toast.success(clientStatus.message)
        dispatch(clearEnableDisableClient())
      } else if (!clientStatus.status) {
        toast.error(clientStatus.message)
      }
    }
  }, [clientStatus])

  useEffect(() => {
    if (addClientResponse !== null) {
      if (addClientResponse.status) {
        toast.success(addClientResponse.message)
        setImage('')
        setImageURL('')
        setImageError('')
        dispatch(getClientList({ pageSize, pageNumber, filterValue: '' }))
        setShowAddModal(false)
        dispatch(clearAddClientResponse())
        setProgress(null)
        setIsUpadate(null)
        onCloseModal()
      } else {
        toast.error(addClientResponse.message)
        dispatch(clearAddClientResponse())
        setProgress(null)
      }
    }
  }, [addClientResponse, dispatch])

  useEffect(() => {
    if (passwordChangeResponse) {
      if (passwordChangeResponse.status) {
        toast.success(passwordChangeResponse.message)
        closePasswordModalHandler()
        dispatch(clearChangeClientPasswordResponse())
      } else {
        toast.error(passwordChangeResponse.message)
        dispatch(clearChangeClientPasswordResponse())
      }
    }
  }, [passwordChangeResponse, dispatch])

  const onAddClinicHAndler = () => {
    setShowAddModal(true)
  }

  const onSearchClinic = (event) => {
    setSearch(event.target.value)
    dispatch(
      getClientList({ pageSize, pageNumber, filterValue: event.target.value })
    )
  }

  const onUpload = (image) => {
    setImageURL(image)
    setImageError('')
  }

  const onEditClick = (item) => {
    setIsUpadate(item)
    setShowAddModal(true)
    setName(item.userName)
    setNumber(item.userMobile)
    setEmail(item.userEmail)
    setAddress(item.userAddress)
    setImage(item.userProfile)
    setClientType(item.clientType)
  }

  const enableDisableHandler = async ({ _id, userIsActiveByAdmin }) => {
    setCurrentItem(_id)
    await dispatch(setEnableDisableClient({ _id, userIsActiveByAdmin }))
    await dispatch(getClientList({ pageSize, pageNumber, filterValue: '' }))
    await dispatch(getEnabledClientList())
    setCurrentItem(null)
  }

  const onSubmitHandler = (event) => {
    event.preventDefault()
    const data = new FormData()

    if (isUpadate !== null) {
      if (imageURL !== '') {
        data.append('thumbnail', imageURL, imageURL.name)
      }

      if (isUpadate._id) {
        data.append('id', isUpadate._id)
      }

      if (clientType === '') {
        return setClientTypeError('Please select client type')
      }
      data.append('clientType', clientType)
      data.append('userName', name)
      data.append('userMobile', number)
      data.append('userAddress', address)
      dispatch(addClient(data, (progress) => setProgress(progress)))
    } else {
      if (name === '') {
        setNameError('Please enter name')
      } else if (name.length < 2) {
        setNameError('name must be two character long')
      } else if (validateOnlyChacters(name)) {
        setNameError('Please enter valid name')
      }
      //  else if (email === '') {
      //   setEmailError('Please enter email')
      else if (email !== '' && !validateEmail(email)) {
        setEmailError('Please enter valid email')
      } else if (number === '') {
        setNumberError('Please enter phone number')
      } else if (number.length < 10) {
        setNumberError('Phone number must be  10 digits')
      } else if (!validatePhone(number)) {
        setNumberError('Please enter valid number')
      } else if (clientType === '') {
        setClientTypeError('Please select client type')
      } else if (address === '') {
        setAddressError('Please enter Address')
      } else if (image === '') {
        setImageError('Please select image')
      } else {
        data.append('userName', name)
        data.append('userMobile', number)
        data.append('userEmail', email)
        data.append('clientType', clientType)
        data.append('thumbnail', imageURL, imageURL.name)
        data.append('userAddress', address)
        data.append('userPassword', generatePassword())
        dispatch(addClient(data, (progress) => setProgress(progress)))
      }
    }
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(
      getClientList({ pageSize, pageNumber: selectedPage + 1, filterValue: '' })
    )
    setPageNumber(selectedPage + 1)
  }

  const onModuleNameChange = (item) => {
    setShowModuleModal(true)
    setSelectedClient(item)
  }

  useEffect(() => {
    const getModuleData = async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          token: credentials && credentials.data && credentials.data.token,
        },
      }
      try {
        const response = await axios.get(
          `${baseUrl}/user/get-rename-module?userId=${selectedClient._id}`,
          config
        )
        if (response.data && response.data.status && response.data.data) {
          setMissionsText({ value: response.data.data.stageOne, error: '' })
          setSpacesText({ value: response.data.data.stageTwo, error: '' })
          setPropertiesText({ value: response.data.data.stageThree, error: '' })
        } else {
          toast.error('No module data is saved for this client')
        }
      } catch (error) {
        toast.error('Something went wrong.')
      }
    }
    if (selectedClient && selectedClient._id) {
      getModuleData()
    }
  }, [selectedClient])

  const onCloseModuleEdit = () => {
    setShowModuleModal(false)
    setSelectedClient({})

    setMissionsText({ value: '', error: '' })
    setSpacesText({ value: '', error: '' })
    setPropertiesText({ value: '', error: '' })
  }

  const onSubmitModuleEdit = async (e) => {
    e.preventDefault()
    if (missionsText.value === '' || missionsText.value.trim() === '') {
      setMissionsText({
        ...missionsText,
        error: 'Enter what would you like to call to missions',
      })
    } else if (spacesText.value === '' || spacesText.value.trim() === '') {
      setSpacesText({
        ...spacesText,
        error: 'Enter what would you like to call to spaces',
      })
    } else if (
      propertiesText.value === '' ||
      propertiesText.value.trim() === ''
    ) {
      setPropertiesText({
        ...propertiesText,
        error: 'Enter what would you like to call to properties',
      })
    } else {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          token: credentials && credentials.data && credentials.data.token,
        },
      }
      const formData = {
        stageOne: missionsText.value,
        stageTwo: spacesText.value,
        stageThree: propertiesText.value,
      }

      try {
        const response = await axios.patch(
          `${baseUrl}/user/update-rename-module?userId=${selectedClient._id}`,
          formData,
          config
        )
        if (response.data && response.data.status) {
          toast.success(response.data.message)
          onCloseModuleEdit()
        }
      } catch (error) {
        toast.error('Something went wrong.')
      }
    }
  }

  const openPasswordModalHandler = (item) => {
    setShowPassModal(true)
    setCurrentItem(item)
  }

  const closePasswordModalHandler = () => {
    setShowPassModal(false)
    setPassword('')
    setConfirmPassword('')
  }

  const passwordSubmitHandler = () => {
    if (password === '') {
      setPasswordError('Password must not be empty')
      return
    } else if (password.length < 2) {
      setPasswordError('Password must be at least 2 characters')
      return
    }

    if (confirmPassword === '') {
      setPasswordError('Confirm Password must not be empty')
      return
    } else if (confirmPassword.length < 2) {
      setPasswordError('Confirm Password must be at least 2 characters')
      return
    }

    if (password !== confirmPassword) {
      setPasswordError('Password do not match try again')
      return
    }

    const data = {
      userEmail: currentItem.userEmail,
      userPassword: password,
      type: 'byAdmin',
      _id: currentItem._id,
    }

    dispatch(changeClientPassword(data))
  }

  const configHandler = (item) => {
    setShowConfigModal(true)
    setCurrentItem(item)
  }

  useEffect(() => {
    if (configurationList) {
      if (configurationList.status) {
        setAdmin({
          ...admin,
          ...configurationList.functionSetting,
        })
        setApp({
          ...app,
          ...configurationList.functionSetting,
        })
      }
    }
  }, [configurationList])

  useEffect(() => {
    if (currentItem && showConfigModal) {
      dispatch(getConfigList(currentItem._id))
    }
  }, [currentItem, showConfigModal])

  const configSubmitHandler = () => {
    if (currentItem && currentItem._id) {
      const data = {
        secureCode: app.secureCode, //if false then secureCode will be 1234. In app
        therapistView: app.therapistView, //In app
        pretestModule: admin.pretestModule, // In admin dashboard
        categoryReport: admin.categoryReport, // In admin dashboard
        statisticsReport: admin.statisticsReport, //In admin dashboard
        progressReport: admin.progressReport, //In admin dashboard
        pointReport: admin.pointReport, // In admin report
        performanceReport: app.performanceReport, // In app and user dashboard
        genericVideo: app.genericVideo, //in app and user dashboard
        responseVideo: admin.responseVideo, // in admin dashboard
        successVideo: admin.successVideo, // in admin dashboard
        renameModule: admin.renameModule, // in admin dashboard
        faq: app.faq, // in user dashboard and app
        aboutUs: app.aboutUs, // in user dashboard and app
        aboutAlgo: app.aboutAlgo, // in user dashboard and app
        privacyPolicy: app.privacyPolicy, // in user dashboard and app
        leaderBoard: app.leaderBoard,
        updateDialogueBox: app.updateDialogueBox,
        report: app.report,
      }

      dispatch(updateConfig(currentItem._id, data))
    }
  }

  const configCloseHandler = () => {
    setShowConfigModal(false)
    setCurrentItem(null)
    dispatch(clearUpdateConfig())
  }

  useEffect(() => {
    if (updateConfigRseponse) {
      if (updateConfigRseponse.status) {
        toast.success(updateConfigRseponse.message)
        dispatch(clearUpdateConfig())
        configCloseHandler()
      } else if (!updateConfigRseponse.status) {
        toast.error(updateConfigRseponse.message)
        // dispatch(clearUpdateConfig())
      }
    }
  }, [updateConfigRseponse])

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Clients'
        isAdd
        onAdd={onAddClinicHAndler}
        isSearch
        searchPlaceholder='Search Clients'
        onSearch={onSearchClinic}
        searchValue={search}
      />
      <div className='clinic_main_content'>
        {clientList !== null &&
        clientList.data &&
        clientList.data.result &&
        clientList.data.result.length > 0 ? (
          clientList.data.result.map((item, index) => (
            <ClinicCard
              key={index}
              item={item}
              loading={loading}
              onEditClick={onEditClick}
              onModuleNameChange={onModuleNameChange}
              currentItem={currentItem}
              onPassChange={openPasswordModalHandler}
              onChangeSwitch={enableDisableHandler}
              showId={true}
              showConfig={true}
              onClickConfig={configHandler}
            />
          ))
        ) : (
          <div className='data_not_found_container'>
            <p className='data_not_found_message'>
              {clientList && clientList.message}
            </p>
          </div>
        )}
      </div>
      {clientList !== null &&
        clientList.data &&
        clientList.data.result &&
        clientList.data.result.length > 0 &&
        clientList.data.totalPageSize > 10 && (
          <Pagination
            list={clientList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
      <AppModal show={showAddModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>
            {isUpadate ? 'edit client' : 'Add Client'}
          </p>
          <form className='clinic_form_container' onSubmit={onSubmitHandler}>
            <div className='clinic_form_top_container'>
              <div className='clinic_form_top_left_container'>
                <TextInput
                  placeholder='Name'
                  src='/images/clinic.png'
                  onChange={(name) => {
                    setNameError('')
                    setName(name.target.value)
                  }}
                  value={name}
                  error={nameError}
                />
                {isUpadate === null && (
                  <TextInput
                    placeholder='Email'
                    src='/images/email.png'
                    onChange={(email) => {
                      setEmailError('')
                      setEmail(email.target.value)
                    }}
                    value={email}
                    error={emailError}
                  />
                )}
                <TextInput
                  placeholder='Phone number'
                  src='/images/clinic-phone.png'
                  onChange={(number) => {
                    if (
                      number.target.value.length <= 10 &&
                      (isNumeric(number.target.value) ||
                        number.target.value.trim() === '')
                    ) {
                      setNumberError('')
                      setNumber(number.target.value)
                    }
                  }}
                  value={number}
                  error={numberError}
                  maxLength={10}
                />

                <div className='mission_dropdown'>
                  <div className='text_input_image_container'>
                    <img
                      src='/images/spaces.png'
                      alt='color'
                      className='text_input_image'
                    />
                  </div>
                  <select
                    name='space-type'
                    id='space-type'
                    value={clientType}
                    onChange={(e) => {
                      setClientType(e.target.value)
                      setClientTypeError('')
                    }}
                    className='mission_dropdown_select dropdown_smallfont '>
                    <option value=''>Select Client type</option>
                    {/* <option value='lab'>Lab</option> */}
                    <option value='clinic'>Clinic</option>
                  </select>
                </div>
                <TextInput
                  placeholder='Address'
                  src='/images/clinic-location.png'
                  onChange={(address) => {
                    setAddressError('')
                    setAddress(address.target.value)
                  }}
                  value={address}
                  error={addressError}
                />
                {clientTypeError && (
                  <p className='input_error_text'>{clientTypeError}</p>
                )}
              </div>
              <div className='clinic_form_image_upload_container'>
                <ImageUploader
                  onUpload={onUpload}
                  image={image}
                  setURL={(image) => setImage(image)}
                  errorMessage={imageError}
                  imageTitle='Upload logo'
                  showMessage={true}
                />
              </div>
            </div>

            <div className='modal_buttons'>
              <AppButton
                title={isUpadate ? 'update' : 'add'}
                type='submit'
                icon={
                  <img
                    src='/images/add-clinic.png'
                    className='app_button_image'
                  />
                }
                onClick={onSubmitHandler}
              />
              <AppButton
                title='cancel'
                type='button'
                buttonContainer={{
                  backgroundColor: 'var(--transparent)',
                  marginLeft: '0.5rem',
                }}
                textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                onClick={onCloseModal}
              />
            </div>
          </form>
        </div>
        <ProgressBar progress={progress * 100} />
      </AppModal>

      <AppModal3 show={showPassModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Change Password</p>
        </div>
        <div>
          <TextInput
            placeholder='New Password'
            type='password'
            src='/images/key.png'
            onChange={(event) => {
              setPassword(event.target.value)
              setPasswordError('')
            }}
            value={password}
          />
          <TextInput
            placeholder='Confirm Password'
            type='password'
            src='/images/key.png'
            onChange={(event) => {
              setConfirmPassword(event.target.value)
              setPasswordError('')
            }}
            value={confirmPassword}
            error={passwordError}
          />
        </div>

        <div className='modal_buttons'>
          <AppButton
            title='Submit'
            type='submit'
            // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
            onClick={passwordSubmitHandler}
          />
          <AppButton
            title='cancel'
            type='button'
            buttonContainer={{
              backgroundColor: 'var(--transparent)',
              marginLeft: '0.5rem',
            }}
            textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
            onClick={closePasswordModalHandler}
          />
        </div>
      </AppModal3>

      <AppModal3 show={showModuleModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Module Nomenclature</p>
          <h4 className='profile__contactInfo--heading'>
            What would you like to call 'Mission', 'Space' and 'Property'?
          </h4>
          <form className='clinic_form_container' onSubmit={onSubmitModuleEdit}>
            <div className='clinic_form_top_container'>
              <div className='clinic_form_top_left_container'>
                <TextInput
                  placeholder='eg, Mission'
                  src='/images/missions.png'
                  value={missionsText.value}
                  onChange={(e) => {
                    setMissionsText({ value: e.target.value, error: '' })
                  }}
                  error={missionsText.error}
                />
                <TextInput
                  placeholder='eg, Space'
                  src='/images/spaces.png'
                  value={spacesText.value}
                  onChange={(e) => {
                    setSpacesText({ value: e.target.value, error: '' })
                  }}
                  error={spacesText.error}
                />
                <TextInput
                  placeholder='eg, Property'
                  src='/images/properties.png'
                  value={propertiesText.value}
                  onChange={(e) => {
                    setPropertiesText({ value: e.target.value, error: '' })
                  }}
                  error={propertiesText.error}
                />
              </div>
            </div>

            <div className='modal_buttons'>
              <AppButton
                title='Save'
                type='submit'
                icon={
                  <img
                    src='/images/add-clinic.png'
                    className='app_button_image'
                  />
                }
                onClick={onSubmitModuleEdit}
              />
              <AppButton
                title='cancel'
                type='button'
                buttonContainer={{
                  backgroundColor: 'var(--transparent)',
                  marginLeft: '0.5rem',
                }}
                textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                onClick={onCloseModuleEdit}
              />
            </div>
          </form>
        </div>
        <ProgressBar progress={progress * 100} />
      </AppModal3>

      <AppModal show={showConfigModal} containerStyles={{ width: '110rem' }}>
        <div className='clinic_show_config_modal_header'>Menu Setting</div>
        <div className='clinic_show_config_modal'>
          {configLoading ? (
            <Loader
              type='bubble-top'
              bgColor={'#FFFFFF'}
              color={'#FFFFFF'}
              size={20}
            />
          ) : (
            <>
              <div className='clinic_show_modal_label'>Client Dashboard</div>
              <div className='clinic_show_config_modal_section'>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Pretest Module
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={false}
                        onChange={() => {
                          // setAdmin({
                          //   ...admin,
                          //   pretestModule: !admin.pretestModule,
                          // })
                        }}
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Pretest module for client
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Category Report
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={admin.categoryReport}
                        onChange={() =>
                          setAdmin({
                            ...admin,
                            categoryReport: !admin.categoryReport,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Category Report for client
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Statistics Report
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={admin.statisticsReport}
                        onChange={() =>
                          setAdmin({
                            ...admin,
                            statisticsReport: !admin.statisticsReport,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Statistics Report for client
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Progress Report
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={admin.progressReport}
                        onChange={() =>
                          setAdmin({
                            ...admin,
                            progressReport: !admin.progressReport,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Progress Report for client
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Point Report
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={admin.pointReport}
                        onChange={() =>
                          setAdmin({
                            ...admin,
                            pointReport: !admin.pointReport,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>Point Report for client</div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Response Video
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={admin.responseVideo}
                        onChange={() =>
                          setAdmin({
                            ...admin,
                            responseVideo: !admin.responseVideo,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Response video for client
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Success Video
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={admin.successVideo}
                        onChange={() =>
                          setAdmin({
                            ...admin,
                            successVideo: !admin.successVideo,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Success video for client
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Rename Module
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={admin.renameModule}
                        onChange={() =>
                          setAdmin({
                            ...admin,
                            renameModule: !admin.renameModule,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Rename module last saved changes will be shown to
                            client
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='clinic_show_modal_label'
                style={{ marginTop: '2rem' }}>
                App and User Dashboard
              </div>
              <div className='clinic_show_config_modal_section'>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Secure Code
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.secureCode}
                        onChange={() =>
                          setApp({
                            ...app,
                            secureCode: !app.secureCode,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            If false then secure code will be 1234 in app
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Therapist View
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.therapistView}
                        onChange={() =>
                          setApp({
                            ...app,
                            therapistView: !app.therapistView,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>Therapist view in app</div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Performance Report
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.performanceReport}
                        onChange={() =>
                          setApp({
                            ...app,
                            performanceReport: !app.performanceReport,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Performance report in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Generic Video
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.genericVideo}
                        onChange={() =>
                          setApp({
                            ...app,
                            genericVideo: !app.genericVideo,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Generic Video in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>FAQ</div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.faq}
                        onChange={() =>
                          setApp({
                            ...app,
                            faq: !app.faq,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            FAQ page in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>About Us</div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.aboutUs}
                        onChange={() =>
                          setApp({
                            ...app,
                            aboutUs: !app.aboutUs,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            About us page in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    About Algo
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.aboutAlgo}
                        onChange={() =>
                          setApp({
                            ...app,
                            aboutAlgo: !app.aboutAlgo,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            About algo page in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Privacy Policy
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.privacyPolicy}
                        onChange={() =>
                          setApp({
                            ...app,
                            privacyPolicy: !app.privacyPolicy,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            privacy policy in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Leaderboard
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.leaderBoard}
                        onChange={() =>
                          setApp({
                            ...app,
                            leaderBoard: !app.leaderBoard,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            Leaderboard page in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className='clinic_show_config_modal_tileContainer'>
                  <div className='clinic_show_config_modal_title'>
                    Progress Report
                  </div>
                  <div className='clinic_show_config_modal_side'>
                    <div>
                      <AppSwitchSmall
                        checked={app.report}
                        onChange={() =>
                          setApp({
                            ...app,
                            report: !app.report,
                          })
                        }
                      />
                    </div>

                    <div className='clinic_show_config_modal_iconContainer'>
                      <Tooltip
                        title={
                          <div className='tooltip'>
                            report page in app and user dashboard
                          </div>
                        }
                        className='tooltip'
                        contentEditable={true}>
                        <svg className='clinic_show_config_modal_icon'>
                          <use
                            xlinkHref={`/images/sprite.svg#icon-questionMark`}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='clinic_show_config_buttonContainer'>
          <AppButton title='Save' onClick={configSubmitHandler} />
          {/* <AppButton title='Cancel' onClick={configCloseHandler} /> */}
          <AppButton
            title='cancel'
            type='button'
            buttonContainer={{
              backgroundColor: 'var(--transparent)',
              marginLeft: '0.5rem',
            }}
            textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
            onClick={configCloseHandler}
          />
        </div>
      </AppModal>

      <AppLoader loader={currentItem === null && loading} />
    </div>
  )
}

export default Clinic
