import React, { useState, useEffect } from 'react'
import Content from '../../components/Content'
import styles from './Leaderboard.module.css'
import { BigHead } from '@bigheads/core'
import { useSelector, useDispatch } from 'react-redux'
import { getWeeklyCounts } from '../../../store/leaderboard/getWeeklyCount'
import LeaderSkeleton from '../../components/Skeletons/LeaderSkeleton'
import { getCountdown } from '../../../utils/validators'
import Avatar, { Piece } from 'avataaars'

const DEFAULT = {
  accessory: 'none',
  bgColor: 'blue',
  clothing: 'vneck',
  clothingColor: 'black',
  eyebrows: 'concerned',
  eyes: 'simple',
  facialHair: 'none',
  hair: 'short',
  hairColor: 'black',
  hat: 'none',
  hatColor: 'blue',
  lipColor: 'purple',
  mouth: 'openSmile',
  skinTone: 'light',
}

const Leaderboard = () => {
  const [defaultStudent, setDafaultStudent] = useState(null)
  const [rank1, setRank1] = useState(null)
  const [rank2, setRank2] = useState(null)
  const [rank3, setRank3] = useState(null)
  const [countDown, setCountDown] = useState('00d 00h 00m')

  const { weeklyCounts, loading } = useSelector(
    (leaderboard) => leaderboard.getWeeklyCounts
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      weeklyCounts &&
      weeklyCounts.data &&
      weeklyCounts.data.result &&
      Array.isArray(weeklyCounts.data.result)
    ) {
      weeklyCounts.data.result.forEach((item, index) => {
        if (item && item.isActiveStudent) {
          setDafaultStudent(item)
        }
        if (item && item.rank === '#1') {
          setRank1(item)
        }
        if (item && item.rank === '#2') {
          setRank2(item)
        }
        if (item && item.rank === '#3') {
          setRank3(item)
        }
      })

      setCountDown(getCountdown(weeklyCounts.data.week, weeklyCounts.data.year))
    }
  }, [weeklyCounts])

  useEffect(() => {
    dispatch(getWeeklyCounts())
  }, [])

  return (
    <Content currentMenu='leaderboard' headerTitle='leaderboard'>
      {loading ? (
        <LeaderSkeleton />
      ) : (
        <>
          {weeklyCounts &&
            weeklyCounts.data &&
            weeklyCounts.data.result &&
            weeklyCounts.status ? (
            <div className={styles.container}>
              <div className={styles.subContainer1}>
                {/* <div className={styles.timer}>{`Ends in ${countDown}`}</div> */}
                <div className={styles.subContainerTop}>
                  {defaultStudent &&
                    defaultStudent._id &&
                    defaultStudent.rank !== '#1' &&
                    defaultStudent.rank !== '#2' &&
                    defaultStudent.rank !== '#3' && (
                      <div className={styles.leaderboardStudentCard}>
                        <div className={styles.box}>
                          <div className={styles.bigheadsContainer}>
                            <Avatar
                              style={{ width: '6rem', height: '6rem' }}
                              avatarStyle={
                                defaultStudent.kid.kidAvatar.avatarStyle
                              }
                              topType={defaultStudent.kid.kidAvatar.topType}
                              accessoriesType={
                                defaultStudent.kid.kidAvatar.accessoriesType
                              }
                              hairColor={defaultStudent.kid.kidAvatar.hairColor}
                              facialHairType={
                                defaultStudent.kid.kidAvatar.facialHairType
                              }
                              clotheType={
                                defaultStudent.kid.kidAvatar.clotheType
                              }
                              clotheColor={
                                defaultStudent.kid.kidAvatar.clotheColor
                              }
                              eyeType={defaultStudent.kid.kidAvatar.eyeType}
                              eyebrowType={
                                defaultStudent.kid.kidAvatar.eyebrowType
                              }
                              mouthType={defaultStudent.kid.kidAvatar.mouthType}
                              skinColor={defaultStudent.kid.kidAvatar.skinColor}
                            />
                          </div>
                          <div className={styles.boxName}>You</div>
                          <div className={styles.boxDetails}>
                            {`${defaultStudent?.countOfCompletedQuestion}`}{' '}
                            questions answered
                          </div>
                          <div className={styles.trophy}>
                            {defaultStudent.rank === '#1' &&
                              defaultStudent.rank === '#2' &&
                              defaultStudent.rank === '#3' ? (
                              <div
                                className={styles.ribbonContainer}
                                key={defaultStudent?._id}>
                                <img
                                  src='/images/ribbon.png'
                                  className={styles.ribbon}
                                />
                                <span className={styles.rankNumber}>
                                  <span className={styles.hash}>#</span>
                                  {defaultStudent.rank.slice(1)}
                                </span>
                              </div>
                            ) : (
                              <div className={styles.rankBigtext}>
                                {defaultStudent.rank}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.progressBar}>
                          <div
                            className={styles.progress}
                            style={{
                              width: `${defaultStudent.countOfCompletedQuestion &&
                                defaultStudent.countOfTotalQuestionAttempted
                                ? (defaultStudent.countOfCompletedQuestion /
                                  defaultStudent.countOfTotalQuestionAttempted) *
                                100
                                : 0
                                }%`,
                            }}></div>
                        </div>
                      </div>
                    )}
                  {rank1 && rank1._id && (
                    <div
                      className={styles.leaderboardStudentCard}
                      key={rank1?._id}>
                      <div className={styles.box}>
                        <div className={styles.bigheadsContainer}>
                          <Avatar
                            style={{ width: '6rem', height: '6rem' }}
                            avatarStyle={rank1.kid.kidAvatar.avatarStyle}
                            topType={rank1.kid.kidAvatar.topType}
                            accessoriesType={
                              rank1.kid.kidAvatar.accessoriesType
                            }
                            hairColor={rank1.kid.kidAvatar.hairColor}
                            facialHairType={rank1.kid.kidAvatar.facialHairType}
                            clotheType={rank1.kid.kidAvatar.clotheType}
                            clotheColor={rank1.kid.kidAvatar.clotheColor}
                            eyeType={rank1.kid.kidAvatar.eyeType}
                            eyebrowType={rank1.kid.kidAvatar.eyebrowType}
                            mouthType={rank1.kid.kidAvatar.mouthType}
                            skinColor={rank1.kid.kidAvatar.skinColor}
                          />
                        </div>
                        <div
                          className={[
                            styles.boxName,
                            rank1.isActiveStudent ? {} : styles.blur,
                          ].join(' ')}>
                          {rank1?.kid?.kidName}
                        </div>
                        <div className={styles.boxDetails}>
                          {rank1?.countOfCompletedQuestion} questions answered
                        </div>
                        <div className={styles.trophy}>
                          <div className={styles.ribbonContainer}>
                            <img
                              src='/images/ribbon.png'
                              className={styles.ribbon}
                            />
                            <span className={styles.rankNumber}>
                              <span className={styles.hash}>#</span>1
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.progressBar}>
                        <div
                          className={styles.progress}
                          style={{
                            width: `${rank1.countOfCompletedQuestion &&
                              rank1.countOfTotalQuestionAttempted
                              ? (rank1.countOfCompletedQuestion /
                                rank1.countOfTotalQuestionAttempted) *
                              100
                              : 0
                              }%`,
                          }}></div>
                      </div>
                    </div>
                  )}
                  {rank2 && rank2._id && (
                    <div
                      className={styles.leaderboardStudentCard}
                      key={rank2?._id}>
                      <div className={styles.box}>
                        <div className={styles.bigheadsContainer}>
                          <Avatar
                            style={{ width: '6rem', height: '6rem' }}
                            avatarStyle={rank2.kid.kidAvatar.avatarStyle}
                            topType={rank2.kid.kidAvatar.topType}
                            accessoriesType={
                              rank2.kid.kidAvatar.accessoriesType
                            }
                            hairColor={rank2.kid.kidAvatar.hairColor}
                            facialHairType={rank2.kid.kidAvatar.facialHairType}
                            clotheType={rank2.kid.kidAvatar.clotheType}
                            clotheColor={rank2.kid.kidAvatar.clotheColor}
                            eyeType={rank2.kid.kidAvatar.eyeType}
                            eyebrowType={rank2.kid.kidAvatar.eyebrowType}
                            mouthType={rank2.kid.kidAvatar.mouthType}
                            skinColor={rank2.kid.kidAvatar.skinColor}
                          />
                        </div>
                        <div
                          className={[
                            styles.boxName,
                            rank2.isActiveStudent ? {} : styles.blur,
                          ].join(' ')}>
                          {rank2?.kid?.kidName}
                        </div>
                        <div className={styles.boxDetails}>
                          {rank2?.countOfCompletedQuestion} questions answered
                        </div>
                        <div className={styles.trophy}>
                          <div className={styles.ribbonContainer}>
                            <img
                              src='/images/ribbon.png'
                              className={styles.ribbon}
                            />
                            <span className={styles.rankNumber}>
                              <span className={styles.hash}>#</span>2
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.progressBar}>
                        <div
                          className={styles.progress}
                          style={{
                            width: `${rank2.countOfCompletedQuestion &&
                              rank2.countOfTotalQuestionAttempted
                              ? (rank2.countOfCompletedQuestion /
                                rank2.countOfTotalQuestionAttempted) *
                              100
                              : 0
                              }%`,
                          }}></div>
                      </div>
                    </div>
                  )}

                  {rank3 && rank3._id && (
                    <div
                      className={styles.leaderboardStudentCard}
                      key={rank3?._id}>
                      <div className={styles.box}>
                        <div className={styles.bigheadsContainer}>
                          <Avatar
                            style={{ width: '6rem', height: '6rem' }}
                            avatarStyle={rank3.kid.kidAvatar.avatarStyle}
                            topType={rank3.kid.kidAvatar.topType}
                            accessoriesType={
                              rank3.kid.kidAvatar.accessoriesType
                            }
                            hairColor={rank3.kid.kidAvatar.hairColor}
                            facialHairType={rank3.kid.kidAvatar.facialHairType}
                            clotheType={rank3.kid.kidAvatar.clotheType}
                            clotheColor={rank3.kid.kidAvatar.clotheColor}
                            eyeType={rank3.kid.kidAvatar.eyeType}
                            eyebrowType={rank3.kid.kidAvatar.eyebrowType}
                            mouthType={rank3.kid.kidAvatar.mouthType}
                            skinColor={rank3.kid.kidAvatar.skinColor}
                          />
                        </div>
                        <div
                          className={[
                            styles.boxName,
                            rank3.isActiveStudent ? {} : styles.blur,
                          ].join(' ')}>
                          {rank3?.kid?.kidName}
                        </div>
                        <div className={styles.boxDetails}>
                          {rank3?.countOfCompletedQuestion} questions answered
                        </div>
                        <div className={styles.trophy}>
                          <div className={styles.ribbonContainer}>
                            <img
                              src='/images/ribbon.png'
                              className={styles.ribbon}
                            />
                            <span className={styles.rankNumber}>
                              <span className={styles.hash}>#</span>3
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.progressBar}>
                        <div
                          className={styles.progress}
                          style={{
                            width: `${rank3.countOfCompletedQuestion &&
                              rank3.countOfTotalQuestionAttempted
                              ? (rank3.countOfCompletedQuestion /
                                rank3.countOfTotalQuestionAttempted) *
                              100
                              : 0
                              }%`,
                          }}></div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.subContainerBottom}>
                  <div className={styles.tilesContainer}>
                    {weeklyCounts &&
                      weeklyCounts.data &&
                      weeklyCounts.data.result &&
                      Array.isArray(weeklyCounts.data.result) &&
                      weeklyCounts.data.result.map((item, index) => {
                        return (
                          <div key={index}>
                            {
                              item.isActiveStudent === false &&
                              item.rank !== '#1' &&
                              item.rank !== '#2' &&
                              item.rank !== '#3' && (
                                <div className={styles.tiles} key={index}>
                                  <div className={styles.section}>
                                    <div className={styles.rankText}>
                                      {item.rank}
                                    </div>
                                    <div className={styles.bigheadsContainer2}>
                                      <Avatar
                                        style={{
                                          width: '3rem',
                                          height: '3rem',
                                        }}
                                        avatarStyle={
                                          item.kid.kidAvatar.avatarStyle
                                        }
                                        topType={item.kid.kidAvatar.topType}
                                        accessoriesType={
                                          item.kid.kidAvatar.accessoriesType
                                        }
                                        hairColor={item.kid.kidAvatar.hairColor}
                                        facialHairType={
                                          item.kid.kidAvatar.facialHairType
                                        }
                                        clotheType={
                                          item.kid.kidAvatar.clotheType
                                        }
                                        clotheColor={
                                          item.kid.kidAvatar.clotheColor
                                        }
                                        eyeType={item.kid.kidAvatar.eyeType}
                                        eyebrowType={
                                          item.kid.kidAvatar.eyebrowType
                                        }
                                        mouthType={item.kid.kidAvatar.mouthType}
                                        skinColor={item.kid.kidAvatar.skinColor}
                                      />
                                    </div>
                                    <div
                                      className={[
                                        styles.tileName,
                                        styles.blur,
                                      ].join(' ')}>
                                      {item?.kid?.kidName}
                                    </div>
                                  </div>
                                  <div className={styles.progressTileContainer}>
                                    {/* <div
                                      className={[
                                        styles.progressBar,
                                        styles.tileProgress,
                                      ].join(' ')}>
                                      <div
                                        className={styles.progress}
                                        style={{
                                          width: `${
                                            item.countOfCompletedQuestion &&
                                            item.countOfTotalQuestionAttempted
                                              ? (item.countOfCompletedQuestion /
                                                  item.countOfTotalQuestionAttempted) *
                                                100
                                              : 0
                                          }%`,
                                        }}></div>
                                    </div> */}
                                    <div
                                      className={[
                                        styles.progressTileText,
                                        styles.coinText,
                                      ].join(' ')}>
                                      {item?.total}
                                    </div>
                                    <img
                                      src='/images/BagOfCoins.png'
                                      className={styles.coin}
                                    />
                                  </div>
                                  <div
                                    className={styles.section}
                                    style={{ marginRight: '1rem' }}>
                                    <div className={styles.progressTileText}>
                                      {item?.countOfCompletedQuestion} questions
                                      answered
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.messageContainer}>
                <div>{weeklyCounts?.message}</div>
              </div>
            </div>
          )}
        </>
      )}
    </Content>
  )
}

export default Leaderboard
