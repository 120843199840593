import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'user-signup',
    initialState: {
        userSignupResponse: null,
        loading: false,
    },
    reducers: {
        requested: (users) => {
            users.loading = true
        },
        success: (users, action) => {
            users.userSignupResponse = action.payload
            users.loading = false
        },
        failed: (users, action) => {
            users.userSignupResponse = action.payload;
            users.loading = false
        },
        reset: (users, action) => {
            users.userSignupResponse = action.payload;
            users.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const userSignup = (data) => apiCallBegan({
    url: '/user/sign-up',
    method: 'POST',
    data,
    onLoginStart: requested.type,
    onLoginSuccess: success.type,
    onLoginFailed: failed.type
})

export const clearUserSignupResponse = () => apiCallBegan({
    onLogout: reset.type,
})