import { GET_SPACES_REQUEST, GET_SPACES_SUCCESS, GET_SPACES_FAIL, GET_SPACES_RESET } from '../constants/spaceConstants';

export const allSpacesReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_SPACES_REQUEST:
			return { loading: true };

		case GET_SPACES_SUCCESS:
			return { spaces: action.payload, loading: false };

		case GET_SPACES_FAIL:
			return { loading: false, error: action.payload };

		case GET_SPACES_RESET:
			return {};

		default:
			return state;
	}
};
