import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'get-about-Bx',
    initialState: {
        aboutTheBx: null,
        loading: false,
    },
    reducers: {
        requested: (about) => {
            about.loading = true
        },
        success: (about, action) => {
            about.aboutTheBx = action.payload
            about.loading = false
        },
        failed: (about, action) => {
            about.aboutTheBx = action.payload;
            about.loading = false
        },
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getAboutTheBx = (tag) => apiCallBegan({
    url: `/generic/get-about-info/${tag}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})
