import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enable-disable-howTo',
    initialState: {
        howToStatus: null,
        loading: false,
    },
    reducers: {
        requested: (howTo) => {
            howTo.loading = true
        },
        success: (howTo, action) => {
            howTo.howToStatus = action.payload;
            howTo.loading = false
        },
        failed: (howTo, action) => {
            howTo.howToStatus = action.payload;
            howTo.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const setEnableDisableHowTo = (data) => apiCallBegan({
    url: '/generic/enable-disable-tiles',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})