import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import './reports.css'

import AppHeader from '../../../../components/AppHeader'
import CategoryCard from '../../../../components/Cards/ReportsCard/CategoryCard'

import { getCategoryReport } from '../../../../store/reports/categoryReport'
import { getCategoryChartReport } from '../../../../store/reports/categoryChartReport'
import { options } from './chartOptions'
import ReportToggle from './ReportToggle'
import CategorySkeleton from '../../../../components/Skeletons/CategorySkeleton'
import { useHistory } from 'react-router'

const CategoryReport = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { categoryReport, loading } = useSelector(
    (report) => report.categoryReport
  )
  const { categoryChartReport } = useSelector(
    (report) => report.categoryChartReport
  )

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const [ischarts, setISCharts] = useState(false)

  useEffect(() => {
    dispatch(getCategoryReport())
    dispatch(getCategoryChartReport())
  }, [dispatch])

  const onClientChange = (item) => {
    if (item && item.clientType === 'lab') {
      history.push('/')
    } else {
      dispatch(getCategoryReport())
      dispatch(getCategoryChartReport())
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Category Report' onClientChange={onClientChange} />
      <ReportToggle onChange={(status) => setISCharts(status)} />
      <div className='client__container'>
        {ischarts ? (
          categoryReport !== null &&
          categoryReport.data &&
          categoryReport.data.length > 0 ? (
            categoryChartReport &&
            categoryChartReport.data && (
              <Bar
                data={categoryChartReport.data}
                options={options}
                className='report_chart_container'
              />
            )
          ) : (
            <div className='data_not_found_container'>
              <p className='data_not_found_message'>
                {categoryReport !== null && categoryReport.message}
              </p>
            </div>
          )
        ) : (
          <div
            className='missions_main_content'
            style={{ rowGap: '4rem', columnGap: '4rem' }}>
            {loading ? (
              <CategorySkeleton />
            ) : categoryReport &&
              categoryReport.data &&
              categoryReport.data.length > 0 ? (
              categoryReport.data.map((item, index) => (
                <CategoryCard item={item} key={index} index={index} />
              ))
            ) : (
              <div className='data_not_found_container'>
                <p className='data_not_found_message'>
                  {categoryReport !== null && categoryReport.message}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CategoryReport
