import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-plans',
  initialState: {
    plansList: null,
    loading: false,
  },
  reducers: {
    requested: (onBoard) => {
      onBoard.loading = true
    },
    success: (onBoard, action) => {
      onBoard.plansList = action.payload
      onBoard.loading = false
    },
    failed: (onBoard, action) => {
      onBoard.plansList = action.payload
      onBoard.loading = false
    },
    reset: (onBoard, action) => {
      onBoard.plansList = action.payload
      onBoard.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getPlans = (type) =>
  apiCallBegan({
    url: `generic/get-price-list?clientType=${type}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearGetPlans = () =>
  apiCallBegan({
    onReset: reset.type,
  })
