import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAboutDataAction } from '../../redux/actions/aboutActions'

import Content from '../../components/Content'
import AboutScreenStyles from './About.module.css'
import AboutSkeleton from '../../components/Skeletons/AboutSkeleton'

const About = () => {
  const dispatch = useDispatch()
  const about = useSelector((state) => state.about)

  React.useEffect(() => {
    dispatch(getAboutDataAction('algo'))
  }, [dispatch])

  return (
    <Content currentMenu='about' headerTitle='About The Algorithm'>
      {about && about.loading ? (
        <AboutSkeleton />
      ) : (
        about &&
        about.data &&
        about.data.data && (
          <Fragment>
            <div className={AboutScreenStyles.about_container}>
              <img
                src={about.data.data.aboutImage}
                alt={about.data.data.type}
                className={AboutScreenStyles.about_container_image}
              />
              <div>
                <h2 className={AboutScreenStyles.about_title}>
                  {about.data.data.aboutDescription}
                </h2>
              </div>
            </div>
            <h2
              className={AboutScreenStyles.about_container_short}
              style={{ marginTop: '2rem' }}>
              {about.data.data.shortDescription}
            </h2>
          </Fragment>
        )
      )}

      <div className={AboutScreenStyles.aboutLinks}>
        <Link to='/about-bx' className={AboutScreenStyles.aboutLink}>
          About Bx
        </Link>
        <Link to='/privacy-policy' className={AboutScreenStyles.aboutLink}>
          Privacy Policy
        </Link>
      </div>
    </Content>
  )
}

export default About
