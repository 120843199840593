import {
  GET_PRETEST_REPORT_REQUEST,
  GET_PRETEST_REPORT_SUCCESS,
  GET_PRETEST_REPORT_FAIL,
  GET_PRETEST_REPORT_RESET,
  GET_USER_INFO_BY_TOKEN_REQUEST,
  GET_USER_INFO_BY_TOKEN_SUCCESS,
  GET_USER_INFO_BY_TOKEN_FAIL,
  GET_USER_INFO_BY_TOKEN_RESET,
  UPDATE_USERS_PROFILE_REQUEST,
  UPDATE_USERS_PROFILE_SUCCESS,
  UPDATE_USERS_PROFILE_FAIL,
  UPDATE_USERS_PROFILE_RESET,
} from '../constants/myProfileConstants'

export const myProfileReducer = (
  state = {
    loading: false,
    pretestReport: null,
  },
  action
) => {
  switch (action.type) {
    case GET_PRETEST_REPORT_REQUEST:
      return { loading: true }

    case GET_PRETEST_REPORT_SUCCESS:
      return { pretestReport: action.payload, loading: false }

    case GET_PRETEST_REPORT_FAIL:
      return { loading: false, error: action.payload }

    case GET_PRETEST_REPORT_RESET:
      return {
        loading: false,
        pretestReport: act,
      }

    default:
      return state
  }
}
export const myProfileUserReducer = (
  state = {
    loading: false,
    userByTokenInfo: null,
  },
  action
) => {
  switch (action.type) {
    case GET_USER_INFO_BY_TOKEN_REQUEST:
      return { loading: true }

    case GET_USER_INFO_BY_TOKEN_SUCCESS:
      return { userByTokenInfo: action.payload, loading: false }

    case GET_USER_INFO_BY_TOKEN_FAIL:
      return { loading: false, error: action.payload }

    case GET_USER_INFO_BY_TOKEN_RESET:
      return {
        loading: false,
        userByTokenInfo: null,
      }

    default:
      return state
  }
}

export const updateUserprofileReducer = (
  state = {
    loading: false,
    updateUserProfileResponse: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_USERS_PROFILE_REQUEST:
      return { loading: true }

    case UPDATE_USERS_PROFILE_SUCCESS:
      return { updateUserProfileResponse: action.payload, loading: false }

    case UPDATE_USERS_PROFILE_FAIL:
      return { loading: false, error: action.payload }

    case UPDATE_USERS_PROFILE_RESET:
      return {
        loading: false,
        updateUserProfileResponse: action.payload,
      }

    default:
      return state
  }
}
