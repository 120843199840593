import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	GET_USER_DETAILS_BY_TOKEN_REQUEST,
	GET_USER_DETAILS_BY_TOKEN_SUCCESS,
	GET_USER_DETAILS_BY_TOKEN_FAIL,
	GET_USER_DETAILS_BY_TOKEN_RESET,
	GET_TILES_REQUEST,
	GET_TILES_SUCCESS,
	GET_TILES_FAIL,
	GET_TILES_RESET
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true };

		case USER_LOGIN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload };

		case USER_LOGOUT:
			return {};

		default:
			return state;
	}
};

export const userDetailsByTokenReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_USER_DETAILS_BY_TOKEN_REQUEST:
			return { loading: true };

		case GET_USER_DETAILS_BY_TOKEN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_USER_DETAILS_BY_TOKEN_FAIL:
			return { loading: false, error: action.payload };

		case GET_USER_DETAILS_BY_TOKEN_RESET:
			return {};

		default:
			return state;
	}
};

export const tilesReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TILES_REQUEST:
			return { loading: true };

		case GET_TILES_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_TILES_FAIL:
			return { loading: false, error: action.payload };

		case GET_TILES_RESET:
			return {};

		default:
			return state;
	}
};
