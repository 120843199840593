import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillPlusCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'

import AppHeader from '../../../../components/AppHeader'
import FaqCard from '../../../../components/Cards/FaqCard'
import AppButton from '../../../../components/forms/AppButton'

import { getFaqList } from '../../../../store/faq/faqList'
import { addFAQ, clearAddFAQtResponse } from '../../../../store/faq/addFAQ'
import { setEnableDisableFAQ } from '../../../../store/faq/enableDisableFAQ'

import './faq.css'
import AppLoader from '../../../../components/AppLoader'

const FAQs = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { faqList } = useSelector((bar) => bar.faqList)
  const { addFAQResponse, loading } = useSelector((user) => user.addFAQResponse)

  const [addHideShow, setAddHideShow] = useState(false)
  const [faqID, setFaqID] = useState(null)
  const [question, setQuestion] = useState('')
  const [questionError, setQuestionError] = useState('')
  const [answer, setAnswer] = useState('')
  const [answerError, setAnswerError] = useState('')

  useEffect(() => {
    dispatch(getFaqList())
  }, [dispatch])

  useEffect(() => {
    if (addFAQResponse != null) {
      if (addFAQResponse.status) {
        setAddHideShow(false)
        toast.success(addFAQResponse.message)
        setQuestion('')
        setAnswer('')
        dispatch(getFaqList())
        dispatch(clearAddFAQtResponse())
      } else {
        toast.error(addFAQResponse.message)
        dispatch(clearAddFAQtResponse())
      }
    }
  }, [addFAQResponse, dispatch])

  const onAddUpdateHandler = async (event) => {
    event.preventDefault()

    if (question === '') {
      setQuestionError('Please enter question')
    } else if (answer === '') {
      setAnswerError('Please enter answer')
    } else {
      if (faqID) {
        const dataForUpdate = {
          question: question.trim(),
          answer: answer.trim(),
          _id: faqID,
        }
        await dispatch(addFAQ(dataForUpdate))
      } else {
        const dataForUpdate = {
          question: question.trim(),
          answer: answer.trim(),
        }
        await dispatch(addFAQ(dataForUpdate))
      }
    }
  }

  const onEditHandler = (item) => {
    setAddHideShow(true)
    setFaqID(item._id)
    setQuestion(item.question)
    setAnswer(item.answer)
  }

  const onCancelHandler = () => {
    setAddHideShow(false)
    setQuestion('')
    setAnswer('')
    setFaqID('')
  }

  const onEnableDisableMissionChangeHandler = async (item) => {
    await dispatch(setEnableDisableFAQ({ _id: item._id, enable: item.enable }))
    await dispatch(getFaqList())
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Frequently Asked Questions'
        isAdd
        onAdd={() => setAddHideShow(true)}
      />

      <div className='faq_main_content'>
        <div className='faq_list'>
          {faqList &&
          faqList.data &&
          faqList.data.result &&
          faqList.data.result.length > 0 ? (
            faqList.data.result.map((item, index) => {
              return (
                <FaqCard
                  key={index}
                  quetion={item.question}
                  answer={item.answer}
                  checked={item.enable}
                  onChange={() => onEnableDisableMissionChangeHandler(item)}
                  onClickEdit={() => onEditHandler(item)}
                />
              )
            })
          ) : (
            <p className='nodata_message'>{faqList && faqList.message}</p>
          )}
        </div>
        {addHideShow && (
          <div className='add_faq_section'>
            <p className='form_header_text' style={{ color: 'var(--black)' }}>
              Add FAQ
            </p>
            <form
              className='bx_algo_edit_modal_left mt-2'
              onSubmit={onAddUpdateHandler}>
              <textarea
                spellCheck={false}
                autoFocus
                className='textarea'
                rows={2}
                placeholder='Question'
                value={question}
                onChange={(event) => {
                  setQuestion(event.target.value)
                  setQuestionError('')
                }}
              />
              {questionError && (
                <p className='error_message'>{questionError}</p>
              )}
              <p
                className='form_header_text'
                style={{ color: 'var(--secondary)' }}>
                FAQ Question
              </p>
              <textarea
                spellCheck={false}
                className='textarea'
                rows={4}
                placeholder='Answer'
                style={{ marginTop: '2rem' }}
                value={answer}
                onChange={(event) => {
                  setAnswer(event.target.value)
                  setAnswerError('')
                }}
              />
              {answerError && <p className='error_message'>{answerError}</p>}
              <p
                className='form_header_text'
                style={{ color: 'var(--secondary)' }}>
                FAQ Answer
              </p>

              <div className='modal_buttons'>
                <AppButton
                  title='add'
                  type='submit'
                  icon={<AiFillPlusCircle className='app_button_image' />}
                  onClick={onAddUpdateHandler}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={onCancelHandler}
                />
              </div>
            </form>
          </div>
        )}
      </div>
      <AppLoader loader={loading} />
    </div>
  )
}

export default FAQs
