import React from 'react';

import './Skeleton.css';
const AllQuestionCategoriesSkeleton = () => {
	const count = [ 1, 2, 3, 4, 5, 6, 7, 8, 10, 11 ];
	return (
		<div className='client__container'>
			<div className='missions_main_content'>
				{count &&
					count.map((item) => (
						<div key={item} className='client_card_skeleton'>
							<div className='flex' style={{ flexDirection: 'column', alignItems: 'center' }}>
								<div className='client_card_image_skeleton' />
								<div className='client_card_heading_skeleton skeleton' style={{ margin: '0 2rem' }} />
							</div>
							<div className='client_card_actions'>
								<div className='flex'>
									<div className='client_card_toggle_skeleton skeleton' />
									<div className='client_card_edit_skeleton skeleton' />
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default AllQuestionCategoriesSkeleton;
