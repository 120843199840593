import React from 'react';

const TextInput = ({ placeholder, onChange, value, error, maxLength, onFocus }) => {
	return (
		<div className='input_container'>
			<div className='text_input_container' style={{ backgroundColor: 'var(--white)' }}>
				<textarea
					placeholder={placeholder}
					className='text_input'
					value={value}
					onChange={onChange}
					maxLength={maxLength}
					onFocus={onFocus}
				/>
			</div>
			<p className='input_error_text'>{error}</p>
		</div>
	);
};

export default TextInput;
