import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'client-password-change',
  initialState: {
    passwordChangeResponse: null,
    loading: false,
  },
  reducers: {
    requested: (users, action) => {
      users.loading = true
    },
    success: (users, action) => {
      users.passwordChangeResponse = action.payload
      users.loading = false
    },
    failed: (users, action) => {
      users.passwordChangeResponse = action.payload
      users.loading = false
    },
    reset: (users, action) => {
      users.passwordChangeResponse = action.payload
      users.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const changeClientPassword = (data) =>
  apiCallBegan({
    url: '/auth/change-password',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearChangeClientPasswordResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
