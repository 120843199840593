import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import './charts.css';

import { getCompletedAndIncompletedMissionCount } from '../../store/dashboard/missionCount';
import SmallChartSkeleton from '../Skeletons/SmallChartSkeleton';

const MissionChart = () => {

	const dispatch = useDispatch();
	const { missionCountList, loading } = useSelector((list) => list.missionCount);
	const { enabledMissionList } = useSelector((list) => list.enabledMissionList);

	const [largestCounts, setLargestCounts] = useState(0);

	useEffect(
		() => {
			const getAllMissions = async () =>
				await enabledMissionList.data.result.forEach((item) =>
					dispatch(getCompletedAndIncompletedMissionCount(item._id, item.title))
				);
			if (enabledMissionList && enabledMissionList.status) {
				getAllMissions();
			}
		},
		[dispatch, enabledMissionList.status]
	);

	useEffect(
		() => {
			Array.isArray(missionCountList) &&
				missionCountList.length > 0 &&
				missionCountList.map(
					(mission) => mission.inCompletedCount > largestCounts && setLargestCounts(mission.inCompletedCount)
				);
		},
		[missionCountList, largestCounts]
	);

	const options = {
		animation: true,
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
		elements: {
			bar: {
				borderRadius: 10,
			}
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true
			}
		}
	}

	return (
		<div className="chart_main_container">
			<div className='chartContainer' style={{ marginLeft: 0 }}>
				{loading ? (
					<SmallChartSkeleton from='missionChart' />
				) : (
					<Fragment>
						<div className='chart'>
							{missionCountList && missionCountList.loading || enabledMissionList && enabledMissionList.loading ?
								<SmallChartSkeleton />
								:
								missionCountList &&
									missionCountList &&
									Array.isArray(missionCountList) &&
									missionCountList.length > 0 ?
									<div className="chart_container">
										{
											<Bar
												data={{
													labels: missionCountList.map(i => i.label),
													datasets: [
														{
															label: "Completed",
															data: missionCountList.map(i => i.completedCount),
															backgroundColor: ["rgb(75, 192, 192)"],
														},
														{
															label: "Incompleted",
															data: missionCountList.map(i => i.inCompletedCount),
															backgroundColor: ["rgb(255, 159, 64)"],
														},
													]
												}}
												options={options}
												className="dashboard_charts"
												height="110rem"
											/>
										}

									</div>
									:
									<div className='chart_no_data'>
										<h1>No Data</h1>
									</div>
							}
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default MissionChart;




