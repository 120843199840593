import React from 'react'
import { Link } from 'react-router-dom'
import AppSwitchSmall from '../../AppSwitchSmall'

const VideoCard = ({
  item,
  onEditClick,
  onDelete,
  onAddClick,
  showLink = true,
  showToggle = false,
  checked,
  onChange = () => {},
  loading = false,
  onAttach = () => {},
  showAttach = true,
}) => {
  return (
    <div className='client_card_container'>
      <div className='client_card_view_container'>
        <div className='client_card_detail_container' style={{ width: '100%' }}>
          <div
            className='client_card_action_container'
            style={{ alignItems: 'flex-start' }}>
            <p className='client_card_title' style={{ width: '18rem' }}>
              {item.title}
            </p>
            <div className='client_card_action_container_left'>
              {showAttach && (
                <img
                  src='/images/attachment.png'
                  className='client_card_edit_image2'
                  onClick={() => onAttach(item)}
                />
              )}
              <img
                src='/images/editPrimary.png'
                className='client_card_edit_image'
                onClick={() => onEditClick(item)}
              />
              <img
                src='/images/delete.png'
                className='client_card_delete_image'
                onClick={() => onDelete(item)}
              />
              {showToggle && (
                <AppSwitchSmall
                  checked={checked}
                  onChange={onChange}
                  loading={loading}
                />
              )}
            </div>
          </div>

          {showLink && (
            <Link
              to={`/videos/questions/${item._id}/${item.title}/${item.fileType}`}
              className='client_card_view_space'>
              {item.questionsCount} Questions
            </Link>
          )}
        </div>
      </div>
      {item?.fileType === 'doc' ? (
        <div className='client_card_pdf_image_container'>
          <img src='/images/pdf.png' className='client_card_pdf_image' />
        </div>
      ) : (
        <>
          {item?.fileType === 'audio' ? (
            <div className='video_card_audio_container'>
              <img
                src='/images/audio_icon.png'
                className='client_card_pdf_image'
                style={{ width: '10.5rem', height: '10.5rem' }}
              />
              <audio
                controls
                style={{ width: '260px' }}
                controlsList='nodownload'>
                <source src={item.videoURL} type='audio/mpeg' />
                <source src={item.videoURL} type='audio/ogg' />
                Your browser does not support HTML video.
              </audio>
            </div>
          ) : (
            <>
              {item?.fileType === 'image' ? (
                <img
                  alt='logo1'
                  src={item.videoURL}
                  style={{
                    width: '260px',
                    height: '14rem',
                    // objectFit: 'contain',
                  }}
                />
              ) : (
                <video controls width='250'>
                  <source src={item.videoURL} type='video/mp4' />
                  Your browser does not support HTML video.
                </video>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default VideoCard
