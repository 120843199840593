import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../../middleware/apis';

const slice = createSlice({
	name: 'pretest-report',
	initialState: {
		preTestReport: null,
		loading: false
	},
	reducers: {
		requested: (report) => {
			report.loading = true;
		},
		success: (report, action) => {
			report.preTestReport = action.payload;
			report.loading = false;
		},
		failed: (report, action) => {
			report.preTestReport = action.payload;
			report.loading = false;
		}
	}
});

const { requested, success, failed } = slice.actions;
export default slice.reducer;

export const getPreTestReport = () =>
	apiCallBegan({
		url: `/report/get-pre-test-point-report`,
		method: 'GET',
		onStart: requested.type,
		onSuccess: failed.type,
		onFailed: success.type
	});
