import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-Skill-Graph-data',
  initialState: {
    skillGraphData: null,
    loading: false,
  },
  reducers: {
    requested: (graph) => {
      graph.loading = true
    },
    success: (graph, action) => {
      graph.skillGraphData = action.payload
      graph.loading = false
    },
    failed: (graph, action) => {
      graph.skillGraphData = action.payload
      graph.loading = false
    },
    reset: (graph, action) => {
      graph.skillGraphData = action.payload
      graph.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getSkillGraphData = (mission) =>
  apiCallBegan({
    url: `point/get-graph-data-web/skill-performance?mission=${mission}&&offset=-330`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearSkillGraphData = () =>
  apiCallBegan({
    onReset: reset.type,
  })
