import React, { Fragment } from 'react';
import ButtonSkeleton from './ButtonSkeleton';
import InputBoxSkeleton from './InputBoxSkeleton';

import SkeletonStyles from './Skeletons.module.css';

const OtpSkeleton = () => {
	return (
		<Fragment>
			<InputBoxSkeleton />
			<ButtonSkeleton />
			<div className={SkeletonStyles.flexColumn} style={{ marginTop: '5rem' }}>
				<div className={[ SkeletonStyles.login_container_loginBox_registerBtn, SkeletonStyles.skeleton2 ].join(' ')} />
			</div>
		</Fragment>
	);
};

export default OtpSkeleton;
