import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseUrl } from '../../../middleware/url';

import AppSwitchSmall from '../../AppSwitchSmall';

const MissionCard = ({ item, loading, onEdit, onChangeSwitch, currentItem, onDelete, totalMissions, handleOrderChange, spaceName }) => {
  // console.log('item', item)
  const [items, setItems] = React.useState([]);
  const [selectedOrder, setSelectedOrder] = React.useState(item.orderNumber);

  React.useState(() => {
    const data = [];
    for (let i = 0; i < totalMissions; i++) {
      data.push(i);
    }
    setItems(data);
  });

  return (
    <div className='client_card_container'>
      <div className='client_card_view_container'>
        <img alt='mission' src={item.imageURL} className='client_card_image' />
        <div className='client_card_detail_container'>
          <p className='client_card_title'>{item.title}</p>
          <div className='flex'>
            <p className='client_card_count'>
              {' '}
              {item.spaceCount} {spaceName} |
            </p>
            <div className='flex'>
              <p className='client_card_count'> Color</p>
              <div
                style={{
                  backgroundColor: item.color !== null && item.color.primaryColor,
                }}
                className='dropdown_selectedColor'
              />
            </div>
          </div>
          <div className='client_card_view_space_container'>
            <select
              value={selectedOrder}
              onChange={e => {
                handleOrderChange(e.target.value, item.orderNumber);
                setSelectedOrder(e.target.value);
              }}
            >
              {/* <option>Select Order</option> */}
              {totalMissions > 0 &&
                items.length > 0 &&
                items.map(item => (
                  <option key={item} value={item + 1}>
                    Order {item + 1}
                  </option>
                ))}
            </select>
            <Link to={`/spaces/${item._id}/${item.title}`} className='client_card_view_space'>
              view {spaceName}
            </Link>
          </div>
        </div>
      </div>
      <div className='client_card_action_container'>
        <div className='client_card_action_container_left' style={{ marginTop: 0 }}>
          <AppSwitchSmall checked={item.enable} onChange={() => onChangeSwitch(item)} loading={currentItem === item._id && loading} />
          <img src='/images/editPrimary.png' className='client_card_edit_image' onClick={() => onEdit(item)} />
        </div>
        <img src='/images/delete.png' className='client_card_delete_image' onClick={() => onDelete(item)} />
      </div>
    </div>
  );
};

export default MissionCard;
