import React, { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import Content from '../../components/Content';
import Modal from '../../components/Modal';
import MissionCardsSkeleton from '../../components/Skeletons/MissionCardsSkeleton';
import HowCardSkeleton from '../../components/Skeletons/HowCardSkeleton';
import SelectedPropertyMediaSkeleton from '../../components/Skeletons/SelectedPropertyMediaSkeleton';
import PropertyAnswerSkeleton from '../../components/Skeletons/PropertyAnswerSkeleton';
import VideoPlayer from '../../components/VideoPlayer';
import PreVideoPlayer from '../../components/VideoPlayer/PreVideoPlayer';
import MatchPairDraggable2 from './MatchPairDraggable2';

import { addLogAction, getTilesAction, userDetailsByTokenAction } from '../../redux/actions/userActions';
import { getAllMissionsAction } from '../../redux/actions/missionActions';
import { getAllSpacesAction } from '../../redux/actions/spaceActions';
import {
  getAllPropertiesAction,
  getMediaDetailsAction,
  getPropertyQuestionsAction,
  getQuestionProgressAction,
  getResponseVideosAction,
} from '../../redux/actions/propertyActions';
import { addStatisticsAction } from '../../redux/actions/statisticsActions';
import { ADD_STATISTICS_RESET } from '../../redux/constants/statisticsConstants';
import { GET_MEDIA_DETAILS_RESET, GET_PROPERTIES_RESET, GET_PROPERTY_QUESTIONS_RESET, GET_QUESTION_PROGRESS_RESET } from '../../redux/constants/propertyConstants';
import { GET_SPACES_RESET } from '../../redux/constants/spaceConstants';

import HomeScreenStyles from './Home.module.css';
import { getConfigList, clearConfigList } from '../../../store/configuration/getConfig';
import PdfViewer from '../../components/PdfViewer';
import PrePdfViewer from '../../components/PdfViewer/PrePdfViewer';
import { getProgressData, clearProgressData } from '../../../store/userDashboard/getProgress';

import axios from 'axios';
import { baseUrl } from '../../../middleware/url';

import { updateToken } from '../../../store/login';
import AudioPlayer from '../../components/AudioPlayer';
import PreAudioPlayer from '../../components/AudioPlayer/PreAudioPlayer';
import { useHistory } from 'react-router-dom';
import ImageViewer from '../../components/ImageViewer';
import PreImageViewer from '../../components/ImageViewer/PreImageViewer';
import { plur } from '../../../utils/plural';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [responseVideoModal, setResponseVideoModal] = useState(false);
  const [currentResponseVideo, setCurrentResponseVideo] = useState('');
  const responseVideos = useSelector(state => state.responseVideos);

  const [howToClicked, setHowToClicked] = useState(false);
  const [howToType, setHowToType] = useState({});
  const [howToTypeVideo, setHowToTypeVideo] = useState({
    url: '',
    loading: false,
  });
  const [howToCompleted, setHowToCompleted] = useState(false);

  const [howToTop, setHowToTop] = useState(false);

  const [selectedMission, setSelectedMission] = useState({});
  const [selectedSpace, setSelectedSpace] = useState({});
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedMedia, setSelectedMedia] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [noAnswerModal, setNoAnswerModal] = useState(false);
  const [wrongAnswerModal, setWrongAnswerModal] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [selectedAnswerError, setSelectedAnswerError] = useState('');
  const [selectedMultipleAnswer, setSelectedMultipleAnswer] = useState([]);
  const [redOptions, setRedOptions] = useState([]);
  const [greenOptions, setGreenOptions] = useState([]);
  const [list, setList] = useState([]);
  const [questionModal, setQuestionModal] = useState(false);

  const [selectedMediaVideoUrl, setSelectedMediaVideoUrl] = useState({
    url: '',
    loading: false,
  });

  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [maxSelectedMediaIndex, setMaxSelectedMediaIndex] = useState(0);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [maxSelectedQuestionIndex, setMaxSelectedQuestionIndex] = useState(0);
  const [closeModal, setCloseModal] = useState(false);
  const [showLottieAnimation, setShowLottieAnimation] = useState(null);
  const [showAnimationModel, setShowAnimationModel] = useState(false);
  const [tokenUpdated, setTokenUpdated] = useState(false);
  const [showMissionCompletedModel, setShowMissionCompletedModel] = useState(false);

  //pre assets states

  const [showPreAssets, setShowPreAssets] = useState(true);

  const { credentials, userInfo } = useSelector(state => state.credentials);
  const userDetails = useSelector(state => state.userDetails);
  const tiles = useSelector(state => state.tiles);
  const missions = useSelector(state => state.missions);
  const spaces = useSelector(state => state.spaces);
  const properties = useSelector(state => state.properties);
  const mediaDetails = useSelector(state => state.mediaDetails);
  const propertyQuestions = useSelector(state => state.propertyQuestions);
  const addStatistics = useSelector(state => state.addStatistics);
  const questionProgress = useSelector(state => state.questionProgress);

  const { configurationList, loading: configLoading } = useSelector(config => config.getConfig);

  const { progressData } = useSelector(state => state.getProgress);

  useEffect(() => {
    if (selectedMission && selectedSpace && selectedProperty) {
      dispatch(getProgressData(selectedMission._id, selectedSpace._id, selectedProperty._id));
    }
  }, [selectedMission, selectedSpace, selectedProperty, selectedQuestionIndex, selectedMediaIndex, showMissionCompletedModel]);

  useEffect(() => {
    if (configurationList === null && userInfo && userInfo.client && userInfo.client._id && credentials !== null && credentials.status) {
      dispatch(getConfigList(userInfo.client._id));
    }
  }, []);

  useEffect(() => {
    if (credentials !== null && credentials.status) {
      dispatch(getTilesAction());
      dispatch(userDetailsByTokenAction());
    }
  }, [credentials, dispatch]);

  useEffect(() => {
    if (tiles && tiles.data && tiles.data.data && tiles.data.data.result && tiles.data.data.result.length > 0) {
      const data = tiles.data.data.result.every(res => {
        return res.isCompleted;
      });
      setHowToCompleted(data);

      // tiles.data.data.result.map((res) => {
      // 	if (res.isCompleted) {
      // 		setHowToCompleted(true);
      // 	} else {
      // 		setHowToCompleted(false);
      // 	}
      // 	return true;
      // });
    } else if (
      (tiles && tiles.data && tiles.data.data && tiles.data.data.result && tiles.data.data.result.length === 0) ||
      (!tiles.status && tiles.data && tiles.data.data && tiles.data.data.length === 0)
    ) {
      setHowToCompleted(true);
    }
  }, [tiles]);

  //changes

  useEffect(() => {
    if (
      howToCompleted ||
      (userDetails && userDetails.userInfo && userDetails.userInfo.data && userDetails.userInfo.data.client && userDetails.userInfo.data.client.clientType === 'lab')
    ) {
      dispatch(getAllMissionsAction());
      dispatch(getResponseVideosAction());
    }
  }, [dispatch, howToCompleted, userDetails]);

  useEffect(() => {
    let randomIndex = Math.floor(Math.random() * 8 + 0);
    if (responseVideos && responseVideos.videos && responseVideos.videos.data && responseVideos.videos.data.length > 0) {
      if (randomIndex >= 0 && randomIndex < responseVideos.videos.data.length) {
        setCurrentResponseVideo(responseVideos.videos.data[randomIndex]);
      }
    }
  }, [responseVideos, selectedQuestionIndex]);

  useEffect(() => {
    if (currentResponseVideo === '') {
      setResponseVideoModal(false);
      if (showLottieAnimation) {
        setShowAnimationModel(true);
      }
    }
  }, [currentResponseVideo, showLottieAnimation]);

  useEffect(() => {
    if (howToCompleted && selectedMission && selectedMission._id) {
      dispatch(getAllSpacesAction(selectedMission._id));
    }
  }, [dispatch, howToCompleted, selectedMission]);

  useEffect(() => {
    if (howToCompleted && selectedMission && selectedMission._id && selectedSpace && selectedSpace._id) {
      dispatch(getAllPropertiesAction(selectedSpace._id));
    }
  }, [dispatch, howToCompleted, selectedMission, selectedSpace]);

  useEffect(() => {
    if (howToCompleted && selectedMission && selectedMission._id && selectedSpace && selectedSpace._id && selectedProperty && selectedProperty._id) {
      dispatch(getMediaDetailsAction(selectedProperty.media));
    }
  }, [dispatch, howToCompleted, selectedMission, selectedSpace, selectedProperty]);

  useEffect(() => {
    if (mediaDetails && mediaDetails.medias && mediaDetails.medias.data && mediaDetails.medias.data.result && mediaDetails.medias.data.result.length > 0) {
      setMaxSelectedMediaIndex(mediaDetails.medias.data.result.length - 1);
    }
  }, [mediaDetails]);

  console.log(selectedQuestion.question, 'question');

  useEffect(() => {
    if (mediaDetails && mediaDetails.medias && mediaDetails.medias.data && mediaDetails.medias.data.result && mediaDetails.medias.data.result.length > 0) {
      setSelectedMedia(mediaDetails.medias.data.result[selectedMediaIndex]);
    }
  }, [mediaDetails, selectedMediaIndex]);

  useEffect(() => {
    if (selectedMedia && Object.keys(selectedMedia).length > 0) {
      setSelectedMediaVideoUrl({ url: selectedMedia.videoURL, loading: false });
    }
  }, [selectedMedia]);

  useEffect(() => {
    if (
      propertyQuestions &&
      propertyQuestions.questions &&
      propertyQuestions.questions.data &&
      propertyQuestions.questions.data.result &&
      propertyQuestions.questions.data.result.length > 0
    ) {
      setMaxSelectedQuestionIndex(propertyQuestions.questions.data.result.length - 1);
    } else if (propertyQuestions && propertyQuestions.questions && propertyQuestions.questions.status === false) {
      if (selectedMediaIndex < maxSelectedMediaIndex) {
        setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
      } else {
        setSelectedProperty({});
        setSelectedMediaIndex(0);
        dispatch({ type: GET_MEDIA_DETAILS_RESET });
      }
      setSelectedMediaVideoUrl({ url: '', loading: true });

      setSelectedQuestion({});
      // setQuestionModal(false);
      dispatch(getAllPropertiesAction(selectedSpace._id));
      dispatch({ type: GET_PROPERTY_QUESTIONS_RESET });
      dispatch(getQuestionProgressAction(selectedMission._id, selectedSpace._id, selectedProperty._id, selectedMedia._id));
    }
    // , selectedMediaIndex, maxSelectedMediaIndex
  }, [propertyQuestions, dispatch, selectedMediaIndex, maxSelectedMediaIndex, selectedProperty]);

  useEffect(() => {
    if (
      propertyQuestions &&
      propertyQuestions.questions &&
      propertyQuestions.questions.data &&
      propertyQuestions.questions.data.result &&
      propertyQuestions.questions.data.result.length > 0
    ) {
      setSelectedQuestion(propertyQuestions.questions.data.result[selectedQuestionIndex]);
    }
  }, [propertyQuestions, selectedQuestionIndex]);

  useEffect(() => {
    if (Object.keys(howToType).length !== 0) {
      setHowToTypeVideo({ url: howToType.videoURL, loading: false });
    }
  }, [howToType]);

  useEffect(() => {
    setTimeout(() => {
      if (howToClicked || howToCompleted) {
        setHowToTop(true);
      }
    }, 1000);
  }, [howToClicked, howToCompleted]);

  useEffect(() => {
    if (addStatistics && addStatistics.stats && addStatistics.stats.status) {
      dispatch({ type: ADD_STATISTICS_RESET });
      setSelectedAnswer('');
      setSelectedMultipleAnswer([]);
      setRedOptions([]);
      setGreenOptions([]);
      setSelectedAnswerError('');
    }
  }, [addStatistics, dispatch]);

  useEffect(() => {
    if (
      !wrongAnswerModal &&
      addStatistics &&
      addStatistics.stats &&
      addStatistics.stats.status &&
      userDetails &&
      userDetails.userInfo &&
      userDetails.userInfo.data &&
      userDetails.userInfo.data.client &&
      userDetails.userInfo.data.client.clientType !== 'lab'
    ) {
      if (selectedQuestionIndex < maxSelectedQuestionIndex) {
        setSelectedQuestionIndex(selectedQuestionIndex + 1);
      } else {
        setSelectedQuestionIndex(0);
        // setQuestionModal(false);

        setSelectedQuestion({});
        setList([]);
        dispatch(getAllPropertiesAction(selectedSpace._id));
        dispatch({ type: GET_PROPERTY_QUESTIONS_RESET });
        dispatch(getQuestionProgressAction(selectedMission._id, selectedSpace._id, selectedProperty._id, selectedMedia._id));
        // if (selectedMediaIndex < maxSelectedMediaIndex) {
        // 	setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
        // } else {
        // 	setSelectedProperty({});
        // 	setSelectedMediaIndex(0);
        // }

        setSelectedMediaVideoUrl({ url: '', loading: true });
        if (selectedMediaIndex < maxSelectedMediaIndex) {
          setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
        } else {
          setSelectedProperty({});
          setSelectedMediaIndex(0);
          dispatch({ type: GET_MEDIA_DETAILS_RESET });
        }
      }
    }

    if (
      addStatistics &&
      addStatistics.stats &&
      addStatistics.stats.status &&
      userDetails &&
      userDetails.userInfo &&
      userDetails.userInfo.data &&
      userDetails.userInfo.data.client &&
      userDetails.userInfo.data.client.clientType === 'lab'
    ) {
      if (selectedQuestionIndex < maxSelectedQuestionIndex) {
        setSelectedQuestionIndex(selectedQuestionIndex + 1);
      } else {
        setSelectedQuestionIndex(0);
        // setQuestionModal(false);

        setSelectedQuestion({});
        setList([]);
        dispatch(getAllPropertiesAction(selectedSpace._id));
        dispatch({ type: GET_PROPERTY_QUESTIONS_RESET });
        dispatch(getQuestionProgressAction(selectedMission._id, selectedSpace._id, selectedProperty._id, selectedMedia._id));
        // if (selectedMediaIndex < maxSelectedMediaIndex) {
        // 	setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
        // } else {
        // 	setSelectedProperty({});
        // 	setSelectedMediaIndex(0);
        // }

        setSelectedMediaVideoUrl({ url: '', loading: true });
        if (selectedMediaIndex < maxSelectedMediaIndex) {
          setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
        } else {
          setSelectedProperty({});
          setSelectedMediaIndex(0);
          dispatch({ type: GET_MEDIA_DETAILS_RESET });
        }
      }
    }
  }, [userDetails, addStatistics, dispatch, selectedQuestionIndex, maxSelectedQuestionIndex, selectedMediaIndex, maxSelectedMediaIndex]);

  useEffect(() => {
    if (
      !wrongAnswerModal &&
      addStatistics &&
      addStatistics.stats &&
      addStatistics.stats.status &&
      userDetails &&
      userDetails.userInfo &&
      userDetails.userInfo.data &&
      userDetails.userInfo.data.client &&
      userDetails.userInfo.data.client.clientType !== 'lab'
    ) {
      // toast.success(addStatistics.stats.points)
      setShowLottieAnimation(addStatistics);

      setResponseVideoModal(true);
    }
  }, [addStatistics, userDetails, currentResponseVideo]);

  useEffect(() => {
    let timers;
    if (showAnimationModel) {
      timers = setTimeout(() => {
        setShowLottieAnimation(null);
        setShowAnimationModel(false);
      }, 3000);
    }

    return () => clearTimeout(timers);
  }, [showAnimationModel]);

  useEffect(() => {
    if (noAnswerModal === true) {
      setTimeout(() => {
        setNoAnswerModal(false);
      }, 5000);
    }
  }, [noAnswerModal]);

  useEffect(() => {
    if (wrongAnswerModal === true) {
      setTimeout(() => {
        setWrongAnswerModal(false);
      }, 5000);
    }
  }, [wrongAnswerModal]);

  const handleHowToClick = data => {
    setHowToClicked(true);
    setHowToType(data);
  };

  const handleEndVideo = async () => {
    setHowToCompleted(false);
    setHowToClicked(false);
    setHowToTop(false);
    setHowToType({});
    const data = await dispatch(addLogAction('generic', howToType._id));

    if (data && data.status) {
      dispatch(getTilesAction());
    }
  };

  const [isMissionNew, setIsMissionNew] = React.useState(false);
  const [isSpaceNew, setIsSpaceNew] = React.useState(false);
  const [isPropertyNew, setIsPropertyNew] = React.useState(false);

  React.useEffect(() => {
    if (selectedMission && selectedMission._id) setIsMissionNew(selectedMission.isNewStatus);
  }, [selectedMission]);

  React.useEffect(() => {
    if (selectedSpace && selectedSpace._id) setIsSpaceNew(selectedSpace.isNewStatus);
  }, [selectedSpace]);

  React.useEffect(() => {
    if (selectedProperty && selectedProperty._id) setIsPropertyNew(selectedProperty.isNewStatus);
  }, [selectedProperty]);

  const handleMediaVideoEnd = () => {
    if (userDetails && userDetails.userInfo && userDetails.userInfo.data && userDetails.userInfo.data?.isTherapistView) {
      if (selectedMediaIndex < maxSelectedMediaIndex) {
        setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
      } else {
        setSelectedProperty({});
        setSelectedMediaIndex(0);
        dispatch({ type: GET_MEDIA_DETAILS_RESET });
      }
    } else {
      setQuestionModal(true);
      // alert(selectedMedia._id + ' // ' + selectedProperty._id)
      dispatch(getPropertyQuestionsAction(selectedMedia._id, selectedProperty._id));
      setIsMissionNew(false);
      setIsSpaceNew(false);
      setIsPropertyNew(false);
    }

    setShowPreAssets(true);

    // setSelectedMission({ ...selectedMission, isNewStatus: false });
    // setSelectedSpace({ ...selectedSpace, isNewStatus: false });
    // setSelectedProperty({ ...selectedProperty, isNewStatus: false });
  };

  const handleAddStatistics = () => {
    const data = {
      mission: selectedMission._id,
      space: selectedSpace._id,
      properties: selectedProperty._id,
      category: selectedQuestion.category ? selectedQuestion.category : '',
      media: selectedMedia._id,
      question: selectedQuestion._id,
      questionType: selectedQuestion.questionType,
    };

    // alert(selectedQuestion.questionType + ' // ' + data)

    if (selectedQuestion.questionType === 'singleOption') {
      if (selectedAnswer === '' || selectedAnswer.trim() === '') {
        setSelectedAnswerError('You did not select any answer. Please select your answer.');
        setNoAnswerModal(true);
      } else {
        data.answer = { answer: selectedAnswer };

        if (selectedQuestion.answer === selectedAnswer) {
          data.isCorrectAnswer = true;
        } else {
          data.isCorrectAnswer = false;
          setWrongAnswerModal(true);
        }

        // alert(' questions  data dispatched single question')

        dispatch(addStatisticsAction(data));
      }
    } else if (selectedQuestion.questionType === 'multipleOption') {
      if (selectedMultipleAnswer.length === 0) {
        setSelectedAnswerError('You did not select any answer. Please select one.');
        setNoAnswerModal(true);
      } else {
        data.answer = { answer: selectedMultipleAnswer };
        if (
          Array.isArray(selectedQuestion.multipleAnswer) &&
          Array.isArray(selectedMultipleAnswer) &&
          selectedQuestion.multipleAnswer.length === selectedMultipleAnswer.length
        ) {
          const result = selectedQuestion.multipleAnswer.filter(correctAnswer => !selectedMultipleAnswer.some(userAnswer => correctAnswer.value === userAnswer));
          if (result.length === 0) {
            data.isCorrectAnswer = true;
            // if (selectedQuestionIndex < maxSelectedQuestionIndex) {
            // 	setSelectedQuestionIndex(selectedQuestionIndex + 1);
            // } else {
            // 	setQuestionModal(false);
            // 	if (selectedMediaIndex < maxSelectedMediaIndex) {
            // 		setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
            // 	} else {
            // 		setSelectedProperty({});
            // 		setSelectedMediaIndex(0);
            // 	}
            // }
          } else {
            data.isCorrectAnswer = false;
            setWrongAnswerModal(true);
          }
        } else {
          data.isCorrectAnswer = false;
          setWrongAnswerModal(true);
        }
        // alert(' questions  data dispatched multioptions')

        dispatch(addStatisticsAction(data));
      }
    } else if (selectedQuestion.questionType === 'rightWrongOption') {
      if (redOptions.length === 0 && greenOptions.length === 0) {
        setSelectedAnswerError('You did not select any answer. Please select your answer.');
        setNoAnswerModal(true);
      } else {
        data.answer = { answer: { red: redOptions, green: greenOptions } };
        if (
          selectedQuestion.rightWrongOptions &&
          Array.isArray(selectedQuestion.rightWrongOptions) &&
          selectedQuestion.rightWrongOptions.length === redOptions.length + greenOptions.length
        ) {
          let validate = false;
          let newGreenOptions = [];
          let newRedOptions = [];
          let greenCount = 0;
          let redCount = 0;

          if (selectedQuestion.rightWrongOptions && Array.isArray(selectedQuestion.rightWrongOptions) && selectedQuestion.rightWrongOptions.length > 0) {
            greenOptions.forEach((item, index) => newGreenOptions.push(item.answerType));

            redOptions.forEach((item, index) => newRedOptions.push(item.answerType));

            newGreenOptions.forEach((item, index) => {
              if (item === 'red') redCount++;
            });

            newRedOptions.forEach((item, index) => {
              if (item === 'green') greenCount++;
            });

            if (redCount === 0 && greenCount === 0) {
              validate = true;
            } else {
              validate = false;
            }

            if (validate) {
              data.isCorrectAnswer = true;
              // if (selectedQuestionIndex < maxSelectedQuestionIndex) {
              // 	setSelectedQuestionIndex(selectedQuestionIndex + 1);
              // } else {
              // 	setQuestionModal(false);
              // 	if (selectedMediaIndex < maxSelectedMediaIndex) {
              // 		setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
              // 	} else {
              // 		setSelectedProperty({});
              // 		setSelectedMediaIndex(0);
              // 	}
              // }
            } else {
              data.isCorrectAnswer = false;
              setWrongAnswerModal(true);
            }
          }
        } else {
          data.isCorrectAnswer = false;
          setWrongAnswerModal(true);
        }

        // alert(' questions  data dispatched right and wrong')

        dispatch(addStatisticsAction(data));
      }
    } else if (selectedQuestion.questionType === 'fillInTheBlanks') {
      if (selectedAnswer === '' || selectedAnswer.trim() === '') {
        setSelectedAnswerError('You did not select any answer. Please select your answer.');
        setNoAnswerModal(true);
      } else {
        data.answer = { answer: selectedAnswer };
        if (selectedQuestion.multipleAnswer && Array.isArray(selectedQuestion.multipleAnswer) && selectedQuestion.multipleAnswer.length > 0) {
          if (JSON.stringify(selectedQuestion?.multipleAnswer[0]?.value) === JSON.stringify(selectedAnswer)) {
            data.isCorrectAnswer = true;
            // if (selectedQuestionIndex < maxSelectedQuestionIndex) {
            // 	setSelectedQuestionIndex(selectedQuestionIndex + 1);
            // } else {
            // 	setQuestionModal(false);
            // 	if (selectedMediaIndex < maxSelectedMediaIndex) {
            // 		setSelectedMediaIndex(Number(selectedMediaIndex) + 1);
            // 	} else {
            // 		setSelectedProperty({});
            // 		setSelectedMediaIndex(0);
            // 	}
            // }
          } else {
            data.isCorrectAnswer = false;
            setWrongAnswerModal(true);
          }
        }
        // alert(' questions  data dispatched fill in the blanks')
        dispatch(addStatisticsAction(data));
      }
    } else if (selectedQuestion.questionType === 'matchPair') {
      data.answer = { answer: list };
      if (
        list &&
        list.length > 0 &&
        selectedQuestion.options[0].PairB &&
        selectedQuestion.options[0].PairB.length > 0 &&
        list.length === selectedQuestion.options[0].PairB.length &&
        list.every((value, index) => value === selectedQuestion.options[0].PairB[index])
      ) {
        // const result = pairB.every((value, index) => value === selectedQuestion.options[0].PairB[index]);
        data.isCorrectAnswer = true;
      } else {
        data.isCorrectAnswer = false;
        setWrongAnswerModal(true);
      }

      // alert(' questions  data dispatched match pair')
      dispatch(addStatisticsAction(data));
    }
  };

  const handleAddGreen = option => {
    setSelectedAnswerError('');
    const greenAnswer = [...greenOptions];
    const redAnswer = [...redOptions];

    if (redAnswer.includes(option)) {
      const red = redAnswer.filter(item => item.value !== option.value);
      setRedOptions(red);
    }

    if (greenAnswer.includes(option)) {
      return;
    }

    greenAnswer.push(option);
    setGreenOptions(greenAnswer);
  };

  const handleAddRed = option => {
    setSelectedAnswerError('');
    const greenAnswer = [...greenOptions];
    const redAnswer = [...redOptions];

    if (greenAnswer.includes(option)) {
      const green = greenAnswer.filter(item => item.value !== option.value);
      setGreenOptions(green);
    }

    if (redAnswer.includes(option)) {
      return;
    }

    redAnswer.push(option);
    setRedOptions(redAnswer);
  };

  const handleAddMultipleAnswer = answer => {
    if (Array.isArray(selectedMultipleAnswer) && !selectedMultipleAnswer.includes(answer)) {
      setSelectedMultipleAnswer([...selectedMultipleAnswer, answer]);
    } else {
      Array.isArray(selectedMultipleAnswer) && setSelectedMultipleAnswer(selectedMultipleAnswer.filter(d => d !== answer));
    }
  };

  const handleReplayVideo = () => {
    if (selectedMedia && selectedMedia?.fileType === 'doc') {
      setQuestionModal(false);
      setSelectedQuestionIndex(0);
      dispatch({ type: GET_QUESTION_PROGRESS_RESET });
      return;
    }

    if (selectedMedia && selectedMedia?.fileType === 'audio') {
      const propVideo = document.getElementById('propertyAudio');
      const playBtnContainer = document.querySelector('.playBtnContainer');
      setQuestionModal(false);
      setSelectedQuestionIndex(0);
      dispatch({ type: GET_QUESTION_PROGRESS_RESET });

      if (propVideo.paused) {
        propVideo.play();
      } else {
        propVideo.pause();
      }
      playBtnContainer.classList.toggle('playBtnContainer_visible');
    } else {
      const propVideo = document.getElementById('propertyVideo');
      const playBtnContainer = document.querySelector('.playBtnContainer');

      setQuestionModal(false);
      setSelectedQuestionIndex(0);
      dispatch({ type: GET_QUESTION_PROGRESS_RESET });

      if (propVideo.paused) {
        propVideo.play();
      } else {
        propVideo.pause();
      }

      playBtnContainer.classList.toggle('playBtnContainer_visible');
    }
  };

  const getSpaceName = (count, string) => {
    return plur(string, count);
    // if (count > 1) {
    //   if (string[string.length - 1] === 's') {
    //     return string
    //   } else {
    //     return string + 's'
    //   }
    // } else {
    //   if (string[string.length - 1] === 's') {
    //     return string.substring(0, string.length - 1)
    //   } else {
    //     return string
    //   }
    // }
  };

  const getTitles = (title, length = 34) => {
    if (title.length > length) {
      const split = title.slice(0, length - 1) + '...';

      return split;
    } else {
      return title;
    }
  };

  return (
    <Content currentMenu='home' noHeader={true}>
      {tiles && tiles.data && tiles.data.data && tiles.data.data.result && tiles.data.data.result.length > 0 && (
        <div className={HomeScreenStyles.howto_container}>
          <div
            // style={{ top: howToTop && '0rem', left: howToTop && '0rem' }}
            style={{
              position: howToTop && 'unset',
              transform: howToTop && 'unset',
            }}
            className={howToClicked || howToCompleted ? HomeScreenStyles.howto_section : HomeScreenStyles.howto_section_center}
          >
            {tiles && tiles.loading ? (
              <HowCardSkeleton items={3} />
            ) : (
              tiles.data.data.result.map(res => (
                <button
                  className={
                    res.color && res.color.title === 'Blue'
                      ? HomeScreenStyles.howto_section_blueCard
                      : res.color && res.color.title === 'Green'
                      ? HomeScreenStyles.howto_section_greenCard
                      : HomeScreenStyles.howto_section_orangeCard
                  }
                  onClick={() => {
                    if (howToType.videoURL !== res.videoURL) {
                      handleHowToClick(res);
                      setHowToTypeVideo({ url: '', loading: true });
                      setHowToCompleted(false);
                    }
                  }}
                  key={res._id}
                >
                  {res.isCompleted && (
                    <svg className={HomeScreenStyles.howto_section_card_tick}>
                      <use xlinkHref={`/assets/sprite.svg#icon-check_circle`} />
                    </svg>
                  )}
                  {res.isNewStatus && <img src='/assets/new_icon.png' alt='New Icon' className={HomeScreenStyles.selectTypes_card_new_icon} style={{ top: '0' }} />}
                  <h3
                    className={
                      res.color && res.color.title === 'Blue'
                        ? HomeScreenStyles.howto_section_blueCard_title
                        : res.color && res.color.title === 'Green'
                        ? HomeScreenStyles.howto_section_greenCard_title
                        : HomeScreenStyles.howto_section_orangeCard_title
                    }
                  >
                    {res.title}
                  </h3>
                  <div
                    className={
                      res.color && res.color.title === 'Blue'
                        ? HomeScreenStyles.howto_section_blueCard_image
                        : res.color && res.color.title === 'Green'
                        ? HomeScreenStyles.howto_section_greenCard_image
                        : HomeScreenStyles.howto_section_orangeCard_image
                    }
                  >
                    <img src={res.imageURL} alt={res.title} />
                    {/* <div />   red & green cirlce removed due to showing in background when adding new images */}
                  </div>
                </button>
              ))
            )}
          </div>
        </div>
      )}

      <div className={HomeScreenStyles.selected_sections}>
        {selectedMission && selectedMission._id && (
          <button
            onClick={() => {
              setSelectedMission({});
              setSelectedSpace({});
              setSelectedProperty({});
              dispatch({ type: GET_SPACES_RESET });
              dispatch({ type: GET_PROPERTIES_RESET });
            }}
            className={[HomeScreenStyles.selectTypes_cardTop, HomeScreenStyles.animated].join(' ')}
          >
            <div className={HomeScreenStyles.selectTypes_card2}>
              <div className={HomeScreenStyles.selectTypes_card_content}>
                {selectedMission && selectedMission.isCompleted && (
                  <svg className={HomeScreenStyles.card_tick_icon}>
                    <use xlinkHref={`/assets/sprite.svg#icon-check_circle`} />
                  </svg>
                )}
                {isMissionNew && <img src='/assets/new_icon.png' alt='New Icon' className={HomeScreenStyles.selectTypes_card_new_icon} style={{ top: '0' }} />}
                <img src={selectedMission.imageURL} alt={selectedMission.title} className={HomeScreenStyles.selectTypes_card_image} />
                <div className={HomeScreenStyles.selectTypes_card_name}>
                  <h4>{getTitles(selectedMission.title, 24)}</h4>
                  <p>
                    {`${selectedMission.spaceCount}  ${
                      userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageTwo !== ''
                        ? getSpaceName(selectedMission.spaceCount, userInfo.client.renameModule.stageTwo)
                        : getSpaceName(selectedMission.spaceCount, 'Spaces')
                    }`}
                  </p>
                </div>
              </div>

              <div className={HomeScreenStyles.selectTypes_card_progress}>
                {/* <p>{selectedMission.progress}</p> */}
                <div className={HomeScreenStyles.selectTypes_card_progress_bar_container}>
                  <div
                    className={HomeScreenStyles.selectTypes_card_progress_bar}
                    style={{
                      width: `${
                        progressData && progressData.data && progressData.data.mission && progressData.data.mission.missionProgress
                          ? progressData.data.mission.missionProgress
                          : selectedMission.missionProgress
                      }%`,
                    }}
                  />
                </div>
                <p>
                  {progressData && progressData.data && progressData.data.mission && progressData.data.mission.missionProgress
                    ? progressData.data.mission.missionProgress
                    : selectedMission.missionProgress}
                  %
                </p>
              </div>
            </div>
          </button>
        )}

        {selectedSpace && selectedSpace._id && (
          <Fragment>
            <img src='/assets/arrow.png' alt='Arrow' className={[HomeScreenStyles.selectTypes_card_arrow, HomeScreenStyles.animated].join(' ')} />
            <button
              onClick={() => {
                setSelectedSpace({});
                setSelectedProperty({});
                dispatch({ type: GET_PROPERTIES_RESET });
                if (selectedMission?._id) {
                  dispatch(getAllSpacesAction(selectedMission._id));
                }
              }}
              className={[HomeScreenStyles.selectTypes_cardTop, HomeScreenStyles.animated].join(' ')}
            >
              <div className={HomeScreenStyles.selectTypes_card2}>
                <div className={HomeScreenStyles.selectTypes_card_content}>
                  {selectedSpace && selectedSpace.isCompleted && (
                    <svg className={HomeScreenStyles.card_tick_icon}>
                      <use xlinkHref={`/assets/sprite.svg#icon-check_circle`} />
                    </svg>
                  )}
                  {isSpaceNew && <img src='/assets/new_icon.png' alt='New Icon' className={HomeScreenStyles.selectTypes_card_new_icon} style={{ top: '0' }} />}
                  <img src={selectedSpace.imageURL} alt={selectedSpace.title} className={HomeScreenStyles.selectTypes_card_image} />
                  <div className={HomeScreenStyles.selectTypes_card_name}>
                    <h4>{getTitles(selectedSpace.title, 24)}</h4>
                    <p>
                      {`${selectedSpace.propertiesCount} ${
                        userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageThree !== ''
                          ? getSpaceName(selectedSpace.propertiesCount, userInfo.client.renameModule.stageThree)
                          : getSpaceName(selectedSpace.propertiesCount, 'Properties')
                      }`}
                    </p>
                  </div>
                </div>

                <div className={HomeScreenStyles.selectTypes_card_progress}>
                  <div className={HomeScreenStyles.selectTypes_card_progress_bar_container}>
                    <div
                      className={HomeScreenStyles.selectTypes_card_progress_bar}
                      style={{
                        width: `${
                          progressData && progressData.data && progressData.data.space && progressData.data.space.spaceProgress
                            ? progressData.data.space.spaceProgress
                            : selectedSpace.spaceProgress
                        }%`,
                      }}
                    />
                  </div>
                  {/* <p>{selectedMission.missionProgress}%</p> */}
                  <p>
                    {progressData && progressData.data && progressData.data.space && progressData.data.space.progressPropertiesCount
                      ? progressData.data.space.progressPropertiesCount + '/' + progressData.data.space.propertiesCount
                      : selectedSpace.progressPropertiesCount + '/' + selectedSpace.propertiesCount}
                  </p>
                </div>
              </div>
            </button>
          </Fragment>
        )}

        {selectedProperty && selectedProperty._id && (
          <Fragment>
            <img src='/assets/arrow.png' alt='Arrow' className={[HomeScreenStyles.selectTypes_card_arrow, HomeScreenStyles.animated].join(' ')} />
            <button
              onClick={() => {
                setSelectedProperty({});
                setSelectedQuestion({});
                setSelectedMedia({});
                dispatch({ type: GET_MEDIA_DETAILS_RESET });
                dispatch({ type: GET_PROPERTY_QUESTIONS_RESET });
              }}
              className={[HomeScreenStyles.selectTypes_cardTop, HomeScreenStyles.animated].join(' ')}
            >
              <div className={HomeScreenStyles.selectTypes_card2}>
                <div className={HomeScreenStyles.selectTypes_card_content}>
                  {selectedProperty && selectedProperty.isCompleted && (
                    <svg className={HomeScreenStyles.card_tick_icon}>
                      <use xlinkHref={`/assets/sprite.svg#icon-check_circle`} />
                    </svg>
                  )}
                  {isPropertyNew && <img src='/assets/new_icon.png' alt='New Icon' className={HomeScreenStyles.selectTypes_card_new_icon} style={{ top: '0' }} />}
                  <img src={selectedProperty.imageURL} alt={selectedProperty.propertyName} className={HomeScreenStyles.selectTypes_card_image} />
                  <div className={HomeScreenStyles.selectTypes_card_name}>
                    <div className={HomeScreenStyles.selectTypes_card_progress_bar_container}>
                      <div
                        className={HomeScreenStyles.selectTypes_card_progress_bar}
                        style={{
                          width: `${
                            progressData && progressData.data && progressData.data.property && progressData.data.property.propertyProgress
                              ? progressData.data.property.propertyProgress
                              : selectedProperty.propertyProgress
                          }%`,
                        }}
                      />
                    </div>
                    <h4>{getTitles(selectedProperty.propertyName, 34)}</h4>
                  </div>
                </div>

                <div className={HomeScreenStyles.selectTypes_card_progress}>
                  <p>
                    {progressData && progressData.data && progressData.data.property && progressData.data.property.progressQuestionCount
                      ? progressData.data.property.progressQuestionCount + '/' + progressData.data.property.questionCount
                      : selectedProperty.progressQuestionCount + '/' + selectedProperty.questionCount}
                    {` `}
                    questions
                  </p>
                </div>
              </div>
            </button>
          </Fragment>
        )}
      </div>

      {Object.keys(howToType).length !== 0 && !howToCompleted && (
        <div className={[HomeScreenStyles.howto_content, HomeScreenStyles.animated].join(' ')}>
          <h1 className={HomeScreenStyles.howto_content_title}>{howToType.title}</h1>
          {!howToTypeVideo.loading && (
            // <video controls onEnded={handleEndVideo}>
            // 	<source src={howToTypeVideo.url} type='video/mp4' />
            // 	Your browser does not support the video tag.
            // </video>
            <VideoPlayer controls={false} onEnded={handleEndVideo} videoSource={howToTypeVideo.url} />
          )}
        </div>
      )}

      {howToCompleted && !(selectedMission && selectedMission._id) && (
        <div className={[HomeScreenStyles.selectTypes, HomeScreenStyles.animated].join(' ')}>
          {missions?.missions?.isStudentPresent || missions?.missions?.status ? (
            <h1 className={HomeScreenStyles.selectTypes_heading}>
              Select your{` `}
              {userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageOne !== ''
                ? userInfo.client.renameModule.stageOne
                : 'Mission'}
            </h1>
          ) : null}
          {/* <h1 className={HomeScreenStyles.selectTypes_heading}>
            Select your{` `}
            {userInfo &&
            userInfo.client &&
            userInfo.client.renameModule &&
            userInfo.client.renameModule.stageOne !== ''
              ? userInfo.client.renameModule.stageOne
              : 'Mission'}
          </h1> */}
          <div className={HomeScreenStyles.selectTypes_cards}>
            {missions && missions.loading ? (
              <MissionCardsSkeleton items={3} />
            ) : missions && missions.missions && missions.missions.data && missions.missions.data.result && missions.missions.data.result.length > 0 ? (
              missions.missions.data.result.map(mission => {
                return (
                  <div className={!mission.isLive && HomeScreenStyles.selectTypes_card_disabledDiv}>
                    {!mission.isLive && <h2 className={HomeScreenStyles.disabledMissionComingSoonText}>Coming Soon</h2>}
                    <button
                      onClick={() => mission.isPreviousMissionCompleted && setSelectedMission(mission)}
                      className={`${!mission.isPreviousMissionCompleted ? HomeScreenStyles.selectTypes_card_disabled : HomeScreenStyles.selectTypes_card} ${
                        !mission.isLive && HomeScreenStyles.blurEffect
                      }`}
                      key={mission._id}
                      disabled={!mission.isLive}
                    >
                      <div className={HomeScreenStyles.selectTypes_card_content}>
                        {mission && mission.isCompleted && (
                          <svg className={HomeScreenStyles.card_tick_icon}>
                            <use xlinkHref={`/assets/sprite.svg#icon-check_circle`} />
                          </svg>
                        )}
                        {mission.isNewStatus && <img src='/assets/new_icon.png' alt='New Icon' className={HomeScreenStyles.selectTypes_card_new_icon} />}
                        <img src={mission.imageURL} alt={mission.title} className={HomeScreenStyles.selectTypes_card_image} />
                        <div className={HomeScreenStyles.selectTypes_card_name}>
                          <h4>{getTitles(mission.title)}</h4>
                          <p>
                            {`${mission.spaceCount} ${
                              userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageTwo !== ''
                                ? getSpaceName(mission.spaceCount, userInfo.client.renameModule.stageTwo)
                                : getSpaceName(mission.spaceCount, 'Spaces')
                            }`}
                          </p>
                        </div>
                      </div>

                      <div className={HomeScreenStyles.selectTypes_card_progress}>
                        <div className={HomeScreenStyles.selectTypes_card_progress_bar_container}>
                          <div className={HomeScreenStyles.selectTypes_card_progress_bar} style={{ width: `${mission.missionProgress}%` }} />
                        </div>
                        <p>{mission.missionProgress}%</p>
                      </div>
                    </button>
                  </div>
                );
              })
            ) : (
              <p
                style={{
                  fontSize: !missions?.missions?.isStudentPresent ? '2.4rem' : '1.4rem',
                  cursor: missions?.missions?.message === 'No students have been added so far, for a better experience, add one.' ? 'pointer' : 'none',
                }}
                onClick={() => {
                  if (!missions?.missions?.isStudentPresent) {
                    history.push(`/student`);
                  }
                }}
              >
                {/* No{` `}
                {userInfo &&
                userInfo.client &&
                userInfo.client.renameModule &&
                userInfo.client.renameModule.stageOne !== ''
                  ? userInfo.client.renameModule.stageOne
                  : 'Missions'} */}
                {missions?.missions?.message}
              </p>
            )}
          </div>
        </div>
      )}

      {howToCompleted && selectedMission && selectedMission._id && !(selectedSpace && selectedSpace._id) && (
        <div className={[HomeScreenStyles.selectTypes2, HomeScreenStyles.animated].join(' ')}>
          <h1 className={HomeScreenStyles.selectTypes_heading}>
            Select your{` `}
            {userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageTwo !== ''
              ? userInfo.client.renameModule.stageTwo
              : 'Spaces'}
          </h1>
          <div className={HomeScreenStyles.selectTypes_cards}>
            {spaces && spaces.loading ? (
              <MissionCardsSkeleton items={4} />
            ) : (
              spaces &&
              spaces.spaces &&
              spaces.spaces.data &&
              spaces.spaces.data.result &&
              spaces.spaces.data.result.length > 0 &&
              spaces.spaces.data.result.map(space => (
                <button
                  onClick={() => space.isPreviousSpaceCompleted && setSelectedSpace(space)}
                  className={!space.isPreviousSpaceCompleted ? HomeScreenStyles.selectTypes_card_disabled : HomeScreenStyles.selectTypes_card}
                  key={space._id}
                >
                  <div className={HomeScreenStyles.selectTypes_card_content}>
                    {space && space.isCompleted && (
                      <svg className={HomeScreenStyles.card_tick_icon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-check_circle`} />
                      </svg>
                    )}
                    {space.isNewStatus && <img src='/assets/new_icon.png' alt='New Icon' className={HomeScreenStyles.selectTypes_card_new_icon} />}
                    <img src={space.imageURL} alt={space.title} className={HomeScreenStyles.selectTypes_card_image} />
                    <div className={HomeScreenStyles.selectTypes_card_name}>
                      <h4>{getTitles(space.title)}</h4>
                      <p>
                        {`${space.propertiesCount} ${
                          userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageThree !== ''
                            ? getSpaceName(space.propertiesCount, userInfo.client.renameModule.stageThree)
                            : getSpaceName(space.propertiesCount, 'Properties')
                        }`}
                      </p>
                    </div>
                  </div>

                  <div className={HomeScreenStyles.selectTypes_card_progress}>
                    <p>{space.progressPropertiesCount + '/' + space.propertiesCount}</p>
                  </div>
                </button>
              ))
            )}
          </div>
        </div>
      )}

      {howToCompleted && selectedMission && selectedMission._id && selectedSpace && selectedSpace._id && !(selectedProperty && selectedProperty._id) && (
        <div className={[HomeScreenStyles.selectTypes2, HomeScreenStyles.animated].join(' ')}>
          <h1 className={HomeScreenStyles.selectTypes_heading}>
            Select your{` `}
            {userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageThree !== ''
              ? userInfo.client.renameModule.stageThree
              : 'Properties'}
          </h1>
          <div className={HomeScreenStyles.selectTypes_cards}>
            {properties && properties.loading ? (
              <MissionCardsSkeleton items={10} />
            ) : (
              properties &&
              properties.properties &&
              properties.properties.data &&
              properties.properties.data.result &&
              properties.properties.data.result.length > 0 &&
              properties.properties.data.result.map(property => (
                <button
                  onClick={() => property.isPreviousPropertyCompleted && setSelectedProperty(property)}
                  className={!property.isPreviousPropertyCompleted ? HomeScreenStyles.selectTypes_card_disabled : HomeScreenStyles.selectTypes_card}
                  key={property._id}
                >
                  <div className={HomeScreenStyles.selectTypes_card_content}>
                    {property && property.isCompleted && (
                      <svg className={HomeScreenStyles.card_tick_icon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-check_circle`} />
                      </svg>
                    )}
                    {property.isNewStatus && <img src='/assets/new_icon.png' alt='New Icon' className={HomeScreenStyles.selectTypes_card_new_icon} />}
                    <img src={property.imageURL} alt={property.propertyName} className={HomeScreenStyles.selectTypes_card_image} />
                    <div className={HomeScreenStyles.selectTypes_card_name}>
                      <h4>{getTitles(property.propertyName)}</h4>
                      {/* <p>{property.properties} Properties</p> */}
                    </div>
                  </div>

                  <div className={HomeScreenStyles.selectTypes_card_progress}>
                    <p>{property.progressQuestionCount + '/' + property.questionCount} questions</p>
                  </div>
                </button>
              ))
            )}
          </div>
        </div>
      )}

      {howToCompleted && selectedMission && selectedMission._id && selectedSpace && selectedSpace._id && selectedProperty && selectedProperty._id && (
        <div className={[HomeScreenStyles.selectTypes2, HomeScreenStyles.animated].join(' ')}>
          {mediaDetails && mediaDetails.loading ? (
            <SelectedPropertyMediaSkeleton />
          ) : Object.keys(selectedMedia ? selectedMedia : {}).length > 0 ? (
            <Fragment>
              {/* <h1 className={HomeScreenStyles.howto_content_title} style={{ marginTop: '0' }}>
								{selectedMedia.title}
							</h1> */}
              {/* <video controls onEnded={handleMediaVideoEnd} id='propertyVideo'>
									<source src={selectedMediaVideoUrl.url} type='video/mp4' />
									<source src={selectedMedia.videoURL} type='video/mp4' />
									Your browser does not support the video tag.
								</video> */}
              {!selectedMediaVideoUrl.loading && (
                <>
                  {selectedMedia.isPreAssetPresent && showPreAssets ? (
                    <>
                      {selectedMedia?.preAssetReference?.fileType === 'doc' && selectedMedia?.preAssetReference?.assetURL !== '' ? (
                        <PrePdfViewer
                          url={selectedMedia?.preAssetReference?.assetURL}
                          onEnded={() => {
                            console.log('pdf ended');
                            setShowPreAssets(false);
                          }}
                        />
                      ) : (
                        <>
                          {selectedMedia?.preAssetReference?.fileType === 'audio' ? (
                            <PreAudioPlayer
                              controls={false}
                              onEnded={() => {
                                console.log('audio ended');
                                setShowPreAssets(false);
                              }}
                              videoSource={selectedMedia?.preAssetReference?.assetURL}
                            />
                          ) : (
                            <>
                              {selectedMedia?.preAssetReference?.fileType === 'image' ? (
                                <PreImageViewer
                                  onEnded={() => {
                                    console.log('iamge ended');
                                    setShowPreAssets(false);
                                  }}
                                  src={selectedMedia?.preAssetReference?.assetURL}
                                />
                              ) : (
                                <PreVideoPlayer
                                  controls={false}
                                  onEnded={() => {
                                    console.log('video ended');
                                    setShowPreAssets(false);
                                  }}
                                  videoSource={selectedMedia?.preAssetReference?.assetURL}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {selectedMedia.fileType === 'doc' && selectedMedia.videoURL !== '' ? (
                        <PdfViewer url={selectedMedia.videoURL} onEnded={handleMediaVideoEnd} />
                      ) : (
                        <>
                          {selectedMedia.fileType === 'audio' ? (
                            <AudioPlayer controls={false} onEnded={handleMediaVideoEnd} videoSource={selectedMedia.videoURL} />
                          ) : (
                            <>
                              {selectedMedia.fileType === 'image' ? (
                                <ImageViewer onEnded={handleMediaVideoEnd} src={selectedMedia.videoURL} />
                              ) : (
                                <VideoPlayer controls={false} onEnded={handleMediaVideoEnd} videoSource={selectedMedia.videoURL} />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Fragment>
          ) : (
            <>
              {mediaDetails && mediaDetails.medias && mediaDetails.medias.message ? (
                <p>
                  {mediaDetails?.medias?.message}
                  {userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageThree !== ''
                    ? userInfo.client.renameModule.stageThree
                    : 'Properties'}
                </p>
              ) : (
                <p>
                  No videos found for
                  {userInfo && userInfo.client && userInfo.client.renameModule && userInfo.client.renameModule.stageThree !== ''
                    ? userInfo.client.renameModule.stageThree
                    : 'this Properties'}
                </p>
              )}
            </>
          )}

          {/* <div>
						<div className={HomeScreenStyles.property_progress}>
							<p className={HomeScreenStyles.property_progress_title}>Property Progress</p>
							<div className={HomeScreenStyles.property_progress_bar}>
								<div />
							</div>
						</div>
						<div className={HomeScreenStyles.property_progress}>
							<p className={HomeScreenStyles.property_progress_title}>Space Progress</p>
							<div className={HomeScreenStyles.property_progress_bar}>
								<div />
							</div>
						</div>
						<div className={HomeScreenStyles.property_progress}>
							<p className={HomeScreenStyles.property_progress_title}>Mission Progress</p>
							<div className={HomeScreenStyles.property_progress_bar}>
								<div />
							</div>
						</div>
					</div> */}
        </div>
      )}

      <Modal mainStyle={{ backgroundColor: '#303c6d' }} show={questionModal}>
        {(propertyQuestions && propertyQuestions.loading) || (addStatistics && addStatistics.loading) ? (
          <PropertyAnswerSkeleton />
        ) : (
          Object.keys(selectedQuestion ? selectedQuestion : {})?.length > 0 && (
            <Fragment>
              <div className='modalAnswerNext_again_btns_top'>
                <button
                  className='modalAnswerNext_again_btn'
                  onClick={() => {
                    setCloseModal(true);
                    // setQuestionModal(false);
                    // setSelectedQuestionIndex(0);
                    // setMaxSelectedQuestionIndex(0);
                    // setSelectedMediaIndex(0);
                    // setMaxSelectedMediaIndex(0);
                    // setSelectedQuestion({});
                    // setSelectedAnswer('');
                    // setSelectedMultipleAnswer([]);
                    // setGreenOptions([]);
                    // setRedOptions([]);

                    // setSelectedProperty({});
                    // setSelectedMedia({});
                    // dispatch({ type: GET_MEDIA_DETAILS_RESET });
                    // dispatch({ type: GET_PROPERTY_QUESTIONS_RESET });
                  }}
                >
                  <p style={{ fontSize: '4rem' }}>close X</p>
                </button>
              </div>
              <p className='modalQuestion'>
                {selectedQuestionIndex + 1} {'. '}
                {selectedQuestion.question.split('*')[0]}
                {'  '}
                {/*                 {selectedQuestion.questionType === 'fillInTheBlanks'
                    ? <span style={{textdecoration: "underline dotted"}}>{selectedAnswer}</span>
                  :'__________'}{' '} */}
                {selectedQuestion.questionType === 'fillInTheBlanks' ? (
                  <span>
                    {selectedAnswer != '' ? <span className='underlinedAnswer'>{selectedAnswer}</span> : '__________'}
                    {'  '}
                  </span>
                ) : (
                  ''
                )}
                {selectedQuestion.question.split('*')[1]}
                {'  '}
              </p>
              <p className='modalQuestion_help'>({selectedQuestion.helpText})</p>
              <div className='modalAnswers'>
                {selectedQuestion.questionType === 'rightWrongOption' && (
                  <div className='modalAnswers_rightWrong'>
                    <div className='modalAnswers_wrong'>
                      {redOptions &&
                        redOptions.length > 0 &&
                        redOptions.map((option, key) => (
                          <p className='modalAnswers_rightWrong_options' key={key}>
                            {option.optionTitle}
                          </p>
                        ))}
                    </div>

                    <div>
                      {selectedQuestion.rightWrongOptions &&
                        selectedQuestion.rightWrongOptions.length > 0 &&
                        selectedQuestion.rightWrongOptions.map((option, key) => (
                          <div className='modalAnswers_flex' key={key}>
                            <button className='modalAnswers_btn' style={{ padding: '0' }} onClick={() => handleAddRed(option)}>
                              <svg className='modalAnswers_icon_red'>
                                <use xlinkHref={`/assets/sprite.svg#icon-${redOptions.includes(option) ? 'x-circle' : 'circle'}`} />
                              </svg>
                            </button>
                            <p className='modalAnswers_flex_text'>{option.optionTitle}</p>
                            <button className='modalAnswers_btn' style={{ padding: '0' }} onClick={() => handleAddGreen(option)}>
                              <svg className='modalAnswers_icon_green'>
                                <use xlinkHref={`/assets/sprite.svg#icon-${greenOptions.includes(option) ? 'check-circle' : 'circle'}`} />
                              </svg>
                            </button>
                          </div>
                        ))}
                    </div>

                    <div className='modalAnswers_right'>
                      {greenOptions &&
                        greenOptions.length > 0 &&
                        greenOptions.map((option, key) => (
                          <p className='modalAnswers_rightWrong_options' key={key}>
                            {option.optionTitle}
                          </p>
                        ))}
                    </div>
                  </div>
                )}

                {selectedQuestion.questionType === 'singleOption' &&
                  selectedQuestion.options &&
                  selectedQuestion.options.length > 0 &&
                  selectedQuestion.options.map((option, key) => (
                    <button
                      key={key}
                      onClick={() => {
                        setSelectedAnswer(option.value);
                        setSelectedAnswerError('');
                      }}
                      className={selectedAnswer === option.value ? 'modalAnswers_btn_active' : 'modalAnswers_btn'}
                    >
                      <div>
                        <svg className='modalAnswers_icon'>
                          <use xlinkHref={`/assets/sprite.svg#icon-${selectedAnswer === option.value ? 'check' : 'check-blank'}`} />
                        </svg>
                      </div>
                      <span className='emojiTextOption'>{option.optionTitle}</span>
                    </button>
                  ))}

                {selectedQuestion.questionType === 'multipleOption' &&
                  selectedQuestion.multipleAnswer &&
                  selectedQuestion.multipleAnswer.length > 0 &&
                  selectedQuestion.options.map((option, key) => (
                    <button
                      key={key}
                      onClick={() => handleAddMultipleAnswer(option.value)}
                      className={Array.isArray(selectedMultipleAnswer) && selectedMultipleAnswer.includes(option.value) ? 'modalAnswers_btn_active' : 'modalAnswers_btn'}
                    >
                      <div>
                        <svg className='modalAnswers_icon'>
                          <use
                            xlinkHref={`/assets/sprite.svg#icon-${
                              Array.isArray(selectedMultipleAnswer) && selectedMultipleAnswer.includes(option.value) ? 'check' : 'check-blank'
                            }`}
                          />
                        </svg>
                      </div>
                      <span className='emojiTextOption'>{option.optionTitle}</span>
                    </button>
                  ))}

                {selectedQuestion.questionType === 'fillInTheBlanks' &&
                  selectedQuestion.options &&
                  selectedQuestion.options.length > 0 &&
                  selectedQuestion.options.map((option, key) => (
                    <button
                      key={key}
                      onClick={() => {
                        setSelectedAnswer(option.value);
                        setSelectedAnswerError('');
                      }}
                      className={selectedAnswer === option.value ? 'modalAnswers_btn_active' : 'modalAnswers_btn'}
                    >
                      <div>
                        <svg className='modalAnswers_icon'>
                          <use xlinkHref={`/assets/sprite.svg#icon-${selectedAnswer === option.value ? 'check' : 'check-blank'}`} />
                        </svg>
                      </div>
                      {option.optionTitle}
                    </button>
                  ))}

                {selectedQuestion.questionType === 'matchPair' && selectedQuestion.options && selectedQuestion.options.length > 0 && selectedQuestion.options[0] && (
                  <div className='modalAnswer_pairs'>
                    <div className='modalAnswer_pair'>
                      <h1 className='modalAnswer_pair_heading'>Pair A</h1>
                      {selectedQuestion.options[0].PairA &&
                        selectedQuestion.options[0].PairA.length > 0 &&
                        selectedQuestion.options[0].PairA.map((option, key) => (
                          <p className='modalAnswer_pair_option' key={key}>
                            {option}
                          </p>
                        ))}
                    </div>
                    <div className='modalAnswer_pair'>
                      <h1
                        className='modalAnswer_pair_heading'
                        // style={{ textAlign: 'end' }}
                      >
                        Pair B
                      </h1>
                      {selectedQuestion.options[0].PairB && selectedQuestion.options[0].PairB.length > 0 && (
                        <MatchPairDraggable2 items={[...selectedQuestion.options[0].PairB]} list={list} setList={setList} />
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className='modalAnswerNext'>
                <button className='modalAnswerNext_next_btn' onClick={handleAddStatistics}>
                  Next <span />
                </button>
                {selectedAnswerError && <p className='modalAnswerError'>{selectedAnswerError}</p>}
                <button className='modalAnswerNext_again_btn' onClick={handleReplayVideo} style={{ marginTop: '2rem', fontSize: '4rem' }}>
                  Watch the media again <span />
                </button>
              </div>
            </Fragment>
          )
        )}

        {userDetails && userDetails.userInfo && userDetails.userInfo.data && userDetails.userInfo.data.client && userDetails.userInfo.data.client.clientType === 'lab'
          ? questionProgress &&
            questionProgress.progress &&
            questionProgress.progress.data && (
              <div className='progress_container'>
                <h4 className='progress_container_heading'>Your Progress</h4>
                <div className='progress_cards'>
                  <div className='progress_card'>
                    <p className='progress_container_count'>Total Answered</p>
                    <h5 className='progress_card_number'>{questionProgress.progress.data.totalAttemptedQuestion}</h5>
                  </div>
                  <div className='progress_card'>
                    <p className='progress_container_count'>Correct Answered</p>
                    <h5 className='progress_card_number'>{questionProgress.progress.data.correctQuestion}</h5>
                  </div>
                  <div className='progress_card'>
                    <p className='progress_container_count'>Wrong Answered</p>
                    <h5 className='progress_card_number'>{questionProgress.progress.data.wrongQuestion}</h5>
                  </div>
                </div>
                <div className='progress_container'>
                  <div className='progress_container_bar'>
                    <div
                      className='progress_container_bar_progress'
                      style={{
                        width: `${questionProgress.progress.data.percentage}%`,
                      }}
                    />
                  </div>
                  <button
                    className='modalAnswerNext_next_btn'
                    style={{ alignSelf: 'center', marginTop: '2rem' }}
                    onClick={() => {
                      setQuestionModal(false);
                      dispatch({ type: GET_QUESTION_PROGRESS_RESET });
                    }}
                  >
                    Close <span />
                  </button>
                </div>
              </div>
            )
          : questionProgress &&
            questionProgress.progress &&
            questionProgress.progress.data &&
            !wrongAnswerModal && (
              <div className='progress_container'>
                <h4 className='progress_container_heading'>
                  Your{` `}
                  {/* {userInfo &&
                  userInfo.client &&
                  userInfo.client.renameModule &&
                  userInfo.client.renameModule.stageTwo !== ''
                  ? userInfo.client.renameModule.stageTwo
                  : 'Space'}
                {` `} */}
                  Progress
                </h4>
                <div className='progress_container_bar'>
                  <div
                    className='progress_container_bar_progress'
                    style={{
                      width: `${questionProgress.progress.data.progressPercentage}%`,
                    }}
                  />
                </div>
                <p className='progress_container_count'>
                  {questionProgress.progress.data.completedQuestionCount + ' out of ' + questionProgress.progress.data.totalQuestionCount + ' questions completed.'}
                </p>
                <button
                  className='modalAnswerNext_next_btn'
                  style={{ alignSelf: 'center', marginTop: '2rem' }}
                  onClick={() => {
                    if (questionProgress?.progress?.data?.isMissionCompleted) {
                      setShowMissionCompletedModel(true);
                      return;
                    }
                    setQuestionModal(false);
                    dispatch({ type: GET_QUESTION_PROGRESS_RESET });
                  }}
                >
                  Close <span />
                </button>
              </div>
            )}
      </Modal>

      {showMissionCompletedModel && (
        <Modal show={showMissionCompletedModel} style={{ width: '98vw' }}>
          {/* <VideoPlayer controls={false} onEnded={handleEndVideo} videoSource={howToTypeVideo.url} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <video
              autoPlay
              onEnded={() => {
                setQuestionModal(false);
                dispatch({ type: GET_QUESTION_PROGRESS_RESET });
                setShowMissionCompletedModel(false);
                setSelectedMission({});
                setSelectedSpace({});
                setSelectedProperty({});
                dispatch({ type: GET_SPACES_RESET });
                dispatch({ type: GET_PROPERTIES_RESET });
                dispatch(getAllMissionsAction());
              }}
              style={{
                height: '92vh',
                borderRadius: '3rem',
              }}
            >
              <source src='/assets/Mission.mp4' type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal>
      )}

      {currentResponseVideo !== '' && (
        <Modal show={responseVideoModal} style={{ width: '98vw' }}>
          {/* <VideoPlayer controls={false} onEnded={handleEndVideo} videoSource={howToTypeVideo.url} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <video
              autoPlay
              onEnded={() => {
                setResponseVideoModal(false);
                setShowAnimationModel(true);
              }}
              style={{
                height: '92vh',
                borderRadius: '3rem',
              }}
            >
              <source src={currentResponseVideo} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal>
      )}

      {showAnimationModel && (
        <Modal show={showAnimationModel} style={{ width: '25vw' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '-10rem',
            }}
          >
            <img src='/images/animation_coin.gif' alt='coin_animation' style={{ height: '30rem', width: '30rem' }} />
            {/* <p className='modal__text_coin'>
              {showLottieAnimation.stats.points}
            </p> */}
          </div>
        </Modal>
      )}

      <Modal show={noAnswerModal} style={{ width: '25vw' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
          }}
        >
          <img src='/assets/thinking.gif' alt='Thinking answer' style={{ height: '25rem' }} />
          <p className='modal__text'>You didn't select any answer. Give this question a try, you can definitely do it.</p>
          <p className='modalQuestion'>PLEASE TRY AGAIN</p>
        </div>
      </Modal>

      {userDetails && userDetails.userInfo && userDetails.userInfo.data && userDetails.userInfo.data.client && userDetails.userInfo.data.client.clientType !== 'lab' && (
        <Modal show={wrongAnswerModal} style={{ width: '25vw' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              lineHeight: '1',
            }}
          >
            <img src='/assets/oops.png' alt='OOPS' style={{ height: '15rem' }} />
            {/* <p className='modal__text'>
                Your answer is incorrect. We are confident enough that you can
                give right answer.
              </p> */}
            <p className='modalQuestion' style={{ marginTop: '2rem' }}>
              PLEASE TRY AGAIN
            </p>
          </div>
        </Modal>
      )}

      <Modal show={closeModal} style={{ width: '25vw' }}>
        <p className='modalQuestion' style={{ textAlign: 'center' }}>
          Are you sure you want to cancel this question?
        </p>
        <p className='modal__text' style={{ textAlign: 'center' }}>
          Your progress is saved, You can come back anytime?
        </p>
        <div className='modalAnswerNext'>
          <div className='modalAnswerNext_again_btns'>
            <button
              className='modalAnswerNext_next_btn'
              onClick={() => {
                setCloseModal(false);
                setQuestionModal(false);
                setSelectedQuestionIndex(0);
                setMaxSelectedQuestionIndex(0);
                setSelectedMediaIndex(0);
                setMaxSelectedMediaIndex(0);
                setSelectedQuestion({});
                setSelectedAnswer('');
                setSelectedMultipleAnswer([]);
                setGreenOptions([]);
                setRedOptions([]);

                dispatch(getAllPropertiesAction(selectedSpace._id));
                setSelectedProperty({});
                setSelectedMedia({});
                dispatch({ type: GET_MEDIA_DETAILS_RESET });
                dispatch({ type: GET_PROPERTY_QUESTIONS_RESET });
                dispatch({ type: GET_QUESTION_PROGRESS_RESET });
              }}
            >
              Yes
              <span />
            </button>
            <button
              className='modalAnswerNext_again_btn'
              onClick={() => {
                setCloseModal(false);
              }}
            >
              No, go back to the question
            </button>
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default HomeScreen;
