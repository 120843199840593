import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { clearUserCountResponse, getUserCount } from '../../store/dashboard/userCount';
import ChartSkeleton from '../Skeletons/ChartSkeleton';
import './charts.css';

var enumerateDaysBetweenDates = function (startDate, endDate) {
	var dates = [];
	for (var m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
		dates.push(m.format('YYYY-MM-DD'));
	}
	return dates;
};

const UserChart = () => {
	const dispatch = useDispatch();
	const { userCountList, loading: mainLoading } = useSelector((list) => list.userCount);
	const { loading } = useSelector((list) => list.missionCount);

	const [fromDate, setFromDate] = useState(
		new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
	);
	const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);


	const onFilterHandler = async () => {
		await dispatch(clearUserCountResponse());
		const dateList = enumerateDaysBetweenDates(fromDate, toDate);
		await dateList.forEach((item) => dispatch(getUserCount(item)));
	};

	useEffect(
		() => {
			onFilterHandler();
		},
		[dispatch, loading]
	);


	const backgroundColor = ['rgb(75, 192, 192)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(255, 99, 132)']
	const options = {
		animation: true,
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
		elements: {
			bar: {
				borderRadius: 10,
			}
		},
	}


	return (
		<div style={{ marginTop: '2rem' }}>
			<div className='date_main_contaner'>
				{mainLoading && !userCountList.length ? (
					<ChartSkeleton />
				) : (
					<Fragment>
						<div className='date_container'>
							<div className='date_input_container'>
								<p>from</p>
								<input
									id='from'
									type='date'
									value={fromDate}
									onChange={(e) => setFromDate(e.target.value)}
									className='date__input'
								/>
							</div>
							<div className='date_input_container'>
								<p>to</p>
								<input
									id='to'
									type='date'
									value={toDate}
									onChange={(e) => setToDate(e.target.value)}
									className='date__input'
								/>
							</div>
							<div className='date_input_container'>
								<div className='date_input_filter_container' onClick={onFilterHandler}>
									<img alt='filter' src='/images/filter.png' className='date_input_filter_img' />
								</div>
							</div>
						</div>
						<div className='chartContainer'>
							{userCountList &&
								Array.isArray(userCountList) &&
								userCountList.length > 0 ?
								<Bar
									data={{
										labels: userCountList && userCountList.map(item => item.label && item.label),
										datasets: [
											{
												data: userCountList && userCountList.map(item => item.count),
												backgroundColor,
												responsive: true,
											}
										]
									}}
									height={55}
									options={options}
								/>
								: <div className='chart_no_data'>
									<h1>No Data</h1>
								</div>
							}
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default UserChart;
