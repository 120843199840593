import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'delete-faq',
    initialState: {
        faqDeleteStatus: null,
        loading: false,
    },
    reducers: {
        requested: (users) => {
            users.loading = true
        },
        success: (users, action) => {
            users.faqDeleteStatus = action.payload;
            users.loading = false
        },
        failed: (users, action) => {
            users.faqDeleteStatus = action.payload;
            users.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const deleteFAQ = (token, item) => apiCallBegan({
    url: '/faq/delete-faq',
    method: 'POST',
    token,
    data: { _id: item._id },
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})