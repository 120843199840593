import React, { useState, useEffect } from 'react'
import './VideoPlayer.css'

const VideoPlayer = ({ controls = false, videoSource, onEnded }) => {
  console.log('source', videoSource)
  const propVideo = document.getElementById('propertyVideo')
  const playBtn = document.querySelector('.play-btn')

  const [totalDuration, setTotalDuration] = React.useState(0)
  const [currentDuration, setCurrentDuration] = React.useState(0)
  const [play, setPlay] = React.useState(false)
  const [src, setSrc] = useState(videoSource)

  const neatTime = (time) => {
    var hours = Math.floor((time % 86400) / 3600)
    var minutes = Math.floor((time % 3600) / 60)
    var seconds = Math.floor(time % 60)
    seconds = seconds > 9 ? seconds : `0${seconds}`
    return `${hours}:${minutes}:${seconds}`
  }

  const togglePlay = () => {
    if (propVideo.paused) {
      propVideo.play()
      setPlay(true)
    } else {
      propVideo.pause()
      setPlay(false)
    }

    playBtn.classList.toggle('paused')
  }

  const togglePlayBtn = () => {
    playBtn.classList.toggle('playing')
  }

  const showPlayBtn = () => {
    const playBtnContainer = document.querySelector('.playBtnContainer')
    playBtnContainer.classList.add('playBtnContainer_visible')
  }
  const removePlayBtn = () => {
    const playBtnContainer = document.querySelector('.playBtnContainer')
    playBtnContainer.classList.remove('playBtnContainer_visible')
  }

  useEffect(() => {
    setSrc(videoSource)
  }, [videoSource])

  console.log('source', src)

  return (
    <div className='videoContainer'>
      {!controls && (
        <div
          className='playBtnContainer'
          onMouseOver={showPlayBtn}
          onMouseEnter={showPlayBtn}
          onMouseLeave={removePlayBtn}
          onClick={togglePlay}>
          <div className='playBtnContainerText'>
            {play ? 'Pause Video' : 'Play Video'}
          </div>
          <div className='play-btn' />
        </div>
      )}

      {src && (
        <video
          controls={controls}
          onEnded={() => {
            onEnded()
            setPlay(false)

            playBtn.classList.remove('paused')

            playBtn.classList.remove('playing')
          }}
          id='propertyVideo'
          onClick={togglePlay}
          onPlay={togglePlayBtn}
          onPause={togglePlayBtn}
          onMouseEnter={showPlayBtn}
          onLoadedData={(e) => setTotalDuration(e.target.duration)}
          // onCanPlay={() => setCanPlay(true)}
          onTimeUpdate={(e) => setCurrentDuration(e.target.currentTime)}
          onMouseLeave={showPlayBtn}>
          <source src={src} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}

      <div className='seekBar'>
        <p>{neatTime(currentDuration)}</p>
        <div
          className='currentPosition'
          style={{
            width: `${(currentDuration / totalDuration) * 100}%`,
          }}
        />
        <p>{neatTime(totalDuration)}</p>
      </div>
    </div>
  )
}

export default VideoPlayer
