import React from 'react';
import classes from './Components.module.css';

const IconButton = ({ icon, type, onClick, disabled }) => {
  return (
    <button type={type} className={classes.iconButton} onClick={onClick} disabled={disabled}>
      <svg className={classes.iconButton_icon}>
        <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
      </svg>
    </button>
  );
};

export default IconButton;
