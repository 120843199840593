import axios from 'axios';
import { GET_SPACES_REQUEST, GET_SPACES_SUCCESS, GET_SPACES_FAIL } from '../constants/spaceConstants';
import { baseUrl } from '../../../middleware/url';

export const getAllSpacesAction = (missionId) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_SPACES_REQUEST });
		const { credentials: { credentials } } = getState();
		const config = {
			headers: {
				token: credentials.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${baseUrl}/space/get-enabled-space-by-mission-id-mobile?missionId=${missionId}`,
			config
		);

		dispatch({
			type: GET_SPACES_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_SPACES_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
