import React, { useEffect, useState } from 'react'
import './clinic-card.css'

import AppSwitch from '../../AppSwitch'

const ClinicCard = ({
  item,
  loading,
  onEditClick,
  onChangeSwitch,
  currentItem,
  onPassChange,
  onModuleNameChange,
  showSwitch = true,
  showPassword = true,
  showModule = true,
  showEdit = true,
  showApproval = false,
  showId = true,
  showConfig = false,
  onApproval = () => {},
  onClickConfig = () => {},
}) => {
  const [copySuccess, setCopySuccess] = useState('')

  const copyToClipboard = (id) => {
    navigator.clipboard.writeText(id)
    setCopySuccess('Copied!')
  }

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess('')
    }, 3000)
  }, [copySuccess])

  const formatPhone = (phnum) => {
    let newph1 = phnum.substr(0, 3)
    let newph2 = phnum.substr(3, 3)
    let newph3 = phnum.substr(6, 4)
    let newph = newph1 + '-' + newph2 + '-' + newph3
    return newph
  }

  return (
    <div className='clinic_card_container'>
      <div className='clinic_card_top_container'>
        <div className='clinic_card_image_container'>
          {item.userProfile !== '' ? (
            <img
              alt='client'
              src={item.userProfile}
              className='clinic_card_image'
            />
          ) : (
            <img
              alt='client'
              src='/images/bx2.png'
              className='clinic_card_image'
            />
          )}
        </div>
        {<div>{}</div>}
        <div className='clinic_card_actions_container'>
          {showSwitch && (
            <AppSwitch
              checked={item.userIsActiveByAdmin}
              onChange={() => onChangeSwitch(item)}
              loading={currentItem === item._id && loading}
            />
          )}
          {showEdit && (
            <img
              src='/images/editPrimary.png'
              className='howto_card_editlogo mt-left'
              onClick={() => onEditClick(item)}
            />
          )}
          {showConfig && (
            <svg className='settings_icon' onClick={() => onClickConfig(item)}>
              <use xlinkHref={`/images/sprite.svg#icon-settings2`} />
            </svg>
          )}
          {/* {showPassword && (
            <img
              src='/images/passkey.png'
              className='howto_card_editlogo mt-left'
              onClick={() => onPassChange(item)}
            />
          )} */}
          {showModule && (
            <img
              src='/images/replace.png'
              className='howto_card_editlogo mt-left'
              onClick={() => onModuleNameChange(item)}
            />
          )}
        </div>
      </div>
      <p className='clinic_username'>{item.userName}</p>
      <div className='clinic_mobile_email_container'>
        <p className='clinic_mobile_email' style={{ marginRight: '.5rem' }}>
          {formatPhone(item.userMobile)}
        </p>
        <p className='clinic_mobile_email'>
          {' | '}
          {item.userEmail}
        </p>
      </div>
      <div className='clinic_userAddress_container'>
        <p className='clinic_userAddress'>{item.userAddress}</p>
        <div className='clinic_card_bottom_container'>
          {showId && (
            <div
              className='clinic_card_ID_container clinic_id'
              onFocus={() => alert('OK')}
              onClick={() => copyToClipboard(item.clientId)}>
              <p className='clinic_card_ID'>
                {copySuccess !== '' ? copySuccess : `ID ${item.clientId}`}
              </p>
            </div>
          )}
          {showApproval && (
            <div
              className='clinic_card_ID_container approve'
              onClick={onApproval}>
              <p className='clinic_card_ID approve'>Approve</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClinicCard
