import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-kid-auditList',
  initialState: {
    auditList: null,
    loading: false,
  },
  reducers: {
    requested: (users) => {
      users.loading = true
    },
    success: (users, action) => {
      users.auditList = action.payload
      users.loading = false
    },
    failed: (users, action) => {
      users.auditList = action.payload
      users.loading = false
    },
    reset: (users, action) => {
      users.auditList = action.payload
      users.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getAuditList = (id, data) =>
  apiCallBegan({
    url: `/user/get-audit-information?user=${id}`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearGetAuditListResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
