import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'delete-coupon',
  initialState: {
    deleteCouponResponse: null,
    loading: false,
  },
  reducers: {
    requested: (Coupon) => {
      Coupon.loading = true
    },
    success: (Coupon, action) => {
      Coupon.deleteCouponResponse = action.payload
      Coupon.loading = false
    },
    failed: (Coupon, action) => {
      Coupon.deleteCouponResponse = action.payload
      Coupon.loading = false
    },
    reset: (Coupon, action) => {
      Coupon.deleteCouponResponse = action.payload
      Coupon.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteCoupon = (id) =>
  apiCallBegan({
    url: `payment/delete-coupon?id=${id}`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearDeleteCouponResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
