import React, { useEffect, useState } from 'react'
import Content from '../../components/Content'
import styles from './MyProfile.module.css'
import { BigHead } from '@bigheads/core'
import { Bar } from 'react-chartjs-2'
import toast from 'react-hot-toast'
import {
  getPretestReport,
  getUserByToken,
  updateUserProfile,
  clearUpdateUserProfile,
} from '../../redux/actions/myProfile'
import { userDetailsByTokenAction } from '../../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import MyProfileSkeleton from '../../components/Skeletons/MyProfileSkeleton'
import { validateEmail } from '../../../utils/validators'
import moment from 'moment'
import {
  getPlayGraphData,
  clearPlayGraphData,
} from '../../../store/performance/getPlayGraphData'
import {
  changeTherapistView,
  clearChangeTherapistViewResponse,
} from '../../../store/therapistView/changeTherapistView'
import {
  verifyUser,
  clearVerifyUserResponse,
} from '../../../store/therapistView/verifyUser'
import InputMask from 'react-input-mask'
import {
  getSubscription,
  clearGetSubscription,
} from '../../../store/settings/getSubscription'
import {
  cancelSubscription,
  clearCancelSubscription,
} from '../../../store/settings/cancelSubscription'
import {
  reactiveSubscription,
  clearReactiveSubscriptionResponse,
} from '../../../store/settings/reactivateSubsription'

const MyProfile = () => {
  const { credentials, userInfo } = useSelector((user) => user.credentials)

  const userDetails = useSelector((state) => state.userDetails)

  const [showModal, setShowModal] = useState(false)
  const [showReactivateModal, setShowReactivateModal] = useState(false)
  const [data, setData] = useState({
    userName: '',
    userEmail: '',
  })
  const [error, setError] = useState({
    userName: '',
    userEmail: '',
  })
  const dispatch = useDispatch()

  const [therapistView, setTherapistView] = useState(
    userDetails &&
      userDetails.userInfo &&
      userDetails.userInfo.data &&
      userDetails.userInfo.data?.isTherapistView
      ? true
      : false
  )

  const [verfiyPassword, setVerfiyPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [disableModal, setDisableModal] = useState(false)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  // const { pretestReport, loading: reportLoading } = useSelector(
  //   (profile) => profile.myProfileReport
  // )

  const { userByTokenInfo, loading } = useSelector(
    (state) => state.userByTokenInfo
  )

  const { updateUserProfileResponse } = useSelector(
    (profile) => profile.updateUserprofile
  )

  // const { playGraphData, loading: playGraphLoading } = useSelector(
  //   (graph) => graph.getPlayGraphData
  // )

  const { changeViewResponse } = useSelector((View) => View.changeTherapistView)

  const { verifyUserResponse } = useSelector((View) => View.verifyUser)

  const { subscriptionDetails, loading: subscriptionLoading } = useSelector(
    (subscription) => subscription.getSubscription
  )
  const { subscriptionDeleteResponse } = useSelector(
    (state) => state.cancelSubscription
  )

  const { subscriptionReactiveResponse } = useSelector(
    (state) => state.reactivateSubsription
  )

  useEffect(() => {
    if (updateUserProfileResponse) {
      if (updateUserProfileResponse.status) {
        setShowModal(false)
        dispatch(getPretestReport())
        dispatch(getUserByToken(data))
        dispatch(clearUpdateUserProfile())
      }
    }
  }, [updateUserProfileResponse])

  useEffect(() => {
    dispatch(getSubscription())
    // dispatch(getPlayGraphData())
  }, [])

  useEffect(() => {
    // dispatch(getPretestReport())
  }, [])

  useEffect(() => {
    if (
      userByTokenInfo &&
      userByTokenInfo.data &&
      (userByTokenInfo.data.userName || userByTokenInfo.data.userEmail)
    ) {
      setData({
        ...data,
        userName: userByTokenInfo.data.userName,
        userEmail: userByTokenInfo.data.userEmail,
      })
      setError({
        ...error,
        userName: '',
        userEmail: '',
      })
    }
  }, [userByTokenInfo])

  useEffect(() => {
    dispatch(getUserByToken(data))
  }, [])

  const modalHandler = () => {
    setShowModal(true)
  }

  const inputChangehandler = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
    setError({
      ...error,
      [event.target.name]: '',
    })
  }

  const submitHandler = () => {
    let errors = {}
    let validate = true
    if (data.userName === '') {
      errors = {
        ...errors,
        userName: 'Please enter  username',
      }
      validate = false
    } else if (data.userName.length < 2) {
      errors = {
        ...errors,
        userName: 'Username must be at least 2 characters',
      }
      validate = false
    }
    if (data.userEmail === '') {
      errors = {
        ...errors,
        userEmail: 'Please enter email',
      }
      validate = false
    } else if (!validateEmail(data.userEmail)) {
      errors = {
        ...errors,
        userEmail: 'Please enter valid email',
      }
      validate = false
    }
    setError(errors)

    if (validate) {
      dispatch(updateUserProfile(data))
    }
  }

  const onChangeTherapistView = () => {
    if (therapistView) {
      setDisableModal(true)
    } else {
      setVerfiyPassword(true)
    }
  }

  const confirmPasswordHandler = () => {
    if (password === '') {
      setPasswordError('Please enter  password')
      return
    }

    dispatch(verifyUser(password))
  }

  const disableTherapistViewHandler = () => {
    dispatch(changeTherapistView(false))
  }

  useEffect(() => {
    if (verifyUserResponse) {
      if (verifyUserResponse.status) {
        toast.success(verifyUserResponse.message)
        setTherapistView(false)
        dispatch(changeTherapistView(true))
        dispatch(userDetailsByTokenAction())
        dispatch(clearVerifyUserResponse())
        setPassword('')
        setPasswordError('')
        setTherapistView(true)
        setVerfiyPassword(false)
      } else {
        toast.error(verifyUserResponse.message)
        dispatch(clearVerifyUserResponse())
      }
    }
  }, [verifyUserResponse])

  useEffect(() => {
    if (changeViewResponse) {
      if (disableModal) {
        if (changeViewResponse.status) {
          toast.success(changeViewResponse.message)
          dispatch(clearChangeTherapistViewResponse())
          dispatch(userDetailsByTokenAction())
          setTherapistView(false)
          setDisableModal(false)
        } else {
          toast.error(changeViewResponse.message)
          dispatch(clearChangeTherapistViewResponse())
        }
      }
    }
  }, [changeViewResponse])

  useEffect(() => {
    if (subscriptionDeleteResponse) {
      if (subscriptionDeleteResponse?.status) {
        toast.success(subscriptionDeleteResponse.message)
        dispatch(getSubscription())
        dispatch(clearCancelSubscription())
        setCurrentItem(null)
        setShowDeleteModal(false)
      } else {
        toast.error(subscriptionDeleteResponse.message)
        dispatch(clearCancelSubscription())
      }
    }
  }, [subscriptionDeleteResponse])

  useEffect(() => {
    if (subscriptionReactiveResponse) {
      if (subscriptionReactiveResponse?.status) {
        toast.success(subscriptionReactiveResponse.message)
        dispatch(getSubscription())
        dispatch(clearReactiveSubscriptionResponse())
        setCurrentItem(null)
        setShowReactivateModal(false)
      } else {
        toast.error(subscriptionReactiveResponse.message)
        dispatch(clearReactiveSubscriptionResponse())
      }
    }
  }, [subscriptionReactiveResponse])

  const cancelSubscriptionHandler = (details) => {
    setShowDeleteModal(true)
    setCurrentItem(details)
  }

  const cancelHandler = () => {
    if (currentItem) {
      const unixTime = currentItem?.current_period_end
      const date = new Date(unixTime * 1000)
      const data = {
        subscriptionID: currentItem?.subscriptionID,
        endDate: date.toLocaleDateString('en-US'),
      }
      const d = new Date()
      let offset = d.getTimezoneOffset()
      dispatch(cancelSubscription(data, offset))
    }
  }

  const subscriptionHandler = (details) => {
    setShowReactivateModal(true)
    setCurrentItem(details)
  }

  const renewSubscriptionHandler = () => {
    if (currentItem) {
      const data = {
        subscriptionID: currentItem?.subscriptionID,
      }

      const d = new Date()
      let offset = d.getTimezoneOffset()

      dispatch(reactiveSubscription(data, offset))
    }
  }

  return (
    <Content
      currentMenu='my-profile'
      headerTitle='My Profile'
      therapistView={
        userInfo && userInfo.client && userInfo.client.clientType === 'clinic'
          ? true
          : false
      }
      onChangeTherapistView={onChangeTherapistView}
      therapistViewValue={therapistView}>
      {loading ? (
        <MyProfileSkeleton />
      ) : (
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <div className={styles.subContainer}>
              <div className={styles.detailsContainer}>
                <div className={styles.title}>Personal Information</div>
                {userByTokenInfo && userByTokenInfo.data && (
                  <div className={styles.name}>
                    {userByTokenInfo.data.userName}
                  </div>
                )}
                <div className={styles.phone}>
                  <svg className={styles.icon}>
                    <use xlinkHref={`/images/sprite.svg#icon-phone3`} />
                  </svg>
                  {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.userMobile !== '' ? (
                    <div className={styles.text}>
                      {userByTokenInfo.data.userMobile}
                    </div>
                  ) : (
                    <div className={styles.text}>N/A</div>
                  )}
                </div>

                <div className={styles.phone}>
                  <svg className={styles.icon}>
                    <use xlinkHref={`/images/sprite.svg#icon-at`} />
                  </svg>
                  {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.userEmail !== '' ? (
                    <div className={styles.text}>
                      {userByTokenInfo.data.userEmail}
                    </div>
                  ) : (
                    <div className={styles.text}>N/A</div>
                  )}
                </div>

                <div className={styles.phone}>
                  <svg className={styles.icon}>
                    <use xlinkHref={`/images/sprite.svg#icon-location3`} />
                  </svg>

                  {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.userAddress !== '' ? (
                    <div className={styles.text}>
                      {userByTokenInfo.data.userAddress}
                    </div>
                  ) : (
                    <div className={styles.text}>N/A</div>
                  )}
                </div>

                <div className={styles.buttonContainer}>
                  <div className={styles.button} onClick={modalHandler}>
                    <div className={styles.text}>Edit Profile</div>
                    <div className={styles.line}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.subContainer}>
              <div className={styles.detailsContainer}>
                <div className={styles.title}>
                  YOUR{' '}
                  {`${
                    userInfo?.client?.clientType
                      ? userInfo?.client?.clientType
                      : ''
                  }`}{' '}
                  INFORMATION
                </div>
                {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.client &&
                  userByTokenInfo.data.client.userProfile && (
                    <div className={styles.imgContainer}>
                      <img
                        alt='clientLogo'
                        src={userByTokenInfo.data.client.userProfile}
                        className={styles.img}
                      />
                    </div>
                  )}
                {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.client && (
                    <div className={styles.name}>
                      {userByTokenInfo.data.client.userName}
                    </div>
                  )}

                {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.client && (
                    <div className={styles.phone}>
                      <svg className={styles.icon}>
                        <use xlinkHref={`/images/sprite.svg#icon-phone3`} />
                      </svg>
                      <div className={styles.text}>
                        {userByTokenInfo.data.client.userMobile}
                      </div>
                    </div>
                  )}
                {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.client && (
                    <div className={styles.phone}>
                      <svg className={styles.icon}>
                        <use xlinkHref={`/images/sprite.svg#icon-at`} />
                      </svg>
                      <div className={styles.text}>
                        {userByTokenInfo.data.client.userEmail}
                      </div>
                    </div>
                  )}
                {userByTokenInfo &&
                  userByTokenInfo.data &&
                  userByTokenInfo.data.client && (
                    <div className={styles.phone}>
                      <svg className={styles.icon}>
                        <use xlinkHref={`/images/sprite.svg#icon-location3`} />
                      </svg>
                      <div className={styles.text}>
                        {userByTokenInfo.data.client.userAddress}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.title} style={{ marginTop: '1rem' }}>
              Subscription Information
            </div>
            <>
              {subscriptionDetails &&
              subscriptionDetails.status &&
              subscriptionDetails?.data?.status !== 'canceled' ? (
                <div className='profile__password'>
                  <div className={styles.text2}>
                    Package Name :{' '}
                    <span className={styles.value}>
                      {subscriptionDetails?.data?.product?.name}
                    </span>{' '}
                  </div>
                  <div className={styles.text2}>
                    Plan :{' '}
                    <span className={styles.value}>
                      {`$${
                        Number(subscriptionDetails?.data?.plan?.amount) / 100
                      } / ${subscriptionDetails?.data?.plan?.interval}`}
                    </span>
                  </div>
                  <div className={styles.text2}>
                    Purchased Date:{' '}
                    <span className={styles.value}>{`${moment
                      .unix(subscriptionDetails?.data?.current_period_start)
                      .format('dddd, MMMM Do, YYYY')}`}</span>
                  </div>
                  <div className={styles.text2}>
                    Next Billing Date:{' '}
                    <span className={styles.value}>{`${moment
                      .unix(subscriptionDetails?.data?.current_period_end)
                      .format('dddd, MMMM Do, YYYY')}`}</span>{' '}
                  </div>
                  <div className='subscription_btn_container'>
                    {/* <a
                      href={subscriptionDetails?.data?.invoice_pdf_url}
                      download
                      className='send_notification_btn3'
                      style={{ textDecoration: 'none' }}>
                      Download Invoice
                      <img
                        src='/images/download_icon.png'
                        alt='notification icon'
                        className='send_notification_btn_icon'
                        style={{ marginLeft: '0.5rem' }}
                      />
                    </a> */}

                    {/* <button
                      className='send_notification_btn2'
                      onClick={cancelSubscriptionHandler.bind(
                        this,
                        subscriptionDetails?.data
                      )}>
                      Cancel
                    </button> */}
                    {subscriptionDetails?.data?.status === 'active' &&
                    subscriptionDetails?.data?.currentSubscription
                      ?.cancel_at_period_end === true ? (
                      <button
                        className='send_notification_btn3'
                        onClick={subscriptionHandler.bind(
                          this,
                          subscriptionDetails?.data
                        )}>
                        Reactivate
                      </button>
                    ) : (
                      <>
                        {subscriptionDetails?.data?.currentSubscription
                          ?.cancel_at_period_end === false &&
                        (subscriptionDetails?.data?.status === 'active' ||
                          subscriptionDetails?.data?.status === 'trialing') ? (
                          <button
                            className='send_notification_btn3'
                            onClick={cancelSubscriptionHandler.bind(
                              this,
                              subscriptionDetails?.data
                            )}>
                            Cancel
                          </button>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {subscriptionDetails?.data?.status === 'canceled' ? (
                    <div className='profile__password'>
                      <h2 className='profile__heading'>
                        Subscription Information
                      </h2>
                      <h2
                        className='profile__subscription_text'
                        style={{ color: 'red' }}>
                        You do not have any subscriptions
                      </h2>
                    </div>
                  ) : (
                    <div className='profile__password'>
                      <h2 className='profile__heading'>
                        Subscription Information
                      </h2>
                      <h2
                        className='profile__subscription_text'
                        style={{ color: 'red' }}>
                        {subscriptionDetails?.message}
                      </h2>
                    </div>
                  )}
                </>
              )}
            </>

            <Modal show={showDeleteModal} style={{ width: '25vw' }}>
              <p className='modalQuestion' style={{ textAlign: 'center' }}>
                Are you sure you want to cancel the subscription?
              </p>

              <div className='logout_btn_container'>
                <button
                  className='modalAnswerNext_next_btn2'
                  onClick={() => {
                    setCurrentItem(null)
                    setShowDeleteModal(false)
                  }}>
                  Cancel <span />
                </button>
                <button
                  className='modalAnswerNext_next_btn3'
                  onClick={cancelHandler}>
                  Confirm <span />
                </button>
              </div>
            </Modal>
          </div>
        </div>
      )}

      <Modal show={showModal}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>Edit Profile</div>
          <div className={styles.modalInputContainer}>
            <div>
              <div className={styles.input}>
                <svg className={styles.inputIcon}>
                  <use xlinkHref={`/images/sprite.svg#icon-messagePhone`} />
                </svg>
                <input
                  name='userName'
                  type='text'
                  value={data.userName}
                  className={styles.inputText}
                  placeholder='John Doe'
                  onChange={inputChangehandler}
                />
              </div>
              {error.userName !== '' ? (
                <p className={styles.errorMessage}>{error.userName}</p>
              ) : null}
            </div>
            <div>
              <div className={styles.input}>
                <svg className={styles.inputIcon}>
                  <use xlinkHref={`/images/sprite.svg#icon-messagePhone`} />
                </svg>
                <input
                  name='userEmail'
                  type='text'
                  value={data.userEmail}
                  className={styles.inputText}
                  placeholder='info@example.com'
                  onChange={inputChangehandler}
                />
              </div>
              {error.userEmail !== '' ? (
                <p className={styles.errorMessage}>{error.userEmail}</p>
              ) : null}
            </div>
          </div>
          <div className={styles.modalButtonContainer}>
            <div>
              <button
                className='modalAnswerNext_next_btn'
                onClick={submitHandler}>
                Save
                <span />
              </button>
            </div>

            <div>
              <button
                className='modalAnswerNext_next_btn2'
                onClick={() => setShowModal(false)}>
                Close <span />
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={verfiyPassword}>
        <p className='modalQuestion' style={{ textAlign: 'center' }}>
          Please enter password to enable therapist view
        </p>
        <div className={styles.verfiyPasswordContainer}>
          <div className={styles.input}>
            <svg className={styles.inputIcon}>
              <use xlinkHref={`/images/sprite.svg#icon-messagePhone`} />
            </svg>
            <InputMask
              name='Password'
              className={styles.inputText}
              mask='999-999'
              maskChar=' '
              placeholder='Password'
              value={password}
              onChange={(event) => {
                setPasswordError('')
                setPassword(event.target.value)
              }}
            />
          </div>
          {passwordError !== '' ? (
            <p className={styles.errorMessage} style={{ marginTop: '1rem' }}>
              {passwordError}
            </p>
          ) : null}
        </div>

        <div className='logout_btn_container'>
          <button
            className='modalAnswerNext_next_btn2'
            onClick={() => {
              setPassword('')
              setPasswordError('')
              setTherapistView(false)
              setVerfiyPassword(false)
            }}>
            Cancel <span />
          </button>
          <button
            className='modalAnswerNext_next_btn3'
            onClick={confirmPasswordHandler}>
            Confirm <span />
          </button>
        </div>
      </Modal>
      <Modal show={disableModal}>
        <p className='modalQuestion' style={{ textAlign: 'center' }}>
          Are you sure you want to disable therapist View ?
        </p>

        <div className='logout_btn_container'>
          <button
            className='modalAnswerNext_next_btn2'
            onClick={() => {
              setDisableModal(false)
            }}>
            Cancel <span />
          </button>
          <button
            className='modalAnswerNext_next_btn3'
            onClick={disableTherapistViewHandler}>
            Confirm <span />
          </button>
        </div>
      </Modal>
      <Modal show={showReactivateModal}>
        <p className='modalQuestion' style={{ textAlign: 'center' }}>
          Are you sure you want to reactivate the subscription?
        </p>

        <div className='logout_btn_container'>
          <button
            className='modalAnswerNext_next_btn2'
            onClick={() => {
              setCurrentItem(null)
              setShowReactivateModal(false)
            }}>
            Cancel <span />
          </button>
          <button
            className='modalAnswerNext_next_btn3'
            onClick={renewSubscriptionHandler}>
            Confirm <span />
          </button>
        </div>
      </Modal>
    </Content>
  )
}

export default MyProfile
