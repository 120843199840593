import React, { useEffect, useRef } from 'react'
import styles from './modal.module.css'

const Modal = ({ children, show, containerStyles }) => {
  const modalRef = useRef(null)

  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible)
    } else {
      modalRef.current.classList.remove(styles.visible)
    }
  }, [show])

  return (
    <React.Fragment>
      <div ref={modalRef} className={`${styles.modal}`}>
        <div className={styles.modal__wrap} style={containerStyles}>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Modal
