import React from 'react'
import styles from './select.module.css'

const Select = ({
  src,
  data = [],
  placeholder = '',
  onChange,
  value = 'placeholder',
}) => {
  return (
    <div className={styles.selectContainer}>
      <div className={styles.imageContainer}>
        <img src={src} alt='select' className={styles.image} />
      </div>
      <select
        value={value === '' || value === null ? 'placeholder' : value}
        defaultValue='placeholder'
        className={styles.select}
        onChange={onChange}>
        <option value='placeholder' disabled>
          {placeholder}
        </option>
        {data.map((item) => {
          return (
            <option key={item.id} value={item.value}>
              {item.title}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Select
