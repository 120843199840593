import React from 'react';
import SkeletonStyles from './Skeletons.module.css';

const InputBoxSkeleton = ({ style }) => {
	return (
		<div
			className={[ SkeletonStyles.login_container_loginBox_inputBox_skeleton, SkeletonStyles.skeleton2 ].join(' ')}
			style={style}
		/>
	);
};

export default InputBoxSkeleton;
