import React, { useEffect, useState } from 'react'
import AppHeader from '../../../../components/AppHeader'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPromo,
  clearGetPromoResponse,
} from '../../../../store/promocode/getPromocode'
import {
  getCoupon,
  clearGetCouponResponse,
} from '../../../../store/Coupon/getCoupons'
import AppSwitchSmall from '../../../../components/AppSwitchSmall'
import AppButton from '../../../../components/forms/AppButton'
import AppModal2 from '../../../../components/AppModal2'
import AppLoader from '../../../../components/AppLoader'
import TextInput from '../../../../components/forms/TextInput'
import styles from './Promocode.module.css'
import {
  addPromo,
  clearAddPromoResponse,
} from '../../../../store/promocode/addPromocode'
import { toast } from 'react-toastify'
import {
  enableDisablePromo,
  clearEnableDisablePromoResponse,
} from '../../../../store/promocode/enablePromocode'

const Promocode = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [code, setCode] = useState('')
  const [selectedCoupons, setSelectedCoupons] = useState('')
  const [max, setMax] = useState(1)
  const [promotype, setPromotype] = useState('limited')
  const [error, setError] = useState({
    code: '',
    selectedCoupons: '',
    max: '',
  })
  const [startId, setStartId] = useState(null)
  const [endId, setEndId] = useState(null)
  const [initialPage, setInitialPage] = useState(true)
  const [lastPage, setLastPage] = useState(false)
  const [isNextPressed, setIsNextPressed] = useState(false)
  const [isPrevPressed, setIsPrevPressed] = useState(false)

  const pageSize = 11
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { promoList, loading } = useSelector((state) => state.getPromocode)
  const { couponList } = useSelector((coupon) => coupon.getCoupon)
  const { promoAddResponse } = useSelector((state) => state.addPromocode)
  const { enablePromocodeResponse } = useSelector(
    (state) => state.enablePromocode
  )

  const nextStyle =
    promoList?.data?.length < pageSize ? styles.true : styles.false

  const previousStyle = initialPage ? styles.true : styles.false

  useEffect(() => {
    if (promoList && promoList.data && Array.isArray(promoList.data)) {
      setStartId(promoList?.data[pageSize - 1]?.id)
      setEndId(promoList?.data[0]?.id)
    }
  }, [promoList])

  useEffect(() => {
    if (promoList && promoList.data && Array.isArray(promoList.data)) {
      if (!promoList.has_more && isNextPressed) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }

      if (!promoList.has_more && isPrevPressed && !lastPage) {
        setInitialPage(true)
      }
    }
  }, [isPrevPressed, promoList, isNextPressed, loading])

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value)
  }

  useEffect(() => {
    dispatch(getPromo(pageSize))
    dispatch(getCoupon())
    return () => {
      dispatch(clearGetCouponResponse())
      dispatch(clearGetPromoResponse())
    }
  }, [])

  const onCloseModal = () => {
    setShowModal(false)
    setCode('')
    setSelectedCoupons('')
    setError({
      code: '',
      selectedCoupons: '',
      max: '',
    })
    setMax(1)
  }

  useEffect(() => {
    let list = []
    if (couponList && couponList.data && Array.isArray(couponList.data)) {
      couponList.data.forEach((item, index) => {
        list.push({ name: item.name, value: item.id })
      })
    }

    setCoupons(list)
  }, [couponList])

  const onSubmitHandler = () => {
    let errors
    let validated = true
    if (code === '' && code.trim() === '') {
      errors = {
        ...errors,
        code: 'Please enter a code',
      }
      validated = false
    }
    if (selectedCoupons === '') {
      errors = {
        ...errors,
        selectedCoupons: 'Please select a coupon',
      }
      validated = false
    }
    if (promotype === 'limited' && max <= 0) {
      errors = {
        ...errors,
        max: 'redeemptions value must be greater than 0',
      }
      validated = false
    } else if (promotype === 'limited' && !isNumeric(max)) {
      errors = {
        ...errors,
        max: 'Please enter numeric value',
      }
      validated = false
    }

    setError({
      ...error,
      ...errors,
    })

    let data

    if (validated) {
      data = {
        code: code,
        coupon: selectedCoupons,
      }

      if (promotype === 'limited') {
        data = {
          ...data,
          max_redemptions: Number(max),
        }
      }

      dispatch(addPromo(data))
    }
  }

  const onAddPromocode = () => {
    setShowModal(true)
  }

  useEffect(() => {
    if (promoAddResponse) {
      if (promoAddResponse.status) {
        toast.success(promoAddResponse.message)
        dispatch(clearAddPromoResponse())
        dispatch(getPromo(pageSize))
        dispatch(getCoupon())
        onCloseModal()
        setInitialPage(true)
      } else {
        toast.error(promoAddResponse.message.code)
        dispatch(clearAddPromoResponse())
      }
    }
  }, [promoAddResponse])

  const onPageChange = (type) => {
    if (type === 'next') {
      setInitialPage(false)
      setIsNextPressed(true)
      setIsPrevPressed(false)
      dispatch(getPromo(pageSize, startId))
    } else {
      setIsNextPressed(false)
      setIsPrevPressed(true)
      dispatch(getPromo(pageSize, null, endId))
    }
  }

  useEffect(() => {
    if (enablePromocodeResponse) {
      if (enablePromocodeResponse.status) {
        toast.success(enablePromocodeResponse.message)
        dispatch(getPromo(pageSize))
        dispatch(getCoupon())
        dispatch(clearEnableDisablePromoResponse())
        setInitialPage(true)
      } else {
        toast.error(enablePromocodeResponse.message)
        dispatch(clearEnableDisablePromoResponse())
      }
    }
  }, [enablePromocodeResponse])

  const enablePromocodeHandler = (item) => {
    if (item) {
      const data = {
        enable: !item.active,
        id: item.id,
      }
      dispatch(enableDisablePromo(data))
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Promo codes' isAdd={true} onAdd={onAddPromocode} />
      <div className='client__container'>
        <div className='missions_main_content'>
          {promoList &&
            promoList.data &&
            Array.isArray(promoList.data) &&
            promoList.data.map((item, i) => {
              return (
                <div className='client_card_container' key={i}>
                  <div className='coupon_card_container'>
                    <div className='coupon_card_text'>{item?.code}</div>
                    <div className='coupon_card_text2'>
                      {item?.coupon?.percent_off}%
                    </div>
                  </div>
                  <div className='coupon_card_duration'>
                    Duration:{` `}
                    {item?.coupon?.duration === 'repeating' &&
                      item?.coupon?.duration_in_months && (
                        <span>{`${item?.coupon?.duration_in_months} Month`}</span>
                      )}
                    {item?.coupon?.duration === 'forever' && (
                      <span>{`${item?.coupon?.duration}`}</span>
                    )}
                    {item?.coupon?.duration === 'once' && (
                      <span>{`${item?.coupon?.duration}`}</span>
                    )}
                  </div>
                  {/* {item?.coupon?.duration === 'repeating' && (
                    <div className='coupon_card_duration'>
                      <span>{`Limit: ${item?.max_redemptions}`}</span>
                    </div>
                  )} */}

                  <div className='coupon_card_active'>
                    {item?.max_redemptions !== null ? (
                      <div className='coupon_card_duration'>
                        <span>{`Limit: ${item?.max_redemptions}`}</span>
                      </div>
                    ) : (
                      <div className='coupon_card_duration'>
                        <span>{`Limit: ${item?.coupon?.duration}`}</span>
                      </div>
                    )}
                    <AppSwitchSmall
                      checked={item?.active ? item?.active : false}
                      onChange={enablePromocodeHandler.bind(this, item)}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <AppLoader loader={loading} />
      <AppModal2 show={showModal}>
        <div
          className={styles.modalContainer}
          style={{ height: '44rem', width: '40rem' }}>
          <div className={styles.modalSection}>
            <p className='form_header_text'>Add Promocode</p>
            <TextInput
              placeholder='Code'
              src='/images/clinic.png'
              onChange={(event) => {
                setError({
                  ...error,
                  code: '',
                })
                setCode(event.target.value)
              }}
              value={code}
              error={error.code}
            />
            {coupons && coupons.length > 0 && (
              <>
                <div className='mission_dropdown'>
                  <div className='text_input_image_container'>
                    <img
                      src='/images/spaces.png'
                      alt='color'
                      className='text_input_image'
                    />
                  </div>
                  <select
                    name='space-type'
                    id='space-type'
                    value={selectedCoupons}
                    onChange={(e) => {
                      setError({
                        ...error,
                        selectedCoupons: '',
                      })
                      setSelectedCoupons(e.target.value)
                    }}
                    className='mission_dropdown_select dropdown_smallfont '>
                    <option value=''>Select Coupon</option>
                    {coupons &&
                      coupons.length > 0 &&
                      coupons.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>
                            {item.name}
                          </option>
                        )
                      })}
                  </select>
                </div>
                {error.selectedCoupons !== '' ? (
                  <p className='input_error_text'>{error.selectedCoupons}</p>
                ) : null}
              </>
            )}
            <div className={styles.promoContainer}>
              <label className={styles.label}>Select promo type</label>
              <div className={styles.chooseContainer}>
                <label className={styles.radioContainer}>
                  Limited
                  <input
                    type='radio'
                    name='promotype'
                    value='limited'
                    checked={promotype === 'limited'}
                    onChange={(e) => {
                      setPromotype(e.target.value)
                    }}
                  />
                  <span className={styles.checkmark}></span>
                </label>
                <label className={styles.radioContainer}>
                  Forever
                  <input
                    type='radio'
                    name='promotype'
                    value='forever'
                    onChange={(e) => {
                      setPromotype(e.target.value)
                    }}
                  />
                  <span className={styles.checkmark}></span>
                </label>
              </div>
            </div>
            {promotype === 'limited' && (
              <TextInput
                placeholder='Max redeemptions'
                src='/images/clinic.png'
                onChange={(event) => {
                  setError({
                    ...error,
                    max: '',
                  })
                  setMax(event.target.value)
                }}
                value={max}
                error={error.max}
              />
            )}
          </div>

          <div className='modal_buttons'>
            <AppButton
              title='Add'
              type='submit'
              icon={
                <img
                  src='/images/add-clinic.png'
                  className='app_button_image'
                />
              }
              onClick={onSubmitHandler}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={onCloseModal}
            />
          </div>
        </div>
      </AppModal2>

      {promoList && promoList.data && promoList.data.length > 0 && (
        <div className={styles.paginationContainer}>
          <div
            className={[styles.paginationBtn, previousStyle].join(' ')}
            onClick={onPageChange.bind(this, 'previous')}>
            Previous
          </div>
          <div
            className={[styles.paginationBtn, nextStyle].join(' ')}
            onClick={onPageChange.bind(this, 'next')}>
            Next
          </div>
        </div>
      )}
    </div>
  )
}

export default Promocode
