import React, { useState, useEffect } from 'react'

const EditInputData = ({
  data,
  index,
  changeData = () => {},
  deleteMetaData = () => {},
}) => {
  const [initialData, setInitialData] = useState(data)
  const [metaData, setMetaData] = useState(data)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    setInitialData(data)
    setMetaData(data)
  }, [data])

  return (
    <div className='answer'>
      <div className='answer_textBox2'>
        {!isEdit ? (
          <>
            <p className='answer_text'>{metaData ? metaData : ''}</p>
            <div className='flex'>
              <img
                src='/images/icon_edit.png'
                alt='right tick'
                onClick={() => {
                  setIsEdit(true)
                }}
                className='answer_text_icon'
              />
              <img
                src='/images/delete.png'
                alt='right tick'
                className='answer_text_icon'
                onClick={() => {
                  deleteMetaData(metaData, index)
                  setIsEdit(false)
                  setInitialData('')
                  setMetaData('')
                }}
              />
            </div>
          </>
        ) : (
          <>
            <input
              type='text'
              className='answer_text_input'
              autoFocus
              value={metaData}
              onChange={(event) => {
                setMetaData(event.target.value)
              }}
            />
            <div className='flex'>
              <img
                src='/images/icon_tick.png'
                alt='right tick'
                className='answer_text_icon'
                onClick={() => {
                  changeData(metaData, index)
                  setIsEdit(false)
                }}
              />
              <img
                src='/images/icon_delete.png'
                alt='right tick'
                onClick={() => {
                  setMetaData(initialData)
                  setIsEdit(false)
                }}
                className='answer_text_icon'
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default EditInputData
