import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-subscription-details',
  initialState: {
    subscriptionDetails: null,
    loading: false,
  },
  reducers: {
    requested: (subscription) => {
      subscription.loading = true
    },
    success: (subscription, action) => {
      subscription.subscriptionDetails = action.payload
      subscription.loading = false
    },
    failed: (subscription, action) => {
      subscription.subscriptionDetails = action.payload
      subscription.loading = false
    },
    reset: (subscription, action) => {
      subscription.subscriptionDetails = action.payload
      subscription.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getSubscription = () =>
  apiCallBegan({
    url: `payment/get-current-subscription`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearGetSubscription = () =>
  apiCallBegan({
    onReset: reset.type,
  })
