import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'toaster',
    initialState: {
        toaster: false,
    },
    reducers: {
        changeToaster: (bar, action) => {
            bar.toaster = action.payload;
        }
    }
})

const { changeToaster } = slice.actions
export default slice.reducer

export const setToaster = (status, message, color) => apiCallBegan({
    data: { status, message, color },
    onChangeToaster: changeToaster.type,
})