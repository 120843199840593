import React, { useState, useEffect } from 'react'
import Content from '../../components/Content'
import './FAQ.css'
import { useDispatch, useSelector } from 'react-redux'
import { getFaqList } from '../../../store/faq/faqList'
import FaqSkeleton from '../../components/Skeletons/FaqSkeleton'

const FAQ = () => {
  const [isActive, setIsActive] = useState(null)

  const { faqList, loading } = useSelector((faq) => faq.faqList)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFaqList())
  }, [])

  const accordionHandler = (id) => {
    setIsActive(id)
  }

  return (
    <Content currentMenu='lab-FAQ' headerTitle='FAQ'>
      <div className='LAB_FAQ_container'>
        {faqList &&
        !loading &&
        faqList.data &&
        faqList.data.result &&
        Array.isArray(faqList.data.result) ? (
          faqList.data.result.map((item, index) => {
            return (
              <div className='LAB_FAQ_card' key={index}>
                <div
                  className='LAB_FAQ_collapse_header'
                  onClick={accordionHandler.bind(this, item._id)}>
                  <div className='LAB_FAQ_collapse_header_left'>
                    {item.question}
                  </div>
                  <div className='LAB_FAQ_collapse_header_right'>
                    <svg
                      className={`LAB_FAQ_collapse_header_icon ${
                        isActive === item._id ? `down` : null
                      }`}>
                      <use xlinkHref={`/images/sprite.svg#icon-arrowUp`} />
                    </svg>
                  </div>
                </div>
                {isActive === item._id ? (
                  <div className='LAB_FAQ_collpase_content'>{item.answer}</div>
                ) : null}
              </div>
            )
          })
        ) : (
          <FaqSkeleton />
        )}

        {/* <div className='LAB_FAQ_card'>
          <div
            className='LAB_FAQ_collapse_header'
            onClick={accordionHandler.bind(this, 2)}>
            <div className='LAB_FAQ_collapse_header_left'>
              Where Can I Edit My Address?
            </div>
            <div className='LAB_FAQ_collapse_header_right'>
              <svg
                className={`LAB_FAQ_collapse_header_icon ${
                  isActive === 2 ? `down` : null
                }`}>
                <use xlinkHref={`/images/sprite.svg#icon-arrowUp`} />
              </svg>
            </div>
          </div>
          {isActive === 2 ? (
            <div className='LAB_FAQ_collpase_content'>
              If you created a new account after or while ordering you can edit
              both addresses (for billing and shipping) in your customer account
            </div>
          ) : null}
        </div> */}
      </div>
    </Content>
  )
}

export default FAQ
