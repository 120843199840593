import React, { useState, useEffect } from 'react'
import Styles from './AddVideo.module.css'
import TextInput from '../../../../../components/forms/TextInput'
import Select from '../../../../../components/forms/Select'
import AppButton from '../../../../../components/forms/AppButton'
import ImageUploader from '../../../../../components/forms/ImageUploader'
import VideoUploader from '../../../../../components/forms/VideoUploader'
import { validateOnlyChacters } from '../../../../../utils/validators'
import {
  addResponseVideo,
  clearAddResponseVideo,
} from '../../../../../store/responseVideo/addResponseVideo'
import { getResponseVideoList } from '../../../../../store/responseVideo/getResponse'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ProgressBar from '../../../../../components/forms/ProgressBar'
import axios from 'axios'

const data1 = [
  {
    id: 1,
    value: 'success',
    title: 'For Success response',
  },
  {
    id: 4,
    value: 'fail',
    title: 'For failure response',
  },
]

const data2 = [
  {
    id: 3,
    value: 'video',
    title: 'Video',
  },
  {
    id: 6,
    value: 'doc',
    title: 'Doc',
  },
]

const AddVideo = ({ onCancel, update }) => {
  const [title, setTitle] = useState(update && update.title ? update.title : '')
  const [titleError, setTitleError] = useState('')
  const [responseType, setResponseType] = useState(
    update && update.type ? update.type : ''
  )
  const [responseTypeError, setResponseTypeError] = useState('')
  const [responseFileType, setResponseFileType] = useState(
    update && update.responseFileType ? update.responseFileType : ''
  )
  const [responseFileTypeError, setResponseFileTypeError] = useState('')

  // console.log('update', update)
  //image
  const [image, setImage] = useState('')
  const [imageError, setImageError] = useState('')
  const [imageURL, setImageURL] = useState('')

  //video
  const [video, setVideo] = useState('')
  const [videoError, setVideoError] = useState('')
  const [videoURL, setVideoURL] = useState('')

  //UI

  const [progress, setProgress] = useState()

  const [addCancelToken, setAddCancelToken] = useState(null)

  const dispatch = useDispatch()

  const { addResponseVideo: addedResponseVideo } = useSelector(
    (state) => state.addResponseVideo
  )

  useEffect(() => {
    if (addedResponseVideo !== null) {
      if (addedResponseVideo?.status) {
        toast.success(addedResponseVideo?.message)
        dispatch(getResponseVideoList(1, 10))
        dispatch(clearAddResponseVideo())
        setProgress(null)
        onCancel()
      } else {
        toast.error(addedResponseVideo?.message)
        dispatch(clearAddResponseVideo())
      }
    }
  }, [addedResponseVideo, dispatch])

  const onChangeTextHandler = (event) => {
    setTitle(event.target.value)
  }
  const onSelectResponseHandler = (event) => {
    setResponseType(event.target.value)
    setResponseTypeError('')
  }

  const onSelectResponseTypeHandler = (event) => {
    setResponseFileType(event.target.value)
    setResponseFileTypeError('')
  }

  const onUploadVideo = (video) => {
    setVideoURL(video)
    setVideoError('')
  }
  const onUploadImage = (image) => {
    setImageURL(image)
    setImageError('')
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault()
    //title validators
    if (title.trim() === '') {
      setTitleError('Please enter title')
      return
    } else if (title.length < 2) {
      setTitleError('Video title must be two character long')
      return
    } else if (validateOnlyChacters(title)) {
      setTitleError('Please enter valid video title')
      return
    } else {
      setTitleError('')
    }

    //response type validators
    if (responseType === '') {
      setResponseTypeError('Please select response content')
      return
    } else setResponseTypeError('')

    //responseFileType validators
    if (responseFileType === '') {
      setResponseFileTypeError('Please select content type')
      return
    } else setResponseFileTypeError('')

    //upload validators
    if (
      video.trim() === '' &&
      (responseFileType === 'video' || responseFileType === '')
    ) {
      setVideoError('Please select video')
      return
    }
    if (image.trim() === '' && responseFileType === 'doc') {
      setImageError('Please select image')
      return
    }

    //api dispatch
    const data = new FormData()
    if (
      titleError === '' &&
      responseFileTypeError === '' &&
      responseTypeError === '' &&
      videoError === '' &&
      responseFileType === 'video'
    ) {
      data.append('title', title)
      data.append('file', videoURL, videoURL.name)
      data.append('responseFileType', responseFileType)
      data.append('type', responseType)
      if (update !== null) {
        data.append('id', update._id)
      }

      const cancelTokenForAdd = axios.CancelToken.source()

      setAddCancelToken(cancelTokenForAdd)

      await dispatch(
        addResponseVideo(
          data,
          responseFileType,
          (progress) => setProgress(progress),
          cancelTokenForAdd
        )
      )
    }
    if (
      titleError === '' &&
      responseFileTypeError === '' &&
      responseTypeError === '' &&
      imageError === '' &&
      responseFileType === 'doc'
    ) {
      const data2 = new FormData()
      data2.append('title', title)
      data2.append('file', imageURL, imageURL.name)
      data2.append('responseFileType', responseFileType)
      data2.append('type', responseType)
      if (update !== null) {
        data2.append('id', update._id)
      }

      const cancelTokenForUpdate = axios.CancelToken.source()

      setAddCancelToken(cancelTokenForUpdate)

      await dispatch(
        addResponseVideo(
          data2,
          responseFileType,
          (progress) => setProgress(progress),
          cancelTokenForUpdate
        )
      )
    }
  }

  const onCancelVideo = () => {
    setTitle(null)
    setResponseFileTypeError('')
    setResponseType(null)
    setResponseFileType(null)
    setImageURL(null)
    setProgress(null)
    setImage(null)
    setAddCancelToken(null)
    setResponseTypeError('')
    setTitleError('')
    setVideo(null)
    setVideoError('')
    setVideoURL(null)
    if (addCancelToken) {
      addCancelToken?.cancel()
    }
  }

  //render

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.container_left}>
          <div className={Styles.title}>
            {update ? 'Update' : 'Add'} Response Content
          </div>
          <TextInput
            src='/images/book1.png'
            placeholder='Response Content Title'
            value={title}
            onChange={onChangeTextHandler}
          />
          {titleError && titleError.trim() !== '' && (
            <p className={Styles.danger}>{titleError}</p>
          )}
          <Select
            src='/images/addresponse2.png'
            placeholder='Select Response Content for'
            data={data1}
            value={responseType}
            onChange={onSelectResponseHandler}
          />
          {responseTypeError && responseTypeError.trim() !== '' && (
            <p className={Styles.danger}>{responseTypeError}</p>
          )}
          <Select
            src='/images/addresponse1.png'
            placeholder='Select Content Type'
            value={responseFileType}
            data={data2}
            onChange={onSelectResponseTypeHandler}
          />
          {responseFileTypeError && responseFileTypeError.trim() !== '' && (
            <p className={Styles.danger}>{responseFileTypeError}</p>
          )}
          <div className={Styles.buttonContainer}>
            <AppButton
              title={update ? 'Update' : 'Add'}
              type='submit'
              icon={
                <img
                  src='/images/add-clinic.png'
                  alt='add'
                  className='app_button_image'
                />
              }
              onClick={onSubmitHandler}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              onClick={() => {
                onCancelVideo()
                onCancel()
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
            />
          </div>
        </div>
        <div className={Styles.container_right}>
          <div className={Styles.container_right_video_uploader}>
            {responseFileType.trim() === '' ||
            responseFileType.trim() === 'video' ? (
              <>
                <VideoUploader
                  onUpload={onUploadVideo}
                  video={video}
                  setURL={(video) => setVideo(video)}
                  containerStyles={{ width: '15vw', height: '9vw' }}
                  videoTitle='Upload Video'
                />
                {videoError && videoError !== '' && (
                  <p className={Styles.danger}>{videoError} </p>
                )}
              </>
            ) : (
              <>
                <ImageUploader
                  onUpload={onUploadImage}
                  image={image}
                  setURL={(image) => setImage('/images/pdf.png')}
                  containerStyles={{ borderRadius: '50%' }}
                  imageTitle='Upload thumbnail'
                />
                {imageError && imageError !== '' && (
                  <p className={Styles.danger}>{imageError} </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ProgressBar progress={progress * 100} />
    </>
  )
}

export default AddVideo
