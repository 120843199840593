import React, { useState } from 'react'

const ReportToggle = ({ onChange }) => {

    const [ischarts, setISCharts] = useState(false)

    const onChangeToggle = () => {
        setISCharts(!ischarts)
        onChange(!ischarts)
    }

    return (
        <div className="reports_graph_toggle_container">
            <div className="reports_graph_ontainer">
                <div className={`reports_graph_toggle ${!ischarts && "active_report_chart"}`} onClick={onChangeToggle}>
                    <img alt="reports" src="/images/reports.png" className="reports_graph_toggle_image" />
                    <p className="reports_graph_toggle_label">reports</p>
                </div> :
                <div className={`reports_graph_toggle ${ischarts && "active_report_chart"}`} onClick={onChangeToggle}>
                    <img alt="charts" src="/images/charts.png" className="reports_graph_toggle_image" />
                    <p className="reports_graph_toggle_label">charts</p>
                </div>
            </div>
        </div>
    )
}

export default ReportToggle;