import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-all-pre-asset-list',
  initialState: {
    preAssetsList: [],
    loading: false,
  },
  reducers: {
    requested: (users, action) => {
      users.loading = true
    },
    success: (users, action) => {
      users.preAssetsList = action.payload
      users.loading = false
    },
    failed: (users, action) => {
      users.preAssetsList = action.payload
      users.loading = false
    },
  },
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getPreAssetList = () =>
  apiCallBegan({
    url: 'pre-asset/get-enabled-pre-asset',
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type,
  })
