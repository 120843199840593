import axios from 'axios'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  GET_USER_DETAILS_BY_TOKEN_REQUEST,
  GET_USER_DETAILS_BY_TOKEN_SUCCESS,
  GET_USER_DETAILS_BY_TOKEN_FAIL,
  GET_USER_DETAILS_BY_TOKEN_RESET,
  GET_TILES_REQUEST,
  GET_TILES_SUCCESS,
  GET_TILES_FAIL,
  GET_TILES_RESET,
} from '../constants/userConstants'
import { baseUrl } from '../../../middleware/url'

export const login = (userMobile, otp) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      `${baseUrl}/auth/otp-verification`,
      { userMobile, otp },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    })
  }
}

export const checkMobileAction = async (userMobile) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const d = new Date()
    let diff = d.getTimezoneOffset()

    const { data } = await axios.post(
      `${baseUrl}/auth/check-mobile-number?requestFrom=web?offset=${diff}`,
      { userMobile },
      config
    )

    return data
  } catch (err) {
    return err
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: GET_USER_DETAILS_BY_TOKEN_RESET })
  dispatch({ type: GET_TILES_RESET })
}

export const userDetailsByTokenAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_DETAILS_BY_TOKEN_REQUEST })

    const {
      credentials: { credentials },
    } = getState()
    const config = {
      headers: {
        token: credentials.data.token,
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.get(
      `${baseUrl}/user/get-user-by-token`,
      config
    )

    dispatch({
      type: GET_USER_DETAILS_BY_TOKEN_SUCCESS,
      payload: data,
    })
  } catch (err) {
    dispatch({
      type: GET_USER_DETAILS_BY_TOKEN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    })
  }
}

export const getTilesAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TILES_REQUEST })
    const {
      credentials: { credentials },
      userDetails,
    } = getState()
    const clientType =
      userDetails &&
      userDetails.userInfo &&
      userDetails.userInfo.data &&
      userDetails.userInfo.data.client &&
      userDetails.userInfo.data.client.clientType

    const config = {
      headers: {
        token: credentials.data.token,
        'Content-Type': 'application/json',
      },
    }

    const uri =
      clientType === 'lab'
        ? `${baseUrl}/generic/get-enabled-tiles-for-lab`
        : `${baseUrl}/generic/get-enabled-tiles`

    const { data } = await axios.get(uri, config)

    dispatch({
      type: GET_TILES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    dispatch({
      type: GET_TILES_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    })
  }
}

export const addLogAction = (status, generic) => async (dispatch, getState) => {
  try {
    const {
      credentials: { credentials },
      userDetails,
    } = getState()
    const clientType =
      userDetails &&
      userDetails.userInfo &&
      userDetails.userInfo.data &&
      userDetails.userInfo.data.client &&
      userDetails.userInfo.data.client.clientType

    const config = {
      headers: {
        token: credentials.data.token,
        'Content-Type': 'application/json',
      },
    }

    const uri =
      clientType === 'lab'
        ? `${baseUrl}/log-event/add-log-event-for-lab`
        : `${baseUrl}/log-event/add-log-event`

    const { data } = await axios.post(uri, { status, generic }, config)

    return data
  } catch (err) {
    return err
  }
}
