import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillPlusCircle } from 'react-icons/ai'
import Dropzone from 'react-dropzone'

import Modal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'
import AppHeader from '../../../../components/AppHeader'

import './howto.css'
import AppButton from '../../../../components/forms/AppButton'
import TextInput from '../../../../components/forms/TextInput'

import ProgressBar from '../../../../components/forms/ProgressBar'
import { toast } from 'react-toastify'
import { getSuccessVideoList } from '../../../../store/successVideo/getSuccessVideos'
import {
  addSuccessVideo,
  clearAddSuccessVideoResponse,
} from '../../../../store/successVideo/addSuccessVideo'
import VideoCard from '../../../../components/Cards/VideoCard'
import ImageUploader from '../../../../components/forms/ImageUploader'
import AllVideosSkeleton from '../../../../components/Skeletons/AllVideosSkeleton'
import AppSwitch from '../../../../components/AppSwitch'
import {
  deleteSuccessVideos,
  clearDeleteSuccessVideosResponse,
} from '../../../../store/successVideo/deleteSuccessVideo'
import {
  enableSuccessVideos,
  clearEnableSuccessVideosResponse,
} from '../../../../store/successVideo/enableSuccessVideo'
import Pagination from '../../../../components/Pagination'
import { useHistory } from 'react-router'

const SuccessVideo = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { sidebar } = useSelector((bar) => bar.sidebar)

  const [addHideShow, setAddHideShow] = useState(false)
  const [search, setSearch] = useState('')

  const [progress, setProgress] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')

  const [isUpadate, setIsUpadate] = useState(null)

  const [video, setVideo] = useState('')
  const [videoError, setVideoError] = useState('')
  const [videoURL, setVideoURL] = useState('')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)

  const [pageNumber, setPageNumber] = useState(0)
  const pageSize = 8

  const { successVideoList, loading } = useSelector(
    (video) => video.getSuccessVideo
  )

  const { addSuccessVideoResponse } = useSelector(
    (video) => video.addSuccessVideo
  )

  const { deleteSuccessVideo } = useSelector(
    (video) => video.deleteSuccessVideo
  )

  const { enableSuccessVideo, loading: enableLoading } = useSelector(
    (video) => video.enableSuccessVideo
  )

  useEffect(() => {
    dispatch(
      getSuccessVideoList({
        pageNumber,
        pageSize,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (addSuccessVideoResponse !== null) {
      if (addSuccessVideoResponse.status) {
        onCloseModal()
        toast.success(addSuccessVideoResponse.message)
        dispatch(
          getSuccessVideoList({
            pageNumber,
            pageSize,
          })
        )
        dispatch(clearAddSuccessVideoResponse())
      } else {
        toast.error(addSuccessVideoResponse.message)
        dispatch(clearAddSuccessVideoResponse())
      }
    }
  }, [addSuccessVideoResponse, dispatch])

  useEffect(() => {
    if (deleteSuccessVideo !== null) {
      if (deleteSuccessVideo.status) {
        closeDeleteModal()
        toast.success(deleteSuccessVideo.message)
        dispatch(
          getSuccessVideoList({
            pageNumber,
            pageSize,
          })
        )
        dispatch(clearDeleteSuccessVideosResponse())
      } else {
        toast.error(deleteSuccessVideo.message)
        dispatch(clearDeleteSuccessVideosResponse())
      }
    }
  }, [deleteSuccessVideo, dispatch])

  const onSearchHandler = (event) => {
    setSearch(event.target.value)
  }

  const onEditHandler = (item) => {
    setAddHideShow(true)

    setIsUpadate(item)
    setTitle(item.title)
    setVideo(item.videoURL)
  }

  const onCloseModal = () => {
    setAddHideShow(false)
    setProgress(null)
    setTitle('')
    setTitleError('')
    setVideo('')
    setVideoError('')
    setVideoURL('')
    setIsUpadate(null)
  }

  const onAddHandler = (event) => {
    event.preventDefault()

    const data = new FormData()

    if (isUpadate) {
      if (videoURL !== '') {
        data.append('video', videoURL, videoURL.name)
      }
      if (isUpadate._id) {
        data.append('id', isUpadate._id)
      }
      if (title === '') {
        setTitleError('Please enter Video title')
        return
      } else if (title.length < 2) {
        setTitleError('Video title must be two character long')
        return
      }

      data.append('title', title)

      dispatch(addSuccessVideo(data, (progress) => setProgress(progress)))
    } else {
      if (title === '') {
        setTitleError('Please enter Video title')
        return
      } else if (title.length < 2) {
        setTitleError('Video title must be two character long')
        return
      } else if (!videoURL) {
        setVideoError('Please select video')
        return
      } else {
        data.append('title', title)
        data.append('video', videoURL, videoURL.name)
      }

      dispatch(addSuccessVideo(data, (progress) => setProgress(progress)))
    }
  }

  const onUpload = (image) => {
    setVideoURL(image)
    setVideoError('')
  }

  const onDeleteHandler = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
  }

  const onDeleteVideo = () => {
    if (deletedItem !== null && deletedItem._id) {
      dispatch(deleteSuccessVideos(deletedItem._id))
    }
  }

  const onChangeHandler = async (item) => {
    setCurrentItem(item._id)
    await dispatch(
      enableSuccessVideos({
        _id: item._id,
        enable: item.enable,
      })
    )
    await dispatch(
      getSuccessVideoList({
        pageNumber,
        pageSize,
      })
    )
    setCurrentItem(null)
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected

    dispatch(
      getSuccessVideoList({
        pageNumber: selectedPage + 1,
        pageSize,
      })
    )

    setPageNumber(selectedPage + 1)
  }

  const onClientChange = (item) => {
    if (item && item.clientType === 'lab') {
      history.push('/')
    } else {
      dispatch(
        getSuccessVideoList({
          pageNumber,
          pageSize,
        })
      )
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Success Video'
        isAdd
        onAdd={() => setAddHideShow(true)}
        isSearch
        searchPlaceholder='Search success video'
        onSearch={onSearchHandler}
        searchValue={search}
        onClientChange={onClientChange}
      />

      {loading ? (
        <AllVideosSkeleton />
      ) : (
        <>
          <div className='client__container'>
            <div className='missions_main_content'>
              {successVideoList &&
              successVideoList.data &&
              successVideoList.data.result ? (
                successVideoList.data.result.map((item, index) => {
                  return (
                    <VideoCard
                      key={index}
                      item={item}
                      showLink={false}
                      onEditClick={onEditHandler}
                      onDelete={onDeleteHandler}
                      showToggle={true}
                      checked={item.enable}
                      onChange={onChangeHandler.bind(this, item)}
                      loading={currentItem === item._id && enableLoading}
                      showAttach={false}
                    />
                  )
                })
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== ''
                      ? 'Search result not found'
                      : "You havn't uploaded any success videos yet."}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <Modal show={addHideShow}>
        <p className='modal_heading'>
          {isUpadate ? 'Update Success Video' : 'Add Success Video'}
        </p>
        <div className='howto_edit_modal mt-2'>
          <form className='howto_edit_modal_left' onSubmit={onAddHandler}>
            <TextInput
              placeholder='Title'
              src='/images/title.png'
              onChange={(title) => {
                setTitleError('')
                setTitle(title.target.value)
              }}
              value={title}
              error={titleError}
            />

            <div className='modal_buttons'>
              <AppButton
                title={isUpadate ? 'update' : 'add'}
                type='submit'
                icon={<AiFillPlusCircle className='app_button_image' />}
                onClick={onAddHandler}
              />
              <AppButton
                title='cancel'
                type='button'
                buttonContainer={{
                  backgroundColor: 'var(--transparent)',
                  marginLeft: '0.5rem',
                }}
                textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                onClick={onCloseModal}
              />
            </div>
          </form>
          <div className='clinic_form_image_upload_container'>
            <ImageUploader
              onUpload={onUpload}
              video={video}
              setURL={(video) => setVideo(video)}
              errorMessage={videoError}
              containerStyles={{ width: '15vw', height: '9vw' }}
              videoTitle='Upload video'
            />
          </div>
        </div>
        <ProgressBar progress={progress * 100} />
      </Modal>
      <AppModal2 show={showDeleteModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Delete Success Video</p>
          <p className='form_header_delete_text'>
            Are you sure you want to delete{' '}
            <span>{deletedItem !== null && deletedItem.title}</span> video?
          </p>

          <div className='modal_buttons'>
            <AppButton title='delete' type='submit' onClick={onDeleteVideo} />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={closeDeleteModal}
            />
          </div>
        </div>
      </AppModal2>

      {successVideoList &&
        successVideoList.data &&
        successVideoList.data.result &&
        successVideoList.data.result.length > 0 &&
        successVideoList.data.totalPageSize > 8 && (
          <Pagination
            list={successVideoList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
            page={pageNumber}
          />
        )}
    </div>
  )
}

export default SuccessVideo
