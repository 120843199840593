import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'update-version',
  initialState: {
    updateVersionResponse: null,
    loading: false,
  },
  reducers: {
    requested: (state, action) => {
      state.loading = true
    },
    success: (state, action) => {
      state.updateVersionResponse = action.payload
      state.loading = false
    },
    failed: (state, action) => {
      state.updateVersionResponse = action.payload
      state.loading = false
    },
    reset: (state, action) => {
      state.updateVersionResponse = action.payload
      state.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const updateVersion = (data) =>
  apiCallBegan({
    url: '/version/enable-disable-update-dialogue-box',
    method: 'PATCH',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearUpdateVersionResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
