import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const AudioUploader = ({
  onUpload,
  containerStyles,
  setURL,
  errorMessage,
  audio,
  audioTitle = '',
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setURL(URL.createObjectURL(acceptedFiles[0]))
    onUpload(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <>
      <div
        {...getRootProps()}
        className='modal_drag_and_drop_view_audio'
        style={containerStyles}>
        <input {...getInputProps()} accept='audio/*' />
        {audio !== '' ? (
          <div className='video_card_audio_container'>
            <img
              src='/images/audio_icon.png'
              className='client_card_pdf_image'
              style={{ width: '10.5rem', height: '10.5rem' }}
            />
            <audio
              controls
              style={{ width: '260px' }}
              controlsList='nodownload'>
              <source src={audio} type='audio/mpeg' />
              <source src={audio} type='audio/ogg' />
              Your browser does not support HTML video.
            </audio>
          </div>
        ) : (
          // <audio controls style={{ width: '260px' }}>
          //   <source src={audio} type='audio/mpeg' />
          //   <source src={audio} type='audio/ogg' />
          //   Your browser does not support HTML video.
          // </audio>
          <>
            <img
              className='modal_logo_image'
              src='/images/select_file.png'
              alt='logo'
            />
            <p className='form_description_text'>
              {audioTitle !== '' ? audioTitle : 'Select audio to upload'}
            </p>
          </>
        )}
      </div>
      {errorMessage !== '' && <p className='error_message'>{errorMessage}</p>}
    </>
  )
}

export default AudioUploader
