import React from 'react';
import HomeScreenStyles from '../../screens/Home/Home.module.css';
import SkeletonStyles from './Skeletons.module.css';

const HowCardSkeleton = ({ items = 3 }) => {
	const [ cards, setCards ] = React.useState([]);

	React.useEffect(
		() => {
			const iter = [];
			for (let i = 0; i < items; i++) {
				iter.push(i);
			}
			setCards(iter);
		},
		[ items ]
	);

	return (
		cards &&
		cards.length > 0 &&
		cards.map((card) => (
			<div
				className={
					card === 1 ? (
						HomeScreenStyles.howto_section_greenCard
					) : card === 2 ? (
						HomeScreenStyles.howto_section_orangeCard
					) : (
						HomeScreenStyles.howto_section_blueCard
					)
				}
				key={card}
				style={{ padding: '1.5rem 2rem' }}>
				<div
					className={[ SkeletonStyles.selectTypes_card_name_skeleton, SkeletonStyles.skeleton ].join(' ')}
					style={{ width: '9rem', marginRight: '1rem' }}
				/>
				<div
					className={[ SkeletonStyles.selectTypes_card_image_skeleton, SkeletonStyles.skeleton ].join(' ')}
					style={{ borderRadius: '50%', height: '5rem', width: '5rem' }}
				/>
			</div>
		))
	);
};

export default HowCardSkeleton;
