import React, { useLayoutEffect, useRef } from 'react'
import styles from './Congrats.module.css'
import { useHistory } from 'react-router-dom'
import { gsap } from 'gsap'
import Support from '../../../components/Support'

const Congrats = ({ data }) => {
  const history = useHistory()

  const container = useRef(null)
  const header = useRef(null)
  const img = useRef(null)
  const img2 = useRef(null)
  const text = useRef(null)
  const text2 = useRef(null)
  const button = useRef(null)

  const redirectHandler = () => {
    history.push('/')
  }

  useLayoutEffect(() => {
    gsap.set(container.current, { opacity: 0, overflow: 'hidden' })

    let timeline = gsap.timeline({ defaults: { opacity: 0 } })

    timeline
      .to(container.current, { opacity: 1 })
      .from(header.current, {
        yPercent: -100,
        opacity: 0,
        scale: 0,
        duration: 0.8,
      })

      // .from(text.current, {
      //   xPercent: -100,
      //   opacity: 0,
      //   scale: 0,
      //   duration: 0.8,
      // })
      .from(
        text2.current,
        { xPercent: 100, opacity: 0, scale: 0, duration: 0.8 },
        '<'
      )
      .from(
        button.current,
        {
          opacity: 0,
          scale: 0,
          duration: 0.8,
        },
        '<'
      )
      .from(
        img2.current,
        {
          xPercent: -100,
          opacity: 0,
          scale: 0,
          duration: 0.8,
        },
        '<'
      )
  }, [])

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage:
          'linear-gradient(to bottom, rgba(11, 26, 91, .58), rgba(11, 26, 91, .58)), url(/assets/child_background.png)',
      }}
      ref={container}>
      <div className={styles.subContainer}>
        <div className={styles.logoContainer} ref={img2}>
          <img src='/images/bx_white_logo.png' className={styles.img2} />
        </div>
        <div className={styles.header} ref={header}>
          Welcome to Bx {`${data && data?.userName ? data?.userName : `User`}`}
        </div>

        {/* <div className={styles.text1} ref={text}>
          Congratulations
        </div> */}
        <div className={styles.text2} ref={text2}>
          You have successfully completed onboarding with Bx !
        </div>
        <div className={styles.button} onClick={redirectHandler} ref={button}>
          Login
        </div>
      </div>
      <Support />
    </div>
  )
}

export default Congrats
