import React, { useState } from 'react'
import './app_button.css'

const AppButton = ({ type, title, icon, onClick, buttonContainer, textStyle }) => {

    return (
        <button type={type} className="app_button_container" onClick={onClick} style={buttonContainer}>
            <p className="app_button_title" style={textStyle}>{title}</p>
            {icon && icon}
        </button>
    )
}

export default AppButton