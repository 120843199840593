export const validateOnlyChacters = (name) => {
  const re = /^[a-zA-Z \s]+$/
  return re.test(name)
}

export const validatePassword = (password) => {
  const re =
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/
  return re.test(password)
}

export const validateEmail = (email) => {
  const re =
    /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return re.test(email)
}

export const validatePhone = (phone) => {
  const re = /^[0-9]{10}$/
  // const re = /^[0-9]{5,12}$/;
  return re.test(phone)
}

export const getCountdown = (week, year) => {
  const source = new Date().getTime()
  const destination = new Date(
    moment()
      .day(0)
      .week(week + 2)
      .year(year)
      .hour(12)
      .minute(0)
      .second(0)
  ).getTime()
  const diffrence = destination - source
  const days = Math.floor(diffrence / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (diffrence % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((diffrence % (1000 * 60 * 60)) / (1000 * 60))
  return `${days}d ${hours}h ${minutes}m`
}
