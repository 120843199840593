import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function StackedBar({ data, title }) {
  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          color: '#fff'
        }
      },
      datalabels: {
        display: (context) => {
          return context.dataset.data[context.dataIndex];
        },
        formatter: function (value, context) {
          return context.dataset.data[context.dataIndex] + " %";
        },
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        top: 24,
        right: 16,
        bottom: 0,
        left: 8,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        fill: false,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "white",
        },
      },
      y: {
        min: 0,
        max: 100,
        stacked: true,
        ticks: {
          color: "white",
          stepSize: 20
        },
      },
    },
  };
  // Blur
  // {
  //   filter: 'blur(8px)',
  //     webkitFilter: 'blur(8px)'
  // }
  return (
    <div
      style={{
        width: "34vw",
        height: "27rem",
      }}
      tabIndex={0}
    >
      {data && data.datasets && Array.isArray(data.datasets) && (
        <Bar
          style={
            !data ||
            data.datasets === [] ||
            (data.datasets[0].data[0] === 0 && data.datasets[1].data[0] === 0)
              ? { filter: "blur(8px)", webkitFilter: "blur(8px)" }
              : {}
          }
          options={options}
          data={data}
        />
      )}
    </div>
  );
}

export default StackedBar;
