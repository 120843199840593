import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AppHeader from '../../../components/AppHeader'
import ProgressBar from '../../../components/forms/ProgressBar'
import TextInput from '../../../components/forms/TextInput'
import AppModal2 from '../../../components/AppModal2'
import {
  changePassword,
  clearChangePasswordResponse,
} from '../../../store/login/changePassword'

import axios from 'axios'
import { baseUrl } from '../../../middleware/url'
import { getModuleNames } from '../../../store/Clients/getModuleNames'
import AppSwitchSmall from '../../../components/AppSwitchSmall'

import './MyProfile.css'

import { getSubscription } from '../../../store/settings/getSubscription'
import moment from 'moment'
import {
  cancelSubscription,
  clearCancelSubscription,
} from '../../../store/settings/cancelSubscription'
import AppButton from '../../../components/forms/AppButton'
import {
  reactiveSubscription,
  clearReactiveSubscriptionResponse,
} from '../../../store/settings/reactivateSubsription'
import { getRegistrationDialogue } from '../../../store/settings/getRegistrationDialogue'
import {
  enableDisableRegistrationDialogue,
  clearEnableDisableRegistrationDialogue,
} from '../../../store/settings/enableRegistrationDialogue'

const MyProfile = () => {
  const [registrationStatus, setRegistrationStatus] = useState(false)

  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { changePasswordResponse } = useSelector(
    (response) => response.changePasswordResponse
  )
  const { userInfo, credentials } = useSelector(
    (response) => response.credentials
  )

  const { configurationList, loading: configLoading } = useSelector(
    (config) => config.getConfig
  )

  const { subscriptionDetails, loading: subscriptionLoading } = useSelector(
    (subscription) => subscription.getSubscription
  )
  const { subscriptionDeleteResponse } = useSelector(
    (state) => state.cancelSubscription
  )
  const { moduleNames } = useSelector((user) => user.moduleNames)

  const { subscriptionReactiveResponse } = useSelector(
    (state) => state.reactivateSubsription
  )

  const { registrationDialogue, loading: registrationLoading } = useSelector(
    (state) => state.getRegistrationDialogue
  )

  const { enableRegistration, loading: enableRegistrationLoading } =
    useSelector((state) => state.enableRegistrationDialogue)

  console.log('registrationDialogue', registrationDialogue)
  console.log('enableRegistration', enableRegistration)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showReactivateModal, setShowReactivateModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [passwordsError, setPasswordsError] = useState({
    currentPasswordError: '',
    newPasswordError: '',
    confirmPasswordError: '',
  })
  const [progress, setProgress] = useState(null)

  const [missionsText, setMissionsText] = useState({ value: '', error: '' })
  const [spacesText, setSpacesText] = useState({ value: '', error: '' })
  const [propertiesText, setPropertiesText] = useState({ value: '', error: '' })

  useEffect(() => {
    if (registrationDialogue && registrationDialogue.status) {
      setRegistrationStatus(true)
    } else {
      setRegistrationStatus(false)
    }
  }, [registrationDialogue, enableRegistration])

  useEffect(() => {
    if (enableRegistration && enableRegistration.status) {
      dispatch(getRegistrationDialogue())
      dispatch(clearEnableDisableRegistrationDialogue())
      if (enableRegistration.message) {
        toast.success(enableRegistration.message)
      }
    } else {
      dispatch(getRegistrationDialogue())
    }
  }, [enableRegistration])
  useEffect(() => {
    dispatch(getRegistrationDialogue())
  }, [])

  useEffect(() => {
    if (userInfo && userInfo.userRole !== 'Admin' && userInfo._id) {
      dispatch(getModuleNames(userInfo._id))
      dispatch(getSubscription())
    }
  }, [userInfo])

  useEffect(() => {
    if (moduleNames && moduleNames.data) {
      setMissionsText({ value: moduleNames.data.stageOne, error: '' })
      setSpacesText({ value: moduleNames.data.stageTwo, error: '' })
      setPropertiesText({ value: moduleNames.data.stageThree, error: '' })
    }
  }, [moduleNames])

  const handleNameChange = async () => {
    if (missionsText.value === '' || missionsText.value.trim() === '') {
      setMissionsText({
        ...missionsText,
        error: 'Enter what would you like to call to missions',
      })
    } else if (spacesText.value === '' || spacesText.value.trim() === '') {
      setSpacesText({
        ...spacesText,
        error: 'Enter what would you like to call to spaces',
      })
    } else if (
      propertiesText.value === '' ||
      propertiesText.value.trim() === ''
    ) {
      setPropertiesText({
        ...propertiesText,
        error: 'Enter what would you like to call to properties',
      })
    } else {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          token: credentials && credentials.data && credentials.data.token,
        },
      }
      const formData = {
        stageOne: missionsText.value,
        stageTwo: spacesText.value,
        stageThree: propertiesText.value,
      }
      const response = await axios.patch(
        `${baseUrl}/user/update-rename-module?userId=${userInfo._id}`,
        formData,
        config
      )
      if (response.data && response.data.status) {
        toast.success(response.data.message)
        dispatch(getModuleNames(userInfo._id))
      }
    }
  }

  useEffect(() => {
    if (changePasswordResponse !== null) {
      if (changePasswordResponse.status) {
        toast.success(changePasswordResponse.message)
        dispatch(clearChangePasswordResponse())
        setProgress(null)
        setPasswords({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        })
      } else {
        toast.error(changePasswordResponse.message)
        dispatch(clearChangePasswordResponse())
      }
    }
  }, [changePasswordResponse, dispatch])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!passwords.currentPassword || passwords.currentPassword.trim() === '') {
      setPasswordsError({
        ...passwordsError,
        currentPasswordError: 'Please enter your current password',
      })
    } else if (!passwords.newPassword || passwords.newPassword.trim() === '') {
      setPasswordsError({
        ...passwordsError,
        newPasswordError: 'Please enter your new password',
      })
    } else if (passwords.newPassword !== passwords.confirmPassword) {
      setPasswordsError({
        ...passwordsError,
        confirmPasswordError: 'Your passwords do not match',
      })
    } else {
      await dispatch(
        changePassword(
          { userPassword: passwords.confirmPassword, type: 'bySelf' },
          (progress) => setProgress(progress)
        )
      )
    }
  }

  const onClientChange = (item) => {
    if (item && item.userRole !== 'Admin' && item._id) {
      dispatch(getModuleNames(item._id))
    }
  }

  useEffect(() => {
    if (subscriptionDeleteResponse) {
      if (subscriptionDeleteResponse?.status) {
        toast.success(subscriptionDeleteResponse.message)
        dispatch(getSubscription())
        dispatch(clearCancelSubscription())
        setCurrentItem(null)
        setShowDeleteModal(false)
      } else {
        toast.error(subscriptionDeleteResponse.message)
        dispatch(clearCancelSubscription())
      }
    }
  }, [subscriptionDeleteResponse])

  useEffect(() => {
    if (subscriptionReactiveResponse) {
      if (subscriptionReactiveResponse?.status) {
        toast.success(subscriptionReactiveResponse.message)
        dispatch(getSubscription())
        dispatch(clearReactiveSubscriptionResponse())
        setCurrentItem(null)
        setShowReactivateModal(false)
      } else {
        toast.error(subscriptionReactiveResponse.message)
        dispatch(clearReactiveSubscriptionResponse())
      }
    }
  }, [subscriptionReactiveResponse])

  const cancelSubscriptionHandler = (details) => {
    setShowDeleteModal(true)
    setCurrentItem(details)
  }

  const subscriptionHandler = (details) => {
    setShowReactivateModal(true)
    setCurrentItem(details)
  }

  const renewSubscriptionHandler = () => {
    if (currentItem) {
      const data = {
        subscriptionID: currentItem?.subscriptionID,
      }

      const d = new Date()
      let offset = d.getTimezoneOffset()

      dispatch(reactiveSubscription(data, offset))
    }
  }

  const cancelHandler = () => {
    if (currentItem) {
      const unixTime = currentItem?.current_period_end
      const date = new Date(unixTime * 1000)
      const data = {
        subscriptionID: currentItem?.subscriptionID,
        endDate: date.toLocaleDateString('en-US'),
      }

      const d = new Date()
      let offset = d.getTimezoneOffset()
      dispatch(cancelSubscription(data, offset))
    }
  }

  const enableRegistrationHandler = () => {
    dispatch(
      enableDisableRegistrationDialogue({
        status: !registrationStatus,
      })
    )
  }
  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Settings' onClientChange={onClientChange} />
      <div className='client__container'>
        <div className='missions_main_content'>
          <div style={{ width: '35vw' }}>
            <h2 className='profile__heading'>Personal Information</h2>
            <div className='profile__content2'>
              <h2 className='profile__name'>{userInfo && userInfo.userName}</h2>
              <div className='profile__contactInfo'>
                <div className='profile__contactInfo--heading'>
                  Contact Information
                </div>
                <div className='profile__contactInfo--contact'>
                  <svg className='profile__contactInfo--contactIcon'>
                    <use xlinkHref={`/images/sprite.svg#icon-phone`} />
                  </svg>
                  <p className='profile__contactInfo--contactName'>
                    {userInfo && userInfo.userMobile
                      ? userInfo.userMobile
                      : 'N/A'}
                  </p>
                </div>
                <div className='profile__contactInfo--contact'>
                  <svg className='profile__contactInfo--contactIcon'>
                    <use xlinkHref={`/images/sprite.svg#icon-email`} />
                  </svg>
                  <p className='profile__contactInfo--contactName'>
                    {userInfo && userInfo.userEmail
                      ? userInfo.userEmail
                      : 'N/A'}
                  </p>
                </div>
                <div className='profile__contactInfo--contact'>
                  <svg className='profile__contactInfo--contactIcon'>
                    <use xlinkHref={`/images/sprite.svg#icon-map-pin`} />
                  </svg>
                  <p className='profile__contactInfo--contactAddress'>
                    {userInfo && userInfo.userAddress
                      ? userInfo.userAddress
                      : 'N/A'}
                  </p>
                </div>
              </div>
            </div>

            {userInfo && userInfo.userRole === 'Admin' && (
              <div>
                <div className='profile__heading'>
                  Enable disable registration dialogue box
                </div>
                <AppSwitchSmall
                  checked={registrationStatus}
                  onChange={enableRegistrationHandler}
                />
              </div>
            )}

            {userInfo &&
              userInfo.userRole !== 'Admin' &&
              configurationList &&
              configurationList.functionSetting &&
              configurationList.functionSetting.renameModule && (
                <div
                  className='profile__content2'
                  style={{ marginTop: '2rem' }}>
                  <div
                    className='login_input_container'
                    style={{ padding: '0', marginTop: '0' }}>
                    <h2
                      className='profile__heading'
                      style={{ marginTop: '1rem' }}>
                      Basic Nomenclature
                    </h2>
                    <h4 className='profile__contactInfo--heading'>
                      What would you like to call 'Missions', 'Spaces' and
                      'Properties'?
                    </h4>
                    <TextInput
                      placeholder='eg, Missions'
                      src='/images/missions.png'
                      value={missionsText.value}
                      onChange={(e) => {
                        setMissionsText({ value: e.target.value, error: '' })
                      }}
                      error={missionsText.error}
                    />
                    <TextInput
                      placeholder='eg, Spaces'
                      src='/images/spaces.png'
                      value={spacesText.value}
                      onChange={(e) => {
                        setSpacesText({ value: e.target.value, error: '' })
                      }}
                      error={spacesText.error}
                    />
                    <TextInput
                      placeholder='eg, Properties'
                      src='/images/properties.png'
                      value={propertiesText.value}
                      onChange={(e) => {
                        setPropertiesText({ value: e.target.value, error: '' })
                      }}
                      error={propertiesText.error}
                    />
                    <button
                      className='send_notification_btn'
                      onClick={handleNameChange}>
                      Save
                    </button>
                  </div>
                </div>
              )}
          </div>

          {userInfo && userInfo.userRole !== 'Admin' && (
            <>
              {subscriptionDetails &&
              subscriptionDetails.status &&
              subscriptionDetails?.data?.status !== 'canceled' ? (
                <div className='profile__password'>
                  <h2 className='profile__heading'>Subscription Information</h2>

                  <div className='profile__subscription_text'>
                    Package Name :{' '}
                    <span className='profile__subscription_color'>
                      {subscriptionDetails?.data?.product?.name}
                    </span>{' '}
                  </div>
                  <div className='profile__subscription_text'>
                    Plan :{' '}
                    <span className='profile__subscription_color'>
                      {`$${
                        Number(subscriptionDetails?.data?.plan?.amount) / 100
                      } / ${subscriptionDetails?.data?.plan?.interval}`}
                    </span>
                  </div>
                  <div className='profile__subscription_text'>
                    Purchased Date:{' '}
                    <span className='profile__subscription_color'>{`${moment
                      .unix(subscriptionDetails?.data?.current_period_start)
                      .format('dddd, MMMM Do, YYYY')}`}</span>
                  </div>
                  <div className='profile__subscription_text'>
                    Next Billing Date:{' '}
                    <span className='profile__subscription_color'>{`${moment
                      .unix(subscriptionDetails?.data?.current_period_end)
                      .format('dddd, MMMM Do, YYYY')}`}</span>{' '}
                  </div>
                  <div className='subscription_btn_container'>
                    {/* <a
                      href={subscriptionDetails?.data?.invoice_pdf_url}
                      download
                      className='send_notification_btn'
                      style={{ textDecoration: 'none' }}>
                      Download Invoice
                      <img
                        src='/images/download_icon.png'
                        alt='notification icon'
                        className='send_notification_btn_icon'
                        style={{ marginLeft: '0.5rem' }}
                      />
                    </a> */}

                    {subscriptionDetails?.data?.status === 'active' &&
                    subscriptionDetails?.data?.currentSubscription
                      ?.cancel_at_period_end === true ? (
                      <button
                        className='send_notification_btn'
                        onClick={subscriptionHandler.bind(
                          this,
                          subscriptionDetails?.data
                        )}>
                        Reactivate
                      </button>
                    ) : (
                      <>
                        {subscriptionDetails?.data?.currentSubscription
                          ?.cancel_at_period_end === false &&
                        subscriptionDetails?.data?.status === 'active' ? (
                          <button
                            className='send_notification_btn'
                            onClick={cancelSubscriptionHandler.bind(
                              this,
                              subscriptionDetails?.data
                            )}>
                            Cancel
                          </button>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {subscriptionDetails?.data?.status === 'canceled' ? (
                    <div className='profile__password'>
                      <h2 className='profile__heading'>
                        Subscription Information
                      </h2>
                      <h2
                        className='profile__subscription_text'
                        style={{ color: 'red' }}>
                        You do not have any subscriptions
                      </h2>
                    </div>
                  ) : (
                    <div className='profile__password'>
                      <h2 className='profile__heading'>
                        Subscription Information
                      </h2>
                      <h2
                        className='profile__subscription_text'
                        style={{ color: 'red' }}>
                        {subscriptionDetails?.message}
                      </h2>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <AppModal2 show={showDeleteModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Cancel Subscription</p>
          <p className='form_header_delete_text'>
            Are you sure you want to cancel the subscription?
          </p>

          <div className='modal_buttons'>
            <AppButton title='delete' type='submit' onClick={cancelHandler} />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={() => {
                setCurrentItem(null)
                setShowDeleteModal(false)
              }}
            />
          </div>
        </div>
      </AppModal2>
      <AppModal2 show={showReactivateModal}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Reactivate Subscription</p>
          <p className='form_header_delete_text'>
            Are you sure you want to reactivate the subscription?
          </p>

          <div className='modal_buttons'>
            <AppButton
              title='Reactivate'
              type='submit'
              onClick={renewSubscriptionHandler}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={() => {
                setCurrentItem(null)
                setShowReactivateModal(false)
              }}
            />
          </div>
        </div>
      </AppModal2>
    </div>
  )
}

export default MyProfile

{
  /* <div className='profile__content2'>
              <div
                className='login_input_container'
                style={{ padding: '0', marginTop: '0' }}>
                <TextInput
                  placeholder='Current Password'
                  type='password'
                  src='/images/key.png'
                  value={passwords.currentPassword}
                  onChange={(e) => {
                    setPasswordsError({
                      ...passwordsError,
                      currentPasswordError: '',
                    })
                    setPasswords({
                      ...passwords,
                      currentPassword: e.target.value,
                    })
                  }}
                  error={passwordsError.currentPasswordError}
                  secure
                />
                <TextInput
                  placeholder='New Password'
                  type='password'
                  src='/images/key.png'
                  value={passwords.newPassword}
                  onChange={(e) => {
                    setPasswordsError({
                      ...passwordsError,
                      newPasswordError: '',
                    })
                    setPasswords({
                      ...passwords,
                      newPassword: e.target.value,
                    })
                  }}
                  error={passwordsError.newPasswordError}
                  secure
                />
                <TextInput
                  placeholder='Confirm Password'
                  type='password'
                  src='/images/key.png'
                  value={passwords.confirmPassword}
                  onChange={(e) => {
                    setPasswordsError({
                      ...passwordsError,
                      confirmPasswordError: '',
                    })
                    setPasswords({
                      ...passwords,
                      confirmPassword: e.target.value,
                    })
                  }}
                  error={passwordsError.confirmPasswordError}
                  secure
                />
                <button
                  className='send_notification_btn'
                  onClick={handleSubmit}>
                  Change Password
                </button>
              </div>
            </div> */
}
