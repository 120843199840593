import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react'
import styles from './Payment.module.css'
import { gsap } from 'gsap'
import {
  CardElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import styled from 'styled-components'
import 'react-credit-cards/es/styles-compiled.css'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { baseUrl } from '../../../middleware/url'
import Loader from 'react-js-loader'
import { getPlans, clearGetPlans } from '../../../store/onBoarding/getPlans'
import {
  verfiyCoupon,
  clearVerfiyCouponResponse,
} from '../../../store/onBoarding/verifyCoupon'
import {
  confirmPayment,
  clearConfirmPayment,
} from '../../../store/onBoarding/paymentConfirmation'

let CardInputWrapper = styled.div`
  background: #313e7682;
  height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
`

const Payment = ({ userMobile, clientType }) => {
  const [amount, setAmount] = useState(null)
  const [selected, setSelected] = useState(null)
  const [index, setIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pay, setPay] = useState(false)

  const [state, setState] = useState({
    name: '',
    issuer: '',
  })

  const container = useRef(null)
  const heading = useRef(null)
  const subheading = useRef(null)
  const title = useRef(null)
  const paymentContainer = useRef(null)
  const backButton = useRef(null)
  const subscription = useRef(null)
  const couponContainer = useRef(null)

  const coupontitle = useRef(null)

  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const history = useHistory()
  const modal = useRef(null)
  const modal2 = useRef(null)
  const [coupon, setCoupon] = useState('')
  const [isVerified, setIsVerified] = useState(false)
  const [touched, setTouched] = useState(false)
  const [showCoupon, setShowCoupon] = useState(false)
  const [showCalc, setShowCalc] = useState(false)
  const [calcData, setCalcData] = useState(null)

  const { plansList, loading: planLoading } = useSelector(
    (plans) => plans.getPlans
  )

  const { verfiyCouponResponse, loading: couponLoading } = useSelector(
    (coupon) => coupon.verifyCoupon
  )

  const { confirmPaymentReponse } = useSelector(
    (pay) => pay.paymentConfirmation
  )

  if (window.screen.width < 575) {
    CardInputWrapper = styled.div`
      background: #313e7682;
      height: 34px;
      border-radius: 8px;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
    `
  } else if (window.screen.width > 767 && window.screen.width < 991.98) {
    CardInputWrapper = styled.div`
      background: #313e7682;
      height: 42px;
      border-radius: 8px;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
    `
  }

  useEffect(() => {
    if (confirmPaymentReponse) {
      // if (confirmPaymentReponse.status) {
      //   toast.success(confirmPaymentReponse.message)
      //   dispatch(clearConfirmPayment())
      //   gotToCongrats()
      // } else {
      //   toast.error(confirmPaymentReponse.message)
      //   dispatch(clearConfirmPayment())
      // }
    }

    return () => {
      dispatch(clearConfirmPayment())
      dispatch(clearVerfiyCouponResponse())
    }
  }, [confirmPaymentReponse])

  useEffect(() => {
    if (verfiyCouponResponse) {
      if (verfiyCouponResponse?.status && amount) {
        if (
          typeof verfiyCouponResponse?.data?.coupon?.percent_off === 'number'
        ) {
          const currentAmount = amount

          let newAmount =
            currentAmount -
            currentAmount *
              (verfiyCouponResponse?.data?.coupon?.percent_off / 100)

          if (newAmount < 0) {
            newAmount = 0
          }

          setSelected({
            ...selected,
            amount: newAmount,
          })

          setCalcData({
            discount: verfiyCouponResponse.data.coupon.percent_off,
            id: verfiyCouponResponse.data.id,
          })
        }
        toast.success(verfiyCouponResponse.message)

        setIsVerified(true)
        setShowCalc(true)

        dispatch(clearVerfiyCouponResponse())
      } else if (!verfiyCouponResponse.status) {
        setCalcData(null)
        setShowCalc(false)
        toast.error(verfiyCouponResponse.message)
        dispatch(clearVerfiyCouponResponse())
      }
    }
  }, [verfiyCouponResponse])

  const clearCouponHandler = () => {
    setCoupon('')
    setIsVerified(false)
    setSelected({
      ...selected,
      amount: amount,
    })
    setTouched(true)
    setShowCalc(false)
    setCalcData(null)
  }

  const amountHandler = (item, index) => {
    if (item) {
      setAmount(item?.details?.unit_amount / 100)
      setIsVerified(false)
      setCoupon('')
      setSelected({
        ...item,
        amount: item?.details?.unit_amount / 100,
      })
      setIndex(index)

      if (item?.details?.unit_amount / 100 === 0) {
        setShowCoupon(false)
        setShowCalc(false)
        setCalcData(null)
      } else {
        setShowCoupon(true)
      }
    }
    setIsVerified(true)
    setCalcData(null)
  }

  useLayoutEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      gsap.set(container.current, { opacity: 0, overflowX: 'hidden' })

      let timeline = gsap.timeline({ defaults: { opacity: 0 } })

      timeline
        .to(container.current, { opacity: 1 })

        .from(heading.current, {
          xPercent: -100,
          ease: 'power3.out',
          duration: 0.5,
        })

        .from(subscription.current, {
          xPercent: -100,
          ease: 'power3.out',
          duration: 0.5,
        })
    }

    return () => (isSubscribed = false)
  }, [])

  useLayoutEffect(() => {
    let isSubscribed = true

    if (
      selected !== null &&
      isSubscribed &&
      !isVerified &&
      coupon === '' &&
      !touched
    ) {
      let timeline = gsap.timeline({ defaults: { opacity: 0 } })

      timeline
        .from(coupontitle.current, {
          xPercent: 100,
          ease: 'power3.out',
          duration: 0.5,
        })
        .fromTo(
          couponContainer.current,
          {
            opacity: 0,
            yPercent: 100,
            duration: 0.5,
            scale: 0,
            display: 'none',
          },
          {
            opacity: 1,
            yPercent: 0,
            scale: 1,
            ease: 'power3.out',
            display: 'block',
            duration: 0.5,
          },
          '<'
        )
    }

    return () => (isSubscribed = false)
  }, [selected])

  useLayoutEffect(() => {
    let isSubscribed = true

    if (isVerified && isSubscribed) {
      let timeline = gsap.timeline({ defaults: { opacity: 0 } })

      timeline
        .from(title.current, {
          xPercent: 100,
          ease: 'power3.out',
          duration: 0.5,
        })
        .fromTo(
          paymentContainer.current,
          {
            opacity: 0,
            yPercent: 100,
            duration: 0.5,
            scale: 0,
            display: 'none',
          },
          {
            opacity: 1,
            yPercent: 0,
            scale: 1,
            ease: 'power3.out',
            display: 'block',
            duration: 0.5,
          },
          '<'
        )
    }

    return () => (isSubscribed = false)
  }, [isVerified])

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      if (clientType) {
        dispatch(getPlans(clientType))
      } else {
        dispatch(getPlans('user'))
      }
      dispatch(clearVerfiyCouponResponse())
      dispatch(clearConfirmPayment())
    }

    return () => (isSubscribed = false)
  }, [userMobile, clientType])

  const freeCouponModalHandler = () => {
    modal.current.style.display = 'block'
  }

  const payConfirmHandler = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (!error) {
      try {
        modal.current.style.display = 'block'
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      toast.error(error.message)
    }
  }

  const closePayHandler = () => {
    modal.current.style.display = 'none'
  }

  const freeSubmitHandler = (event) => {
    event.preventDefault()

    try {
      setLoading(true)

      var config = {
        method: 'post',
        url: `${baseUrl}/user/add-onboard-data?offset=-360`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          userEmail: data.userEmail,
          userMobile: data.userMobile,
          userName: data.userName,
          userRole: data.clientType === 'user' ? 'User' : 'Client',
          clientType: data.clientType === 'user' ? '' : data.clientType,
          amount: selected.amount,
          source: undefined,
        },
      }

      axios(config)
        .then(function (response) {
          setLoading(false)
          if (response.data && response.data.status) {
            toast.success(response.data.message)
            gotToCongrats()
          } else {
            toast.error(response.data.message)
            closePayHandler()
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    } catch (error) {}
  }

  const reset = () => {
    setIsVerified(false)

    setShowCoupon(true)
    setShowCalc(false)
    setCalcData(null)
    setCoupon('')

    setSelected({
      ...selected,
      amount: amount,
    })
  }

  const submitHandler = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (!error) {
      try {
        setLoading(true)

        const d = new Date()
        let offset = d.getTimezoneOffset()
        const { id } = paymentMethod

        var config = {
          method: 'post',
          url: `${baseUrl}/user/repurchase-subscription?offset=${offset}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            userMobile: userMobile,
            source: id,
            plan: selected?.details?.id,
            promotion_code: calcData?.id ? calcData?.id : '',
          },
        }

        axios(config)
          .then(function (response) {
            setLoading(false)
            closePayHandler()
            if (response.data && response.data.status) {
              const { client_secret, paymentStatus, status, message } =
                response.data

              if (paymentStatus === 'requires_action') {
                stripe
                  .confirmCardPayment(client_secret)
                  .then(function (result) {
                    // modal2.current.style.display = 'block'

                    if (result.error) {
                      closePayHandler()

                      dispatch(confirmPayment(data.userMobile, 'fail'))

                      // The card was declined (i.e. insufficient funds, card has expired, etc)
                      toast.error(result.error.message)
                      closePayHandler()
                      reset()
                    } else {
                      // modal2.current.style.display = 'none'

                      closePayHandler()

                      toast.success('Your payment is successful')
                      history.push('/')
                      dispatch(confirmPayment(data.userMobile, 'success'))
                    }
                  })
              } else if (paymentStatus === 'fail') {
                // modal2.current.style.display = 'none'

                reset()
                closePayHandler()
              } else {
                // modal2.current.style.display = 'none'
                closePayHandler()

                toast.success('Your payment is successful')
                history.push('/')
              }
            } else {
              reset()
              // modal2.current.style.display = 'none'

              toast.error(response.data.message)
              closePayHandler()
            }
          })
          .catch(function (error) {
            closePayHandler()
            setLoading(false)
          })
      } catch (error) {
        closePayHandler()
      }
    } else {
      closePayHandler()
      toast.error(error.message)
    }
  }

  const Card = ({ item, i }) => {
    const planCard = useRef(null)

    useLayoutEffect(() => {
      let isSubscribed = true

      if (selected === null && isSubscribed) {
        let timeline = gsap.timeline({ defaults: { opacity: 0 } })

        timeline.from(planCard.current, {
          xPercent: -100,
          stagger: 0.5,
          ease: 'power3.out',
          duration: 0.5,
          delay: 2,
        })
      }

      return () => (isSubscribed = false)
    }, [])

    return (
      <div
        ref={planCard}
        className={styles.planCard}
        style={{
          border: index !== null && index === i ? '2px solid white' : 'none',
        }}>
        <div className={styles.planCardContainer}>
          <div className={styles.planCardTitle}>{item?.name}</div>
          <div className={styles.priceContainer}>
            <div className={styles.price}>{`${item?.currency}${
              item?.details?.unit_amount / 100
            }`}</div>
            <div className={styles.italicLine}></div>
            <div className={styles.perMonth}>
              {item?.details?.recurring?.interval}
            </div>
          </div>
          <div className={styles.planCardDesc}>{item.tagLine}</div>
          <div className={styles.tileContainer}>
            {item.features.map((item, i) => {
              return (
                <div className={styles.tiles} key={i}>
                  <div className={styles.cardIconWrapper}>
                    <svg className={styles.icon2}>
                      <use xlinkHref={`/images/sprite.svg#icon-checkmark2`} />
                    </svg>
                  </div>

                  <div className={styles.tileText}>{item}</div>
                </div>
              )
            })}
          </div>
        </div>

        <div
          className={styles.planCardButton}
          onClick={amountHandler.bind(this, item, i)}>
          <div>Get Started</div>
        </div>
      </div>
    )
  }

  const logoutHandler = () => {
    history.push('/')
  }

  const couponVerificationhandler = () => {
    if (coupon !== '') {
      dispatch(verfiyCoupon(coupon))
    } else if (coupon === '') {
      toast.error('Please enter coupon code')
    }
  }

  const skipVerificationHandler = () => {
    setIsVerified(true)
  }

  return (
    <div
      style={{
        backgroundImage:
          'linear-gradient(to bottom, rgba(11, 26, 91, .58), rgba(11, 26, 91, .58)), url(/assets/child_background.png)',
      }}
      className={styles.container}
      ref={container}>
      <div className={styles.topContainer}>
        <div className={styles.topButtonContainer}>
          {/* <div className={styles.backButton} ref={backButton}>
            <svg
              className={styles.icon}
              style={{ cursor: 'pointer' }}
              onClick={goback}>
              <use xlinkHref={`/images/sprite.svg#icon-backButton`} />
            </svg>
          </div> */}
          <div className={styles.logout} onClick={logoutHandler}>
            <svg className={styles.logouticon} style={{ cursor: 'pointer' }}>
              <use xlinkHref={`/images/sprite.svg#icon-logout3`} />
            </svg>
          </div>
        </div>
        <div className={styles.headerContainer}>
          <h1 className={styles.heading} ref={heading}>
            Buy Subscription
          </h1>
        </div>

        <div className={styles.paymentContainer}>
          <div className={styles.paymentSubContainer2}>
            <div className={styles.title} ref={subscription}>
              Choose a subscription
            </div>
            <div className={styles.subscription}>
              {plansList &&
                plansList.data &&
                plansList.data.map((item, i) => {
                  return <Card key={i} item={item} i={i} />
                })}
            </div>
          </div>

          <div className={styles.paymentSubContainer1}>
            {selected !== null && (
              <div ref={couponContainer} className={styles.couponContainer}>
                {showCoupon && (
                  <>
                    <div className={styles.title} ref={coupontitle}>
                      Have a coupon code ?
                    </div>
                    <div className={styles.inputSuperContainer}>
                      <div className={styles.inputContainer}>
                        <input
                          value={coupon}
                          type='text'
                          className={styles.input}
                          placeholder='Enter coupon code'
                          onChange={(event) => {
                            setCoupon(event.target.value)
                            setIsVerified(false)
                            setSelected({
                              ...selected,
                              amount: amount,
                            })
                            setTouched(true)
                            setCalcData(null)
                            setShowCalc(false)
                          }}
                        />
                        <svg
                          className={styles.iconclear}
                          onClick={clearCouponHandler}>
                          <use xlinkHref={`/images/sprite.svg#icon-clear`} />
                        </svg>
                      </div>
                      {selected && (
                        <>
                          <button
                            className={styles.button}
                            onClick={couponVerificationhandler}>
                            <p>Apply</p>
                            <div className={styles.line}></div>
                          </button>
                          {/* <button
                            className={styles.skip}
                            onClick={skipVerificationHandler}>
                            <p>Skip</p>
                            <div className={styles.line}></div>
                          </button> */}

                          {couponLoading && (
                            <div className={styles.couponLoader}>
                              <Loader
                                type='spinner-default'
                                bgColor={'#FFFFFF'}
                                color={'#FFFFFF'}
                                size={window.screen.width > 575 ? 40 : 20}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}

                {showCalc && calcData && amount && selected && (
                  <div className={styles.calculationsContainer}>
                    <div className={styles.calculationsSection}>
                      <div className={styles.originalText}>
                        Original Amount{' '}
                      </div>
                      <div className={styles.discountText}>
                        Discount ({calcData.discount}% OFF )
                      </div>
                      <div className={styles.payableText}>Payable Amount</div>
                    </div>
                    <div className={styles.calculationsSection2}>
                      <div
                        className={styles.originalText}
                        style={{ color: 'white' }}>
                        {amount.toFixed(2)} $
                      </div>
                      <div className={styles.discountText}>
                        {(amount * (Number(calcData.discount) / 100)).toFixed(
                          2
                        )}{' '}
                        $
                      </div>
                      <div
                        className={styles.payableText}
                        style={{ color: 'white' }}>
                        {selected.amount.toFixed(2)} $
                      </div>
                    </div>
                  </div>
                )}

                {/* {!showCoupon && (
                  <div
                    className={styles.title}
                    style={{ textTransform: 'none' }}
                    ref={coupontitle}>
                    Press finish button to complete your registration
                  </div>
                )} */}

                {/* <div className={styles.buttonContainer}>
                  {selected && selected.amount !== 0 ? null : ( // </button> //   <div className={styles.line}></div> //   <p>Apply</p> //   onClick={couponVerificationhandler}> //   className={styles.button} // <button
                    <button
                      className={styles.button}
                      onClick={freeCouponModalHandler}>
                      <p>Finish</p>
                      <div className={styles.line}></div>
                    </button>
                  )}
                </div> */}
              </div>
            )}
            {selected && (
              // selected.amount !== 0 &&
              <div>
                <div
                  className={styles.title}
                  ref={title}
                  style={{ marginTop: '2rem' }}>
                  PAYMENT INFORMATION
                </div>
                <div className={styles.formContainer} ref={paymentContainer}>
                  <form className={styles.form} onSubmit={payConfirmHandler}>
                    <CardInputWrapper>
                      <CardNumberElement
                        onChange={(event) => {}}
                        options={{
                          showIcon: true,
                          style: {
                            base: {
                              iconColor: '#ffffff',
                              color: '#ffffff',
                              fontWeight: '500',
                              fontFamily:
                                ' Roboto, Open Sans, Segoe UI, sans-serif Raleway',
                              fontSize: '14px',
                              fontSmoothing: 'antialiased',
                              padding: '25px',
                              ':-webkit-autofill': {
                                color: '#aa9999',
                              },
                              '::placeholder': {
                                color: '#aa9999',
                                fontWeight: '500',
                              },
                            },
                          },
                        }}
                      />
                    </CardInputWrapper>
                    <div className={styles.dateContainer}>
                      <div className={styles.date}>
                        <CardInputWrapper>
                          <CardExpiryElement
                            options={{
                              showIcon: true,
                              style: {
                                base: {
                                  iconColor: '#ffffff',
                                  color: '#ffffff',
                                  fontWeight: '500',
                                  textAlign: 'center',
                                  fontFamily:
                                    ' Roboto, Open Sans, Segoe UI, sans-serif Raleway',
                                  fontSize: '14px',
                                  fontSmoothing: 'antialiased',
                                  padding: '25px',
                                  ':-webkit-autofill': {
                                    color: '#aa9999',
                                  },
                                  '::placeholder': {
                                    color: '#aa9999',
                                    fontWeight: '500',
                                  },
                                },
                              },
                            }}
                          />
                        </CardInputWrapper>
                      </div>

                      <div className={styles.date}>
                        <CardInputWrapper>
                          <CardCvcElement
                            options={{
                              showIcon: true,
                              style: {
                                base: {
                                  iconColor: '#ffffff',
                                  color: '#ffffff',
                                  fontWeight: '500',
                                  textAlign: 'center',
                                  fontFamily:
                                    ' Roboto, Open Sans, Segoe UI, sans-serif Raleway',
                                  fontSize: '14px',
                                  fontSmoothing: 'antialiased',
                                  padding: '25px',
                                  ':-webkit-autofill': {
                                    color: '#aa9999',
                                  },
                                  '::placeholder': {
                                    color: '#aa9999',
                                    fontWeight: '500',
                                  },
                                },
                                invalid: {
                                  iconColor: '#FFC7EE',
                                  color: '#FFC7EE',
                                },
                              },
                            }}
                          />
                        </CardInputWrapper>
                      </div>
                    </div>

                    <div className={styles.buttonContainer}>
                      <button type='submit' className={styles.button}>
                        <p>Pay</p>
                        <div className={styles.line}></div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.modalContainer} ref={modal}>
        <div className={styles.modal}>
          <div className={styles.confirmCardContainer}>
            {loading ? (
              <div className={styles.loadingContainer}>
                <Loader
                  type='bubble-top'
                  bgColor={'#FFFFFF'}
                  color={'#FFFFFF'}
                  size={20}
                />
                <div className={styles.loaderText1}>Please wait ....</div>
                <div className={styles.loaderText1}>
                  Do not press back button or refresh page
                </div>
              </div>
            ) : (
              <div className={styles.confirmCard}>
                <div className={styles.confirmCardHeader}>
                  Payment Confirmation
                </div>
                <div className={styles.confirmCardcontent}>
                  <div className={styles.confirmCardTopCantainer}>
                    <div className={styles.confirmCardTag1}>
                      <div className={styles.confirmCardText}>
                        Selected Plan
                      </div>
                      <div
                        className={
                          styles.confirmCardTextBold
                        }>{`${selected?.name}`}</div>
                    </div>
                    <div className={styles.confirmCardTag1}>
                      <div className={styles.confirmCardText}>Total Amount</div>
                      <div className={styles.confirmCardTextBold}>{`${
                        selected?.currency
                      }${selected?.amount.toFixed(2)}`}</div>
                    </div>
                    <div className={styles.confirmCardDesc}>
                      Would you like to proceed with the above selected plan?
                    </div>
                  </div>

                  {selected && selected.amount === 0 ? (
                    <div
                      className={styles.confirmCardButton}
                      onClick={submitHandler}>
                      Confirm
                    </div>
                  ) : (
                    <div
                      className={styles.confirmCardButton}
                      onClick={submitHandler}>
                      Confirm
                    </div>
                  )}
                  <div className={styles.confirmCardButtonClose}>
                    <span className={styles.close} onClick={closePayHandler}>
                      Close
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.modalContainer} ref={modal2}>
        <div className={styles.modal}>
          <div className={styles.confirmCardContainer}>
            <div className={styles.loadingContainer}>
              <Loader
                type='bubble-top'
                bgColor={'#FFFFFF'}
                color={'#FFFFFF'}
                size={20}
              />
              <div className={styles.loaderText1}>Please wait ....</div>
              <div className={styles.loaderText1}>
                Do not press back button or refresh page
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
