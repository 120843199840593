import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoChevronBackSharp } from 'react-icons/io5'
import styles from './audit.module.css'
import AppHeader from '../../../../components/AppHeader'
import {
  getAuditList,
  clearGetAuditListResponse,
} from '../../../../store/users/getKidAudit'

import moment from 'moment'
import Pagination from '../../../../components/Pagination'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'

const currentDate = moment(new Date()).format('MM/DD/YYYY')

const yesterday = moment(new Date()).subtract(1, 'days').format('MM/DD/YYYY')

const lastweekday = moment(new Date()).subtract(7, 'days').format('MM/DD/YYYY')

const audit = ({ match, history }) => {
  const { id } = match.params

  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState('')

  const pageSize = 100

  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)

  const { auditList, loading } = useSelector((state) => state.getKidAudit)

  useEffect(() => {
    if (id) {
      dispatch(
        getAuditList(id, {
          filter: '',
          pageNumber,
          pageSize,
        })
      )
    }
  }, [])

  const getTime = (messageDate) => {
    const checkDate = moment(messageDate).format('MM/DD/YYYY')

    const time = moment(messageDate).format('h:mm a')

    const day = moment(messageDate).format('dddd')

    const formatedDate = moment(messageDate).format('DD MMMM')

    if (checkDate.toString() === currentDate.toString()) {
      return `Today ${time}`
    } else if (checkDate.toString() === yesterday.toString()) {
      return `${day} ${time}`
    } else if (
      checkDate.toString() < yesterday.toString() &&
      checkDate.toString() > lastweekday.toString()
    ) {
      return `${day}`
    } else {
      return formatedDate
    }
  }

  const onPageChange = (page) => {
    const selectedPage = page.selected

    dispatch(
      getAuditList(id, {
        filter: '',
        pageNumber: selectedPage + 1,
        pageSize,
      })
    )
    setPageNumber(selectedPage + 1)
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Audit' searchPlaceholder='Search Kid' />
      <div className='back_button' onClick={() => history.goBack()}>
        <IoChevronBackSharp />
        <p className='label'>back</p>
      </div>
      {loading ? (
        <TableSkeleton style={{ marginTop: '-1rem' }} />
      ) : (
        <div className='client__container' style={{ marginTop: '-1rem' }}>
          <div className='missions_main_content'>
            <div className={styles.table_box}>
              <table className={styles.table_content}>
                <thead>
                  <tr>
                    <th>Message</th>
                    <th>Generic</th>
                    <th>Mission</th>
                    <th>Space</th>
                    <th>Property</th>
                    <th>Question</th>
                    <th>Certificate</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {auditList &&
                    auditList.data &&
                    auditList.data.result &&
                    Array.isArray(auditList.data.result) &&
                    auditList.data.result.map((item, index) => {
                      return (
                        <tr key={item._id + index}>
                          <td>{item?.actionMessage}</td>
                          <td>N/A</td>
                          <td>
                            {item.action === 'mission'
                              ? item.mission?.title
                              : 'N/A'}
                          </td>
                          <td>
                            {item.action === 'space'
                              ? item.space?.title
                              : 'N/A'}
                          </td>
                          <td>
                            {item.action === 'property'
                              ? item.properties?.propertyName
                              : 'N/A'}
                          </td>
                          <td>
                            {item.action === 'question'
                              ? item.question?.question
                              : 'N/A'}
                          </td>
                          <td>N/A</td>
                          <td>{getTime(item?.unixTimeStamp)}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {auditList &&
        auditList.data &&
        auditList.data.result &&
        auditList.data.result.length > 0 &&
        auditList.data.totalPageSize > pageSize && (
          <Pagination
            list={auditList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
    </div>
  )
}

export default audit
