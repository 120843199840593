import React, { Fragment, useState } from 'react'
import EmogiPicker from '../../../../../components/EmogiPicker'
import TextInput from '../../../../../components/forms/TextInput'

const MatchPair = ({
  pairAOptions = [],
  pairBOptions = [],
  setPairAOptions,
  setPairBOptions,
  errorMessage,
  onRemoveError,
}) => {
  const [pairA, setPairA] = useState('')
  const [pairB, setPairB] = useState('')
  const [pairAError, setPairAError] = useState('')
  const [pairBError, setPairBError] = useState('')

  const [isEdit, setIsEdit] = useState(null)
  const [pairAEdit, setPairAEdit] = useState('')
  const [pairBEdit, setPairBEdit] = useState('')

  const [displayEmogiesA, setDisplayEmogiesA] = useState(false)
  const [displayEmogiesB, setDisplayEmogiesB] = useState(false)
  const [emogiA, setEmogiA] = useState(false)
  const [emogiB, setEmogiB] = useState(false)

  const onAddMatchPairHAndler = () => {
    onRemoveError()
    if (pairA === '') {
      setPairAError('Please enter Pair A option')
    } else if (pairB === '') {
      setPairBError('Please enter Pair A option')
    } else {
      setPairAOptions([...pairAOptions, pairA])
      setPairBOptions([...pairBOptions, pairB])
      setPairA('')
      setPairB('')
    }
  }

  const onEditHandler = (i) => {
    setIsEdit(i)
    setPairAEdit(pairAOptions.find((_, index) => index === i))
    setPairBEdit(pairBOptions.find((_, index) => index === i))
  }

  const onEditCancelHandler = () => {
    setIsEdit(null)
  }

  const onOptionDeleteHandler = (i) => {
    setPairAOptions(pairAOptions.filter((_, index) => index !== i))
    setPairBOptions(pairBOptions.filter((_, index) => index !== i))
  }

  const onSetEdit = (index) => {
    setIsEdit(null)
    const dataA = JSON.parse(JSON.stringify(pairAOptions))
    const dataB = JSON.parse(JSON.stringify(pairBOptions))
    dataA[index] = pairAEdit
    dataB[index] = pairBEdit
    setPairAOptions(dataA)
    setPairBOptions(dataB)
  }

  return (
    <Fragment>
      <div className='flex' style={{ gap: '2rem' }}>
        <Fragment>
          <TextInput
            placeholder='Pair A'
            src='/images/question_answer.png'
            onChange={(mission) => {
              setPairAError('')
              onRemoveError()
              setPairA(mission.target.value)
            }}
            value={pairA}
            error={pairAError}
            inputStyles={{
              width: '12rem',
            }}
          />
          <div
            className='emogi_wrapper'
            onClick={() => setDisplayEmogiesA(!displayEmogiesA)}>
            <p>😊</p>
          </div>
        </Fragment>
        <Fragment>
          <TextInput
            placeholder='Pair B'
            src='/images/question_answer.png'
            onChange={(mission) => {
              setPairBError('')
              onRemoveError()
              setPairB(mission.target.value)
            }}
            value={pairB}
            error={pairBError}
            inputStyles={{
              width: '12rem',
            }}
          />
          <div
            className='emogi_wrapper'
            onClick={() => setDisplayEmogiesB(!displayEmogiesA)}>
            <p>😊</p>
          </div>
        </Fragment>
        <div className='addBtn' onClick={onAddMatchPairHAndler}>
          <img
            src='/images/add_blue.png'
            alt='add icon'
            className='addBtnIcon'
          />
        </div>
      </div>
      <div className='flex' style={{ justifyContent: 'space-between' }}>
        <div className='answer'>
          <div className='answer_textBox' style={{ width: '100%' }}>
            <p className='answer_text'>Pair A</p>
          </div>
        </div>
        <div className='answer'>
          <div className='answer_textBox' style={{ width: '100%' }}>
            <p className='answer_text'>Pair B</p>
          </div>
        </div>
        <div className='answer'>
          <p style={{ visibility: 'hidden', fontSize: '1.2rem' }}>Actions </p>
        </div>
      </div>
      <div className='flex' style={{ justifyContent: 'space-between' }}>
        <div className='answer' style={{ flexDirection: 'column' }}>
          {displayEmogiesA && (
            <EmogiPicker
              onClose={() => setDisplayEmogiesA(false)}
              setEmogi={(emogi) => setPairA((prev) => prev + emogi)}
            />
          )}
          {displayEmogiesB && (
            <EmogiPicker
              onClose={() => setDisplayEmogiesB(false)}
              setEmogi={(emogi) => setPairB((prev) => prev + emogi)}
            />
          )}
          {pairAOptions &&
            pairAOptions.length > 0 &&
            pairAOptions.map((pairAOption, pairAIndex) => (
              <Fragment>
                <div
                  className='answer_textBox'
                  style={{ width: '100%' }}
                  key={pairAIndex}>
                  {isEdit !== null && isEdit === pairAIndex ? (
                    <Fragment>
                      <input
                        type='text'
                        className='answer_text_input'
                        autoFocus
                        value={pairAEdit}
                        onChange={(a) => setPairAEdit(a.target.value)}
                      />
                      <div
                        className='edit_emogi'
                        onClick={() => setEmogiA(!emogiA)}>
                        <p>😊</p>
                      </div>
                    </Fragment>
                  ) : (
                    <p className='answer_text'>{pairAOption}</p>
                  )}
                </div>
                {isEdit === pairAIndex && emogiA && (
                  <EmogiPicker
                    onClose={() => setEmogiA(false)}
                    setEmogi={(emogi) => setPairAEdit((prev) => prev + emogi)}
                  />
                )}
              </Fragment>
            ))}
        </div>

        <div className='answer' style={{ flexDirection: 'column' }}>
          {pairBOptions &&
            pairBOptions.length > 0 &&
            pairBOptions.map((pairBOption, pairBIndex) => (
              <Fragment>
                <div
                  className='answer_textBox'
                  style={{ width: '100%' }}
                  key={pairBIndex}>
                  {isEdit !== null && isEdit === pairBIndex ? (
                    <Fragment>
                      <input
                        type='text'
                        className='answer_text_input'
                        value={pairBEdit}
                        onChange={(b) => setPairBEdit(b.target.value)}
                      />
                      <div
                        className='edit_emogi'
                        onClick={() => setEmogiB(!emogiB)}>
                        <p>😊</p>
                      </div>
                    </Fragment>
                  ) : (
                    <p className='answer_text'>{pairBOption}</p>
                  )}
                </div>
                {isEdit === pairBIndex && emogiB && (
                  <EmogiPicker
                    onClose={() => setEmogiB(false)}
                    setEmogi={(emogi) => setPairBEdit((prev) => prev + emogi)}
                  />
                )}
              </Fragment>
            ))}
        </div>
        {pairAOptions &&
          pairAOptions.length > 0 &&
          pairBOptions &&
          pairBOptions.length > 0 && (
            <div
              className='answer'
              style={{ flexDirection: 'column', gap: '3rem' }}>
              {pairBOptions.map((_, i) => (
                <div className='flex' key={i}>
                  {isEdit !== null && isEdit === i ? (
                    <div className='flex'>
                      <img
                        src='/images/icon_tick.png'
                        alt='right tick'
                        className='answer_text_icon'
                        onClick={() => onSetEdit(i)}
                      />
                      <img
                        src='/images/icon_delete.png'
                        alt='right tick'
                        className='answer_text_icon'
                        onClick={onEditCancelHandler}
                      />
                    </div>
                  ) : (
                    <Fragment>
                      <img
                        src='/images/icon_edit.png'
                        alt='right tick'
                        className='answer_text_icon'
                        onClick={() => onEditHandler(i)}
                      />
                      <img
                        src='/images/delete.png'
                        alt='right tick'
                        className='answer_text_icon'
                        onClick={() => onOptionDeleteHandler(i)}
                      />
                    </Fragment>
                  )}
                </div>
              ))}
            </div>
          )}
      </div>
      <p className='input_error_text'>{errorMessage}</p>
    </Fragment>
  )
}

export default MatchPair
