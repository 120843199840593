import axios from 'axios';
import {
	GET_ABOUT_DATA_REQUEST,
	GET_ABOUT_DATA_SUCCESS,
	GET_ABOUT_DATA_FAIL,
	GET_PRIVACY_POLICY_REQUEST,
	GET_PRIVACY_POLICY_SUCCESS,
	GET_PRIVACY_POLICY_FAIL
} from '../constants/aboutConstants';
import { baseUrl } from '../../../middleware/url';

export const getAboutDataAction = (type = 'algo') => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ABOUT_DATA_REQUEST });
		const { credentials: { credentials } } = getState();

		const config = {
			headers: {
				token: credentials.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${baseUrl}/generic/get-about-info/${type}`, config);

		dispatch({
			type: GET_ABOUT_DATA_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ABOUT_DATA_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getPrivacyPolicyAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_PRIVACY_POLICY_REQUEST });
		const { credentials: { credentials } } = getState();

		const config = {
			headers: {
				token: credentials.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${baseUrl}/privacy/get-privacy-content`, config);

		dispatch({
			type: GET_PRIVACY_POLICY_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_PRIVACY_POLICY_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
