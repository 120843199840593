import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import AppHeader from '../../../../components/AppHeader'
import PropertyCard from '../../../../components/Cards/PropertyCard'
import AppModal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'
import ProgressBar from '../../../../components/forms/ProgressBar'
import ImageUploader from '../../../../components/forms/ImageUploader'
import AppButton from '../../../../components/forms/AppButton'
import AllPropertiesSkeleton from '../../../../components/Skeletons/AllPropertiesSkeleton'
import TextInput from '../../../../components/forms/TextInput'
import Pagination from '../../../../components/Pagination'

import { getColorList } from '../../../../store/colors/colorList'
import { getPropertyListById } from '../../../../store/properties/getPropertiesById'
import { getAllPropertyList } from '../../../../store/properties/getAllProperties'
import { getEnabledVideoList } from '../../../../store/video/enabledVideoList'
import { setEnableDisableproperty } from '../../../../store/properties/enableDisableProperties'
import { validateOnlyChacters } from '../../../../utils/validators'
import {
  addProperty,
  clearaddPropertyResponse,
} from '../../../../store/properties/addProperty'
import { getEnabledSpaceList } from '../../../../store/spaces/enabledSpaceList'
import {
  clearDeletePropertyResponse,
  deleteProperty,
} from '../../../../store/properties/deleteProperties'
import AppSwitch from '../../../../components/AppSwitch'

import axios from 'axios'
import { baseUrl } from '../../../../middleware/url'

const Properties = ({ match }) => {
  const { spaceId, spaceName } = match.params

  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { colorList } = useSelector((color) => color.colorList)
  const { allPropertyList, loading } = useSelector(
    (list) => list.allPropertyList
  )
  const { propertyListById, loading: byIdLoading } = useSelector(
    (list) => list.propertyListById
  )
  const { enabledVideoList, loading: videoLoading } = useSelector(
    (list) => list.enabledVideoList
  )
  const { addPropertyResponse } = useSelector(
    (list) => list.addPropertyResponse
  )
  const { enabledSpaceList } = useSelector((list) => list.enabledSpaceList)
  const { deletePropertyResponse } = useSelector(
    (list) => list.deletePropertyResponse
  )

  const credentials = useSelector((state) => state.credentials)

  const [search, setSearch] = useState('')
  const [searchVideos, setSearchVideos] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [progress, setProgress] = useState(null)
  const [isUpadate, setIsUpadate] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)
  const [currentColor, setCurrentColor] = useState('select-color')
  const [currentColorError, setCurrentColorError] = useState('')
  const [currentSpace, setCurrentSpace] = useState('select-space')
  const [currentSpace2, setCurrentSpace2] = useState(null)
  const [currentSpaceError, setCurrentSpaceError] = useState('')

  const [enabledVideoListing, setEnabledVideoListing] = useState([])

  const [propertyName, setPropertyName] = useState('')
  const [propertyNameError, setPropertyNameError] = useState('')
  const [selectedVideos, setSelectedVideos] = useState([])
  const [selectedVideosError, setSelectedVideosError] = useState('')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [deleteWithReference, setDeleteWithReference] = useState(false)

  const [image, setImage] = useState('')
  const [imageError, setImageError] = useState('')
  const [imageURL, setImageURL] = useState('')

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 10

  console.log(currentSpace)

  window.onunload = function () {
    localStorage.setItem('propertySearch', '')
    setSearch('')
  }

  useEffect(() => {
    const initialAPIs = async () => {
      if (spaceId) {
        await dispatch(getPropertyListById(spaceId))
      } else {
        await dispatch(getAllPropertyList(pageSize, pageNumber))
      }
      await dispatch(getColorList())
      await dispatch(getEnabledVideoList())
      await dispatch(getEnabledSpaceList())
    }

    if (localStorage.getItem('propertySearch') === '') {
      initialAPIs()
    }
  }, [dispatch, spaceId, pageNumber, search])

  useEffect(() => {
    const initialAPIs = async () => {
      if (spaceId) {
        await dispatch(getPropertyListById(spaceId))
      } else {
        await dispatch(getAllPropertyList(pageSize, pageNumber))
      }
      await dispatch(getColorList())
      await dispatch(getEnabledVideoList())
      await dispatch(getEnabledSpaceList())
    }

    initialAPIs()
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('propertySearch')) {
      setSearch(localStorage.getItem('propertySearch'))

      dispatch(
        getAllPropertyList(
          pageSize,
          pageNumber,
          localStorage.getItem('propertySearch')
        )
      )
    }
  }, [dispatch, spaceId, pageNumber, search])

  useEffect(() => {
    if (addPropertyResponse !== null) {
      if (addPropertyResponse.status) {
        toast.success(addPropertyResponse.message)
        if (spaceId) {
          dispatch(getPropertyListById(spaceId))
        } else {
          dispatch(getAllPropertyList(pageSize, pageNumber))
        }
        dispatch(clearaddPropertyResponse())
        setProgress(null)
        onCloseModal()
      } else {
        toast.error(addPropertyResponse.message)
        dispatch(clearaddPropertyResponse())
      }
    }
  }, [addPropertyResponse, dispatch, spaceId])

  useEffect(() => {
    if (deletePropertyResponse !== null) {
      if (deletePropertyResponse.status) {
        closeDeleteModal()
        toast.success(deletePropertyResponse.message)
        if (spaceId) {
          dispatch(getPropertyListById(spaceId))
        } else {
          dispatch(getAllPropertyList(pageSize, pageNumber))
        }
        dispatch(clearDeletePropertyResponse())
      } else {
        toast.error(deletePropertyResponse.message)
        dispatch(clearDeletePropertyResponse())
      }
    }
  }, [deletePropertyResponse, dispatch])

  useEffect(() => {
    if (currentSpace2 === null) {
      if (
        enabledSpaceList !== null &&
        enabledSpaceList.data &&
        enabledSpaceList.data.result &&
        Array.isArray(enabledSpaceList.data.result) &&
        enabledSpaceList.data.result.length > 0
      ) {
        setCurrentSpace2(enabledSpaceList.data.result[0])
      }
    }
  }, [showAddModal, currentSpace2])

  useEffect(() => {
    if (
      enabledVideoList !== null &&
      enabledVideoList.data &&
      enabledVideoList.data.result &&
      Array.isArray(enabledVideoList.data.result) &&
      enabledVideoList.data.result.length > 0
    ) {
      setEnabledVideoListing(enabledVideoList.data.result)
    }
  }, [enabledVideoList])

  const onSearchProperties = (event) => {
    setSearch(event.target.value)
    localStorage.setItem('propertySearch', event.target.value)
    dispatch(getAllPropertyList(pageSize, pageNumber, event.target.value))
  }

  const onSearchVideo = (event) => {
    setSearchVideos(event.target.value)
    if (
      enabledVideoList &&
      enabledVideoList.data &&
      enabledVideoList.data.result &&
      Array.isArray(enabledVideoList.data.result) &&
      enabledVideoList.data.result.length > 0
    ) {
      const result = enabledVideoList.data.result.filter((item) =>
        item.title.toLowerCase().includes(event.target.value.toLowerCase())
      )
      setEnabledVideoListing(result)
    }
  }

  const handleAddSpace = () => {
    setShowAddModal(true)
  }

  const enableDisableHandler = async ({ _id, enable }) => {
    setCurrentItem(_id)
    await dispatch(setEnableDisableproperty({ propertiesId: _id, enable }))
    await dispatch(getAllPropertyList(pageSize, pageNumber))
    setCurrentItem(null)
  }

  const onUpload = (image) => {
    setImageURL(image)
    setImageError('')
  }

  const onCloseModal = () => {
    setIsUpadate(null)
    setShowAddModal(false)
    setImage('')
    setImageURL('')
    setPropertyName('')
    setImageError('')
    setSelectedVideos([])
    setCurrentColor('select-color')
    setCurrentSpace('select-space')
    setCurrentColorError('')
    setCurrentSpaceError('')
    setProgress(null)
    setSearchVideos('')
    if (
      enabledVideoList &&
      enabledVideoList.data &&
      enabledVideoList.data.result
    ) {
      setEnabledVideoListing(enabledVideoList.data.result)
    }

    // setSearchVideos('')
  }

  const onEditHandler = (item) => {
    setIsUpadate(item)
    setShowAddModal(true)
    setImage(item.imageURL)
    setPropertyName(item.propertyName)
    setCurrentColor(item.color)
    setSearchVideos('')

    if (
      enabledVideoList &&
      enabledVideoList.data &&
      enabledVideoList.data.result &&
      Array.isArray(enabledVideoList.data.result) &&
      enabledVideoList.data.result.length > 0
    ) {
      if (item.media && Array.isArray(item.media) && item.media.length > 0) {
        const videos = enabledVideoList.data.result.filter((video) => {
          const result = item.media.filter((media) => media === video._id)
          if (result && Array.isArray(result) && result.length > 0) {
            return video
          }
        })
        setSelectedVideos(videos)
      }
    }
  }

  const onDeleteHandler = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
    setDeleteWithReference(false)
  }

  const onDeleteMission = () => {
    if (deletedItem !== null) {
      dispatch(deleteProperty(deletedItem._id, deleteWithReference))
    }
  }

  const selectVideo = (video) => {
    setSelectedVideosError('')
    const isPresent = selectedVideos.find((item) => item._id === video._id)
    if (isPresent) {
      setSelectedVideos(selectedVideos.filter((item) => item._id !== video._id))
    } else {
      setSelectedVideos([...selectedVideos, video])
    }
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    setPageNumber(selectedPage + 1)
    if (spaceId) {
      dispatch(
        getPropertyListById({
          pageSize,
          pageNumber: selectedPage + 1,
          filterValue: '',
        })
      )
    } else {
      dispatch(getAllPropertyList(pageSize, selectedPage + 1, ''))
    }
  }

  const onChangeColor = (e) => {
    const result =
      colorList !== null &&
      colorList.data &&
      colorList.data.filter((colors) => colors.title === e.target.value)
    if (Array.isArray(result) && result.length > 0 && result[0]) {
      setCurrentColor(result[0])
    }
    setCurrentColorError('')
  }

  const onChangeSpace = (e) => {
    console.log('space change', e.target.value)
    // const result =
    //   enabledSpaceList !== null &&
    //   enabledSpaceList.data &&
    //   enabledSpaceList.data.result &&
    //   enabledSpaceList.data.result.filter(
    //     (colors) => colors.title === e.target.value
    //   )
    // if (Array.isArray(result) && result.length > 0 && result[0]) {
    //   if (spaceId) {
    //     const newResult = result.filter((item) => item._id === spaceId)
    //     console.log('new result', newResult)
    //     setCurrentSpace(newResult[0])
    //   } else {
    //     setCurrentSpace(result[0])
    //   }
    // }
    setCurrentSpace(e.target.value)
    setCurrentSpaceError('')
  }

  // console.log('isUpadate', isUpadate, spaceId)

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const data = new FormData()

    if (isUpadate) {
      if (propertyName !== '') {
        data.append('propertyName', propertyName)
      }

      if (imageURL !== '') {
        data.append('thumbnail', imageURL, imageURL.name)
      }

      data.append('id', isUpadate._id)
      data.append(
        'space',
        isUpadate !== null && isUpadate.space
          ? isUpadate.space
          : spaceId !== null || spaceId !== undefined
          ? spaceId
          : ''
      )
      selectedVideos &&
        selectedVideos.length > 0 &&
        selectedVideos.forEach((item, index) =>
          data.append(`media[${index}]`, item._id)
        )
      data.append('color[primaryColor]', currentColor.primaryColor)
      data.append('color[secondaryColor]', currentColor.secondaryColor)
      data.append('color[darkColor]', currentColor.darkColor)
      data.append('color[title]', currentColor.title)
      dispatch(addProperty(data, (progress) => setProgress(progress)))
    } else {
      if (propertyName === '') {
        setPropertyNameError('Please enter Property title')
      } else if (propertyName.length < 2) {
        setPropertyNameError('Property title must be two character long')
      } else if (currentSpace === 'select-space') {
        //   else if (validateOnlyChacters(propertyName)) {
        //   setPropertyNameError('Please enter valid Property title')
        // }
        setCurrentSpaceError('Please select space')
      } else if (currentColor === 'select-color') {
        setCurrentColorError('Please select color')
      } else if (image === '') {
        setImageError('Please select image')
      } else if (selectedVideos && selectedVideos.length <= 0) {
        if (
          enabledVideoList &&
          enabledVideoList.data &&
          enabledVideoList.data.result &&
          Array.isArray(enabledVideoList.data.result) &&
          enabledVideoList.data.result.length > 0
        ) {
          data.append('propertyName', propertyName)
          data.append('space', currentSpace)
          data.append('thumbnail', imageURL, imageURL.name)
          data.append('color[primaryColor]', currentColor.primaryColor)
          data.append('color[secondaryColor]', currentColor.secondaryColor)
          data.append('color[darkColor]', currentColor.darkColor)
          data.append('color[title]', currentColor.title)
          selectedVideos &&
            selectedVideos.length > 0 &&
            selectedVideos.forEach((item, index) =>
              data.append(`media[${index}]`, item._id)
            )
          dispatch(addProperty(data, (progress) => setProgress(progress)))
        } else {
          setSelectedVideosError('Please select atleast one video')
        }
      } else {
        data.append('propertyName', propertyName)
        data.append('space', currentSpace)
        data.append('thumbnail', imageURL, imageURL.name)
        data.append('color[primaryColor]', currentColor.primaryColor)
        data.append('color[secondaryColor]', currentColor.secondaryColor)
        data.append('color[darkColor]', currentColor.darkColor)
        data.append('color[title]', currentColor.title)
        selectedVideos &&
          selectedVideos.length > 0 &&
          selectedVideos.forEach((item, index) =>
            data.append(`media[${index}]`, item._id)
          )
        dispatch(addProperty(data, (progress) => setProgress(progress)))
      }
    }
  }

  const handleOrderChange = (value, orderNumber) => {
    var data = JSON.stringify({
      currentOrderNumber: Number(value),
      previousOrderNumber: Number(orderNumber),
    })

    var config = {
      method: 'patch',
      url: `${baseUrl}/space/change-property-order?space=${spaceId}`,
      headers: {
        token:
          credentials &&
          credentials.credentials &&
          credentials.credentials.data &&
          credentials.credentials.data.token,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        if (response.data && response.data.status) {
          toast.success(response.data.message)
          if (spaceId) {
            dispatch(getPropertyListById(spaceId))
          } else {
            dispatch(getAllPropertyList(pageSize, pageNumber))
          }
        }
      })
      .catch(function (error) {})
  }

  const { moduleNames } = useSelector((user) => user.moduleNames)
  const [spaceModuleName, setSpaceModuleName] = useState('Spaces')
  const [propertyModuleName, setPropertyModuleName] = useState('Property')

  useEffect(() => {
    if (moduleNames && moduleNames.data && moduleNames.data.stageOne) {
      setSpaceModuleName(moduleNames.data.stageTwo)
      setPropertyModuleName(moduleNames.data.stageThree)
    }
  }, [moduleNames])

  const onClientChange = (item) => {
    const initialAPIs = async () => {
      if (spaceId) {
        await dispatch(getPropertyListById(spaceId))
      } else {
        await dispatch(getAllPropertyList(pageSize, pageNumber))
      }
      await dispatch(getColorList())
      await dispatch(getEnabledVideoList())
      await dispatch(getEnabledSpaceList())
    }

    initialAPIs()
    setSearch('')
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading={propertyModuleName}
        isAdd
        onAdd={handleAddSpace}
        isSearch
        searchPlaceholder={`Search ${propertyModuleName}`}
        onSearch={onSearchProperties}
        searchValue={search}
        onClientChange={onClientChange}
      />
      {byIdLoading || (loading && currentItem === null) ? (
        <AllPropertiesSkeleton />
      ) : (
        <Fragment>
          <div className='client__container' style={{ paddingTop: '0rem' }}>
            {spaceId && spaceName ? (
              <div className='client__container_message'>
                {propertyModuleName} from {spaceName}
              </div>
            ) : (
              <div className='client__container_message'>
                All {propertyModuleName}
              </div>
            )}
            <div className='missions_main_content'>
              {spaceId ? (
                propertyListById !== null &&
                propertyListById.data &&
                propertyListById.data.result &&
                propertyListById.data.result.length > 0 ? (
                  propertyListById.data.result.map((item, index) => (
                    <PropertyCard
                      key={index}
                      item={item}
                      loading={byIdLoading}
                      onEditClick={onEditHandler}
                      currentItem={currentItem}
                      onChangeSwitch={enableDisableHandler}
                      onDelete={onDeleteHandler}
                      totalProperties={propertyListById.data.result.length}
                      handleOrderChange={handleOrderChange}
                    />
                  ))
                ) : (
                  <div className='data_not_found_container'>
                    <p className='data_not_found_message'>
                      {search !== ''
                        ? 'Search result not found'
                        : `You havn't created any ${propertyModuleName} yet. Please create one`}
                    </p>
                  </div>
                )
              ) : allPropertyList !== null &&
                allPropertyList.data &&
                allPropertyList.data.result &&
                allPropertyList.data.result.length > 0 ? (
                allPropertyList.data.result.map((item, index) => (
                  <PropertyCard
                    key={index}
                    item={item}
                    loading={loading}
                    onEditClick={onEditHandler}
                    currentItem={currentItem}
                    onChangeSwitch={enableDisableHandler}
                    onDelete={onDeleteHandler}
                  />
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== ''
                      ? 'Search result not found'
                      : `You havn't created any ${propertyModuleName} yet. Please create one`}
                  </p>
                </div>
              )}
            </div>
          </div>
          {spaceId
            ? propertyListById !== null &&
              propertyListById.data &&
              propertyListById.data.result &&
              propertyListById.data.result.length > 0 &&
              propertyListById.data.totalPageSize > 15 && (
                <Pagination
                  list={propertyListById}
                  onPageChange={onPageChange}
                  rowsPerPage={pageSize}
                />
              )
            : allPropertyList !== null &&
              allPropertyList.data &&
              allPropertyList.data.result &&
              allPropertyList.data.result.length > 0 &&
              allPropertyList.data.totalPageSize > 15 && (
                <Pagination
                  list={allPropertyList}
                  onPageChange={onPageChange}
                  rowsPerPage={pageSize}
                />
              )}
          <AppModal show={showAddModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>
                {isUpadate
                  ? `Edit ${propertyModuleName}`
                  : `Add ${propertyModuleName}`}
              </p>
              <form
                className='clinic_form_container'
                onSubmit={onSubmitHandler}>
                <div className='clinic_form_top_container'>
                  <div
                    className='clinic_form_top_left_container'
                    style={{
                      display:
                        isUpadate !== null &&
                        isUpadate.questionCount &&
                        isUpadate.questionCount !== 0
                          ? 'block'
                          : 'flex',
                      gap: '2rem',
                    }}>
                    <div style={{ flex: 2 }}>
                      <TextInput
                        placeholder={`${propertyModuleName} name`}
                        src='/images/missions-input.png'
                        onChange={(mission) => {
                          setPropertyNameError('')
                          setPropertyName(mission.target.value)
                        }}
                        value={propertyName}
                        // maxLength={20}
                        error={propertyNameError}
                      />
                      {isUpadate === null && (
                        <Fragment>
                          <div className='mission_dropdown'>
                            <div className='text_input_image_container'>
                              <img
                                src='/images/spaces.png'
                                alt='color'
                                className='text_input_image'
                              />
                            </div>
                            <select
                              name='color-select'
                              id='color-select'
                              value={
                                currentSpace === 'select-space'
                                  ? 'select-space'
                                  : currentSpace
                              }
                              onChange={onChangeSpace}
                              className='mission_dropdown_select'
                              style={{ width: '100%' }}>
                              <option value='select-space' disabled>
                                Select {spaceModuleName}
                              </option>
                              {enabledSpaceList &&
                                enabledSpaceList.data &&
                                enabledSpaceList.data.result &&
                                enabledSpaceList.data.result.map(
                                  (item, index) => {
                                    if (spaceId) {
                                      if (item._id === spaceId) {
                                        return (
                                          <option key={index} value={item._id}>
                                            {item.title}
                                          </option>
                                        )
                                      }
                                    } else {
                                      return (
                                        <option key={index} value={item._id}>
                                          {item.title}
                                        </option>
                                      )
                                    }
                                  }
                                )}
                            </select>
                          </div>
                          {currentSpaceError && (
                            <p className='input_error_text'>
                              {currentSpaceError}
                            </p>
                          )}
                        </Fragment>
                      )}

                      <div className='mission_dropdown'>
                        <div className='text_input_image_container'>
                          <img
                            src='/images/color-input.png'
                            alt='color'
                            className='text_input_image'
                          />
                        </div>
                        <select
                          name='color-select'
                          id='color-select'
                          value={
                            currentColor === 'select-color'
                              ? 'select-color'
                              : currentColor.title
                          }
                          onChange={onChangeColor}
                          className='mission_dropdown_select'>
                          <option value='select-color' disabled>
                            Select color
                          </option>
                          {colorList &&
                            colorList.data &&
                            colorList.data.map((item, index) => (
                              <option key={index} value={item.title}>
                                {item.title}
                              </option>
                            ))}
                        </select>
                        <div
                          style={{
                            backgroundColor:
                              currentColor !== null &&
                              currentColor.primaryColor,
                          }}
                          className='dropdown_selectedColor'
                        />
                      </div>
                      {currentColorError && (
                        <p className='input_error_text'>{currentColorError}</p>
                      )}
                    </div>
                    <div className='mission_upload2' style={{ flex: 1 }}>
                      <ImageUploader
                        onUpload={onUpload}
                        image={image}
                        setURL={(image) => setImage(image)}
                        errorMessage={imageError}
                        containerStyles={{
                          height: '12vw',
                          width: '12vw',
                          borderRadius: '50%',
                        }}
                        imageTitle='Upload thumbnail'
                        showMessage={true}
                        style={{
                          width: '18rem',
                        }}
                      />
                    </div>
                  </div>
                  <div className='properties_card_videos__list--modalVideos'>
                    <div className='properties_card_videos__list_heading_search'>
                      <h3 className='properties_card_videos__list--modalTitle'>
                        Select Assets
                      </h3>
                      <div className='search_container'>
                        <img
                          className='howto_search_logo'
                          src='/images/search.png'
                        />
                        <input
                          className='background_container_header_input mt-left'
                          placeholder='Search assets'
                          value={searchVideos}
                          onChange={onSearchVideo}
                        />
                      </div>
                    </div>
                    <div className='properties_card_videos__list--modal'>
                      {enabledVideoListing && enabledVideoListing.length > 0 ? (
                        enabledVideoListing.map((video, vIndex) => {
                          const selected =
                            selectedVideos &&
                            selectedVideos.length > 0 &&
                            selectedVideos.find(
                              (item) => item._id === video._id
                            )

                          return (
                            <div
                              className='properties_card_videos__list--videoSelect'
                              key={vIndex}>
                              {video.fileType === 'doc' ? (
                                <div className='properties_card_pdf_conatiner'>
                                  <img
                                    className='properties_card_pdf'
                                    src='/images/pdf.png'
                                  />
                                </div>
                              ) : (
                                <>
                                  {video.fileType === 'audio' ? (
                                    <div className='properties_card_pdf_conatiner'>
                                      <img
                                        className='properties_card_pdf2'
                                        src='/images/audio_icon.png'
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {video.fileType === 'image' ? (
                                        <img
                                          alt='logo3'
                                          src={video.videoURL}
                                          style={{
                                            width: '18.5rem',
                                            height: '9rem',
                                          }}
                                        />
                                      ) : (
                                        <video width='185'>
                                          <source
                                            src={video.videoURL}
                                            type='video/mp4'
                                          />
                                          Your browser does not support HTML
                                          video.
                                        </video>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              <p>{video.title}</p>
                              {selected ? (
                                <p
                                  className='properties_card_videos__list--modalBtn2'
                                  onClick={() => selectVideo(video)}>
                                  Selected
                                </p>
                              ) : (
                                <p
                                  className='properties_card_videos__list--modalBtn'
                                  onClick={() => selectVideo(video)}>
                                  {video.fileType === 'doc'
                                    ? 'select pdf'
                                    : video.fileType === 'audio'
                                    ? 'Select audio'
                                    : video.fileType === 'image'
                                    ? 'Select Image'
                                    : 'Select video'}
                                </p>
                              )}
                            </div>
                          )
                        })
                      ) : (
                        <p style={{ color: 'var(--red)' }}>
                          {searchVideos !== ''
                            ? 'Search result not found'
                            : "You havn't uploaded any assets, Please upload"}
                        </p>
                      )}
                    </div>
                    <p className='input_error_text'>{selectedVideosError}</p>
                  </div>
                </div>

                <div className='modal_buttons'>
                  <AppButton
                    title={isUpadate ? 'update' : 'add'}
                    type='submit'
                    icon={
                      <img
                        src='/images/add-clinic.png'
                        className='app_button_image'
                      />
                    }
                    onClick={onSubmitHandler}
                  />
                  <AppButton
                    title='cancel'
                    type='button'
                    buttonContainer={{
                      backgroundColor: 'var(--transparent)',
                      marginLeft: '0.5rem',
                    }}
                    textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                    onClick={onCloseModal}
                  />
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
          <AppModal2 show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete {propertyModuleName}</p>
              <p className='form_header_delete_text'>
                Are you sure you want to delete{' '}
                <span>{deletedItem !== null && deletedItem.propertyName}</span>?
              </p>
              {/* <div className='form_header_delete_ref'>
                <p className='form_header_delete_ref_text'>
                  Delete all it's reference
                </p>
                <AppSwitch
                  checked={deleteWithReference}
                  onChange={onDeleteStatusChange}
                />
              </div> */}
              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteMission}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal2>
        </Fragment>
      )}
    </div>
  )
}

export default Properties
