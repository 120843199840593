import React, { useState, useEffect } from 'react'
import './AllResponseVideo.module.css'
import AppHeader from '../../../../components/AppHeader'
import ResponseVideoCard from '../../../../components/Cards/ResponseVideoCard'
import AppModal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'
import AddVideo from '../../../app/Client/AllResponseVideos/AddVideo/AddVideo'
import { getResponseVideoList } from '../../../../store/responseVideo/getResponse'
import { useDispatch, useSelector } from 'react-redux'
import AppButton from '../../../../components/forms/AppButton'
import AllVideosSkeleton from '../../../../components/Skeletons/AllVideosSkeleton'
import Pagination from '../../../../components/Pagination'
import {
  deleteResponseVideo,
  clearDeleteResponseVideo,
} from '../../../../store/responseVideo/deleteResponseVideo'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

const pageSize = 9

const AllResponseVideos = () => {
  const [onAdd, setOnAdd] = useState(false)
  const [onDelete, setOnDelete] = useState(false)
  const [updateData, setUpdateData] = useState(null)
  const [search, setSearch] = useState('')
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const [pageNumber, setPageNumber] = useState(1)
  const [deleteItem, setDeleteItem] = useState('')

  let data

  const dispatch = useDispatch()
  const history = useHistory()

  const { responseVideoList, loading } = useSelector(
    (state) => state.responseVideoList
  )

  const { deleteResponseVideo: deleteMessage } = useSelector(
    (state) => state.deleteResponseVideo
  )

  const { credentials, userInfo } = useSelector((user) => user.credentials)

  useEffect(() => {
    dispatch(getResponseVideoList(pageNumber, pageSize))
  }, [dispatch])

  useEffect(() => {
    if (deleteMessage !== null) {
      if (deleteMessage.status) {
        toast.success(deleteMessage.message)
        dispatch(getResponseVideoList(pageNumber, pageSize))
        dispatch(clearDeleteResponseVideo())
        setOnDelete(false)
      } else {
        toast.error(deleteMessage.message)
        dispatch(clearDeleteResponseVideo())
      }
    }
  }, [deleteMessage, dispatch])

  const onAddHandler = () => {
    setOnAdd(true)
  }
  const onCancelHandler = () => {
    setOnAdd(false)
    setUpdateData(null)
  }

  const closeDeleteModal = () => {
    setOnDelete(false)
  }

  const onEditHandler = (item) => {
    setOnAdd(true)
    setUpdateData(item)
  }
  const onDeleteHandler = (item) => {
    setDeleteItem(item)
    setOnDelete(true)
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(getResponseVideoList(selectedPage + 1, pageSize))
    setPageNumber(selectedPage + 1)
  }

  if (
    responseVideoList &&
    responseVideoList.data &&
    responseVideoList.data.result
  ) {
    data = responseVideoList.data.result
  } else {
    data = []
  }

  const onDeleteSubmitHandler = async () => {
    await dispatch(deleteResponseVideo(deleteItem._id))
  }

  const onSearchSpaces = (event) => {
    setSearch(event.target.value)
    dispatch(getResponseVideoList(pageNumber, pageSize, event.target.value))
  }

  const onClientChange = (item) => {
    if (item && item.clientType === 'lab') {
      history.push('/')
    } else {
      dispatch(getResponseVideoList(pageNumber, pageSize))
      setSearch('')
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Response videos'
        isAdd
        onAdd={onAddHandler}
        isSearch
        searchPlaceholder='Search response videos'
        onSearch={onSearchSpaces}
        searchValue={search}
        onClientChange={onClientChange}
      />
      {loading ? (
        <AllVideosSkeleton />
      ) : (
        <div className='client__container'>
          <div className='missions_main_content'>
            {data && data.length > 0 ? (
              data.map((item) => {
                return (
                  <div key={item._id}>
                    <ResponseVideoCard
                      data={item}
                      onEdit={() => onEditHandler(item)}
                      onDelete={() => onDeleteHandler(item)}
                    />
                  </div>
                )
              })
            ) : (
              <div className='data_not_found_container'>
                <p className='data_not_found_message'>
                  {search !== ''
                    ? 'Search result not found'
                    : "You havn't created any users yet. Please create one"}
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {onAdd && (
        <AppModal show={onAdd}>
          <AddVideo onCancel={onCancelHandler} update={updateData} />
        </AppModal>
      )}

      <AppModal2 show={onDelete}>
        <div className='clinic_add_modal_container'>
          <p className='form_header_text'>Delete</p>
          <p className='form_header_delete_text'>
            Are you sure you want to delete{' '}
            <span className={{ color: 'red' }}>{deleteItem.title}</span>
            {/* <span>{deletedItem !== null && deletedItem.title}</span> */}?
          </p>

          <div className='modal_buttons'>
            <AppButton
              title='delete'
              type='submit'
              // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
              onClick={onDeleteSubmitHandler}
            />
            <AppButton
              title='cancel'
              type='button'
              buttonContainer={{
                backgroundColor: 'var(--transparent)',
                marginLeft: '0.5rem',
              }}
              textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
              onClick={closeDeleteModal}
            />
          </div>
        </div>
      </AppModal2>

      {responseVideoList !== null &&
        responseVideoList.data &&
        responseVideoList.data.result &&
        responseVideoList.data.result.length > 0 &&
        responseVideoList.data.totalPageSize > pageSize && (
          <Pagination
            list={responseVideoList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
            page={pageNumber}
          />
        )}
    </div>
  )
}

export default AllResponseVideos
