import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'delete-avatar-property',
    initialState: {
        deleteAvatarPropsResponse: null,
        loading: false,
    },
    reducers: {
        requested: (mission) => {
            mission.loading = true
        },
        success: (mission, action) => {
            mission.deleteAvatarPropsResponse = action.payload
            mission.loading = false
        },
        failed: (mission, action) => {
            mission.deleteAvatarPropsResponse = action.payload;
            mission.loading = false
        },
        reset: (mission, action) => {
            mission.deleteAvatarPropsResponse = action.payload;
            mission.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteAvatarProps = (data) => apiCallBegan({
    url: `/avatar-properties/delete-avatar-properties`,
    method: 'DELETE',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearDeleteAvatarPropsResponse = () => apiCallBegan({
    onReset: reset.type,
})