import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'enable-disable-coupon',
  initialState: {
    enableCouponResponse: null,
    loading: false,
  },
  reducers: {
    requested: (Coupon) => {
      Coupon.loading = true
    },
    success: (Coupon, action) => {
      Coupon.enableCouponResponse = action.payload
      Coupon.loading = false
    },
    failed: (Coupon, action) => {
      Coupon.enableCouponResponse = action.payload
      Coupon.loading = false
    },
    reset: (Coupon, action) => {
      Coupon.enableCouponResponse = action.payload
      Coupon.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const enableDisableCoupon = (data) =>
  apiCallBegan({
    url: `payment/enable-disable-coupon`,
    method: 'PATCH',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearEnableDisableCouponCouponResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
