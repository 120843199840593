import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AppHeader from '../../../../components/AppHeader'
import styles from './Configuration.module.css'
import {
  getConfigList,
  clearConfigList,
} from '../../../../store/configuration/getConfig'
import {
  updateConfig,
  clearUpdateConfig,
} from '../../../../store/configuration/patchConfig'
import AppSwitchSmall from '../../../../components/AppSwitchSmall'
import AppButton from '../../../../components/forms/AppButton'
const Configuration = () => {
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { credentials, userInfo } = useSelector((user) => user.credentials)
  const { configurationList, loading: configLoading } = useSelector(
    (config) => config.getConfig
  )

  const { updateConfigRseponse, loading: updateLoading } = useSelector(
    (config) => config.patchConfig
  )

  const [admin, setAdmin] = useState({
    pretestModule: true, // In admin dashboard
    categoryReport: true, // In admin dashboard
    statisticsReport: true, //In admin dashboard
    progressReport: true, //In admin dashboard
    pointReport: true, // In admin report
    responseVideo: true, // in admin dashboard
    successVideo: true, // in admin dashboard
    renameModule: true, // in admin dashboard
  })

  const [app, setApp] = useState({
    secureCode: true, //if false then secureCode will be 1234. In app
    therapistView: true, //In app
    performanceReport: true, // In app and user dashboard
    genericVideo: true, //in app and user dashboard
    faq: true, // in user dashboard and app
    aboutUs: true, // in user dashboard and app
    aboutAlgo: true, // in user dashboard and app
    privacyPolicy: true, // in user dashboard and app
    leaderBoard: true, // in app and web dashboard
  })

  const [dashboard, setDashboard] = useState({
    performanceReport: true, // In app and user dashboard
    genericVideo: true, //in app and user dashboard
    faq: true, // in user dashboard and app
    aboutUs: true, // in user dashboard and app
    aboutAlgo: true, // in user dashboard and app
    privacyPolicy: true, // in user dashboard and app
    leaderBoard: true, // in app and web dashboard
  })

  const [OTP, setOTP] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo._id) {
      dispatch(getConfigList(userInfo._id))
    }
  }, [userInfo])

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Configuration' />
      <div className='client__container'>
        <div className={styles.container}>
          <div className={styles.label}>Admin</div>
          <div className={styles.section}>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Pretest Module</div>
              <div>
                <AppSwitchSmall
                  checked={admin.pretestModule}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      pretestModule: !admin.pretestModule,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Category Report</div>
              <div>
                <AppSwitchSmall
                  checked={admin.categoryReport}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      categoryReport: !admin.categoryReport,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Statistics Report</div>
              <div>
                <AppSwitchSmall
                  checked={admin.statisticsReport}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      statisticsReport: !admin.statisticsReport,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Progress Report</div>
              <div>
                <AppSwitchSmall
                  checked={admin.progressReport}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      progressReport: !admin.progressReport,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Point Report</div>
              <div>
                <AppSwitchSmall
                  checked={admin.pointReport}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      pointReport: !admin.pointReport,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Response Video</div>
              <div>
                <AppSwitchSmall
                  checked={admin.responseVideo}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      responseVideo: !admin.responseVideo,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Success Video</div>
              <div>
                <AppSwitchSmall
                  checked={admin.successVideo}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      successVideo: !admin.successVideo,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Rename Module</div>
              <div>
                <AppSwitchSmall
                  checked={admin.renameModule}
                  onChange={() =>
                    setAdmin({
                      ...admin,
                      renameModule: !admin.renameModule,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.label} style={{ marginTop: '2rem' }}>
            App
          </div>
          <div className={styles.section}>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Secure Code</div>
              <div>
                <AppSwitchSmall
                  checked={app.secureCode}
                  onChange={() =>
                    setApp({
                      ...app,
                      secureCode: !app.secureCode,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Therapist View</div>
              <div>
                <AppSwitchSmall
                  checked={app.therapistView}
                  onChange={() =>
                    setApp({
                      ...app,
                      therapistView: !app.therapistView,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Performance Report</div>
              <div>
                <AppSwitchSmall
                  checked={app.performanceReport}
                  onChange={() =>
                    setApp({
                      ...app,
                      performanceReport: !app.performanceReport,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Generic Video</div>
              <div>
                <AppSwitchSmall
                  checked={app.genericVideo}
                  onChange={() =>
                    setApp({
                      ...app,
                      genericVideo: !app.genericVideo,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Faq</div>
              <div>
                <AppSwitchSmall
                  checked={app.faq}
                  onChange={() =>
                    setApp({
                      ...app,
                      faq: !app.faq,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>About Us</div>
              <div>
                <AppSwitchSmall
                  checked={app.aboutUs}
                  onChange={() =>
                    setApp({
                      ...app,
                      aboutUs: !app.aboutUs,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>About Algo</div>
              <div>
                <AppSwitchSmall
                  checked={app.aboutAlgo}
                  onChange={() =>
                    setApp({
                      ...app,
                      aboutAlgo: !app.aboutAlgo,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Privacy Policy</div>
              <div>
                <AppSwitchSmall
                  checked={app.privacyPolicy}
                  onChange={() =>
                    setApp({
                      ...app,
                      privacyPolicy: !app.privacyPolicy,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Leaderboard</div>
              <div>
                <AppSwitchSmall
                  checked={app.leaderBoard}
                  onChange={() =>
                    setApp({
                      ...app,
                      leaderBoard: !app.leaderBoard,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.label} style={{ marginTop: '2rem' }}>
            Dashboard
          </div>
          <div className={styles.section}>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Performance Report</div>
              <div>
                <AppSwitchSmall
                  checked={dashboard.performanceReport}
                  onChange={() =>
                    setDashboard({
                      ...dashboard,
                      performanceReport: !dashboard.performanceReport,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Generic Video</div>
              <div>
                <AppSwitchSmall
                  checked={dashboard.genericVideo}
                  onChange={() =>
                    setDashboard({
                      ...dashboard,
                      genericVideo: !dashboard.genericVideo,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Faq</div>
              <div>
                <AppSwitchSmall
                  checked={dashboard.faq}
                  onChange={() =>
                    setDashboard({
                      ...dashboard,
                      faq: !dashboard.faq,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>About Us</div>
              <div>
                <AppSwitchSmall
                  checked={dashboard.aboutUs}
                  onChange={() =>
                    setDashboard({
                      ...dashboard,
                      aboutUs: !dashboard.aboutUs,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>About Algo</div>
              <div>
                <AppSwitchSmall
                  checked={dashboard.aboutAlgo}
                  onChange={() =>
                    setDashboard({
                      ...dashboard,
                      aboutAlgo: !dashboard.aboutAlgo,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Privacy Policy</div>
              <div>
                <AppSwitchSmall
                  checked={dashboard.privacyPolicy}
                  onChange={() =>
                    setDashboard({
                      ...dashboard,
                      privacyPolicy: !dashboard.privacyPolicy,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
            <div className={styles.tilesContainer}>
              <div className={styles.title}>Leaderboard</div>
              <div>
                <AppSwitchSmall
                  checked={dashboard.leaderBoard}
                  onChange={() =>
                    setDashboard({
                      ...dashboard,
                      leaderBoard: !dashboard.leaderBoard,
                    })
                  }
                />
              </div>

              <div className={styles.iconContainer}>
                <svg className={styles.icon}>
                  <use xlinkHref={`/images/sprite.svg#icon-questionMark`} />
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <AppButton title='Save' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Configuration
