import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'delete-pre-assets',
  initialState: {
    deletePreAssetsResponse: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.deletePreAssetsResponse = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.deletePreAssetsResponse = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.deletePreAssetsResponse = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deletePreAssets = (id, status) =>
  apiCallBegan({
    url: `pre-asset/delete-pre-asset/${id}`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearDeletePreAssetsResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
