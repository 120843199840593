import axios from 'axios';
import { ADD_STATISTICS_REQUEST, ADD_STATISTICS_SUCCESS, ADD_STATISTICS_FAIL } from '../constants/statisticsConstants';
import { baseUrl } from '../../../middleware/url';

export const addStatisticsAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_STATISTICS_REQUEST });
		const { credentials: { credentials }, userDetails } = getState();
		const clientType =
			userDetails &&
			userDetails.userInfo &&
			userDetails.userInfo.data &&
			userDetails.userInfo.data.client &&
			userDetails.userInfo.data.client.clientType;

		const config = {
			headers: {
				token: credentials.data.token,
				'Content-Type': 'application/json'
			}
		};

		const uri =
			clientType === 'lab' ? `${baseUrl}/statistics/add-statistics-pretest` : `${baseUrl}/statistics/add-statistics`;

		const { data } = await axios.post(uri, formData, config);

		dispatch({
			type: ADD_STATISTICS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ADD_STATISTICS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
