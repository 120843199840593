import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'change-therapistView',
  initialState: {
    changeViewResponse: null,
    loading: false,
  },
  reducers: {
    requested: (user) => {
      user.loading = true
    },
    success: (user, action) => {
      user.changeViewResponse = action.payload
      user.loading = false
    },
    failed: (user, action) => {
      user.changeViewResponse = action.payload
      user.loading = false
    },
    reset: (user, action) => {
      user.changeViewResponse = action.payload
      user.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const changeTherapistView = (isTherapistView) =>
  apiCallBegan({
    url: `/user/change-therapist-view`,
    method: 'POST',
    data: { isTherapistView },
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearChangeTherapistViewResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
