import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-coupon',
  initialState: {
    couponList: null,
    loading: false,
  },
  reducers: {
    requested: (Coupon) => {
      Coupon.loading = true
    },
    success: (Coupon, action) => {
      Coupon.couponList = action.payload
      Coupon.loading = false
    },
    failed: (Coupon, action) => {
      Coupon.couponList = action.payload
      Coupon.loading = false
    },
    reset: (Coupon, action) => {
      Coupon.couponList = action.payload
      Coupon.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getCoupon = (pageSize, startId, endId) =>
  apiCallBegan({
    url: startId
      ? `payment/get-coupon?limit=${pageSize}&starting_after=${startId} `
      : endId
      ? `payment/get-coupon?limit=${pageSize}&ending_before=${endId}`
      : pageSize
      ? `payment/get-coupon?limit=${pageSize}`
      : `payment/get-coupon`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearGetCouponResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
