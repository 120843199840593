import React from 'react'
import styles from './Support.module.css'

const Support = ({}) => {
  return (
    <div className={styles.support}>
      <svg className={styles.icon}>
        <use xlinkHref={`/images/sprite.svg#icon-email`} />
      </svg>
      <div className={styles.text}>support@bxbuilders.com</div>
    </div>
  )
}

export default Support
