import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan as authApiCallBegan } from '../../middleware/authApis';
import { apiCallBegan } from '../../middleware/apis';
import JWT from 'jwt-decode';

const slice = createSlice({
  name: 'user-login',
  initialState: {
    credentials: null,
    loading: false,
    userInfo: null,
    isExpiredInfo: null,
  },
  reducers: {
    requested: (users, action) => {
      users.loading = true;
    },
    success: (users, action) => {
      users.credentials = action.payload;
      users.loading = false;
      users.userInfo = action.payload && action.payload.data && action.payload.data.token && JWT(action.payload.data.token);
    },
    failed: (users, action) => {
      users.credentials = action.payload;
      users.loading = false;
      users.userInfo = null;
    },
    logout: (users, action) => {
      users.credentials = action.payload;
      users.loading = false;
      users.userInfo = null;
    },
    update: (users, action) => {
      users.credentials = action.payload;
      users.loading = false;
    },
    expireStart: (otp, action) => {},
    expireSuccess: (otp, action) => {
      otp.isExpiredInfo = action?.payload?.status;
    },
    expireFail: (otp, action) => {
      otp.isExpiredInfo = action?.payload?.status;
    },
    expireReset: (otp, action) => {
      otp.isExpiredInfo = null;
    },
  },
});

const { requested, success, failed, logout, update, expireStart, expireSuccess, expireFail, expireReset } = slice.actions;
export default slice.reducer;

export const login = data =>
  authApiCallBegan({
    // url: '/auth/login',
    url: '/auth/otp-verification',
    method: 'POST',
    data,
    onLoginStart: requested.type,
    onLoginSuccess: success.type,
    onLoginFailed: failed.type,
  });

export const replaceToken = id =>
  apiCallBegan({
    url: `/user/get-client-token/${id}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  });

export const updateToken = data =>
  apiCallBegan({
    data,
    onUpdateToken: update.type,
  });

export const logoutUser = () =>
  authApiCallBegan({
    onLogout: logout.type,
  });

export const introReplaceToken = data => async dispatch => {
  try {
    dispatch(success(data));
  } catch (e) {}
};

//use this for getting latest token
export const getLatestToken = () =>
  apiCallBegan({
    url: `user/verify-account`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  });

export const getLatestUserInfo = () =>
  apiCallBegan({
    url: `/user/get-user-by-token`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  });

export const getExpiredTokenInfo = token =>
  apiCallBegan({
    url: `/user/verify-token`,
    method: 'GET',
    token,
    onStart: expireStart.type,
    onSuccess: expireSuccess.type,
    onFailed: expireFail.type,
  });

export const clearExpiredTokenInfo = () => async dispatch => {
  dispatch(expireReset());
};
