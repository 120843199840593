const options = {
  animation: true,
  responsive: true,
  layout: {
    padding: 10,
  },
  plugins: {
    legend: {
      align: 'end',
    },
  },
  elements: {
    bar: {
      borderRadius: 20,
    },
  },
  scales: {
    xAxes: [
      {
        barPercentage: 10,
        ticks: {
          fontColor: 'white',
        },
      },
    ],
  },
}

export { options }

export const optionsStack = {
  animation: true,
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
  },
  layout: {
    padding: 10,
  },
  plugins: {
    legend: {
      align: 'end',
    },
  },
  elements: {
    bar: {
      borderRadius: 20,
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}
