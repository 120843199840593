import React from 'react'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLISHABLE_KEY } from '../../../middleware/url'
import Payment from './Payment'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const Subscription = ({ match }) => {
  const { userMobile, clientType } = match.params

  return (
    <Elements stripe={stripePromise}>
      <Payment userMobile={userMobile} clientType={clientType} />
    </Elements>
  )
}

export default Subscription
