import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ToastContainer } from 'react-toastify'
import { Toaster } from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'

import configureStore from './store/configureStore'
import Routers from './routers'
import './App.css'

const store = configureStore()
const persistor = persistStore(store)

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routers />
      <ToastContainer position='bottom-right' />
      <Toaster
        position='top-center'
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
        }}
      />
    </PersistGate>
  </Provider>
)
