import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'add-question',
  initialState: {
    addQuestionResponse: null,
    loading: false,
  },
  reducers: {
    requested: (question) => {
      question.loading = true
    },
    success: (question, action) => {
      question.addQuestionResponse = action.payload
      question.loading = false
    },
    failed: (question, action) => {
      question.addQuestionResponse = action.payload
      question.loading = false
    },
    reset: (question, action) => {
      question.addQuestionResponse = action.payload
      question.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addQuestion = (data, onUploadProgress) =>
  apiCallBegan({
    url: '/media/create-and-update-question',
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearAddQuestionResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
