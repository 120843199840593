import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'notification-list',
    initialState: {
        notificationList: [],
        loading: false,
    },
    reducers: {
        requested: notification => {
            notification.loading = true
        },
        success: (notification, action) => {
            notification.notificationList = action.payload;
            notification.loading = false
        },
        failed: (notification, action) => {
            notification.notificationList = action.payload;
            notification.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getNotificationList = data => apiCallBegan({
    url: `/notification/get-all-notification`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})