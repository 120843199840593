import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'register-user-by-client',
  initialState: {
    addUserResponse: null,
    loading: false,
  },
  reducers: {
    requested: (user) => {
      user.loading = true
    },
    success: (user, action) => {
      user.addUserResponse = action.payload
      user.loading = false
    },
    failed: (user, action) => {
      user.addUserResponse = action.payload
      user.loading = false
    },
    reset: (user, action) => {
      user.addUserResponse = action.payload
      user.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addUser = (data) =>
  apiCallBegan({
    url: 'user/user-registration',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearAddUserResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
