import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'privacy',
    initialState: {
        privacyData: [],
        loading: false,
    },
    reducers: {
        requested: privacy => {
            privacy.loading = true
        },
        success: (privacy, action) => {
            privacy.privacyData = action.payload;
            privacy.loading = false
        },
        failed: (privacy, action) => {
            privacy.privacyData = action.payload;
            privacy.loading = false
        },
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getPrivacy = () => apiCallBegan({
    url: `/privacy/get-privacy-content`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})
