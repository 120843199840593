import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-Play-Graph-data',
  initialState: {
    playGraphData: null,
    loading: false,
  },
  reducers: {
    requested: (graph) => {
      graph.loading = true
    },
    success: (graph, action) => {
      graph.playGraphData = action.payload
      graph.loading = false
    },
    failed: (graph, action) => {
      graph.playGraphData = action.payload
      graph.loading = false
    },
    reset: (graph, action) => {
      graph.playGraphData = action.payload
      graph.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getPlayGraphData = (mission) =>
  apiCallBegan({
    url: `point/get-graph-data-web/type-of-play-performance?mission=${mission}&&offset=-330`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearPlayGraphData = () =>
  apiCallBegan({
    onReset: reset.type,
  })
