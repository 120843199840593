import React from 'react'
import ReactPaginate from 'react-paginate'

import './pagination.css'

const Pagination = ({ questionList, list, onPageChange, rowsPerPage, page = 1 }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p >
            </p>
            <ReactPaginate
                forcePage={page - 1}
                nextClassName={"nextClassName"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={questionList ? Array.isArray(questionList.data) && questionList.data.length / rowsPerPage : list.data.totalPageSize / rowsPerPage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                subContainerClassName={"pages pagination"}
                activeLinkClassName={"activeLink"}
                disabledClassName={"disabledClassName"}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                breakLinkClassName="page-link"
                nextLinkClassName="page-link"
                previousLinkClassName="page-link"
                pageClassName="page-item"
                previousClassName="previousClassName"
                nextLabel=">"
                previousLabel="<"
            />
        </div>
    )
}

export default Pagination