import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from '../../../store/onBoarding/getPlans';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY, baseUrl } from '../../../middleware/url';

import classes from './Onboard.module.css';
import Step1 from './Step1';
import Step2 from './Step2';
import { unMasking, validateEmail, validatePhone } from '../../../utils/validators';
import Step3 from './Step3';
import { clearVerfiyCouponResponse, verfiyCoupon } from '../../../store/onBoarding/verifyCoupon';
import toast from 'react-hot-toast';
import { clearConfirmPayment } from '../../../store/onBoarding/paymentConfirmation';
import axios from 'axios';
import Step4 from './Step4';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const OnBoardScreen = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [userState, setUserState] = React.useState({
    step1: {
      name: { value: '', error: '' },
      email: { value: '', error: '' },
      phone: { value: '', error: '' },
      tnc: false,
    },
    step2: { plan: { value: {}, type: '', error: '' } },
    step3: { coupon: { value: '', error: '' }, paymentId: '' },
  });

  const { verfiyCouponResponse } = useSelector(coupon => coupon.verifyCoupon);

  React.useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      dispatch(getPlans('user'));
      dispatch(clearVerfiyCouponResponse());
      dispatch(clearConfirmPayment());
    }

    return () => (isSubscribed = false);
  }, []);

  React.useEffect(() => {
    if (verfiyCouponResponse && !verfiyCouponResponse.status && verfiyCouponResponse.message) {
      toast.error(verfiyCouponResponse.message);
      setUserState(prevVal => ({ ...prevVal, step3: { ...prevVal.step3, coupon: { ...prevVal.step3.coupon, error: verfiyCouponResponse.message } } }));
    } else if (verfiyCouponResponse && verfiyCouponResponse.status && verfiyCouponResponse.message) {
      toast.success(verfiyCouponResponse.message);
    }
  }, [verfiyCouponResponse]);

  const handleStep1 = e => {
    e.preventDefault();

    if (!userState.step1.name.value.trim()) {
      setUserState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, name: { ...prevVal.step1.name, error: 'Please enter user name' } } }));
    } else if (!validateEmail(userState.step1.email.value)) {
      setUserState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, email: { ...prevVal.step1.email, error: 'Please enter email' } } }));
    } else if (!validatePhone(unMasking(userState.step1.phone.value))) {
      setUserState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, phone: { ...prevVal.step1.phone, error: 'Please enter your mobile number' } } }));
    } else {
      setCurrentStep(prevVal => prevVal + 1);
    }
  };

  const handleStep2 = e => {
    e.preventDefault();

    if (!(userState.step2.plan.value && userState.step2.plan.value.id)) {
      setUserState(prevVal => ({ ...prevVal, step2: { ...prevVal.step2, name: { ...prevVal.step2.plan, error: 'Please choose your plan' } } }));
    } else {
      setCurrentStep(prevVal => prevVal + 1);
    }
    // setCurrentStep(3);
  };

  const onBackPress = () => {
    setCurrentStep(prevVal => prevVal - 1);
  };

  const onNextStep = () => {
    setCurrentStep(prevVal => prevVal + 1);
  };

  const handleCouponVerify = e => {
    e.preventDefault();

    if (userState.step3.coupon.value) {
      dispatch(verfiyCoupon(userState.step3.coupon.value));
    } else if (!userState.step3.coupon.value) {
      toast.error('Please enter coupon code');
    }
  };

  const handlePayment = async () => {
    const config = {
      method: 'post',
      url: `${baseUrl}/user/add-onboard-data?offset=-360`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userEmail: userState.step1.email.value,
        userMobile: unMasking(userState.step1.phone.value),
        userName: userState.step1.name.value,
        userRole: 'User',
        clientType: '',
        source: userState.step3.paymentId,
        plan: userState.step2.plan.type === 'annually' ? userState.step2.plan.value?.details[0]?.id : userState.step2.plan.value?.details[1]?.id,
        promotion_code: verfiyCouponResponse && verfiyCouponResponse.data && verfiyCouponResponse.data.id ? verfiyCouponResponse.data.id : '',
      },
    };
    const response = await axios(config);
    return response;
  };

  return (
    <Elements stripe={stripePromise}>
      <div className={classes.container}>
        {currentStep === 1 ? (
          <Step1 userState={userState} setUserState={setUserState} handleStep1={handleStep1} />
        ) : currentStep === 2 ? (
          <Step2 userState={userState} setUserState={setUserState} handleStep2={handleStep2} onBackPress={onBackPress} />
        ) : currentStep === 3 ? (
          <Step3
            userState={userState}
            setUserState={setUserState}
            handleCouponVerify={handleCouponVerify}
            handlePayment={handlePayment}
            onBackPress={onBackPress}
            onNextStep={onNextStep}
          />
        ) : currentStep === 4 ? (
          <Step4 userState={userState} />
        ) : null}
      </div>
    </Elements>
  );
};

export default OnBoardScreen;
