import React from 'react'
import styles from './ImageViewer.module.css'
import HomeScreenStyles from '../../screens/Home/Home.module.css'

function ImageViewer({ onEnded = () => {}, src }) {
  console.log('source', src)

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button className={styles.modalAnswerNext_next_btn3} onClick={onEnded}>
          Next <span />
        </button>
      </div>
      <img
        alt='logo3'
        src={src}
        style={{
          width: '100%',
          height: '90%',
          objectFit: 'contain',
          // objectPosition: 'center',
          marginTop: '1rem',
        }}
      />
    </div>
  )
}

export default ImageViewer
