import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from './components';
import classes from './Onboard.module.css';

const Step4 = ({ userState }) => {
  const history = useHistory();

  return (
    <div className={classes.content}>
      <div className={classes.header_box}>
        <img src='/assets/bx_black_logo.png' alt='BX Logo' className={classes.logo} />
        <h1 className={classes.main_heading}>BX Builders</h1>
        <p className={classes.main_sub_heading}>Fostering growth. Building connections.</p>
      </div>

      <p className={classes.heading}>Welcome {userState.step1.name.value}!</p>
      <p className={classes.main_sub_heading}>
        You have successfully registered with Bx.
        <br />
        You can now download and login into the Bx App and
        <br />
        check your email for your exclusive link to the Bx Resource Center.
      </p>
      <Button type='button' onClick={() => history.push('/')}>
        Login
      </Button>
    </div>
  );
};

export default Step4;
