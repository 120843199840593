import React, { Fragment, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getConfigList,
  clearConfigList,
} from '../../store/configuration/getConfig'

import { logoutUser } from '../../store/login'

import './side.css'

const Sidebar = () => {
  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { credentials, userInfo } = useSelector((user) => user.credentials)
  const { moduleNames } = useSelector((user) => user.moduleNames)
  const { currentClient } = useSelector((user) => user.currentClient)
  const { configurationList, loading: configLoading } = useSelector(
    (config) => config.getConfig
  )

  const [showReports, setShowReports] = useState(false)
  const [showAvatarProps, setShowAvatarProps] = useState(false)
  const [showGeneric, setShowGeneric] = useState(false)
  const [showUsers, setShowUsers] = useState(false)

  const [missionsText, setMissionsText] = useState('Missions')
  const [spacesText, setSpacesText] = useState('Spaces')
  const [propertiesText, setPropertiesText] = useState('Properties')

  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getConfigList(userInfo._id))
    }
  }, [userInfo])

  useEffect(() => {
    if (moduleNames && moduleNames.data) {
      setMissionsText(moduleNames.data.stageOne)
      setSpacesText(moduleNames.data.stageTwo)
      setPropertiesText(moduleNames.data.stageThree)
    }
  }, [moduleNames])

  return (
    <Fragment>
      <div
        className={`sidebar_container ${
          !sidebar ? 'sidebar_container_active' : 'sidebar_overflow'
        }`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'hidden',
            overflowX: 'hidden',
          }}
          className='not_show_scrollbar'>
          <NavLink
            to='/'
            onClick={() => [
              setShowReports(false),
              setShowAvatarProps(false),
              setShowGeneric(false),
            ]}>
            <img
              src='/images/bx_white_logo.png'
              className={`${sidebar ? 'sidebar_logo' : 'sidebar_logo_small'}`}
            />
          </NavLink>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          className='not_show_scrollbar scrollY'>
          <NavLink
            exact
            to='/'
            className='sidebar_menu_items'
            activeClassName={`active_tab`}>
            <img
              src='/images/dashboard.png'
              className='sidebar_menu_item_logo'
            />
            <img
              src='/images/dashboard2.png'
              className='sidebar_menu_item_logo2'
            />
            {sidebar && <p className='sidebar_menu_item_title'>Dashboard</p>}
          </NavLink>
          {userInfo && userInfo.userRole === 'Admin' ? (
            <Fragment>
              <NavLink
                exact
                to='/client'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/users.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/users2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && <p className='sidebar_menu_item_title'>Clients</p>}
              </NavLink>
              <NavLink
                exact
                to='/coupons'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/coupon.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/coupon2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && <p className='sidebar_menu_item_title'>Coupons</p>}
              </NavLink>
              <NavLink
                exact
                to='/promocode'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/promo.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/promo2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>Promocode</p>
                )}
              </NavLink>
              {/* <NavLink
                exact
                to='/onboard'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/onboard.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/onboard2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && <p className='sidebar_menu_item_title'>OnBoard</p>}
              </NavLink> */}
              <NavLink
                exact
                to='/how-to'
                style={{
                  width: '100%',
                }}
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(true)}>
                <img
                  src='/images/bulb.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/bulb2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && <p className='sidebar_menu_item_title'>How To</p>}
              </NavLink>
              <NavLink
                exact
                to='/how-to/background'
                style={{
                  width: '100%',
                }}
                className='sidebar_menu_items'
                activeClassName={`active_tab`}>
                <img
                  src='/images/points.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/points2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>Backgrounds</p>
                )}
              </NavLink>
              <NavLink
                exact
                to='/how-to/tags'
                style={{
                  width: '100%',
                }}
                className='sidebar_menu_items'
                activeClassName={`active_tab`}>
                <img
                  src='/images/state.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/state2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && <p className='sidebar_menu_item_title'>Tags</p>}
              </NavLink>
              <NavLink
                exact
                to='/appversion'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/users.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/users2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>App Versions</p>
                )}
              </NavLink>

              <NavLink
                exact
                to='/faqs'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img src='/images/qa.png' className='sidebar_menu_item_logo' />
                <img
                  src='/images/qa2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && <p className='sidebar_menu_item_title'>FAQs</p>}
              </NavLink>
              <NavLink
                exact
                to='/about-the-algo'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/algo.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/algo2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>About The Algorithm</p>
                )}
              </NavLink>
              <NavLink
                exact
                to='/about-the-bx'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img src='/images/bx.png' className='sidebar_menu_item_logo' />
                <img
                  src='/images/bx2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>About The Bx</p>
                )}
              </NavLink>
              <NavLink
                exact
                to='/privacy-policy'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/privacy.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/privacy2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>Privacy Policy</p>
                )}
              </NavLink>
              {/* <NavLink
                exact
                to='/configuration'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => setShowAvatarProps(false)}>
                <img
                  src='/images/privacy.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/privacy2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>Configuration</p>
                )}
              </NavLink> */}
            </Fragment>
          ) : (
            <Fragment>
              <>
                <div
                  className='sidebar_menu_items'
                  onClick={() => {
                    setShowReports(false)
                    setShowGeneric(false)
                    setShowUsers(!showUsers)
                  }}>
                  <img
                    src='/images/categories.png'
                    className='sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/categories2.png'
                    className='sidebar_menu_item_logo2'
                  />
                  {sidebar && <p className='sidebar_menu_item_title'>Users</p>}
                </div>
              </>

              {showUsers && (
                <div>
                  <NavLink
                    to='/users'
                    className='sidebar_menu_items'
                    style={{
                      width: '100%',
                    }}
                    activeClassName={`active_tab`}>
                    <img
                      src='/images/users.png'
                      className='sidebar_menu_item_logo'
                    />
                    <img
                      src='/images/users2.png'
                      className='sidebar_menu_item_logo2'
                    />
                    {sidebar && <p className='sidebar_menu_item_title'>List</p>}
                  </NavLink>
                  <NavLink
                    to='/users-audit'
                    className='sidebar_menu_items'
                    style={{
                      width: '100%',
                    }}
                    activeClassName={`active_tab`}>
                    <img
                      src='/images/audit.png'
                      className='sidebar_menu_item_logo'
                    />
                    <img
                      src='/images/audit2.png'
                      className='sidebar_menu_item_logo2'
                    />
                    {sidebar && (
                      <p className='sidebar_menu_item_title'>Audit</p>
                    )}
                  </NavLink>
                </div>
              )}
              <NavLink
                to='/missions'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => {
                  setShowReports(false)
                  setShowGeneric(false)
                  setShowUsers(false)
                }}>
                <img
                  src='/images/missions.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/missions2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>{missionsText}</p>
                )}
              </NavLink>
              <NavLink
                to='/spaces'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => {
                  setShowReports(false)
                  setShowGeneric(false)
                  setShowUsers(false)
                }}>
                <img
                  src='/images/spaces.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/spaces2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>{spacesText}</p>
                )}
              </NavLink>
              <NavLink
                to='/properties'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => {
                  setShowReports(false)
                  setShowGeneric(false)
                  setShowUsers(false)
                }}>
                <img
                  src='/images/properties.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/properties2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>{propertiesText}</p>
                )}
              </NavLink>
              <NavLink
                to='/videos'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => {
                  setShowReports(false)
                  setShowGeneric(false)
                  setShowUsers(false)
                }}>
                <img
                  src='/images/video.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/video2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && <p className='sidebar_menu_item_title'>Assets</p>}
              </NavLink>
              <NavLink
                to='/preassets'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => {
                  setShowReports(false)
                  setShowGeneric(false)
                  setShowUsers(false)
                }}>
                <img
                  src='/images/video.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/video2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>Pre Assets</p>
                )}
              </NavLink>
              {userInfo !== null &&
                userInfo.clientType !== 'lab' &&
                configurationList &&
                configurationList.functionSetting &&
                configurationList.functionSetting.responseVideo && (
                  <NavLink
                    exact
                    to='/allresponse'
                    className='sidebar_menu_items'
                    activeClassName={`active_tab`}
                    onClick={() => {
                      setShowReports(false)
                      setShowGeneric(false)
                      setShowUsers(false)
                    }}>
                    <img
                      src='/images/response-icon.png'
                      className='sidebar_menu_item_logo'
                    />
                    <img
                      src='/images/response-icon2.png'
                      className='sidebar_menu_item_logo2'
                    />
                    {sidebar && (
                      <p className='sidebar_menu_item_title'>
                        Response Content
                      </p>
                    )}
                  </NavLink>
                )}
              <NavLink
                exact
                to='/question-categories'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => {
                  setShowReports(false)
                  setShowGeneric(false)
                  setShowUsers(false)
                }}>
                <img
                  src='/images/categories.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/categories2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>Question Categories</p>
                )}
              </NavLink>

              <>
                <div
                  className='sidebar_menu_items'
                  onClick={() => {
                    setShowReports(false)
                    setShowGeneric(!showGeneric)
                    setShowUsers(false)
                  }}>
                  <img
                    src='/images/categories.png'
                    className='sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/categories2.png'
                    className='sidebar_menu_item_logo2'
                  />
                  {sidebar && (
                    <p className='sidebar_menu_item_title'>Generic</p>
                  )}
                </div>
              </>

              {showGeneric &&
                configurationList &&
                configurationList.functionSetting &&
                configurationList.functionSetting.successVideo && (
                  <div>
                    <NavLink
                      exact
                      to='/successVideo'
                      style={{
                        width: '100%',
                      }}
                      className='sidebar_menu_items'
                      activeClassName={`active_tab`}
                      onClick={() => setShowAvatarProps(true)}>
                      <img
                        src='/images/bulb.png'
                        className='sidebar_menu_item_logo'
                      />
                      <img
                        src='/images/bulb2.png'
                        className='sidebar_menu_item_logo2'
                      />
                      {sidebar && (
                        <p className='sidebar_menu_item_title'>
                          Success Videos
                        </p>
                      )}
                    </NavLink>
                  </div>
                )}

              {userInfo !== null && (
                <Fragment>
                  <div
                    className='sidebar_menu_items'
                    onClick={() => {
                      setShowReports(!showReports)
                      setShowGeneric(false)
                      setShowUsers(false)
                    }}>
                    <img
                      src='/images/categories.png'
                      className='sidebar_menu_item_logo'
                    />
                    <img
                      src='/images/categories2.png'
                      className='sidebar_menu_item_logo2'
                    />
                    {sidebar && (
                      <p className='sidebar_menu_item_title'>Reports</p>
                    )}
                  </div>
                  {showReports && (
                    <div>
                      {configurationList &&
                        configurationList.functionSetting &&
                        configurationList.functionSetting.categoryReport && (
                          <NavLink
                            exact
                            to='/category-reports'
                            style={{
                              width: '100%',
                            }}
                            className='sidebar_menu_items'
                            activeClassName={`active_tab`}>
                            <img
                              src='/images/points.png'
                              className='sidebar_menu_item_logo'
                            />
                            <img
                              src='/images/points2.png'
                              className='sidebar_menu_item_logo2'
                            />
                            {sidebar && (
                              <p className='sidebar_menu_item_title'>
                                Category reports
                              </p>
                            )}
                          </NavLink>
                        )}
                      {configurationList &&
                        configurationList.functionSetting &&
                        configurationList.functionSetting.pointReport && (
                          <NavLink
                            exact
                            to='/point-reports'
                            style={{
                              width: '100%',
                            }}
                            className='sidebar_menu_items'
                            activeClassName={`active_tab`}>
                            <img
                              src='/images/points.png'
                              className='sidebar_menu_item_logo'
                            />
                            <img
                              src='/images/points2.png'
                              className='sidebar_menu_item_logo2'
                            />
                            {sidebar && (
                              <p className='sidebar_menu_item_title'>
                                Points reports
                              </p>
                            )}
                          </NavLink>
                        )}
                      {configurationList &&
                        configurationList.functionSetting &&
                        configurationList.functionSetting.statisticsReport && (
                          <NavLink
                            exact
                            to='/statistics-reports'
                            style={{
                              width: '100%',
                            }}
                            className='sidebar_menu_items'
                            activeClassName={`active_tab`}>
                            <img
                              src='/images/state.png'
                              className='sidebar_menu_item_logo'
                            />
                            <img
                              src='/images/state2.png'
                              className='sidebar_menu_item_logo2'
                            />
                            {sidebar && (
                              <p className='sidebar_menu_item_title'>
                                Statistics reports
                              </p>
                            )}
                          </NavLink>
                        )}

                      {configurationList &&
                        configurationList.functionSetting &&
                        configurationList.functionSetting.progressReport && (
                          <NavLink
                            exact
                            to='/process-reports'
                            className='sidebar_menu_items'
                            activeClassName={`active_tab`}>
                            <img
                              src='/images/state.png'
                              className='sidebar_menu_item_logo'
                            />
                            <img
                              src='/images/state2.png'
                              className='sidebar_menu_item_logo2'
                            />
                            {sidebar && (
                              <p className='sidebar_menu_item_title'>
                                Progress reports
                              </p>
                            )}
                          </NavLink>
                        )}
                    </div>
                  )}
                </Fragment>
              )}

              {userInfo !== null &&
                userInfo.clientType === 'lab' &&
                configurationList &&
                configurationList.functionSetting &&
                configurationList.functionSetting.pretestModule && (
                  <NavLink
                    exact
                    to='/pretest-reports'
                    className='sidebar_menu_items'
                    activeClassName={`active_tab`}>
                    <img
                      src='/images/state.png'
                      className='sidebar_menu_item_logo'
                    />
                    <img
                      src='/images/state2.png'
                      className='sidebar_menu_item_logo2'
                    />
                    {sidebar && (
                      <p className='sidebar_menu_item_title'>Pretest reports</p>
                    )}
                  </NavLink>
                )}

              <NavLink
                exact
                to='/notification'
                className='sidebar_menu_items'
                activeClassName={`active_tab`}
                onClick={() => {
                  setShowReports(false)
                  setShowGeneric(false)
                  setShowUsers(false)
                }}>
                <img
                  src='/images/notification.png'
                  className='sidebar_menu_item_logo'
                />
                <img
                  src='/images/notification2.png'
                  className='sidebar_menu_item_logo2'
                />
                {sidebar && (
                  <p className='sidebar_menu_item_title'>Notifications</p>
                )}
              </NavLink>
            </Fragment>
          )}
        </div>
        <div>
          <NavLink
            exact
            to='/my-profile'
            className='sidebar_menu_items'
            activeClassName={`active_tab`}>
            <svg className='sidebar_menu_item_logo'>
              <use xlinkHref={`/images/sprite.svg#icon-settings`} />
            </svg>
            <svg className='sidebar_menu_item_logo2'>
              <use xlinkHref={`/images/sprite.svg#icon-settings`} />
            </svg>
            {sidebar && <p className='sidebar_menu_item_title'>Settings</p>}
          </NavLink>
          <div
            onClick={() => dispatch(logoutUser())}
            className='logout_link_container'
            activeclassname={`active_tab`}>
            <img src='/images/logout.png' className='sidebar_menu_item_logo' />
            {sidebar && <p className='sidebar_menu_item_title'>Logout</p>}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Sidebar
