import React from 'react';

const SmallChartSkeleton = ({ from }) => {
	const count = [ 7, 2, 5, 8 ];

	return (
		<div className='chartContainer'>
			<div className='chart'>
				{count &&
					count.map((item) => (
						<div className='chart__stack' key={item}>
							<div className='chart__bar'>
								<div className='incomplete_stack_sleleton skeleton' style={{ height: `${item + 10}vh` }}>
									<div className='complete_stack_sleleton' style={{ height: `${item + 3}vh` }} />
								</div>
							</div>
							<div className='chart__bar_label_skeleton skeleton' />
						</div>
					))}
			</div>
			{from === 'spaceChart' ? (
				<div className='date_container'>
					<div className='dropdown_container'>
						<div className='date__input_skeleton skeleton' />
					</div>
				</div>
			) : (
				<div className='chartIndicator'>
					<div className='chartBottom'>
						<div className='indicator__android_sleleton skeleton' />
						<div className='indicator__android_label_sleleton skeleton' />
					</div>
					<div className='chartBottom'>
						<div className='indicator__ios_sleleton sleleton' />
						<div className='indicator__android_label_sleleton skeleton' />
					</div>
				</div>
			)}

			<div id='ticks'>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
				<div className='tick' style={{ height: '4rem' }}>
					<div className='tick_skeleton skeleton' />
				</div>
			</div>
		</div>
	);
};

export default SmallChartSkeleton;
