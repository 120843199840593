import {
	GET_MISSIONS_REQUEST,
	GET_MISSIONS_SUCCESS,
	GET_MISSIONS_FAIL,
	GET_MISSIONS_RESET
} from '../constants/missionConstants';

export const allMissionsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_MISSIONS_REQUEST:
			return { loading: true };

		case GET_MISSIONS_SUCCESS:
			return { missions: action.payload, loading: false };

		case GET_MISSIONS_FAIL:
			return { loading: false, error: action.payload };

		case GET_MISSIONS_RESET:
			return {};

		default:
			return state;
	}
};
