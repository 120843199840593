import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'fail-response-content-list',
  initialState: {
    failResponseContentList: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.failResponseContentList = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.failResponseContentList = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.failResponseContentList = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getFailResponseContent = () =>
  apiCallBegan({
    url: '/response-content/get-response-content-by-return-type?type=fail',
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearGetFailResponseContent = () =>
  apiCallBegan({
    onLogout: reset.type,
  })
