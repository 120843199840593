import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiSave } from 'react-icons/fi'
import { toast } from 'react-toastify'

import AppHeader from '../../../../components/AppHeader'
import AppButton from '../../../../components/forms/AppButton'
import AppLoader from '../../../../components/AppLoader'

import { getPrivacy } from '../../../../store/privacy'
import {
  addPrivacy,
  clearaddPrivacyResponse,
} from '../../../../store/privacy/addPrivacy'

import './privacy.css'

const PrivacyPolicy = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { privacyData } = useSelector((privacy) => privacy.privacyData)
  const { addPrivacyResponse, loading } = useSelector(
    (about) => about.addPrivacyResponse
  )

  const [toggleButton, setToggleButton] = useState(false)
  const [privacyText, setPrivacyText] = useState('')

  const PRIVACY =
    privacyData &&
    privacyData.data &&
    privacyData.data.result &&
    privacyData.data.result.content

  useEffect(() => {
    dispatch(getPrivacy())
  }, [dispatch])

  useEffect(() => {
    if (addPrivacyResponse !== null) {
      if (addPrivacyResponse.status) {
        toast.success(addPrivacyResponse.message)
        dispatch(getPrivacy())
        dispatch(clearaddPrivacyResponse())
        setToggleButton(false)
      } else {
        toast.error(addPrivacyResponse.message)
        dispatch(clearaddPrivacyResponse())
        setToggleButton(true)
      }
    }
  }, [addPrivacyResponse, dispatch])

  const onChangeHandler = (event) => {
    setPrivacyText(event.target.value)
  }

  const onEditHandler = () => {
    setPrivacyText(PRIVACY)
    setToggleButton(true)
  }

  const onSavetHandler = async (event) => {
    event.preventDefault()
    if (privacyText === '') {
      toast.error('Please enter description of privacy policy')
    } else {
      await dispatch(addPrivacy({ content: privacyText.trim() }))
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Privacy Policy' />
      <div className='privacy_main_content'>
        {!toggleButton ? (
          <div>
            <div className='privacy_default_content'>
              <p className='form_description_text'>{PRIVACY && PRIVACY}</p>
            </div>
            <AppButton
              title='edit'
              buttonContainer={{ backgroundColor: 'var(--secondary)' }}
              icon={<img src='/images/edit.png' className='app_button_image' />}
              onClick={onEditHandler}
            />
          </div>
        ) : (
          <form className='policy_container' onSubmit={onSavetHandler}>
            <textarea
              spellCheck={false}
              autoFocus
              rows={17}
              className='textarea'
              style={{ borderBottom: 'none' }}
              value={privacyText}
              onChange={onChangeHandler}
            />
            <AppButton
              title='save'
              type='submit'
              buttonContainer={{ backgroundColor: 'var(--secondary)' }}
              icon={<FiSave className='app_button_image' />}
              onClick={onSavetHandler}
            />
          </form>
        )}
      </div>
      <AppLoader loader={loading} />
    </div>
  )
}

export default PrivacyPolicy
