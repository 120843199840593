import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'add-space',
    initialState: {
        addSpaceResponse: null,
        loading: false,
    },
    reducers: {
        requested: (space) => {
            space.loading = true
        },
        success: (space, action) => {
            space.addSpaceResponse = action.payload
            space.loading = false
        },
        failed: (space, action) => {
            space.addSpaceResponse = action.payload;
            space.loading = false
        },
        reset: (space, action) => {
            space.addSpaceResponse = action.payload;
            space.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addSpace = (data, onUploadProgress) => apiCallBegan({
    url: '/space/create-and-update-space',
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearAddSpaceResponse = () => apiCallBegan({
    onReset: reset.type,
})