import React from 'react';
import styles from './Components.module.css';
import Button from './Button';

const PaymentConfirmationModal = ({ loading, selected, onConfirm, closePayHandler, verfiyCouponResponse }) => {
  const [discountPrice, setDiscountPrice] = React.useState(0);
  const planAmount =
    selected.type === 'annually' ? `${(selected?.value?.details[0]?.unit_amount / 100).toFixed(2)}` : `${(selected?.value?.details[1]?.unit_amount / 100).toFixed(2)}`;
  const cPlanAmount = `${selected?.value?.currency}${planAmount}`;

  React.useEffect(() => {
    if (
      verfiyCouponResponse &&
      verfiyCouponResponse.status &&
      verfiyCouponResponse.data &&
      verfiyCouponResponse.data.coupon &&
      typeof verfiyCouponResponse.data.coupon.percent_off === 'number'
    ) {
      let newAmount = planAmount - planAmount * (verfiyCouponResponse.data.coupon.percent_off / 100);

      if (newAmount < 0) {
        newAmount = 0;
      }
      setDiscountPrice(newAmount.toFixed(2));
    }
  }, [verfiyCouponResponse]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.confirmCardContainer}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <div className={styles.loaderText1}>Please wait ....</div>
              <div className={styles.loaderText1}>Do not press back button or refresh page</div>
            </div>
          ) : (
            <div className={styles.confirmCard}>
              <p className={styles.confirmCardHeader}>Payment Confirmation</p>
              <div className={styles.confirmCardTopCantainer}>
                <p className={styles.confirmCardText}>
                  Selected Plan:<span>{`${selected?.value?.name} (${selected.type})`}</span>
                </p>
                <p className={styles.confirmCardText}>
                  Total Amount:
                  {discountPrice ? (
                    <>
                      <span className={styles.confirmCardTextPrice_striked}>{cPlanAmount}</span>
                      <span className={styles.confirmCardTextPrice}>
                        {selected?.value?.currency}
                        {discountPrice}
                      </span>
                    </>
                  ) : (
                    <span className={styles.confirmCardTextPrice}>{cPlanAmount}</span>
                  )}
                </p>
                <p className={styles.confirmCardDesc}>Would you like to proceed with the above selected plan?</p>
              </div>

              {selected?.value && selected.value?.amount === 0 ? (
                <Button type='button' disabled={loading} onClick={onConfirm}>
                  Yes, Proceed for Payment
                </Button>
              ) : (
                <Button type='button' disabled={loading} onClick={onConfirm}>
                  Yes, Proceed for Payment
                </Button>
              )}
              <button className={styles.confirmCardButtonClose} onClick={closePayHandler}>
                No, I would like to edit my details
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmationModal;
