import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'

import AppHeader from '../../../../components/AppHeader'
import StatisticsCard from '../../../../components/Cards/ReportsCard/StatisticsCard'
import AllMissionsSkeleton from '../../../../components/Skeletons/AllMissionsSkeleton'

import { getStatisticsReport } from '../../../../store/reports/statisticsReport'
import { getStatisticsChartReport } from '../../../../store/reports/statisticsChartReport'
import ReportToggle from './ReportToggle'
import { options } from './chartOptions'
import { useHistory } from 'react-router'
const StatisticsReport = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { statisticsReport, loading } = useSelector(
    (report) => report.statisticsReport
  )
  const { statisticsChartReport } = useSelector(
    (report) => report.statisticsChartReport
  )

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const [ischarts, setISCharts] = useState(false)

  useEffect(() => {
    dispatch(getStatisticsReport())
    dispatch(getStatisticsChartReport())
  }, [dispatch])

  const onClientChange = (item) => {
    if (item && item.clientType === 'lab') {
      history.push('/')
    } else {
      dispatch(getStatisticsReport())
      dispatch(getStatisticsChartReport())
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Statistics Report' onClientChange={onClientChange} />
      <ReportToggle onChange={(status) => setISCharts(status)} />
      <div className='client__container'>
        {ischarts ? (
          statisticsReport !== null &&
          statisticsReport.data &&
          statisticsReport.data.length > 0 ? (
            statisticsChartReport &&
            statisticsChartReport.data && (
              <Bar
                data={statisticsChartReport.data}
                options={options}
                className='report_chart_container'
              />
            )
          ) : (
            <div className='data_not_found_container'>
              <p className='data_not_found_message'>
                {statisticsReport !== null && statisticsReport.message}
              </p>
            </div>
          )
        ) : (
          <div className='missions_main_content'>
            {loading ? (
              <AllMissionsSkeleton />
            ) : statisticsReport !== null &&
              statisticsReport.data &&
              statisticsReport.data.length > 0 ? (
              statisticsReport.data.map((item, index) => (
                <StatisticsCard item={item} key={index} index={index} />
              ))
            ) : (
              <div className='data_not_found_container'>
                <p className='data_not_found_message'>
                  {statisticsReport !== null && statisticsReport.message}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default StatisticsReport
