import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { checkMobileAction } from '../../redux/actions/userActions';
import { login, logoutUser } from '../../../store/login';
import { validatePhone } from '../../utils/validators';

import LoginSkeleton from '../../components/Skeletons/LoginSkeleton';
import OtpSkeleton from '../../components/Skeletons/OtpSkeleton';
import OtpInput from 'react-otp-input';
import toast from 'react-hot-toast';
import LoginStyles from './Login.module.css';
import { useHistory } from 'react-router';
import Support from '../../../components/Support';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [mobileNumber, setMobileNumber] = useState({ value: '', error: '' }); //2086310747 7202448905 9595389500
  const [otpValue, setOtpValue] = useState({ value: '', error: '' });
  const [checkMobileResponseLoading, setCheckMobileResponseLoading] = useState(false);
  const [checkMobileResponse, setCheckMobileResponse] = useState(null);

  // const userLogin = useSelector((state) => state.userLogin);
  const { credentials, loading } = useSelector(state => state.credentials);

  useEffect(() => {
    if (credentials !== null) {
      if (credentials.status) {
        toast.success(credentials.message);
      } else {
        setOtpValue(prevOtpData => ({
          ...prevOtpData,
          error: credentials.message,
        }));
        toast.error(credentials.message);
        dispatch(logoutUser());
      }
    }
  }, [credentials, dispatch]);

  useEffect(() => {
    if (checkMobileResponse !== null && !checkMobileResponse.status) {
      setMobileNumber(prevMobData => ({
        ...prevMobData,
        error: checkMobileResponse.message,
      }));

      if (mobileNumber?.value && !checkMobileResponse?.isSubscriptionActive && checkMobileResponse.status) {
        history.push(`/reactive-subscription/${mobileNumber.value}/${checkMobileResponse?.clientType}`);
      }
    }

    if (checkMobileResponse !== null && checkMobileResponse.status) {
      setStep(2);
      setMobileNumber(prevMobData => ({
        ...prevMobData,
        error: checkMobileResponse.message,
      }));
    }
  }, [checkMobileResponse]);

  const handleCheckMobile = async e => {
    e.preventDefault();

    if (mobileNumber.value === '' || mobileNumber.value.trim() === '') {
      setMobileNumber({
        ...mobileNumber,
        error: 'Please enter your mobile number',
      });
    } else if (mobileNumber.value.length !== 10) {
      setMobileNumber({
        ...mobileNumber,
        error: 'Mobile number must have 10 digits',
      });
    } else if (!validatePhone(mobileNumber.value)) {
      setMobileNumber({
        ...mobileNumber,
        error: 'Mobile number should be in digits',
      });
    } else {
      setCheckMobileResponseLoading(true);

      const data = await checkMobileAction(mobileNumber.value);
      setCheckMobileResponse(data);
      setCheckMobileResponseLoading(false);

      if (data && data.status) {
        toast.success(data.message);
      } else if (data && !data.status) {
        toast.error(data.message);
      }
    }
  };

  const verifyOtp = () => {
    if (otpValue.value === '' || otpValue.value.trim() === '') {
      setOtpValue({ ...otpValue, error: 'Please enter your OTP to proceed' });
    } else {
      dispatch(login({ userMobile: mobileNumber.value, otp: otpValue.value }));
    }
  };

  return (
    <div className={LoginStyles.container}>
      <div className={LoginStyles.login_container}>
        <div className={LoginStyles.login_container_cards}>
          <div className={LoginStyles.login_container_card}>
            <h3 className={LoginStyles.login_container_card_text}>Connect, Practice,</h3>
            {/* <h2 className={LoginStyles.login_container_card_text_big}>interventions to teach</h2> */}
            <h2 className={LoginStyles.login_container_card_text2}>Master.</h2>
          </div>
          <div className={LoginStyles.login_container_card}>
            <h3 className={LoginStyles.login_container_card_text}>Play And</h3>
            {/* <h2 className={LoginStyles.login_container_card_text_big}>learn</h2> */}
            <h2 className={LoginStyles.login_container_card_text2}>Learn.</h2>
          </div>
          <div className={LoginStyles.login_container_card}>
            <h3 className={LoginStyles.login_container_card_text}>Fostering</h3>
            {/* <h2 className={LoginStyles.login_container_card_text_big}>connect, practice,</h2> */}
            <h2 className={LoginStyles.login_container_card_text2}>Growth.</h2>
          </div>
          <div className={LoginStyles.login_container_card}>
            <h3 className={LoginStyles.login_container_card_text}>Building</h3>
            {/* <h2 className={LoginStyles.login_container_card_text_big}>free access to underfunded and underserved</h2> */}
            <h2 className={LoginStyles.login_container_card_text2}>Connections.</h2>
          </div>
        </div>

        {step === 1 ? (
          <div className={LoginStyles.login_container_login}>
            <h1 className={LoginStyles.login_container_loginHeading2}>Login</h1>
            <div className={LoginStyles.login_container_loginBox}>
              <img src='/assets/bx_black_logo.png' alt='BX Logo' className={LoginStyles.login_container_loginBox_logo} />
              {checkMobileResponseLoading ? (
                <LoginSkeleton />
              ) : (
                <Fragment>
                  <form onSubmit={handleCheckMobile} className={LoginStyles.login_container_form}>
                    <div>
                      <div className={LoginStyles.login_container_loginBox_input}>
                        <svg className={LoginStyles.login_container_loginBox_inputIcon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-phone`} />
                        </svg>
                        <input
                          type='text'
                          placeholder='Phone Number'
                          value={mobileNumber.value}
                          autoFocus
                          maxLength={10}
                          onChange={e =>
                            setMobileNumber({
                              value: e.target.value,
                              error: '',
                            })
                          }
                          className={LoginStyles.login_container_loginBox_inputBox}
                        />
                      </div>
                      {mobileNumber.error && <p className={LoginStyles.login_container_loginBox_inputBoxError}>{mobileNumber.error}</p>}
                    </div>

                    {/* <OTPTextInput inputs={10} setOtpValue={setOtpValue} /> */}
                    <button className={LoginStyles.login_container_loginBox_button} onClick={handleCheckMobile}>
                      login
                      <svg className={LoginStyles.login_container_loginBox_buttonIcon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-login`} />
                      </svg>
                    </button>
                  </form>
                  <div>
                    <p className={LoginStyles.login_container_loginBox_register}>Don't have an account?</p>
                    <Link to='/on-board' className={LoginStyles.login_container_loginBox_registerBtn}>
                      Register Now
                    </Link>
                    <div className={LoginStyles.login_container_card_bottom}>
                      <p>
                        <a
                          className={LoginStyles.termsandcondition}
                          href='https://d3qtg5d4imwnsn.cloudfront.net/general/bx_tc.pdf'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Terms and Conditions
                        </a>
                      </p>
                      <p className={LoginStyles.line}>|</p>

                      <p>
                        <a className={LoginStyles.agreement} href='https://d3qtg5d4imwnsn.cloudfront.net/general/bx_eula.pdf' target='_blank' rel='noopener noreferrer'>
                          EULA
                        </a>
                      </p>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
            <h1 className={LoginStyles.login_container_loginHeading}>Login</h1>
          </div>
        ) : (
          <div className={LoginStyles.login_container_login}>
            <h1 className={LoginStyles.login_container_loginHeading2}>VERIFY OTP</h1>
            <div className={LoginStyles.login_container_loginBox}>
              <img src='/assets/bx_black_logo.png' alt='BX Logo' className={LoginStyles.login_container_loginBox_logo} />
              {checkMobileResponseLoading || loading ? (
                <OtpSkeleton />
              ) : (
                <Fragment>
                  <form onSubmit={verifyOtp} className={LoginStyles.login_container_form}>
                    <div>
                      {/* <div
                        className={LoginStyles.login_container_loginBox_input}>
                        <svg
                          className={
                            LoginStyles.login_container_loginBox_inputIcon
                          }>
                          <use
                            xlinkHref={`/assets/sprite.svg#icon-phone-message`}
                          />
                        </svg>
                        <input
                          type='text'
                          placeholder='Enter Secure code'
                          value={otpValue.value}
                          autoFocus
                          onChange={(e) => {
                            setOtpValue({ value: e.target.value, error: '' })
                          }}
                          className={
                            LoginStyles.login_container_loginBox_inputBox
                          }
                        />
                      </div> */}
                      <OtpInput
                        shouldAutoFocus={true}
                        value={otpValue.value}
                        onChange={value => {
                          setOtpValue({ value: value, error: '' });
                        }}
                        numInputs={4}
                        separator={false}
                        inputStyle={{
                          width: window.screen.width > 767 && window.screen.width < 991.98 ? '5rem' : '4rem',
                          height: window.screen.width > 767 && window.screen.width < 991.98 ? '5rem' : '4rem',
                          marginRight: '1rem',
                          fontSize: window.screen.width > 767 && window.screen.width < 991.98 ? '2rem' : '1.6rem',
                          borderRadius: '0.5rem',
                          color: 'var(--black2)',
                          backgroundColor: 'var(--white2)',
                          boxShadow: '0px 2px 3rem 5px rgba(30, 112, 152, 0.1)',
                          border: '2px solid var(--white2)',
                          outline: 'none',
                        }}
                        focusStyle={{
                          border: '2px solid #9fcbe6',
                          color: '#303c6d',
                          outline: 'none',
                        }}
                      />
                      {otpValue.error && <p className={LoginStyles.login_container_loginBox_inputBoxError}>{otpValue.error}</p>}
                    </div>
                    <button className={LoginStyles.login_container_loginBox_button} onClick={verifyOtp}>
                      Verify
                      <svg className={LoginStyles.login_container_loginBox_buttonIcon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-verify`} />
                      </svg>
                    </button>
                  </form>

                  <div>
                    <p className={LoginStyles.login_container_loginBox_register}>&nbsp;</p>
                    <button onClick={handleCheckMobile} className={LoginStyles.login_container_loginBox_registerBtn}>
                      <span>Resend</span> Secure Code
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
            <h1 className={LoginStyles.login_container_loginHeading}>VERIFY OTP</h1>
          </div>
        )}
      </div>
      <Support />
    </div>
  );
};

export default LoginScreen;
