import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { FiSave } from 'react-icons/fi'
import { toast } from 'react-toastify'

import AppHeader from '../../../../components/AppHeader'
import Modal from '../../../../components/AppModal'
import AppButton from '../../../../components/forms/AppButton'

import { getAboutTheAlgorithm } from '../../../../store/about/getAboutTheAlgo'
import {
  addAboutTheAlgorithm,
  clearAddAboutTheAlgorithmResponse,
} from '../../../../store/about/aboutTheAlgorithm'

import './aboutAlgo.css'
import AppLoader from '../../../../components/AppLoader'

const AboutTheAlgo = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { aboutTheAlgorithm } = useSelector((about) => about.aboutTheAlgorithm)
  const { aboutTheAlgorithmResponse, loading } = useSelector(
    (res) => res.aboutTheAlgorithmResponse
  )

  const [show, setShow] = useState(false)
  const [shortDescription, setShortDescription] = useState('')
  const [shortDescriptionError, setShortDescriptionError] = useState('')
  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [image, setImage] = useState('')
  const [imageError, setImageError] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    dispatch(getAboutTheAlgorithm('algo'))
  }, [dispatch])

  useEffect(() => {
    if (aboutTheAlgorithmResponse !== null) {
      if (aboutTheAlgorithmResponse.status) {
        setShow(false)
        toast.success(aboutTheAlgorithmResponse.message)
        dispatch(getAboutTheAlgorithm('algo'))
        dispatch(clearAddAboutTheAlgorithmResponse())
      } else {
        toast.error(aboutTheAlgorithmResponse.message)
        dispatch(clearAddAboutTheAlgorithmResponse())
      }
    }
  }, [aboutTheAlgorithmResponse, dispatch])

  const onDrop = useCallback((acceptedFiles) => {
    setImageError('')
    setSelectedFile(acceptedFiles[0])
    setImage(URL.createObjectURL(acceptedFiles[0]))
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const onEditHandler = () => {
    setShow(true)
    if (aboutTheAlgorithm !== null && aboutTheAlgorithm.data) {
      setShortDescription(aboutTheAlgorithm.data.shortDescription)
      setDescription(aboutTheAlgorithm.data.aboutDescription)
      setImage(aboutTheAlgorithm.data.aboutImage)
    }
  }

  const onUpdateHandler = async (event) => {
    event.preventDefault()

    if (shortDescription === '') {
      setShortDescriptionError('Please enter Short Description')
    } else if (description === '') {
      setDescriptionError('Please enter Description')
    } else if (image === '') {
      setImageError('Please select thumbnail')
    } else {
      const data = new FormData()

      if (selectedFile !== null) {
        data.append('image', selectedFile, selectedFile.name)
      }

      data.append('type', 'algo')
      if (
        aboutTheAlgorithm &&
        aboutTheAlgorithm.data &&
        aboutTheAlgorithm.data._id
      ) {
        data.append('id', aboutTheAlgorithm.data._id)
      }
      data.append('shortDescription', shortDescription.trim())
      data.append('aboutDescription', description.trim())
      await dispatch(addAboutTheAlgorithm(data))
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='About The Algorithm' />
      <div className='algo_main_content'>
        <div className='algo_default_content'>
          <img
            src={
              aboutTheAlgorithm &&
              aboutTheAlgorithm.data &&
              aboutTheAlgorithm.data.aboutImage
            }
            className='bx_algo_logo_image'
          />
          <p className='form_header_text mt-2'>Short Description</p>
          <p className='form_description_text mt-1'>
            {aboutTheAlgorithm &&
              aboutTheAlgorithm.data &&
              aboutTheAlgorithm.data.shortDescription}
          </p>

          <p className='form_header_text mt-2'>Description</p>
          <p className='form_description_text mt-1'>
            {aboutTheAlgorithm &&
              aboutTheAlgorithm.data &&
              aboutTheAlgorithm.data.aboutDescription}
          </p>
        </div>
      </div>
      <AppButton
        title='edit'
        buttonContainer={{ backgroundColor: 'var(--secondary)' }}
        icon={<img src='/images/edit.png' className='app_button_image' />}
        onClick={onEditHandler}
      />
      <Modal show={show}>
        <p className='modal_heading'>Edit About The Algorithm Details</p>
        <div className='bx_algo_edit_modal mt-2'>
          <form className='bx_algo_edit_modal_left' onSubmit={onUpdateHandler}>
            <textarea
              spellCheck={false}
              autoFocus
              className='textarea'
              rows={2}
              value={shortDescription}
              onChange={(event) => {
                setShortDescription(event.target.value)
                setShortDescriptionError('')
              }}
            />
            {shortDescriptionError && (
              <p className='error_message'>{shortDescriptionError}</p>
            )}
            <p
              className='form_header_text'
              style={{ color: 'var(--secondary)' }}>
              Short Description
            </p>
            <textarea
              spellCheck={false}
              autoFocus
              className='textarea'
              rows={4}
              style={{ marginTop: '2rem' }}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value)
                setDescriptionError('')
              }}
            />
            {descriptionError && (
              <p className='error_message'>{descriptionError}</p>
            )}
            <p
              className='form_header_text'
              style={{ color: 'var(--secondary)' }}>
              Description
            </p>

            <div className='modal_buttons'>
              <AppButton
                title='update'
                type='submit'
                icon={<FiSave className='app_button_image' />}
                onClick={onUpdateHandler}
              />
              <AppButton
                title='cancel'
                type='button'
                buttonContainer={{
                  backgroundColor: 'var(--transparent)',
                  marginLeft: '0.5rem',
                }}
                textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                onClick={() => setShow(false)}
              />
            </div>
          </form>
          <div className='bx_algo_edit_modal_right'>
            <p
              className='form_header_text'
              style={{ color: 'var(--secondary)' }}>
              Upload Logo
            </p>
            <div {...getRootProps()} className='modal_drag_and_drop_view'>
              <input {...getInputProps()} accept='image/png, image/jpg' />
              {image ? (
                <img
                  alt='image'
                  src={image}
                  className='modal_actual_logo_image'
                />
              ) : (
                <Fragment>
                  <img
                    className='modal_logo_image'
                    src='/images/select_file.png'
                    alt='logo'
                  />
                  <p className='form_description_text'>Select file to upload</p>
                </Fragment>
              )}
            </div>
            {imageError && <p className='error_message'>{imageError}</p>}
          </div>
        </div>
      </Modal>
      <AppLoader loader={loading} />
    </div>
  )
}

export default AboutTheAlgo
