import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'cetegory-list',
    initialState: {
        cetegoryList: [],
        loading: false,
    },
    reducers: {
        requested: (cetegory, action) => {
            cetegory.loading = true
        },
        success: (cetegory, action) => {
            cetegory.cetegoryList = action.payload;
            cetegory.loading = false
        },
        failed: (cetegory, action) => {
            cetegory.cetegoryList = action.payload;
            cetegory.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getCetegoryList = data => apiCallBegan({
    url: '/category/get-category',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})