import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'delete-success-videos',
  initialState: {
    deleteSuccessVideo: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.deleteSuccessVideo = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.deleteSuccessVideo = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.deleteSuccessVideo = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteSuccessVideos = (id) =>
  apiCallBegan({
    url: `general/delete-general-video?id=${id}`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearDeleteSuccessVideosResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
