import React from 'react';
import classes from './Components.module.css';

const CheckBox = ({ onClick, checked, label }) => {
  return (
    <button type='button' onClick={onClick} className={classes.checkBtn}>
      <span>
        <svg>
          <use xlinkHref={`/assets/sprite.svg#icon-${checked ? 'check-circle-o' : 'circle-o'}`} />
        </svg>
      </span>
      {label}
    </button>
  );
};

export default CheckBox;
