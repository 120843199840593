import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import './reports.css'

import AppHeader from '../../../../components/AppHeader'
import { useHistory } from 'react-router'

import { getPreTestChartReport } from '../../../../store/reports/preTestChartReport'
import { getPreTestReport } from '../../../../store/reports/preTestReport'
import { options } from './chartOptions'
import ReportToggle from './ReportToggle'
import AllMissionsSkeleton from '../../../../components/Skeletons/AllMissionsSkeleton'

const PreTestReport = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { preTestReport, loading } = useSelector(
    (report) => report.preTestReport
  )
  const { preTestChartReport } = useSelector(
    (report) => report.preTestChartReport
  )

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const [ischarts, setISCharts] = useState(false)

  useEffect(() => {
    dispatch(getPreTestChartReport())
    dispatch(getPreTestReport())
  }, [dispatch])

  const onClientChange = (item) => {
    if (item && item.clientType !== 'lab') {
      history.push('/')
    } else {
      dispatch(getPreTestChartReport())
      dispatch(getPreTestReport())
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Pretest Report' onClientChange={onClientChange} />
      <ReportToggle onChange={(status) => setISCharts(status)} />
      <div className='client__container'>
        {ischarts ? (
          preTestReport !== null &&
          preTestReport.data &&
          preTestReport.data.length > 0 ? (
            preTestChartReport &&
            preTestChartReport.data && (
              <Bar
                data={preTestChartReport.data}
                options={options}
                className='report_chart_container'
              />
            )
          ) : (
            <div className='data_not_found_container'>
              <p className='data_not_found_message'>
                {preTestReport !== null && preTestReport.message}
              </p>
            </div>
          )
        ) : (
          <div className='missions_main_content'>
            {loading ? (
              <AllMissionsSkeleton />
            ) : preTestReport !== null &&
              preTestReport.data &&
              preTestReport.data.length > 0 ? (
              preTestReport.data.map((item, index) => (
                <div className='big_heades_main_card_container' key={index}>
                  <div className='big_heades_card_container'>
                    <div className='big_heades_card_view'>
                      <div className='big_heades_card_view_container'>
                        <div className='client_card_detail_container'>
                          <p className='big_heades_card_title'>
                            {item.userName}
                          </p>
                        </div>
                      </div>
                      <p className='total_answered'>
                        Total Attempted: {item.totalAttemptedQuestion}
                      </p>
                      <p className='right_wrong_answered'>
                        Right Answer: {item.correctQuestion} | Wrong Answer:{' '}
                        {item.wrongQuestion}
                      </p>
                      <div className='progressbar_outer'>
                        <div
                          className='progressbar_inner_right'
                          style={{
                            width: item.percentage + '%',
                          }}
                        />
                        {/* <div
												className='progressbar_inner_wrong'
												style={{
													width: item.percentage + '%'
												}}
											/> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='data_not_found_container'>
                <p className='data_not_found_message'>
                  {preTestReport !== null && preTestReport.message}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default PreTestReport
