import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import './Questions.css'

import { IoChevronBackSharp } from 'react-icons/io5'

import AppHeader from '../../../../components/AppHeader'
import QuestionCard from '../../../../components/Cards/QuestionCard'
import AppModal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'
import AppButton from '../../../../components/forms/AppButton'
import TextInput from '../../../../components/forms/TextInput'
import ProgressBar from '../../../../components/forms/ProgressBar'
import AllQuestionsSkeleton from '../../../../components/Skeletons/AllQuestionsSkeleton'

import SingleOption from './SingleOption'
import MatchPair from './MatchPair'
import MultipleOptions from './MultipleOptions'
import RightWrong from './RightWrong'

import { getQuestionList } from '../../../../store/questions/questionList'
import { getEnabledCetegoryList } from '../../../../store/cetegory/enabledCetegoryList'
import { setEnableDisableQuestion } from '../../../../store/questions/enableDisableQuestion'
import { getResponseContent } from '../../../../store/questions/responseContentList'
import { getFailResponseContent } from '../../../../store/questions/failResponseContentList'
import {
  addQuestion,
  clearAddQuestionResponse,
} from '../../../../store/questions/addQuestion'
import {
  clearDeleteQuestionResponse,
  deleteQuestion,
} from '../../../../store/questions/deleteQuestion'
import AppSwitch from '../../../../components/AppSwitch'
import Pagination from '../../../../components/Pagination'
import AddResponseVideo from './AddResponseVideo'

import { validateFillInTheBlank } from '../../../../utils/validators'

const Quesions = ({ match, history }) => {
  const { videoId, videoName, mediaType } = match.params

  const questionTypes = [
    {
      id: 1,
      value: 'singleOption',
      type: 'Single Option',
    },
    {
      id: 2,
      value: 'matchPair',
      type: 'Match Pair',
    },
    {
      id: 3,
      value: 'multipleOption',
      type: 'Multiple Options',
    },
    {
      id: 4,
      value: 'rightWrongOption',
      type: 'Right Wrong',
    },
    {
      id: 5,
      value: 'fillInTheBlanks',
      type: 'Fill in the Blanks',
    },
  ]

  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { questionList, loading } = useSelector((color) => color.questionList)
  const { enabledCetegoryList } = useSelector(
    (list) => list.enabledCetegoryList
  )
  const { addQuestionResponse } = useSelector(
    (list) => list.addQuestionResponse
  )
  const { deleteQuestionResponse } = useSelector(
    (list) => list.deleteQuestionResponse
  )
  const { enabledVideoList } = useSelector((list) => list.enabledVideoList)

  const { message } = useSelector((state) => state.questionResponseContent)

  const [questionCategory, setQuestionCategory] = useState('select-category')
  const [questionCategoryError, setQuestionCategoryError] = useState('')
  const [questionType, setQuestionType] = useState('select-question-type')
  const [questionTypeError, setQuestionTypeError] = useState('')

  const [videoTitle, setVideoTitle] = useState('')

  const [questionTitle, setQuestionTitle] = useState('')
  const [questionTitleError, setQuestionTitleError] = useState('')
  const [questionId, setQuestionId] = useState()
  const [currentItem, setCurrentItem] = useState(null)

  const [showAddModal, setShowAddModal] = useState(false)
  const [isUpadate, setIsUpadate] = useState(null)
  const [questionItem, setQuestionItem] = useState(null)
  const [progress, setProgress] = useState(null)

  const [singleOptions, setSingleOptions] = useState([])
  const [singleOptionAnswer, setSingleOptionAnswer] = useState('')
  const [singleOptionsError, setSingleOptionsError] = useState('')

  const [multipleOptions, setMultipleOptions] = useState([])
  const [multipleAnswers, setMultipleAnswers] = useState([])
  const [multipleOptionsError, setMultipleOptionsError] = useState('')

  const [rightWrongOptions, setRightWrongOptions] = useState([])
  const [rightWrongOptionsError, setRightWrongOptionsError] = useState('')

  const [pairAOptions, setPairAOptions] = useState([])
  const [pairBOptions, setPairBOptions] = useState([])
  const [matchPairOptionsError, setMatchPairOptionsError] = useState('')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [deleteWithReference, setDeleteWithReference] = useState(true)
  const [search, setSearch] = useState('')

  const [pageNumber, setPageNumber] = useState(1)
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const [reRender, setReRender] = useState()
  const pageSize = 3

  useEffect(() => {
    const initialAPIs = async () => {
      await dispatch(getQuestionList(videoId, pageSize, pageNumber))
      await dispatch(getEnabledCetegoryList())
      setVideoTitle(videoName)
    }
    initialAPIs()
  }, [dispatch, videoId])

  useEffect(() => {
    if (message !== null) {
      if (message.status) {
        dispatch(getQuestionList(videoId, pageSize, pageNumber))
      }
    }
  }, [message, dispatch])

  useEffect(() => {
    dispatch(getResponseContent())
  }, [dispatch])

  useEffect(() => {
    dispatch(getFailResponseContent())
  }, [dispatch])

  useEffect(() => {
    if (addQuestionResponse !== null) {
      if (addQuestionResponse.status) {
        toast.success(addQuestionResponse.message)
        dispatch(getQuestionList(videoId, pageSize, pageNumber))
        dispatch(clearAddQuestionResponse())
        setProgress(null)
        onCloseModal()
      } else {
        toast.error(addQuestionResponse.message)
        dispatch(clearAddQuestionResponse())
      }
    }
  }, [addQuestionResponse, dispatch])

  useEffect(() => {
    if (deleteQuestionResponse !== null) {
      if (deleteQuestionResponse.status) {
        closeDeleteModal()
        toast.success(deleteQuestionResponse.message)
        dispatch(getQuestionList(videoId, pageSize, pageNumber))
        dispatch(clearDeleteQuestionResponse())
      } else {
        toast.error(deleteQuestionResponse.message)
        dispatch(clearDeleteQuestionResponse())
      }
    }
  }, [deleteQuestionResponse, dispatch])

  // useEffect(() => {
  //   if (
  //     enabledVideoList &&
  //     enabledVideoList.data &&
  //     enabledVideoList.data.result &&
  //     Array.isArray(enabledVideoList.data.result) &&
  //     enabledVideoList.data.result.length > 0
  //   ) {
  //     const videos = enabledVideoList.data.result.find(
  //       (video) => video._id === videoId
  //     )
  //     if (videos) {

  //     }
  //   }
  // }, [enabledVideoList, videoId])

  const handleAddSpace = () => {
    setShowAddModal(true)
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault()
    if (questionTitle === '') {
      setQuestionTitleError('Please enter Question title')
    } else if (questionCategory === 'select-category') {
      setQuestionCategoryError('Please select question category')
    } else if (questionType === 'select-question-type') {
      setQuestionTypeError('Please select question type')
    } else {
      if (questionType === 'singleOption') {
        if (singleOptions && singleOptions.length < 2) {
          setSingleOptionsError('Please add atleast two options')
        } else if (
          singleOptionAnswer === '' &&
          singleOptionAnswer.trim() === ''
        ) {
          setSingleOptionsError('Please choose one option for correct answer')
        } else {
          if (isUpadate) {
            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: singleOptions,
                answer: singleOptionAnswer,
                helpText: 'Choose single option',
                questionType: 'singleOption',
                _id: isUpadate._id,
              },
            ]
            await dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          } else {
            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: singleOptions,
                answer: singleOptionAnswer,
                helpText: 'Choose single option',
                questionType: 'singleOption',
              },
            ]
            await dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          }
        }
      }
      if (questionType === 'matchPair') {
        if (pairAOptions && pairAOptions.length < 2) {
          setMatchPairOptionsError('Please add atleast two options')
        } else {
          if (isUpadate) {
            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: [{ PairA: pairAOptions, PairB: pairBOptions }],
                helpText:
                  'Slide B section (upward or downward) to match A section',
                questionType: 'matchPair',
                _id: isUpadate._id,
              },
            ]
            await dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          } else {
            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: [{ PairA: pairAOptions, PairB: pairBOptions }],
                helpText:
                  'Slide B section (upward or downward) to match A section',
                questionType: 'matchPair',
              },
            ]
            await dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          }
        }
      }
      if (questionType === 'multipleOption') {
        if (multipleOptions && multipleOptions.length < 2) {
          setMultipleOptionsError('Please add atleast two options')
        } else {
          if (isUpadate) {
            const answers = multipleOptions.filter(
              (item) => item.isChecked === true
            )

            if (answers.length === 0) {
              setMultipleOptionsError(
                'Please choose atleast one option for correct answer'
              )
              return
            }

            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: multipleOptions,
                multipleAnswer: answers,
                helpText: 'Choose multiple options',
                questionType: 'multipleOption',
                _id: isUpadate._id,
              },
            ]
            dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          } else {
            const answers = multipleOptions.filter(
              (item) => item.isChecked === true
            )

            if (answers.length === 0) {
              setMultipleOptionsError(
                'Please choose atleast one option for correct answer'
              )
              return
            }

            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: multipleOptions,
                multipleAnswer: answers,
                helpText: 'Choose multiple options',
                questionType: 'multipleOption',
              },
            ]
            dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          }
        }
      }
      if (questionType === 'fillInTheBlanks') {
        if (multipleOptions && multipleOptions.length < 2) {
          setMultipleOptionsError('Please add atleast two options')
        } else if (validateFillInTheBlank(questionTitle)) {
          setMultipleOptionsError('Please enter only one * for the blank space')
        } else {
          if (isUpadate) {
            const answers = multipleOptions.filter(
              (item) => item.isChecked === true
            )

            if (answers.length !== 1) {
              setMultipleOptionsError(
                'Please choose one option for correct answer'
              )
              return
            }

            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: multipleOptions,
                multipleAnswer: answers,
                helpText: 'Fill in the blanks',
                questionType: 'fillInTheBlanks',
                _id: isUpadate._id,
              },
            ]
            dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          } else {
            const answers = multipleOptions.filter(
              (item) => item.isChecked === true
            )
            if (answers.length !== 1) {
              setMultipleOptionsError(
                'Please choose one correct answer from options'
              )
              return
            }
            const questions = [
              {
                question: questionTitle,
                category: questionCategory && questionCategory._id,
                options: multipleOptions,
                multipleAnswer: answers,
                helpText: 'Fill in the blanks',
                questionType: 'fillInTheBlanks',
              },
            ]
            dispatch(
              addQuestion({ id: videoId, questions }, (progress) =>
                setProgress(progress)
              )
            )
            onCloseModal()
          }
        }
      }
      if (questionType === 'rightWrongOption') {
        if (rightWrongOptions && rightWrongOptions.length < 2) {
          setRightWrongOptionsError('Please add atleast two options')
        } else {
          const result = rightWrongOptions.some(
            (item) => item.answerType === ''
          )
          if (result) {
            setRightWrongOptionsError(
              'Please select red or green answer for all options'
            )
          } else {
            if (isUpadate) {
              const questions = [
                {
                  question: questionTitle,
                  category: questionCategory && questionCategory._id,
                  rightWrongOptions,
                  helpText: 'Sort the red and green behaviors',
                  questionType: 'rightWrongOption',
                  _id: isUpadate._id,
                },
              ]
              dispatch(
                addQuestion({ id: videoId, questions }, (progress) =>
                  setProgress(progress)
                )
              )
              onCloseModal()
            } else {
              const questions = [
                {
                  question: questionTitle,
                  category: questionCategory && questionCategory._id,
                  rightWrongOptions,
                  helpText: 'Sort the red and green behaviors',
                  questionType: 'rightWrongOption',
                },
              ]
              dispatch(
                addQuestion({ id: videoId, questions }, (progress) =>
                  setProgress(progress)
                )
              )
              onCloseModal()
            }
          }
        }
      }
    }
  }

  const onCloseModal = () => {
    setShowAddModal(false)

    setQuestionTitle('')
    setQuestionTitleError('')

    setQuestionCategory('select-category')
    setQuestionType('select-question-type')

    setQuestionCategoryError('')
    setQuestionTypeError('')

    setSingleOptionsError('')
    setSingleOptionAnswer('')
    setSingleOptions([])

    setMatchPairOptionsError('')
    setPairAOptions([])
    setPairBOptions([])

    setMultipleOptions([])
    setMultipleAnswers([])
    setMultipleOptionsError()

    setRightWrongOptions([])
    setRightWrongOptionsError()

    setIsUpadate(null)
    setCurrentItem(null)
    setProgress(null)
    setShowAddVideoModal(false)
  }

  const onEditHandler = (item) => {
    setShowAddModal(true)
    setIsUpadate(item)

    if (
      enabledCetegoryList !== null &&
      enabledCetegoryList.data &&
      enabledCetegoryList.data.result
    ) {
      const result = enabledCetegoryList.data.result.find(
        (category) => category._id === item.category
      )
      if (result) {
        setQuestionCategory(result)
      }
    }
    setQuestionTitle(item.question)
    setQuestionType(item.questionType)

    if (item.questionType === 'singleOption') {
      if (
        item &&
        item.options &&
        Array.isArray(item.options) &&
        item.options.length > 0
      ) {
        setSingleOptions(item.options)
        setSingleOptionAnswer(item.answer)
      }
    }
    if (item.questionType === 'matchPair') {
      if (
        item &&
        item.options &&
        Array.isArray(item.options) &&
        item.options.length > 0
      ) {
        setPairAOptions(item.options[0].PairA)
        setPairBOptions(item.options[0].PairB)
      }
    }
    if (item.questionType === 'multipleOption') {
      if (
        item &&
        item.options &&
        Array.isArray(item.options) &&
        item.options.length > 0
      ) {
        setMultipleOptions(item.options)
        setMultipleAnswers(item.multipleAnswer)
      }
    }
    if (item.questionType === 'rightWrongOption') {
      if (
        item &&
        item.rightWrongOptions &&
        Array.isArray(item.rightWrongOptions) &&
        item.rightWrongOptions.length > 0
      ) {
        setRightWrongOptions(item.rightWrongOptions)
      }
    }
    if (item.questionType === 'fillInTheBlanks') {
      if (
        item &&
        item.options &&
        Array.isArray(item.options) &&
        item.options.length > 0
      ) {
        setMultipleOptions(item.options)
        setMultipleAnswers(item.multipleAnswer)
      }
    }
  }

  const setFinalSingleOptionAnswer = (data, answer) => {
    setSingleOptions(data)
    setSingleOptionAnswer(answer)
  }

  const onChangeCategory = (e) => {
    setQuestionCategoryError('')
    if (
      enabledCetegoryList !== null &&
      enabledCetegoryList.data &&
      enabledCetegoryList.data.result
    ) {
      const result = enabledCetegoryList.data.result.filter(
        (colors) => colors.title === e.target.value
      )
      if (Array.isArray(result) && result.length > 0 && result[0]) {
        setQuestionCategory(result[0])
      }
    }
  }

  const onQuestionDelete = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
    setDeleteWithReference(false)
  }

  const onDeleteQuestion = () => {
    if (deletedItem !== null) {
      dispatch(deleteQuestion(deletedItem._id, deleteWithReference))
    }
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    setPageNumber(selectedPage + 1)
    dispatch(getQuestionList(videoId, pageSize, selectedPage + 1))
  }

  const onSearchQuestions = async (event) => {
    setSearch(event.target.value)
    await dispatch(
      getQuestionList(videoId, pageSize, pageNumber, event.target.value)
    )
  }

  const onAddHandler = (item) => {
    setReRender(Math.random())
    setQuestionItem(item)
    setQuestionId(item._id)
    setShowAddVideoModal(true)
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading={` Questions of ${videoTitle ? videoTitle : 'unknown'} `}
        isAdd={true}
        onAdd={handleAddSpace}
        isSearch
        searchPlaceholder='Search Questions'
        onSearch={onSearchQuestions}
        value={search}
      />
      <div className='back_button' onClick={() => history.goBack()}>
        <IoChevronBackSharp />
        <p className='label'>back</p>
      </div>

      {loading ? (
        <AllQuestionsSkeleton />
      ) : (
        <Fragment>
          <div className='client__container'>
            <div className='missions_main_content'>
              {questionList !== null &&
              questionList.data &&
              questionList.data.length > 0 ? (
                questionList.data.map((item, index) => (
                  <QuestionCard
                    key={index}
                    index={index}
                    item={item}
                    loading={loading}
                    onEditClick={onEditHandler}
                    currentItem={currentItem}
                    onDelete={onQuestionDelete}
                    onAddClick={() => {
                      onAddHandler(item)
                    }}
                  />
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {questionList && questionList.message}
                  </p>
                </div>
              )}
            </div>
          </div>
          <AppModal show={showAddVideoModal}>
            <AddResponseVideo
              onClose={onCloseModal}
              questionId={questionId}
              currentItem={questionItem}
              reRender={reRender}
            />
          </AppModal>

          <AppModal
            show={showAddModal}
            containerStyles={{ overflowY: 'unset', overflowX: 'unset' }}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>
                {isUpadate ? 'Edit Question' : 'Add Question'}
              </p>
              <form
                className='clinic_form_container'
                onSubmit={onSubmitHandler}>
                <div className='clinic_form_top_container2'>
                  <div className='clinic_form_top_equal_container'>
                    <TextInput
                      placeholder='Question'
                      src='/images/missions-input.png'
                      onChange={(title) => {
                        setQuestionTitleError('')
                        setQuestionTitle(title.target.value)
                      }}
                      value={questionTitle}
                      error={questionTitleError}
                    />

                    <div className='mission_dropdown'>
                      <div className='text_input_image_container'>
                        <img
                          src='/images/question_category.png'
                          alt='color'
                          className='text_input_image'
                        />
                      </div>
                      <select
                        name='color-select'
                        id='color-select'
                        value={
                          questionCategory === 'select-category'
                            ? 'select-category'
                            : questionCategory.title
                        }
                        onChange={onChangeCategory}
                        className='mission_dropdown_select'
                        style={{ width: '100%' }}>
                        <option value='select-category' disabled>
                          Select Question Category
                        </option>
                        {enabledCetegoryList &&
                          enabledCetegoryList.data &&
                          enabledCetegoryList.data.result &&
                          enabledCetegoryList.data.result.map((item, index) => (
                            <option key={index} value={item.title}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                    <p className='input_error_text'>{questionCategoryError}</p>
                    {isUpadate === null && (
                      <Fragment>
                        <div className='mission_dropdown'>
                          <div className='text_input_image_container'>
                            <img
                              src='/images/question_type.png'
                              alt='color'
                              className='text_input_image'
                            />
                          </div>
                          <select
                            name='color-select'
                            id='color-select'
                            value={questionType}
                            onChange={(e) => {
                              setQuestionTypeError('')
                              setQuestionType(e.target.value)
                            }}
                            className='mission_dropdown_select'
                            style={{ width: '100%' }}>
                            <option value='select-question-type' disabled>
                              Question Type
                            </option>
                            {questionTypes &&
                              questionTypes.map((item) => (
                                <option key={item.id} value={item.value}>
                                  {item.type}
                                </option>
                              ))}
                          </select>
                        </div>
                        <p className='input_error_text'>{questionTypeError}</p>
                      </Fragment>
                    )}
                  </div>

                  <div className='clinic_form_top_equal_container'>
                    {questionType === 'singleOption' && (
                      <SingleOption
                        singleOptions={singleOptions}
                        setFinalAnswer={setFinalSingleOptionAnswer}
                        errorMessage={singleOptionsError}
                        onRemoveError={() => setSingleOptionsError('')}
                        correctAnswer={singleOptionAnswer}
                      />
                    )}
                    {questionType === 'matchPair' && (
                      <MatchPair
                        pairAOptions={pairAOptions}
                        pairBOptions={pairBOptions}
                        setPairAOptions={setPairAOptions}
                        setPairBOptions={setPairBOptions}
                        errorMessage={matchPairOptionsError}
                        onRemoveError={() => setMatchPairOptionsError('')}
                      />
                    )}
                    {(questionType === 'multipleOption' ||
                      questionType === 'fillInTheBlanks') && (
                      <MultipleOptions
                        multipleOptions={multipleOptions}
                        setMultipleOptions={setMultipleOptions}
                        multipleAnswers={multipleAnswers}
                        setMultipleAnswers={setMultipleAnswers}
                        errorMessage={multipleOptionsError}
                        onRemoveError={() => setMultipleOptionsError('')}
                      />
                    )}
                    {questionType === 'rightWrongOption' && (
                      <RightWrong
                        setRightWrongOptions={setRightWrongOptions}
                        rightWrongOptions={rightWrongOptions}
                        errorMessage={rightWrongOptionsError}
                        onRemoveError={() => setRightWrongOptionsError('')}
                      />
                    )}
                  </div>
                </div>

                <div className='modal_buttons'>
                  <AppButton
                    title={isUpadate ? 'update' : 'add'}
                    type='submit'
                    icon={
                      <img
                        src='/images/add-clinic.png'
                        className='app_button_image'
                      />
                    }
                    onClick={onSubmitHandler}
                  />
                  <AppButton
                    title='cancel'
                    type='button'
                    buttonContainer={{
                      backgroundColor: 'var(--transparent)',
                      marginLeft: '0.5rem',
                    }}
                    textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                    onClick={onCloseModal}
                  />
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
          <AppModal2 show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete Question</p>
              {/* <p className='form_header_delete_text'>
                Are you sure you want to delete{' '}
                <span>{deletedItem !== null && deletedItem.question}</span>?
              </p> */}
              {/* <div className='form_header_delete_ref'>
                <p className='form_header_delete_ref_text'>
                  Delete all it's reference
                </p>
                <AppSwitch
                  checked={deleteWithReference}
                  onChange={onDeleteStatusChange}
                />
              </div> */}
              <div className='form_header_delete_note form_header_delete_text'>
                The question that you are trying to delete has been previously
                answered by a user. Please realize that deleting this question
                will also delete the responses, previously submitted, by all the
                users.
              </div>
              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteQuestion}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal2>
        </Fragment>
      )}
      {questionList !== null &&
        questionList.data &&
        Array.isArray(questionList.data) &&
        questionList.data.length > 15 && (
          <Pagination
            questionList={questionList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
    </div>
  )
}

export default Quesions
