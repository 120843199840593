import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'client-list',
    initialState: {
        clientList: [],
        loading: false,
    },
    reducers: {
        requested: (clients, action) => {
            clients.loading = true
        },
        success: (clients, action) => {
            clients.clientList = action.payload;
            clients.loading = false
        },
        failed: (clients, action) => {
            clients.clientList = action.payload;
            clients.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getClientList = data => apiCallBegan({
    url: '/user/get-clients',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})