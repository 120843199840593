import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'response-video-list',
  initialState: {
    responseVideoList: [],
    loading: false,
  },
  reducers: {
    requested: (space) => {
      space.loading = true
    },
    success: (space, action) => {
      space.responseVideoList = action.payload
      space.loading = false
    },
    failed: (space, action) => {
      space.responseVideoList = action.payload
      space.loading = false
    },
  },
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getResponseVideoList = (pageNumber, pageSize, filterValue = '') =>
  apiCallBegan({
    url: `/response-content/get-response-content?pageNumber=${pageNumber}&pageSize=${pageSize}&filterValue=${filterValue}`,
    method: 'GET',

    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type,
  })

// export const getResponseVideoList = (pageNumber, pageSize,filterValue) =>
// apiCallBegan({
//   url: `/response-content/get-response-content?pageNumber=${pageNumber}&pageSize=${pageSize}&filterValue=${filterValue}`,
//   method: 'GET',
//   onStart: requested.type,
//   onSuccess: failed.type,
//   onFailed: success.type,
// })
