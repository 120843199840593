import React from 'react'
import styles from '../../screens/Student/Student.module.css'
import SkeletonStyles from './Skeletons.module.css'

const AddStudentSkeleton = () => {
  return (
    <div className={styles.container}>
      {Array(5)
        .fill(null)
        .map((u, index) => {
          return (
            <div
              className={[styles.studentcard, styles.sleletonCard].join(' ')}
              key={index}>
              <div
                className={[styles.skeletonCoin, SkeletonStyles.skeleton].join(
                  ' '
                )}></div>
              <div
                className={[styles.skeletonImage, SkeletonStyles.skeleton].join(
                  ' '
                )}></div>
              <div
                className={[styles.skeletonName, SkeletonStyles.skeleton].join(
                  ' '
                )}></div>
              <div
                className={[styles.skeletonCoin, SkeletonStyles.skeleton].join(
                  ' '
                )}></div>
            </div>
          )
        })}
    </div>
  )
}

export default AddStudentSkeleton
