import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'add-promocode',
  initialState: {
    promoAddResponse: null,
    loading: false,
  },
  reducers: {
    requested: (promo) => {
      promo.loading = true
    },
    success: (promo, action) => {
      promo.promoAddResponse = action.payload
      promo.loading = false
    },
    failed: (promo, action) => {
      promo.promoAddResponse = action.payload
      promo.loading = false
    },
    reset: (promo, action) => {
      promo.promoAddResponse = action.payload
      promo.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addPromo = (data) =>
  apiCallBegan({
    url: `payment/add-promo-code`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearAddPromoResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
