import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../../middleware/apis'

const slice = createSlice({
    name: 'video-pagination',
    initialState: {
        videoPageNumber: 1
    },
    reducers: {
        changePage: (page, action) => {
            page.videoPageNumber = action.payload;
        }
    }
})

const { changePage } = slice.actions
export default slice.reducer

export const setVideoPageNumber = data => apiCallBegan({
    data,
    onChangePage: changePage.type,
})