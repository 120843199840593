import React, { useEffect } from 'react';
import { HashRouter as Router, Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getConfigList, clearConfigList } from '../store/configuration/getConfig';

// auth route
import Login from '../pages/auth/Login';
import LoginPage from '../src2/screens/Login';
import onBoard from '../pages/auth/OnBoard';
import onBoard2 from '../pages/auth/OnBoard.depreciated';

// common routes
import ClinicIntro from '../pages/app/Dashboard/ClinicIntro';
import Dashboard from '../pages/app/Dashboard';
import MyProfile from '../pages/app/MyProfile';
import Sidebar from '../components/Sidebar';
import MobileSidebar from '../components/Sidebar/MobileSidebar';
import { setSidebar } from '../store/localStates/sidebar';

// Admin's routes
import Clinic from '../pages/app/Admin/Clinic';
import AppVersion from '../pages/app/Admin/AppVersion/AppVersion';
import HowTo from '../pages/app/Client/Generic';
import FAQs from '../pages/app/Admin/FAQs';
import AboutTheAlgo from '../pages/app/Admin/aboutTheAlgo';
import PrivacyPolicy from '../pages/app/Admin/PrivacyPolicy';
import AboutThebx from '../pages/app/Admin/aboutTheBx';

// client's routes
import Users from '../pages/app/Client/Users';
import Mission from '../pages/app/Client/Mission';
import Spaces from '../pages/app/Client/Spaces';
import Properties from '../pages/app/Client/Properties';
import Videos from '../pages/app/Client/Videos';
import QuestionCategory from '../pages/app/Client/QuestionCategory';
import Notification from '../pages/app/Client/Notification';
import Questions from '../pages/app/Client/Questions';
import Quesions from '../pages/app/Client/Questions';
import Reports from '../pages/app/Client/Reports/PointsReport';
import PointsReport from '../pages/app/Client/Reports/PointsReport';
import StatisticsReport from '../pages/app/Client/Reports/StatisticsReport';
import ProcessReport from '../pages/app/Client/Reports/ProcessReport';
import Background from '../pages/app/Client/Generic/Background';
import Tags from '../pages/app/Client/Generic/Tags';
import SuccessVideo from '../pages/app/Client/Generic/SuccessVideo';
import Kids from '../pages/app/Client/Users/Kids';
import AllResponseVideos from '../pages/app/Client/AllResponseVideos';
import CategoryReport from '../pages/app/Client/Reports/CategoryReport';
import PreTestReport from '../pages/app/Client/Reports/preTestReport';
import { getModuleNames } from '../store/Clients/getModuleNames';
import UsersAudit from '../pages/app/Client/Audit';
import OnBoard from '../pages/app/Admin/onBoard';
import Configuration from '../pages/app/Admin/configuration';

// User Routes
import Home from '../src2/screens/Home';
import AboutBX from '../src2/screens/About/index';
import AboutAlgo from '../src2/screens/About/AboutTheAlgo';
import UserPrivacyPolicy from '../src2/screens/About/PrivacyPolicy';
import MyProfile2 from '../src2/screens/MyProfile';
import LabFAQs from '../src2/screens/FAQ';
import Intro from '../src2/screens/Intro';
import Student from '../src2/screens/Student';
import Performance from '../src2/screens/Performance';
import Leaderboard from '../src2/screens/Leaderboard';
import EditAvatar from '../src2/screens/EditAvatar';
import Coupon from '../pages/app/Admin/Coupon';
import Promocode from '../pages/app/Admin/Promocode';
import Privacy from '../pages/auth/PrivacyPolicy/index';

import Subscription from '../pages/auth/Subscription';
import KidAudit from '../pages/app/Client/Users/Audit';
import PreAssets from '../pages/app/Client/PreAssets';

const Routers = () => {
  const { credentials, userInfo } = useSelector(user => user.credentials);
  const { sidebar } = useSelector(bar => bar.sidebar);
  const { configurationList, loading: configLoading } = useSelector(config => config.getConfig);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo && userInfo.client) {
      dispatch(getConfigList(userInfo.client._id));
    }
  }, []);

  useEffect(() => {
    dispatch(setSidebar(true));
  }, []);

  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getModuleNames(userInfo._id));
    }
  }, [dispatch, userInfo]);

  if (credentials !== null && credentials.status) {
    return (
      <div className={`container ${sidebar && 'container_active'}`}>
        {userInfo && userInfo.userRole === 'Admin' ? (
          <Router>
            <Switch>
              <Route exact path='/' component={ClinicIntro} />
              <Route exact path='/client' component={Clinic} />
              <Route exact path='/appversion' component={AppVersion} />
              <Route exact path='/how-to' component={HowTo} />
              <Route exact path='/how-to/background' component={Background} />
              <Route exact path='/how-to/tags' component={Tags} />
              <Route exact path='/faqs' component={FAQs} />
              <Route exact path='/about-the-algo' component={AboutTheAlgo} />
              <Route exact path='/about-the-bx' component={AboutThebx} />
              <Route exact path='/privacy-policy' component={PrivacyPolicy} />
              <Route exact path='/my-profile' component={MyProfile} />
              <Route exact path='/onboard' component={OnBoard} />
              <Route exact path='/configuration' component={Configuration} />
              <Route exact path='/coupons' component={Coupon} />
              <Route exact path='/promocode' component={Promocode} />
              <Redirect to='/' />
            </Switch>

            <Sidebar />
            <MobileSidebar />
          </Router>
        ) : userInfo && userInfo.userRole === 'User' ? (
          <Router>
            <Switch>
              {/* <Route exact path='/' component={Home} /> */}
              <Route exact path='/' component={Intro} />
              <Route exact path='/about-bx' component={AboutBX} />
              <Route exact path='/about-algo' component={AboutAlgo} />
              <Route exact path='/privacy-policy' component={UserPrivacyPolicy} />
              <Route exact path='/my-profile' component={MyProfile2} />

              {userInfo && userInfo.client && userInfo.client.clientType !== 'lab' && <Route exact path='/student' component={Student} />}

              <Route exact path='/performance' component={Performance} />
              <Route exact path='/leaderboard' component={Leaderboard} />
              <Route exact path='/student/editAvatar/:avatarId' component={EditAvatar} />
              <Route exact path='/lab-FAQ' component={LabFAQs} />
              <Redirect to='/' />
            </Switch>
          </Router>
        ) : (
          <Router>
            <Switch>
              <Route exact path='/' component={ClinicIntro} />

              <Route exact path='/notification' component={Notification} />
              <Route exact path='/users' component={Users} />
              <Route exact path='/users-audit' component={UsersAudit} />
              <Route exact path='/users/kids/:id?' component={Kids} />
              <Route exact path='/users/audit/:id?' component={KidAudit} />

              <Route exact path='/missions' component={Mission} />
              <Route exact path='/spaces/:missionId?/:missionName?' component={Spaces} />
              <Route exact path='/properties/:spaceId?/:spaceName?' component={Properties} />
              <Route exact path='/properties/:questions?/:videoId?' component={Questions} />
              <Route exact path='/how-to' component={HowTo} />
              <Route exact path='/how-to/background' component={Background} />
              <Route exact path='/how-to/tags' component={Tags} />
              <Route exact path='/successVideo' component={SuccessVideo} />
              <Route exact path='/videos' component={Videos} />
              <Route exact path='/preassets' component={PreAssets} />
              <Route exact path='/videos/questions/:videoId?/:videoName?/:mediaType?' component={Quesions} />
              <Route exact path='/question-categories' component={QuestionCategory} />
              <Route exact path='/my-profile' component={MyProfile} />
              <Route exact path='/point-reports' component={PointsReport} />
              <Route exact path='/category-reports' component={CategoryReport} />
              <Route exact path='/pretest-reports' component={PreTestReport} />
              <Route exact path='/statistics-reports' component={StatisticsReport} />
              <Route exact path='/process-reports' component={ProcessReport} />
              <Route exact path='/allresponse' component={AllResponseVideos} />

              <Redirect to='/' />
            </Switch>
            <Sidebar />
            <MobileSidebar />
          </Router>
        )}
      </div>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route path='/on-board' component={onBoard} />
          <Route path='/on-board-d' component={onBoard2} />

          {/* <Route exact path='/login' component={Login} /> */}

          <Route exact path='/login' component={LoginPage} />

          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/reactive-subscription/:userMobile?/:clientType?' component={Subscription} />
          <Route exact path='/on-board'>
            <Redirect to='/on-board' />
          </Route>
          <Route exact path='/'>
            <Redirect to='/login' />
          </Route>
          <Route path='/'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default Routers;
