import React from 'react';
import SkeletonStyles from './Skeletons.module.css';

const ButtonSkeleton = ({ style }) => {
	return (
		<div
			className={[ SkeletonStyles.login_container_button_skeleton, SkeletonStyles.skeleton2 ].join(' ')}
			style={style}
		/>
	);
};

export default ButtonSkeleton;
