import React from 'react'
import './emogi-picker-styles.css'

const emogies = [
  {
    id: 0,
    emogi: '👍',
    name: 'Thumbs Up',
    value: 'thumbs_up',
  },
  {
    id: 1,
    emogi: '👎',
    name: 'Thumbs Down',
    value: 'thumbs_down',
  },
  {
    id: 2,
    emogi: '😊',
    name: 'Smile Face',
    value: 'smile_face',
  },
  {
    id: 3,
    emogi: '🙁',
    name: 'Frown Face',
    value: 'frown_face',
  },
  {
    id: 4,
    emogi: '❌',
    name: 'Wrong',
    value: 'wrong',
  },
  {
    id: 5,
    emogi: '✅',
    name: 'Right',
    value: 'right',
  },
]

const EmogiPicker = ({ setEmogi, containerStyles, onClose }) => {
  return (
    <div
      className='emogi_container'
      style={containerStyles}
      tabIndex='0'
      onBlur={onClose}>
      {emogies.map((emogi, index) => (
        <div
          key={index}
          onClick={() => setEmogi(emogi.emogi)}
          className='emogi_item'>
          <p>{emogi.emogi}</p>
        </div>
      ))}
    </div>
  )
}

export default EmogiPicker
