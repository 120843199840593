import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'purchase-avatar-properties',
  initialState: {
    purchaseReponse: null,
    loading: false,
  },
  reducers: {
    requested: (avatar) => {
      avatar.loading = true
    },
    success: (avatar, action) => {
      avatar.purchaseReponse = action.payload
      avatar.loading = false
    },
    failed: (avatar, action) => {
      avatar.purchaseReponse = action.payload
      avatar.loading = false
    },
    reset: (avatar, action) => {
      avatar.purchaseReponse = action.payload
      avatar.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const purchaseAvatarProperties = (data) =>
  apiCallBegan({
    url: `point/purchase-avatar-properties`,
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearPurchaseAvatarProperties = () =>
  apiCallBegan({
    onReset: reset.type,
  })
