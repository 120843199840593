import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'delete-video',
    initialState: {
        deleteVideoResponse: null,
        loading: false,
    },
    reducers: {
        requested: (video) => {
            video.loading = true
        },
        success: (video, action) => {
            video.deleteVideoResponse = action.payload
            video.loading = false
        },
        failed: (video, action) => {
            video.deleteVideoResponse = action.payload;
            video.loading = false
        },
        reset: (video, action) => {
            video.deleteVideoResponse = action.payload;
            video.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const deleteVideo = (id, status) => apiCallBegan({
    url: `/media/delete-media/${id}/${status}`,
    method: 'DELETE',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearDeleteVideoResponse = () => apiCallBegan({
    onReset: reset.type,
})