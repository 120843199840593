import React from 'react'
import { BigHead } from '@bigheads/core'

import './category-card.css'
import ProgressBar from './progressBar'
import { useSelector, useDispatch } from 'react-redux'

const CategoryCard = ({ item, index }) => {
  const { credentials, userInfo } = useSelector((user) => user.credentials)

  return (
    <div className='big_heades_main_category_card_container'>
      <div className='big_heades_category_card_container'>
        {/*  <div className='kid_avatar_container' >
         {item.kid && item.kid.kidAvatar && item.kid.kidAvatar.background && (
            <img
              alt='avatar'
              src={item.kid.kidAvatar.background}
              style={{borderRadius:'1rem'}}
              className='kid_avatar_background_image'
            />
          )}
          {item.kid.kidAvatar && item.kid.kidAvatar && item.kid.kidAvatar.tag && (
            <img
              alt='avatar'
              src={item.kid.kidAvatar.tag}
              className='kid_avatar_tag_image'
            />
          )}
        </div> */}
        <div className='big_heades_category_card_view'>
          <div className='big_heades_category_card_view_container'>
            <div className='client_card_detail_category_container'>
              <p className='big_heades_category_card_title'>
                {item.kid && item.kid.kidName && item.kid.kidName}
                {userInfo && userInfo.clientType !== 'lab' ? ' | Age:' : null}
                {item.kid &&
                item.kid.kidName &&
                item.kid.kidAge &&
                userInfo &&
                userInfo.clientType !== 'lab'
                  ? item.kid.kidAge
                  : null}
              </p>
              <div className='flex'>
                {userInfo && userInfo.clientType !== 'lab' ? (
                  <p
                    className='client_card_count'
                    style={{ color: 'var(--secondary)', fontSize: '1.5rem' }}>
                    Parent Name: {item.userName}{' '}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {item.category && item.category.length > 0 && (
        <ProgressBar data={item.category} />
      )}
    </div>
  )
}

export default CategoryCard
