import React from 'react'

import './progress-bar.css'

const ProgressBar = ({ progress }) => {
    return (
        <div className="progress_bar_container" style={{ backgroundColor: progress === 0 ? "var(--transparent)" : " var(--gray)" }}>
            <div className="progress_bar" style={{ width: `${progress}%` }} />
        </div>
    )
}

export default ProgressBar