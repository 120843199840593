import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import './Spaces.css';

import AppHeader from '../../../../components/AppHeader';
import SpaceCard from '../../../../components/Cards/SpaceCard';
import AppModal from '../../../../components/AppModal';
import AppModal2 from '../../../../components/AppModal2';
import ProgressBar from '../../../../components/forms/ProgressBar';
import ImageUploader from '../../../../components/forms/ImageUploader';
import AppButton from '../../../../components/forms/AppButton';
import AllMissionsSkeleton from '../../../../components/Skeletons/AllMissionsSkeleton';
import TextInput from '../../../../components/forms/TextInput';
import Pagination from '../../../../components/Pagination';
import AppSwitch from '../../../../components/AppSwitch';

import { getColorList } from '../../../../store/colors/colorList';
import { getSpaceList } from '../../../../store/spaces/SpaceList';
import { getEnabledMissionList } from '../../../../store/missions/enabledMissionList';
import { setEnableDisableSpace } from '../../../../store/spaces/enableDisableSpaces';
import { addSpace, clearAddSpaceResponse } from '../../../../store/spaces/addSpaces';
import { clearDeleteSpaceResponse, deleteSpace } from '../../../../store/spaces/deleteSpace';
import { getSpaceListById } from '../../../../store/spaces/getSpaceById';

import { validateOnlyChacters } from '../../../../utils/validators';

import axios from 'axios';
import { baseUrl } from '../../../../middleware/url';

const Spaces = ({ match }) => {
  const { missionId, missionName } = match.params;

  const dispatch = useDispatch();

  const { sidebar } = useSelector(bar => bar.sidebar);
  const { spaceList, loading } = useSelector(list => list.spaceList);
  const { spaceListById, loading: byIdLoading } = useSelector(list => list.spaceListById);
  const { colorList } = useSelector(color => color.colorList);
  const { enabledMissionList } = useSelector(list => list.enabledMissionList);
  const { addSpaceResponse } = useSelector(list => list.addSpaceResponse);
  const { deleteSpaceResponse } = useSelector(list => list.deleteSpaceResponse);

  const credentials = useSelector(state => state.credentials);

  const [search, setSearch] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [progress, setProgress] = useState(null);
  const [isUpadate, setIsUpadate] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentColor, setCurrentColor] = useState('select-color');
  const [currentColorError, setCurrentColorError] = useState('');
  const [currentMission, setCurrentMission] = useState('select-mission');
  const [currentMissionError, setCurrentMissionError] = useState('');

  const [spaceType, setSpaceType] = useState('general');
  const [spaceTypeError, setSpaceTypeError] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [deleteWithReference, setDeleteWithReference] = useState(true);

  const [spaceName, setSpaceName] = useState('');
  const [spaceNameError, setSpaceNameError] = useState('');

  const [image, setImage] = useState('');
  const [imageError, setImageError] = useState('');
  const [imageURL, setImageURL] = useState('');

  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 15;

  useEffect(() => {
    const initialAPIs = async () => {
      if (missionId) {
        await dispatch(getSpaceListById(missionId));
      } else {
        await dispatch(getSpaceList({ pageSize, pageNumber, filterValue: '' }));
      }
      await dispatch(getColorList());
      await dispatch(getEnabledMissionList());
    };
    initialAPIs();
  }, [dispatch, missionId]);

  useEffect(() => {
    if (currentColor === null) {
      if (
        enabledMissionList !== null &&
        enabledMissionList.data &&
        enabledMissionList.data.result &&
        Array.isArray(enabledMissionList.data.result) &&
        enabledMissionList.data.result.length > 0
      ) {
        setCurrentMission(enabledMissionList.data.result[0]);
      }
    }
  }, [currentMission, enabledMissionList, showAddModal]);

  useEffect(() => {
    if (addSpaceResponse !== null) {
      if (addSpaceResponse.status) {
        toast.success(addSpaceResponse.message);
        if (missionId) {
          dispatch(getSpaceListById(missionId));
        } else {
          dispatch(getSpaceList({ pageSize, pageNumber, filterValue: '' }));
        }
        dispatch(clearAddSpaceResponse());
        setProgress(null);
        onCloseModal();
      } else {
        toast.error(addSpaceResponse.message);
        dispatch(clearAddSpaceResponse());
      }
    }
  }, [addSpaceResponse, dispatch]);

  useEffect(() => {
    if (deleteSpaceResponse !== null) {
      if (deleteSpaceResponse.status) {
        toast.success(deleteSpaceResponse.message);
        dispatch(getSpaceList({ pageSize, pageNumber, filterValue: '' }));
        closeDeleteModal();
        dispatch(clearDeleteSpaceResponse());
      } else {
        toast.error(deleteSpaceResponse.message);
        dispatch(clearDeleteSpaceResponse());
      }
    }
  }, [deleteSpaceResponse, dispatch]);

  const onSearchSpaces = event => {
    setSearch(event.target.value);
    dispatch(getSpaceList({ pageSize, pageNumber, filterValue: event.target.value }));
  };

  const handleAddSpace = () => {
    setShowAddModal(true);
  };

  const enableDisableHandler = async ({ _id, enable }) => {
    setCurrentItem(_id);
    await dispatch(setEnableDisableSpace({ _id, enable }));
    await dispatch(getSpaceList({ pageSize, pageNumber, filterValue: '' }));
    setCurrentItem(null);
  };

  const onUpload = image => {
    setImageURL(image);
    setImageError('');
  };

  const onCloseModal = () => {
    setIsUpadate(null);
    setShowAddModal(false);
    setCurrentColor('select-color');
    setCurrentColorError('');
    setCurrentMission('select-mission');
    setSpaceType('');
    setSpaceTypeError('');
    setSpaceName('');
    setImage('');
    setImageError('');
    setImageURL('');
    setCurrentMissionError('');
  };

  const onEditHandler = item => {
    const result =
      enabledMissionList !== null &&
      enabledMissionList.data &&
      enabledMissionList.data.result &&
      enabledMissionList.data.result.filter(missions => missions._id === item.mission);
    if (Array.isArray(result) && result.length > 0 && result[0]) {
      setCurrentMission(result[0]);
    }

    setShowAddModal(true);
    setIsUpadate(item);
    setSpaceType(item.spaceType);
    setCurrentColor(item.color);
    setSpaceName(item.title);
    setImage(item.imageURL);
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    const data = new FormData();

    if (isUpadate !== null) {
      if (imageURL !== '') {
        data.append('thumbnail', imageURL, imageURL.name);
      }

      if (isUpadate._id) {
        data.append('id', isUpadate._id);
      }

      if (currentMission) {
        data.append('mission', currentMission);
      }

      // if (spaceType === '') {
      //   return setSpaceTypeError('Please select space type')
      // }

      data.append('spaceType', spaceType);
      data.append('title', spaceName);
      data.append('color[primaryColor]', currentColor.primaryColor);
      data.append('color[secondaryColor]', currentColor.secondaryColor);
      data.append('color[darkColor]', currentColor.darkColor);
      data.append('color[title]', currentColor.title);
      dispatch(addSpace(data, progress => setProgress(progress)));
    } else {
      if (spaceName === '') {
        setSpaceNameError('Please enter Space title');
      } else if (spaceName.length < 2) {
        setSpaceNameError('Space title must be two character long');
      } else if (currentMission === 'select-mission') {
        // 	else if (validateOnlyChacters(spaceName)) {
        // 	setSpaceNameError('Please enter valid Mission title');
        // }
        setCurrentMissionError('Please select Mission');
      } else if (currentColor === 'select-color') {
        setCurrentColorError('Please select color');
      } else if (spaceType === '') {
        setSpaceTypeError('Please select space type');
      } else if (image === '') {
        setImageError('Please select image');
      } else {
        data.append('title', spaceName);
        data.append('thumbnail', imageURL, imageURL.name);
        data.append('mission', currentMission);
        data.append('spaceType', spaceType);
        data.append('color[primaryColor]', currentColor.primaryColor);
        data.append('color[secondaryColor]', currentColor.secondaryColor);
        data.append('color[darkColor]', currentColor.darkColor);
        data.append('color[title]', currentColor.title);
        dispatch(addSpace(data, progress => setProgress(progress)));
      }
    }
  };

  const onChangeColor = e => {
    const result = colorList !== null && colorList.data && colorList.data.filter(colors => colors.title === e.target.value);
    if (Array.isArray(result) && result.length > 0 && result[0]) {
      setCurrentColor(result[0]);
    }
    setCurrentColorError('');
  };

  const onChangeMission = e => {
    // const result =
    //   enabledMissionList !== null &&
    //   enabledMissionList.data &&
    //   enabledMissionList.data.result &&
    //   enabledMissionList.data.result.filter(
    //     (missions) => missions.title === e.target.value
    //   )
    // if (Array.isArray(result) && result.length > 0 && result[0]) {
    //   setCurrentMission(result[0])
    // }
    setCurrentMission(e.target.value);
    setCurrentMissionError('');
  };

  const onPageChange = async page => {
    const selectedPage = page.selected;
    dispatch(getSpaceList({ pageSize, pageNumber: selectedPage + 1, filterValue: '' }));
    setPageNumber(selectedPage + 1);
  };

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference);
  };

  const onDeleteHandler = item => {
    setShowDeleteModal(true);
    setDeletedItem(item);
  };

  const onDeleteMission = () => {
    if (deletedItem !== null) {
      dispatch(deleteSpace(deletedItem._id, deleteWithReference));
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeletedItem(null);
    setDeleteWithReference(false);
  };

  const handleOrderChange = (value, orderNumber) => {
    var data = JSON.stringify({
      currentOrderNumber: Number(value),
      previousOrderNumber: Number(orderNumber),
    });

    var config = {
      method: 'patch',
      url: `${baseUrl}/space/change-space-order?mission=${missionId}`,
      headers: {
        token: credentials && credentials.credentials && credentials.credentials.data && credentials.credentials.data.token,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data && response.data.status) {
          toast.success(response.data.message);
          if (missionId) {
            dispatch(getSpaceListById(missionId));
          } else {
            dispatch(getSpaceList({ pageSize, pageNumber, filterValue: '' }));
          }
        }
      })
      .catch(function (error) {});
  };

  const { moduleNames } = useSelector(user => user.moduleNames);
  const [missionModuleName, setMissionModuleName] = useState('Missions');
  const [spaceModuleName, setSpaceModuleName] = useState('Spaces');
  const [propertyModuleName, setPropertyModuleName] = useState('Spaces');

  useEffect(() => {
    if (moduleNames && moduleNames.data && moduleNames.data.stageOne) {
      setMissionModuleName(moduleNames.data.stageOne);
      setSpaceModuleName(moduleNames.data.stageTwo);
      setPropertyModuleName(moduleNames.data.stageThree);
    }
  }, [moduleNames]);

  const onClientChange = item => {
    const initialAPIs = async () => {
      if (missionId) {
        await dispatch(getSpaceListById(missionId));
      } else {
        await dispatch(getSpaceList({ pageSize, pageNumber, filterValue: '' }));
      }
      await dispatch(getColorList());
      await dispatch(getEnabledMissionList());
    };
    initialAPIs();
    setSearch('');
  };

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading={spaceModuleName}
        isAdd
        onAdd={handleAddSpace}
        isSearch
        searchPlaceholder={`Search ${spaceModuleName}`}
        onSearch={onSearchSpaces}
        searchValue={search}
        onClientChange={onClientChange}
      />
      {currentItem === null && (byIdLoading || loading) ? (
        <AllMissionsSkeleton />
      ) : (
        <Fragment>
          <div className='client__container' style={{ paddingTop: '0rem' }}>
            {missionId && missionName ? (
              <div className='client__container_message'>
                {spaceModuleName} from {missionName}
              </div>
            ) : (
              <div className='client__container_message'>All {spaceModuleName}</div>
            )}
            <div className='missions_main_content'>
              {missionId ? (
                spaceListById !== null && spaceListById.data && spaceListById.data.result && spaceListById.data.result.length > 0 ? (
                  spaceListById.data.result.map((item, index) => (
                    <SpaceCard
                      key={index}
                      item={item}
                      loading={byIdLoading}
                      onEditClick={onEditHandler}
                      currentItem={currentItem}
                      onChangeSwitch={enableDisableHandler}
                      onDelete={onDeleteHandler}
                      totalSpaces={spaceListById.data.result.length}
                      handleOrderChange={handleOrderChange}
                      propertyModuleName={propertyModuleName}
                    />
                  ))
                ) : (
                  <div className='data_not_found_container'>
                    <p className='data_not_found_message'>
                      {search !== '' ? 'Search result not found' : `You havn't created any ${spaceModuleName} yet. Please create one`}
                    </p>
                  </div>
                )
              ) : spaceList !== null && spaceList.data && spaceList.data.result && spaceList.data.result.length > 0 ? (
                spaceList.data.result.map((item, index) => (
                  <SpaceCard
                    key={index}
                    item={item}
                    loading={loading}
                    onEditClick={onEditHandler}
                    currentItem={currentItem}
                    onChangeSwitch={enableDisableHandler}
                    onDelete={onDeleteHandler}
                  />
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== '' ? 'Search result not found' : `You havn't created any ${spaceModuleName} yet. Please create one`}
                  </p>
                </div>
              )}
            </div>
          </div>
          {missionId
            ? spaceListById !== null &&
              spaceListById.data &&
              spaceListById.data.result &&
              spaceListById.data.result.length > 0 &&
              spaceListById.data.totalPageSize > 15 && <Pagination list={spaceListById} onPageChange={onPageChange} rowsPerPage={pageSize} />
            : spaceList !== null &&
              spaceList.data &&
              spaceList.data.result &&
              spaceList.data.result.length > 0 &&
              spaceList.data.totalPageSize > 15 && <Pagination list={spaceList} onPageChange={onPageChange} rowsPerPage={pageSize} />}
          <AppModal show={showAddModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>{isUpadate ? `Edit ${spaceModuleName}` : `Add ${spaceModuleName}`}</p>
              <form className='clinic_form_container' onSubmit={onSubmitHandler}>
                <div className='clinic_form_top_container'>
                  <div className='clinic_form_top_left_container'>
                    <TextInput
                      placeholder={`${spaceModuleName} name`}
                      src='/images/missions-input.png'
                      onChange={mission => {
                        setSpaceNameError('');
                        setSpaceName(mission.target.value);
                      }}
                      value={spaceName}
                      // maxLength={20}
                      error={spaceNameError}
                    />
                    <div className='mission_dropdown'>
                      <div className='text_input_image_container'>
                        <img src='/images/missions.png' alt='color' className='text_input_image' />
                      </div>
                      <select
                        name='mission-select'
                        id='mission-select'
                        value={currentMission === 'select-mission' ? `select-mission` : currentMission}
                        onChange={onChangeMission}
                        className='mission_dropdown_select'
                      >
                        <option value='select-mission' disabled>
                          Select {missionModuleName}
                        </option>
                        {enabledMissionList !== null && enabledMissionList.data && enabledMissionList.data.result && enabledMissionList.data.result.length > 0 ? (
                          enabledMissionList.data.result.map((item, index) => {
                            if (missionId) {
                              if (item._id === missionId) {
                                return (
                                  <option key={index} value={item._id}>
                                    {item.title}
                                  </option>
                                );
                              }
                            } else {
                              return (
                                <option key={index} value={item._id}>
                                  {item.title}
                                </option>
                              );
                            }
                          })
                        ) : (
                          <option value='no mission'>No {missionModuleName} found</option>
                        )}
                      </select>
                    </div>
                    {currentMissionError && <p className='input_error_text'>{currentMissionError}</p>}
                    <div className='mission_dropdown'>
                      <div className='text_input_image_container'>
                        <img src='/images/color-input.png' alt='color' className='text_input_image' />
                      </div>
                      <select
                        name='color-select'
                        id='color-select'
                        value={currentColor === 'select-color' ? 'select-color' : currentColor.title}
                        onChange={onChangeColor}
                        className='mission_dropdown_select'
                      >
                        <option value='select-color' disabled>
                          Select color
                        </option>
                        {colorList &&
                          colorList.data &&
                          colorList.data.map((item, index) => (
                            <option key={index} value={item.title}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                      <div
                        style={{
                          backgroundColor: currentColor !== null && currentColor.primaryColor,
                        }}
                        className='dropdown_selectedColor'
                      />
                    </div>
                    {currentColorError && <p className='input_error_text'>{currentColorError}</p>}

                    {/* <div className='mission_dropdown'>
                      <div className='text_input_image_container'>
                        <img
                          src='/images/spaces.png'
                          alt='color'
                          className='text_input_image'
                        />
                      </div>
                      <select
                        name='space-type'
                        id='space-type'
                        value={spaceType}
                        onChange={(e) => {
                          setSpaceType(e.target.value)
                          setSpaceTypeError('')
                        }}
                        className='mission_dropdown_select'>
                        <option value=''>Select {spaceModuleName} type</option>
                        <option value='general'>General</option>
                        <option value='pretest'>Pretest</option>
                      </select>
                    </div> */}
                    {spaceTypeError && <p className='input_error_text'>{spaceTypeError}</p>}
                  </div>

                  <div className='clinic_form_image_upload_container'>
                    <ImageUploader
                      onUpload={onUpload}
                      image={image}
                      setURL={image => setImage(image)}
                      errorMessage={imageError}
                      containerStyles={{ borderRadius: '50%' }}
                      imageTitle='Upload thumbnail'
                      showMessage={true}
                    />
                  </div>
                </div>

                <div className='modal_buttons'>
                  <AppButton
                    title={isUpadate ? 'update' : 'add'}
                    type='submit'
                    icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                    onClick={onSubmitHandler}
                  />
                  <AppButton
                    title='cancel'
                    type='button'
                    buttonContainer={{
                      backgroundColor: 'var(--transparent)',
                      marginLeft: '0.5rem',
                    }}
                    textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                    onClick={onCloseModal}
                  />
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
          <AppModal2 show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete {spaceModuleName}</p>
              <p className='form_header_delete_text'>
                Are you sure you want to delete <span>{deletedItem !== null && deletedItem.title}</span>?
              </p>
              {/* <div className='form_header_delete_ref'>
                <p className='form_header_delete_ref_text'>
                  Delete all it's reference
                </p>
                <AppSwitch
                  checked={deleteWithReference}
                  onChange={onDeleteStatusChange}
                />
              </div> */}

              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteMission}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal2>
        </Fragment>
      )}
    </div>
  );
};

export default Spaces;
