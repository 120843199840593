import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'question-list',
    initialState: {
        questionList: [],
        loading: false,
    },
    reducers: {
        requested: (missions, action) => {
            missions.loading = true
        },
        success: (missions, action) => {
            missions.questionList = action.payload;
            missions.loading = false
        },
        failed: (missions, action) => {
            missions.questionList = action.payload;
            missions.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getQuestionList = (id, pageSize, pageNumber, filterValue = "") => apiCallBegan({
    url: `/media/get-questions?id=${id}&pageSize=${pageSize}&pageNumber=${pageNumber}&filterValue=${filterValue}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})