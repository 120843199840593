import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Notification.css'

import AppHeader from '../../../../components/AppHeader'
import TextInput from '../../../../components/forms/TextInput'
import TextArea from '../../../../components/forms/TextArea'

import { getNotificationList } from '../../../../store/notifications/notificationList'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  clearSendNotificationResponse,
  sendNotification,
} from '../../../../store/notifications/sendNotification'
import ProgressBar from '../../../../components/forms/ProgressBar'
import Pagination from '../../../../components/Pagination'
import PreviousNotificationsSkeleton from '../../../../components/Skeletons/PreviousNotificationsSkeleton'

const Notifications = () => {
  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { notificationList, loading } = useSelector(
    (list) => list.notificationList
  )
  const { sendNotificationResponse } = useSelector(
    (list) => list.sendNotificationResponse
  )

  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationTitleError, setNotificationTitleError] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationMessageError, setNotificationMessageError] = useState('')
  const [progress, setProgress] = useState(null)
  const [search, setSearch] = useState('')

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 15

  useEffect(() => {
    dispatch(getNotificationList({ pageSize, pageNumber }))
  }, [dispatch])

  useEffect(() => {
    if (sendNotificationResponse !== null) {
      if (sendNotificationResponse.status) {
        toast.success(sendNotificationResponse.message)
        dispatch(getNotificationList({ pageSize, pageNumber }))
        dispatch(clearSendNotificationResponse())
        setProgress(null)
      } else {
        toast.error(sendNotificationResponse.message)
        dispatch(clearSendNotificationResponse())
      }
    }
  }, [sendNotificationResponse, dispatch])

  const onSubmitHandler = async (event) => {
    event.preventDefault()

    if (notificationTitle.trim() === '') {
      setNotificationTitleError('Please enter notification title')
    } else if (notificationMessage.trim() === '') {
      setNotificationMessageError('Please enter notification message')
    } else {
      const data = {
        title: notificationTitle,
        body: notificationMessage,
        entryAddedFrom: 'dashboard',
      }
      await dispatch(
        sendNotification(data, (progress) => setProgress(progress))
      )
      setNotificationTitle('')
      setNotificationMessage('')
    }
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(getNotificationList({ pageSize, pageNumber: selectedPage + 1 }))
    setPageNumber(selectedPage + 1)
  }

  const onSearchNotification = (event) => {
    setSearch(event.target.value)
    dispatch(
      getNotificationList({
        pageSize,
        pageNumber,
        filterValue: event.target.value,
      })
    )
  }

  const onClientChange = (item) => {
    dispatch(getNotificationList({ pageSize, pageNumber }))
  }
  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Notifications' onClientChange={onClientChange} />
      <div className='client__container'>
        <div
          className='missions_main_content'
          style={{ alignItems: 'flex-start' }}>
          <div className='send_notification'>
            <h3 className='send_notification_heading'>Send Notification</h3>
            <TextInput
              placeholder='Notification Title'
              src='/images/missions-input.png'
              onChange={(e) => {
                setNotificationTitleError('')
                setNotificationTitle(e.target.value)
              }}
              value={notificationTitle}
              error={notificationTitleError}
            />
            <TextArea
              placeholder='Notification Message'
              value={notificationMessage}
              onChange={(e) => {
                setNotificationMessageError('')
                setNotificationMessage(e.target.value)
              }}
              error={notificationMessageError}
            />
            <button className='send_notification_btn' onClick={onSubmitHandler}>
              Send Notification
              <img
                src='/images/icon_bell.png'
                alt='notification icon'
                className='send_notification_btn_icon'
              />
            </button>
            <ProgressBar progress={progress * 100} />
          </div>
          <div className='previous_notifications'>
            <h3 className='send_notification_heading'>
              Previously Sent Notifications
            </h3>
            <div className='previous_notifications_search'>
              <label
                htmlFor='search-text'
                className='previous_notifications_search_label'>
                <img
                  src='/images/search.png'
                  alt='search icon'
                  className='previous_notifications_search_icon'
                />
              </label>
              <input
                type='text'
                id='search-text'
                placeholder='Search Notifications'
                className='previous_notifications_search_input'
                value={search}
                onChange={onSearchNotification}
              />
            </div>
            {loading ? (
              <PreviousNotificationsSkeleton />
            ) : (
              <Fragment>
                <div className='previous_notifications_cards'>
                  {notificationList &&
                  notificationList.data &&
                  notificationList.data.result &&
                  notificationList.data.result.length > 0 ? (
                    notificationList.data.result.map((item, index) => (
                      <div className='previous_notifications_card' key={index}>
                        <h5 className='previous_notifications_heading'>
                          {item.title}
                        </h5>
                        <div className='previous_notifications_content'>
                          {item.body}
                        </div>
                        <div className='previous_notifications_date'>
                          Sent on{' '}
                          {moment(item.createdAt).format('DD MMMM YYYY')} at{' '}
                          {moment(item.createdAt).format('hh:mm A')}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className='data_not_found_message'>
                      {search !== ''
                        ? 'No search results found'
                        : "You havn't sent any notifications yet."}
                    </p>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {notificationList !== null &&
        notificationList.data &&
        notificationList.data.result &&
        notificationList.data.result.length > 0 &&
        notificationList.data.totalPageSize > 15 && (
          <Pagination
            list={notificationList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
    </div>
  )
}

export default Notifications
