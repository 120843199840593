import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import './reports.css'

import AppHeader from '../../../../components/AppHeader'
import PointsCard from '../../../../components/Cards/ReportsCard/PointsCard'
import AllMissionsSkeleton from '../../../../components/Skeletons/AllMissionsSkeleton'

import { getPointsReport } from '../../../../store/reports/pointReport'
import { getPointsChartReport } from '../../../../store/reports/pointChartReport'
import { options } from './chartOptions'
import ReportToggle from './ReportToggle'
import { useHistory } from 'react-router'

const PointsReport = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pointsReport, loading } = useSelector((report) => report.pointsReport)
  const { pointsChartReport } = useSelector(
    (report) => report.pointsChartReport
  )

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const [ischarts, setISCharts] = useState(false)

  useEffect(() => {
    dispatch(getPointsReport())
    dispatch(getPointsChartReport())
  }, [dispatch])

  const onClientChange = (item) => {
    if (item && item.clientType === 'lab') {
      history.push('/')
    } else {
      dispatch(getPointsReport())
      dispatch(getPointsChartReport())
    }
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader heading='Points Report' onClientChange={onClientChange} />
      <ReportToggle onChange={(status) => setISCharts(status)} />
      <div className='client__container'>
        {ischarts ? (
          pointsReport !== null &&
          pointsReport.data &&
          pointsReport.data.length > 0 ? (
            pointsChartReport &&
            pointsChartReport.data && (
              <Bar
                data={pointsChartReport.data}
                options={options}
                className='report_chart_container'
              />
            )
          ) : (
            <div className='data_not_found_container'>
              <p className='data_not_found_message'>
                {pointsReport !== null && pointsReport.message}
              </p>
            </div>
          )
        ) : (
          <div
            className='missions_main_content'
            style={{ rowGap: '4rem', columnGap: '4rem' }}>
            {loading ? (
              <AllMissionsSkeleton />
            ) : pointsReport &&
              pointsReport.data &&
              pointsReport.data.length > 0 ? (
              pointsReport.data.map((item, index) => (
                <PointsCard item={item} key={index} index={index} />
              ))
            ) : (
              <div className='data_not_found_container'>
                <p className='data_not_found_message'>
                  {pointsReport !== null && pointsReport.message}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default PointsReport
