import React from 'react';
import moment from 'moment'
import './avatar-props.css'

import AppSwitchSmall from '../../AppSwitchSmall';

const BackgroundCard = ({ item, loading, onEdit, onChangeSwitch, currentItem, onDelete }) => {
	return (
		<div className='client_card_container' style={{ minWidth: "45rem" }}>
			<div className='client_card_view_container'>
				<img alt='mission' src={item.imageURL} className='client_card_image' />
				<div className='client_card_detail_container'>
					<p className='client_card_title'>{item.title}</p>
					<div className="flex">
						<p className='avatar_props_created'>Created at {moment(item.createdAt).format("DD MMM YYYY")} </p>
					</div>
				</div>
			</div>
			<div className='client_card_action_container'>
				<div className='client_card_action_container_left' style={{ marginTop: 0 }}>
					<AppSwitchSmall
						checked={item.enable}
						onChange={() => onChangeSwitch(item)}
						loading={currentItem === item._id && loading}
					/>
					<img src='/images/editPrimary.png' className='client_card_edit_image' onClick={() => onEdit(item)} />
				</div>
				<img src='/images/delete.png' className='client_card_delete_image' onClick={() => onDelete(item)} />
			</div>
		</div>
	);
};

export default BackgroundCard;
