import React from 'react'
import styles from '../../screens/Leaderboard/Leaderboard.module.css'
import SkeletonStyles from './Skeletons.module.css'

const LeaderSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer1}>
        <div className={styles.subContainerTop}>
          {Array(4)
            .fill(null)
            .map((u, i) => {
              return (
                <div className={styles.leaderboardStudentCard} key={i}>
                  <div className={styles.boxSkeleton}>
                    <div
                      className={[
                        styles.circleSekeleton,
                        SkeletonStyles.skeleton,
                      ].join(' ')}></div>
                    <div
                      className={[
                        styles.lineSekeleton,
                        SkeletonStyles.skeleton,
                      ].join(' ')}></div>
                    <div
                      className={[
                        styles.lineSekeleton,
                        SkeletonStyles.skeleton,
                      ].join(' ')}></div>
                    <div className={styles.smallCircleSekeletonContainer}>
                      <div
                        className={[
                          styles.smallCircleSekeleton,
                          SkeletonStyles.skeleton,
                        ].join(' ')}></div>
                      <div
                        className={[
                          styles.smallCircleSekeleton,
                          SkeletonStyles.skeleton,
                        ].join(' ')}></div>
                      <div
                        className={[
                          styles.smallCircleSekeleton,
                          SkeletonStyles.skeleton,
                        ].join(' ')}></div>
                    </div>
                  </div>
                  <div
                    className={[
                      styles.progressSekeleton,
                      SkeletonStyles.skeleton,
                    ].join(' ')}></div>
                </div>
              )
            })}
        </div>
        <div className={styles.subContainerBottom}>
          <div className={styles.tilesContainer}>
            {Array(9)
              .fill(null)
              .map((item, index) => {
                return (
                  <div className={styles.tilesSkeleton} key={index}>
                    <div
                      className={[
                        styles.mediumCircleSekeleton,
                        SkeletonStyles.skeleton,
                      ].join(' ')}></div>
                    <div
                      className={[
                        styles.lineSekeleton,
                        SkeletonStyles.skeleton,
                      ].join(' ')}
                      style={{ width: '25rem', marginTop: '0' }}></div>
                    <div
                      className={[
                        styles.lineSekeleton,
                        SkeletonStyles.skeleton,
                      ].join(' ')}
                      style={{
                        width: '25rem',
                        marginTop: '0',
                        marginRight: '1rem',
                      }}></div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <div className={styles.subContainer2}>
        <div
          className={[styles.rightHeaderSkeleton, SkeletonStyles.skeleton].join(
            ' '
          )}></div>
        <div
          className={[styles.rightTitleSkeleton, SkeletonStyles.skeleton].join(
            ' '
          )}></div>
        <div
          className={[styles.rightDescSkeleton, SkeletonStyles.skeleton].join(
            ' '
          )}></div>

        <div
          className={[styles.rightTitleSkeleton, SkeletonStyles.skeleton].join(
            ' '
          )}></div>
        <div
          className={[styles.rightDescSkeleton, SkeletonStyles.skeleton].join(
            ' '
          )}></div>

        <div
          className={[styles.rightTitleSkeleton, SkeletonStyles.skeleton].join(
            ' '
          )}></div>
        <div
          className={[styles.rightDescSkeleton, SkeletonStyles.skeleton].join(
            ' '
          )}></div>
      </div>
    </div>
  )
}

export default LeaderSkeleton
