import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-config',
  initialState: {
    configurationList: null,
    loading: false,
  },
  reducers: {
    requested: (user) => {
      user.loading = true
    },
    success: (user, action) => {
      user.configurationList = action.payload
      user.loading = false
    },
    failed: (user, action) => {
      user.configurationList = action.payload
      user.loading = false
    },
    reset: (user, action) => {
      user.configurationList = action.payload
      user.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const getConfigList = (id) =>
  apiCallBegan({
    url: `/user/get-function-setting?clientId=${id}`,
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearConfigList = () =>
  apiCallBegan({
    onReset: reset.type,
  })
