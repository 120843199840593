import React, { Fragment } from 'react'
import './category-card.css'

const ProgressBar = ({ data }) => {
  return (
    <div style={{ margin: '1rem' }} className='topData__col'>
      {data &&
        data.map((d, index) => (
          <Fragment key={index + 1}>
            {d.category && (
              <div className='topData__col'>
                <p className='topData__text'>
                  {d.category.title} ({d.categoryCompletedCount}/
                  {d.category.questionCount})
                </p>

                <div key={d._id} className='topData'>
                  <div
                    className='topData__bar'
                    style={{
                      backgroundColor:
                        d.categoryCompletedCount > 0
                          ? '#0bd318'
                          : 'transparent',
                      width: `${
                        (d.categoryCompletedCount * 100) /
                        d.category.questionCount
                      }%`,
                    }}></div>
                  <p className='topData__view'> </p>
                </div>
              </div>
            )}
          </Fragment>
        ))}
    </div>
  )
}

export default ProgressBar
