import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'color-list',
    initialState: {
        colorList: [],
        loading: false,
    },
    reducers: {
        requested: (color) => {
            color.loading = true
        },
        success: (color, action) => {
            color.colorList = action.payload;
            color.loading = false
        },
        failed: (color, action) => {
            color.colorList = action.payload;
            color.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getColorList = () => apiCallBegan({
    url: '/generic/getColorList',
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})