import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enabled-cetegory-list',
    initialState: {
        enabledCetegoryList: [],
        loading: false,
    },
    reducers: {
        requested: (clients, action) => {
            clients.loading = true
        },
        success: (clients, action) => {
            clients.enabledCetegoryList = action.payload;
            clients.loading = false
        },
        failed: (clients, action) => {
            clients.enabledCetegoryList = action.payload;
            clients.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getEnabledCetegoryList = () => apiCallBegan({
    url: '/category/get-enabled-category',
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})