import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'verfiy-user-therapistView',
  initialState: {
    verifyUserResponse: null,
    loading: false,
  },
  reducers: {
    requested: (user) => {
      user.loading = true
    },
    success: (user, action) => {
      user.verifyUserResponse = action.payload
      user.loading = false
    },
    failed: (user, action) => {
      user.verifyUserResponse = action.payload
      user.loading = false
    },
    reset: (user, action) => {
      user.verifyUserResponse = action.payload
      user.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const verifyUser = (password) =>
  apiCallBegan({
    url: `/user/verify-client`,
    method: 'POST',
    data: { password },
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
export const clearVerifyUserResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
