import React, { useState, useEffect } from 'react';
import IntroRegistration from '../IntroRegistration';
import { useSelector, useDispatch } from 'react-redux';
import Redirect from '../Redirect';
import Home from '../Home';

const Intro = () => {
  const [completed, setCompleted] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const { credentials, userInfo } = useSelector(user => user.credentials);

  const completeHandler = () => {
    setCompleted(true);
  };

  useEffect(() => {
    const screenChangeHandler = type => {
      if (type === 'event') {
        if (window.screen.width < 991.98) {
          setShowRedirectModal(true);
        } else {
          setShowRedirectModal(false);
        }
      }
    };

    if (window.screen.width < 991.98) {
      setShowRedirectModal(true);
    }

    window.addEventListener('resize', screenChangeHandler.bind(this, 'event'));
  }, []);

  const MainScreen = () => {
    // if (showRedirectModal) {
    //   return <IntroRegistration processComplete={completeHandler} />;
    // }
    if (!completed) {
      if (false) {
        // if (userInfo && !userInfo.isOnboardProcessCompleted && userInfo.clientType === '') {
        return <IntroRegistration processComplete={completeHandler} />;
      } else {
        return <Home />;
      }
    } else {
      return <Home />;
    }
  };
  return <MainScreen />;
};

export default Intro;
