import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AppHeader from '../../../../components/AppHeader'
import VideoCard from '../../../../components/Cards/VideoCard'
import AppModal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'
import ProgressBar from '../../../../components/forms/ProgressBar'
import ImageUploader from '../../../../components/forms/ImageUploader'
import AppButton from '../../../../components/forms/AppButton'
import AllVideosSkeleton from '../../../../components/Skeletons/AllVideosSkeleton'
import TextInput from '../../../../components/forms/TextInput'
import Pagination from '../../../../components/Pagination'
import AppSwitch from '../../../../components/AppSwitch'
import Select from '../../../../components/forms/Select'
import { validateOnlyChacters } from '../../../../utils/validators'
import { getVideoList } from '../../../../store/video/videoList'
import {
  addVideo,
  clearAddVideoResponse,
} from '../../../../store/video/addVideo'
import {
  clearDeleteVideoResponse,
  deleteVideo,
} from '../../../../store/video/deleteVideo'
import { getEnabledVideoList } from '../../../../store/video/enabledVideoList'
import { setVideoPageNumber } from '../../../../store/localStates/paginations/videoPagination'
import AudioUploader from '../../../../components/forms/AudioUploader/index'
import socketIOClient from 'socket.io-client'
import { socketUrl } from '../../../../middleware/url'
import { getPreAssetList } from '../../../../store/preAsset/getAllPreAssets'
import {
  attachPreAssets,
  clearAttachPreAssetsResponse,
} from '../../../../store/preAsset/attachPreAsset'
import axios from 'axios'
import CheckBox from '../../../../components/forms/CheckBox'

const fileTypeData = [
  {
    id: 1,
    title: 'Video',
    value: 'video',
  },
  {
    id: 4,
    title: 'Image',
    value: 'image',
  },
  {
    id: 2,
    title: 'Pdf',
    value: 'doc',
  },
  {
    id: 3,
    title: 'Audio',
    value: 'audio',
  },
]

const Videos = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { credentials } = useSelector((bar) => bar.credentials)
  const { addVideoResponse } = useSelector((list) => list.addVideoResponse)
  const { deleteVideoResponse } = useSelector(
    (list) => list.deleteVideoResponse
  )
  const { videoList, loading } = useSelector((list) => list.videoList)
  const { videoPageNumber } = useSelector((list) => list.videoPageNumber)
  const { preAssetsList, loading: preAssetsLoading } = useSelector(
    (list) => list.getAllPreAssets
  )

  const { attachPreVideoResponse } = useSelector(
    (state) => state.attachPreAsset
  )

  const [search, setSearch] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [progress, setProgress] = useState(null)
  const [isUpadate, setIsUpadate] = useState(null)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [deleteWithReference, setDeleteWithReference] = useState(true)

  const [videoTitle, setVideoTitle] = useState('')
  const [videoTitleError, setVideoTitleError] = useState('')

  const [video, setVideo] = useState('')
  const [videoError, setVideoError] = useState('')
  const [videoURL, setVideoURL] = useState('')

  const [audio, setAudio] = useState('')
  const [audioError, setAudioError] = useState('')
  const [audioURL, setAudioURL] = useState('')

  const [fileType, setFileType] = useState('video')
  const [fileTypeError, setFileTypeError] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileExtension, setFileExtension] = useState(null)
  const [addCancelToken, setAddCancelToken] = useState(null)

  const [attachData, setAttachData] = useState(null)
  const [selectAttachment, setSelectAttachment] = useState(null)
  const [selectAttachmentError, setSelectAttachmentError] = useState('')
  const [searchPreAssets, setSearchPreAssets] = useState('')
  const [filter, setFilter] = useState([])

  const pageSize = 8

  window.onunload = function () {
    localStorage.setItem('videoSearch', '')
    setSearch('')
  }

  useEffect(() => {
    const socket = socketIOClient(socketUrl)
    socket.on('file-upload-complete', (data) => {
      onRefresh()
    })

    // CLEAN UP THE EFFECT
    return () => socket.disconnect()
    //
  }, [])

  useEffect(() => {
    const initialAPIs = async () => {
      await dispatch(
        getVideoList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: search,
          filterFileType: filter,
        })
      )
      await dispatch(getEnabledVideoList())
      await dispatch(clearAddVideoResponse())
    }

    if (localStorage.getItem('videoSearch') === '') {
      initialAPIs()
    }
  }, [dispatch, videoPageNumber, credentials])

  useEffect(() => {
    const initialAPIs = async () => {
      await dispatch(
        getVideoList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: search,
          filterFileType: filter,
        })
      )
      await dispatch(getPreAssetList())
      await dispatch(getEnabledVideoList())
      await dispatch(clearAddVideoResponse())
    }

    initialAPIs()
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('videoSearch')) {
      setSearch(localStorage.getItem('videoSearch'))
      dispatch(
        getVideoList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: localStorage.getItem('videoSearch'),
          filterFileType: filter,
        })
      )
    }
  }, [dispatch, videoPageNumber, credentials])

  useEffect(() => {
    if (addVideoResponse !== null) {
      if (addVideoResponse?.status) {
        toast.success(addVideoResponse?.message)
        setVideo('')
        setVideoURL('')
        dispatch(
          getVideoList({
            pageSize,
            pageNumber: videoPageNumber,
            filterValue: search,
            filterFileType: filter,
          })
        )
        dispatch(clearAddVideoResponse())
        setProgress(null)
        onCloseModal()
      } else {
        toast.error(addVideoResponse?.message)
        setProgress(null)
        dispatch(clearAddVideoResponse())
      }
    }
  }, [addVideoResponse, dispatch])

  useEffect(() => {
    if (deleteVideoResponse !== null) {
      if (deleteVideoResponse.status) {
        toast.success(deleteVideoResponse.message)
        closeDeleteModal()
        dispatch(
          getVideoList({
            pageSize,
            pageNumber: videoPageNumber,
            filterValue: search,
            filterFileType: filter,
          })
        )
        dispatch(clearDeleteVideoResponse())
      } else {
        toast.error(deleteVideoResponse.message)
        closeDeleteModal()
        dispatch(
          getVideoList({
            pageSize,
            pageNumber: videoPageNumber,
            filterValue: search,
            filterFileType: filter,
          })
        )
        dispatch(clearDeleteVideoResponse())
      }
    }
  }, [deleteVideoResponse, dispatch])

  const onSearchVideo = (event) => {
    setSearch(event.target.value)
    localStorage.setItem('videoSearch', event.target.value)
    dispatch(
      getVideoList({
        pageSize,
        pageNumber: videoPageNumber,
        filterValue: event.target.value,
        filterFileType: filter,
      })
    )
  }

  const onAddVideoHandler = () => {
    setShowAddModal(true)
    setAddCancelToken(null)
  }

  const onUpload = (image) => {
    if (image.type === 'application/pdf') {
      const name = image?.name

      setFileName(name)
    } else {
      setFileName('Upload pdf')
    }

    setVideoURL(image)
    setVideoError('')
  }

  const audioUpload = (audio) => {
    setVideoURL(audio)
    setVideoError('')
  }

  const onCloseModal = () => {
    setIsUpadate(null)
    setShowAddModal(false)
    setVideoTitle('')
    setVideo('')
    setVideoURL('')
    setProgress(null)
    setFileTypeError('')
    setVideoError('')
    setVideoTitleError('')
    setFileType('video')
    setFileName(null)
    setFileExtension(null)
    if (addCancelToken) {
      addCancelToken?.cancel()
    }
  }

  const onEditHandler = (item) => {
    const ulrSplit = item?.videoURL.split('/')

    const name = ulrSplit[ulrSplit.length - 1]

    // const extension = name.split('.')

    if (item.fileType === 'doc') {
      setFileName(name)
    } else {
      setFileName('Upload pdf')
    }

    setShowAddModal(true)
    setIsUpadate(item)
    setVideoTitle(item.title)
    setVideo(item.videoURL)
    setFileType(item.fileType)
    setAddCancelToken(null)
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault()

    const data = new FormData()

    if (isUpadate) {
      if (videoURL !== '') {
        data.append('file', videoURL, videoURL.name)
      }
      if (isUpadate._id) {
        data.append('id', isUpadate._id)
      }
      data.append('title', videoTitle)
      // data.append('fileType', fileType)

      if (videoTitle === '') {
        setVideoTitleError('Please enter asset title')
        return
      } else if (videoTitle.length < 2) {
        setVideoTitleError('asset title must be two character long')
        return
      } else if (fileType === '' || fileType === null) {
        setFileTypeError('Please Select a file type')
        return
      } else if (video === '' && fileType === 'video') {
        setVideoError('Please select video')
        return
      } else if (video === '' && fileType === 'doc') {
        setVideoError('Please select pdf')
      } else if (video === '' && fileType === 'audio') {
        setVideoError('Please select audio file')
        return
      } else if (video === '' && fileType === 'image') {
        setVideoError('Please select image file')
        return
      }
      const cancelTokenForUpdate = axios.CancelToken.source()

      setAddCancelToken(cancelTokenForUpdate)

      await dispatch(
        addVideo(
          data,
          fileType,
          (progress) => setProgress(progress),
          cancelTokenForUpdate
        )
      )
    } else {
      if (videoTitle === '') {
        setVideoTitleError('Please enter asset title')
        return
      } else if (videoTitle.length < 2) {
        setVideoTitleError('asset title must be two character long')
        return
      } else if (fileType === '' || fileType === null) {
        setFileTypeError('Please Select a file type')
        return
      } else if (video === '' && fileType === 'video') {
        setVideoError('Please select video')
        return
      } else if (video === '' && fileType === 'doc') {
        setVideoError('Please select pdf')
        return
      } else if (video === '' && fileType === 'audio') {
        setVideoError('Please select audio file')
        return
      } else if (video === '' && fileType === 'image') {
        setVideoError('Please select image file')
        return
      } else {
        data.append('title', videoTitle)
        data.append('file', videoURL, videoURL.name)
        // data.append('fileType', fileType)

        const cancelTokenForAdd = axios.CancelToken.source()

        setAddCancelToken(cancelTokenForAdd)

        await dispatch(
          addVideo(
            data,
            fileType,
            (progress) => setProgress(progress),
            cancelTokenForAdd
          )
        )
      }
    }
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(
      getVideoList({
        pageSize,
        pageNumber: selectedPage + 1,
        filterValue: search,
        filterFileType: filter,
      })
    )
    dispatch(setVideoPageNumber(selectedPage + 1))
  }

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference)
  }

  const onDeleteHandler = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }

  const onDeleteMission = () => {
    if (deletedItem !== null) {
      dispatch(deleteVideo(deletedItem._id, deleteWithReference))
    }
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
    setDeleteWithReference(false)
  }

  const onAddSettingsHandler = () => {}

  const onClientChange = (item) => {
    const initialAPIs = async () => {
      await dispatch(
        getVideoList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: search,
          filterFileType: filter,
        })
      )
      await dispatch(getEnabledVideoList())
      await dispatch(clearAddVideoResponse())
    }
    initialAPIs()
    setSearch('')
  }

  const onRefresh = () => {
    dispatch(
      getVideoList({
        pageSize,
        pageNumber: videoPageNumber,
        filterValue: search,
        filterFileType: filter,
      })
    )
    dispatch(getEnabledVideoList())
    dispatch(clearAddVideoResponse())
  }

  const getVideoTitle = (type) => {
    if (type === 'video') {
      return 'Upload video'
    } else if (type === 'image') {
      return 'Upload image'
    } else if (type === 'doc') {
      return 'Upload pdf'
    } else {
      return 'Upload video'
    }
  }

  // console.log('fileType', fileType)
  // console.log('videoURL', videoURL)

  const setFileNamehandler = (type) => {
    const title = getVideoTitle(type)
    setFileName(title)
  }

  const closePreAssetsModal = () => {
    setAttachData(null)
    setSelectAttachment(null)
    setSelectAttachmentError('')
  }

  const onAttachPreAssetsHandler = (item) => {
    // console.log('item', item)
    setAttachData(item)
  }

  const attachPreAssestsHandler = () => {
    if (selectAttachment === null) {
      setSelectAttachmentError('Please select pre assets')
    }

    if (selectAttachment && attachData && attachData._id) {
      if (selectAttachment.selected) {
        const data = {
          _id: attachData._id, // medid aid
          preAssetReference: selectAttachment._id, //pre asset reference
          preAssetType: selectAttachment.fileType, //video', 'image'
        }
        if (data) {
          dispatch(attachPreAssets(data))
        }
      } else {
        const data = {
          _id: attachData._id, // medid aid
          preAssetReference: null, //pre asset reference
          preAssetType: selectAttachment.fileType, //video', 'image'
        }
        if (data) {
          dispatch(attachPreAssets(data))
        }
      }
    }
  }

  // console.log('selectAttachmentError', selectAttachmentError)
  // console.log('selectAttachment', selectAttachment)

  useEffect(() => {
    if (attachPreVideoResponse !== null) {
      if (attachPreVideoResponse.status) {
        toast.success(attachPreVideoResponse.message)
        dispatch(
          getVideoList({
            pageSize,
            pageNumber: videoPageNumber,
            filterValue: search,
            filterFileType: filter,
          })
        )
        dispatch(clearAttachPreAssetsResponse())
        closePreAssetsModal()
      } else {
        toast.error(attachPreVideoResponse.message)
        dispatch(clearAttachPreAssetsResponse())
        closePreAssetsModal()
      }
    }
  }, [attachPreVideoResponse, dispatch])

  const onSearchPreAssets = (event) => {
    setSearchPreAssets(event.target.value)
  }

  const isPreAssetPresent = (data) => {
    if (selectAttachment === null && attachData) {
      if (attachData) {
        if (data._id === attachData.preAssetReference) {
          return true
        }
      } else {
        return false
      }
    } else if (selectAttachment?._id === data._id) {
      return true
    } else {
      return false
    }
  }

  const checkbox = (item) => {
    let arr = [...filter]

    const isPresent = filter.includes(item.value)

    if (isPresent) {
      arr = arr.filter((data) => data !== item.value)
    } else {
      arr.push(item.value)
    }
    setFilter(arr)
    dispatch(setVideoPageNumber(1))
  }

  useEffect(() => {
    dispatch(
      getVideoList({
        pageSize,
        pageNumber: videoPageNumber,
        filterValue: search,
        filterFileType: filter,
      })
    )
  }, [filter, videoPageNumber])

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Assets'
        isAdd
        onAdd={onAddVideoHandler}
        isSearch
        searchPlaceholder='Search Assets'
        onSearch={onSearchVideo}
        searchValue={search}
        onClientChange={onClientChange}
      />
      {loading ? (
        <AllVideosSkeleton />
      ) : (
        <Fragment>
          <div className='client__container'>
            <div className='filter_container'>
              {fileTypeData.map((item, i) => {
                return (
                  <div className='filter_section' key={i}>
                    <CheckBox
                      onClick={checkbox.bind(this, item)}
                      isSelected={filter.includes(item.value)}
                    />
                    <div className='filter_label'>{item.title}</div>
                  </div>
                )
              })}
            </div>

            <div className='missions_main_content'>
              {videoList !== null &&
              videoList.data &&
              videoList.data.result &&
              videoList.data.result.length > 0 ? (
                videoList.data.result.map((item, index) => (
                  <VideoCard
                    key={index}
                    item={item}
                    onEditClick={onEditHandler}
                    onDelete={onDeleteHandler}
                    onAddClick={onAddSettingsHandler}
                    onAttach={onAttachPreAssetsHandler}
                  />
                ))
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== '' || filter.length > 0
                      ? 'Search result not found'
                      : "You haven't uploaded any asset yet."}
                  </p>
                </div>
              )}
            </div>
          </div>

          <AppModal show={showAddModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>
                {isUpadate ? 'Edit Asset' : 'Add Asset'}
              </p>
              <form
                className='clinic_form_container'
                onSubmit={onSubmitHandler}>
                <div className='clinic_form_top_container'>
                  <div className='clinic_form_top_left_container'>
                    <TextInput
                      placeholder='Title'
                      src='/images/missions-input.png'
                      onChange={(video) => {
                        setVideoTitleError('')
                        setVideoTitle(video.target.value)
                      }}
                      value={videoTitle}
                      error={videoTitleError}
                    />
                    <Select
                      src='/images/missions-input.png'
                      data={fileTypeData}
                      placeholder='Select file type'
                      onChange={(event) => {
                        setFileType(event.target.value)
                        setFileTypeError('')
                        setVideo('')
                        setVideoURL('')
                        setFileNamehandler(event.target.value)

                        // setFileName('Upload pdf')
                      }}
                      value={fileType}
                      fileExtension={fileExtension}
                    />
                    {fileTypeError !== '' ? (
                      <p className='input_error_text'>{fileTypeError}</p>
                    ) : null}
                    <div className='modal_buttons'>
                      <AppButton
                        title={isUpadate ? 'update' : 'add'}
                        type='submit'
                        icon={
                          <img
                            src='/images/add-clinic.png'
                            className='app_button_image'
                          />
                        }
                        onClick={onSubmitHandler}
                      />
                      <AppButton
                        title='cancel'
                        type='button'
                        buttonContainer={{
                          backgroundColor: 'var(--transparent)',
                          marginLeft: '0.5rem',
                        }}
                        textStyle={{
                          color: 'var(--black)',
                          fontWeight: 'bold',
                        }}
                        onClick={onCloseModal}
                      />
                    </div>
                  </div>

                  <div className='clinic_form_image_upload_container'>
                    {fileType === 'audio' ? (
                      <AudioUploader
                        onUpload={audioUpload}
                        audio={fileType === 'audio' ? video : ''}
                        setURL={(video) => setVideo(video)}
                        audioTitle='Upload Audio'
                        errorMessage={videoError}
                        containerStyles={{ width: '15vw', height: '9vw' }}
                      />
                    ) : (
                      <ImageUploader
                        onUpload={onUpload}
                        video={
                          fileType === 'video' || fileType === 'image'
                            ? video
                            : ''
                        }
                        setURL={(video) => setVideo(video)}
                        errorMessage={videoError}
                        containerStyles={{ width: '15vw', height: '9vw' }}
                        videoTitle={
                          isUpadate ? fileName : getVideoTitle(fileType)
                        }
                        fileType={fileType}
                        isUpadate={isUpadate}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
          <AppModal2 show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete Asset</p>
              <p className='form_header_delete_text'>
                Are you sure you want to delete{' '}
                <span>{deletedItem !== null && deletedItem.title}</span>?
              </p>
              {/* <div className='form_header_delete_ref'>
                <p className='form_header_delete_ref_text'>
                  Delete all it's reference
                </p>
                <AppSwitch
                  checked={deleteWithReference}
                  onChange={onDeleteStatusChange}
                />
              </div> */}

              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteMission}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal2>

          <AppModal show={attachData !== null}>
            <div className='clinic_add_modal_container'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <div>
                  <p className='form_header_text'>Attach Pre Asset</p>
                </div>
                <div className='search_container2'>
                  <img className='howto_search_logo' src='/images/search.png' />
                  <input
                    className='background_container_header_input mt-left'
                    placeholder='Search assets'
                    value={searchPreAssets}
                    onChange={onSearchPreAssets}
                  />
                </div>
              </div>
              <form className='clinic_form_container'>
                <div className='clinic_form_top_container3'>
                  <div className='properties_card_videos__list--preassetmodal'>
                    {preAssetsList !== null &&
                    preAssetsList.data &&
                    preAssetsList.data.result &&
                    preAssetsList.data.result.length > 0 &&
                    preAssetsList.data.result.filter((data) =>
                      data.title
                        .toLowerCase()
                        .includes(searchPreAssets.toLowerCase())
                    ).length > 0 ? (
                      preAssetsList.data.result
                        .filter((data) =>
                          data.title
                            .toLowerCase()
                            .includes(searchPreAssets.toLowerCase())
                        )
                        .map((video, vIndex) => {
                          return (
                            <div
                              className='properties_card_videos__list--videoSelect'
                              key={vIndex}>
                              {video.fileType === 'doc' ? (
                                <div className='properties_card_pdf_conatiner'>
                                  <img
                                    className='properties_card_pdf'
                                    src='/images/pdf.png'
                                  />
                                </div>
                              ) : (
                                <>
                                  {video.fileType === 'audio' ? (
                                    <div className='properties_card_pdf_conatiner'>
                                      <img
                                        className='properties_card_pdf2'
                                        src='/images/audio_icon.png'
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {video.fileType === 'image' ? (
                                        <img
                                          alt='logo3'
                                          src={video.assetURL}
                                          style={{
                                            width: '18.5rem',
                                            height: '9rem',
                                          }}
                                        />
                                      ) : (
                                        <video width='185'>
                                          <source
                                            src={video.assetURL}
                                            type='video/mp4'
                                          />
                                          Your browser does not support HTML
                                          video.
                                        </video>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              <p>{video.title}</p>
                              {isPreAssetPresent(video) ? (
                                <p
                                  className='properties_card_videos__list--modalBtn2'
                                  onClick={() => {
                                    const newdata = {
                                      ...video,
                                      _id: '',
                                      selected: false,
                                    }

                                    setSelectAttachment(newdata)
                                    setSelectAttachmentError('')
                                  }}>
                                  Selected
                                </p>
                              ) : (
                                <p
                                  className='properties_card_videos__list--modalBtn'
                                  onClick={() => {
                                    const newdata = {
                                      ...video,
                                      selected: true,
                                    }
                                    setSelectAttachment(newdata)
                                    setSelectAttachmentError('')
                                  }}>
                                  {video.fileType === 'doc'
                                    ? 'select pdf'
                                    : video.fileType === 'audio'
                                    ? 'Select audio'
                                    : video.fileType === 'image'
                                    ? 'Select Image'
                                    : 'Select video'}
                                </p>
                              )}
                            </div>
                          )
                        })
                    ) : (
                      <div className='data_not_found_container2'>
                        <div className='data_not_found_message'>
                          {searchPreAssets !== ''
                            ? 'Search result not found'
                            : "You haven't uploaded any asset yet."}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {selectAttachmentError !== '' ? (
                  <div style={{ color: 'var(--red)', fontSize: '1.2rem' }}>
                    {selectAttachmentError}
                  </div>
                ) : null}

                <div className='modal_buttons'>
                  <AppButton
                    title='Submit'
                    type='submit'
                    icon={
                      <img
                        src='/images/add-clinic.png'
                        className='app_button_image'
                      />
                    }
                    onClick={attachPreAssestsHandler}
                  />
                  <AppButton
                    title='Cancel'
                    type='button'
                    buttonContainer={{
                      backgroundColor: 'var(--transparent)',
                      marginLeft: '0.5rem',
                    }}
                    textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                    onClick={closePreAssetsModal}
                  />
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
        </Fragment>
      )}
      {videoList !== null &&
        videoList.data &&
        videoList.data.result &&
        videoList.data.result.length > 0 &&
        videoList.data.totalPageSize > 8 && (
          <Pagination
            list={videoList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
            page={videoPageNumber}
          />
        )}
    </div>
  )
}

export default Videos
