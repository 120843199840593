import axios from 'axios';
import * as actions from './authApis';
import { baseUrl } from './url';

const api =
  ({ dispatch }) =>
  next =>
  async action => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data, token, onLoginStart, onLoginSuccess, onLoginFailed, onLogout } = action.payload;

    if (onLoginStart) dispatch({ type: onLoginStart });
    next(action);

    try {
      if (onLogout) return dispatch({ type: onLogout, payload: null });

      const headers = { 'Content-Type': 'application/json' };
      if (token) headers.token = token;

      const response = await axios.request({
        baseURL: baseUrl,
        url,
        method,
        data,
        headers,
      });

      // For General
      // dispatch(actions.apiCallSuccess(response.data))

      // For Specific
      if (onLoginSuccess) dispatch({ type: onLoginSuccess, payload: response.data });
    } catch (error) {
      // For General
      // dispatch(actions.apiCallFailed(error.response && error.response.data.message ? error.response.data : error.message))

      // For Specific
      if (onLoginFailed)
        dispatch({
          type: onLoginFailed,
          payload: error.response && error.response.data.message ? error.response.data : error.message,
        });
    }
  };

export default api;
