import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'enabled-video-list',
  initialState: {
    enabledVideoList: [],
    loading: false,
  },
  reducers: {
    requested: (clients, action) => {
      clients.loading = true
    },
    success: (clients, action) => {
      clients.enabledVideoList = action.payload
      clients.loading = false
    },
    failed: (clients, action) => {
      clients.enabledVideoList = action.payload
      clients.loading = false
    },
  },
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getEnabledVideoList = () =>
  apiCallBegan({
    url: '/media/get-enabled-media-if-question-available',
    method: 'GET',
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type,
  })
