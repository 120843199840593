import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'user-auth',
    initialState: {
        isLoggedIn: false,
    },
    reducers: {
        onAuth: (auth, action) => {
            auth.isLoggedIn = action.payload;
        }
    }
})

const { onAuth } = slice.actions
export default slice.reducer

export const setLoggedIn = data => apiCallBegan({
    data,
    onAuthentication: onAuth.type,
})