import React from 'react';
import { Link } from 'react-router-dom';
import AppSwitchSmall from '../../AppSwitchSmall';

import './SpaceCard.css';

const SpaceCard = ({ item, loading, onEditClick, onChangeSwitch, currentItem, onDelete, totalSpaces, handleOrderChange, propertyModuleName }) => {
  const [items, setItems] = React.useState([]);
  const [selectedOrder, setSelectedOrder] = React.useState(item.orderNumber);

  React.useState(() => {
    const data = [];
    for (let i = 0; i < totalSpaces; i++) {
      data.push(i);
    }
    setItems(data);
  });

  return (
    <div className='client_card_container'>
      <div className='client_card_view_container'>
        <img alt='mission' src={item.imageURL} className='client_card_image' />
        <div className='client_card_detail_container'>
          <p className='client_card_title'>{item.title}</p>

          <div className='flex'>
            <p className='client_card_count'>
              {item.propertiesCount} {propertyModuleName} |{' '}
            </p>
            <p className='client_card_count'> Color</p>
            <div
              style={{
                backgroundColor: item.color !== null && item.color.primaryColor,
              }}
              className='dropdown_selectedColor'
            />
          </div>
          <div className='client_card_view_space_container'>
            {totalSpaces > 0 && items.length > 0 && (
              <select
                value={selectedOrder}
                onChange={e => {
                  handleOrderChange(e.target.value, item.orderNumber);
                  setSelectedOrder(e.target.value);
                }}
              >
                {/* <option>Select Order</option> */}
                {items.map(item => (
                  <option key={item} value={item + 1}>
                    Order {item + 1}
                  </option>
                ))}
              </select>
            )}
            <Link to={`/properties/${item._id}/${item.title}`} className='client_card_view_space'>
              view {propertyModuleName ? propertyModuleName : 'property'}
            </Link>
          </div>
        </div>
      </div>
      <div className='client_card_action_container'>
        <div className='client_card_action_container_left'>
          <AppSwitchSmall checked={item.enable} onChange={() => onChangeSwitch(item)} loading={currentItem === item._id && loading} />
          <img src='/images/editPrimary.png' className='client_card_edit_image' onClick={() => onEditClick(item)} />
        </div>
        <img src='/images/delete.png' className='client_card_delete_image' onClick={() => onDelete(item)} />
      </div>
    </div>
  );
};

export default SpaceCard;
