import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'

import { logoutUser } from '../../store/login'
import { setSidebar } from '../../store/localStates/sidebar'

import './mobile.css'

const MobileSidebar = () => {
  const dispatch = useDispatch()
  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { userInfo } = useSelector((user) => user.credentials)

  const [showReports, setShowReports] = useState(false)
  const [showAvatarProps, setShowAvatarProps] = useState(false)

  const onHandlerLogout = async () => {
    await dispatch(logoutUser())
    await dispatch(setSidebar(!sidebar))
  }

  return (
    <div
      className={
        sidebar ? 'mobile_sidebar_container' : 'mobile_sidebar_overflow'
      }>
      <div>
        <div className='hamburger_container'>
          <NavLink
            to='/'
            onClick={() => [
              setShowReports(false),
              dispatch(setSidebar(false)),
              setShowAvatarProps(false),
            ]}>
            <img
              src='/images/bx_white_logo.png'
              className='mobile_sidebar_logo'
            />
          </NavLink>
          <AiOutlineCloseCircle
            className='hamburger_mobile_menu_icon'
            onClick={() => [setShowReports(false), dispatch(setSidebar(false))]}
          />
        </div>
        <NavLink
          exact
          to='/'
          className='mobile_sidebar_items'
          activeClassName={`mobile_sidebar_active_tab`}
          onClick={() => [
            dispatch(setSidebar(!sidebar)),
            setShowAvatarProps(false),
          ]}>
          <img
            src='/images/dashboard.png'
            className='mobile_sidebar_menu_item_logo'
          />
          <img
            src='/images/dashboard2.png'
            className='mobile_sidebar_menu_item_logo2'
          />
          <p className='mobile_sidebar_menu_item_title'>Dashboard</p>
        </NavLink>
        {userInfo && userInfo.userRole === 'Admin' ? (
          <>
            <NavLink
              exact
              to='/client'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                dispatch(setSidebar(!sidebar)),
                setShowAvatarProps(false),
              ]}>
              <img
                src='/images/users.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/users2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Client</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/appversion'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                dispatch(setSidebar(!sidebar)),
                setShowAvatarProps(false),
              ]}>
              <img
                src='/images/users.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/users2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>App Versions</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/how-to'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => setShowAvatarProps(true)}>
              <img
                src='/images/bulb.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/bulb2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>How To</p>
              )}
            </NavLink>
            {showAvatarProps && (
              <div>
                <NavLink
                  exact
                  to='/how-to/background'
                  className='mobile_sidebar_items'
                  activeClassName={`mobile_sidebar_active_tab`}
                  onClick={() => dispatch(setSidebar(!sidebar))}>
                  <img
                    src='/images/points.png'
                    className='mobile_sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/points2.png'
                    className='mobile_sidebar_menu_item_logo2'
                  />
                  {sidebar && (
                    <p className='mobile_sidebar_menu_item_title'>
                      Backgrounds
                    </p>
                  )}
                </NavLink>
                <NavLink
                  exact
                  to='/how-to/tags'
                  className='mobile_sidebar_items'
                  activeClassName={`mobile_sidebar_active_tab`}
                  onClick={() => dispatch(setSidebar(!sidebar))}>
                  <img
                    src='/images/state.png'
                    className='mobile_sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/state2.png'
                    className='mobile_sidebar_menu_item_logo2'
                  />
                  {sidebar && (
                    <p className='mobile_sidebar_menu_item_title'>Tags</p>
                  )}
                </NavLink>
              </div>
            )}
            <NavLink
              exact
              to='/faqs'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                dispatch(setSidebar(!sidebar)),
                setShowAvatarProps(false),
              ]}>
              <img
                src='/images/qa.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/qa2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>FAQs</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/about-the-algo'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                dispatch(setSidebar(!sidebar)),
                setShowAvatarProps(false),
              ]}>
              <img
                src='/images/algo.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/algo2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>About The Algo</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/about-the-bx'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                dispatch(setSidebar(!sidebar)),
                setShowAvatarProps(false),
              ]}>
              <img
                src='/images/bx.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/bx2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>About The Bx</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/privacy-policy'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                dispatch(setSidebar(!sidebar)),
                setShowAvatarProps(false),
              ]}>
              <img
                src='/images/privacy.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/privacy2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Privacy Policy</p>
              )}
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              exact
              to='/users'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/users.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/users2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Users</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/missions'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/missions.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/missions2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Missions</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/spaces'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/spaces.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/spaces2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Spaces</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/properties'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/properties.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/properties2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Properties</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/videos'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/video.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/video2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Videos</p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/allresponse'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/video.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/video2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>
                  Response Content
                </p>
              )}
            </NavLink>
            <NavLink
              exact
              to='/question-categories'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/categories.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/categories2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>
                  Question Categories
                </p>
              )}
            </NavLink>
            <div
              className='mobile_sidebar_items'
              onClick={() => setShowReports(!showReports)}>
              <img
                src='/images/categories.png'
                className='sidebar_menu_item_logo'
              />
              <img
                src='/images/categories2.png'
                className='sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Reports</p>
              )}
            </div>
            {showReports && (
              <div>
                <NavLink
                  exact
                  to='/category-reports'
                  className='mobile_sidebar_items'
                  activeClassName={`mobile_sidebar_active_tab`}
                  onClick={() => dispatch(setSidebar(!sidebar))}>
                  <img
                    src='/images/points.png'
                    className='mobile_sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/points2.png'
                    className='mobile_sidebar_menu_item_logo2'
                  />
                  {sidebar && (
                    <p className='mobile_sidebar_menu_item_title'>
                      Category reports
                    </p>
                  )}
                </NavLink>
                <NavLink
                  exact
                  to='/point-reports'
                  className='mobile_sidebar_items'
                  activeClassName={`mobile_sidebar_active_tab`}
                  onClick={() => dispatch(setSidebar(!sidebar))}>
                  <img
                    src='/images/points.png'
                    className='mobile_sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/points2.png'
                    className='mobile_sidebar_menu_item_logo2'
                  />
                  {sidebar && (
                    <p className='mobile_sidebar_menu_item_title'>
                      Points reports
                    </p>
                  )}
                </NavLink>
                <NavLink
                  exact
                  to='/statistics-reports'
                  className='mobile_sidebar_items'
                  activeClassName={`mobile_sidebar_active_tab`}
                  onClick={() => dispatch(setSidebar(!sidebar))}>
                  <img
                    src='/images/state.png'
                    className='mobile_sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/state2.png'
                    className='mobile_sidebar_menu_item_logo2'
                  />
                  {sidebar && (
                    <p className='mobile_sidebar_menu_item_title'>
                      Statistics reports
                    </p>
                  )}
                </NavLink>
                <NavLink
                  exact
                  to='/process-reports'
                  className='mobile_sidebar_items'
                  activeClassName={`mobile_sidebar_active_tab`}
                  onClick={() => dispatch(setSidebar(!sidebar))}>
                  <img
                    src='/images/state.png'
                    className='mobile_sidebar_menu_item_logo'
                  />
                  <img
                    src='/images/state2.png'
                    className='mobile_sidebar_menu_item_logo2'
                  />
                  {sidebar && (
                    <p className='mobile_sidebar_menu_item_title'>
                      Progress reports
                    </p>
                  )}
                </NavLink>
              </div>
            )}
            <NavLink
              exact
              to='/notification'
              className='mobile_sidebar_items'
              activeClassName={`mobile_sidebar_active_tab`}
              onClick={() => [
                setShowReports(false),
                dispatch(setSidebar(false)),
              ]}>
              <img
                src='/images/notification.png'
                className='mobile_sidebar_menu_item_logo'
              />
              <img
                src='/images/notification2.png'
                className='mobile_sidebar_menu_item_logo2'
              />
              {sidebar && (
                <p className='mobile_sidebar_menu_item_title'>Notifications</p>
              )}
            </NavLink>
          </>
        )}
      </div>
      <div>
        <NavLink
          exact
          to='/my-profile'
          className='mobile_sidebar_items'
          activeClassName={`mobile_sidebar_active_tab`}
          onClick={() => dispatch(setSidebar(!sidebar))}>
          <svg className='mobile_sidebar_menu_item_logo'>
            <use xlinkHref={`/images/sprite.svg#icon-settings`} />
          </svg>
          <svg className='mobile_sidebar_menu_item_logo2'>
            <use xlinkHref={`/images/sprite.svg#icon-settings`} />
          </svg>
          {sidebar && (
            <p className='mobile_sidebar_menu_item_title'>Settings</p>
          )}
        </NavLink>
        <div
          onClick={onHandlerLogout}
          className='mobile_logout_link_container'
          activeclassname={`mobile_sidebar_active_tab`}>
          <img
            src='/images/logout.png'
            className='mobile_sidebar_menu_item_logo'
          />
          {sidebar && <p className='mobile_sidebar_menu_item_title'>Logout</p>}
        </div>
      </div>
    </div>
  )
}

export default MobileSidebar
