import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'enable-registration-dialogue',
  initialState: {
    enableRegistration: null,
    loading: false,
  },
  reducers: {
    requested: (registration) => {
      registration.loading = true
    },
    success: (registration, action) => {
      registration.enableRegistration = action.payload
      registration.loading = false
    },
    failed: (registration, action) => {
      registration.enableRegistration = action.payload
      registration.loading = false
    },
    reset: (registration, action) => {
      registration.enableRegistration = action.payload
      registration.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const enableDisableRegistrationDialogue = (data) =>
  apiCallBegan({
    url: `version/enable-disable-registration-dialogue-box`,
    method: 'PATCH',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearEnableDisableRegistrationDialogue = () =>
  apiCallBegan({
    onReset: reset.type,
  })
