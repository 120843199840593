import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'attach-pre-assets',
  initialState: {
    attachPreVideoResponse: null,
    loading: false,
  },
  reducers: {
    requested: (video) => {
      video.loading = true
    },
    success: (video, action) => {
      video.attachPreVideoResponse = action.payload
      video.loading = false
    },
    failed: (video, action) => {
      video.attachPreVideoResponse = action.payload
      video.loading = false
    },
    reset: (video, action) => {
      video.attachPreVideoResponse = action.payload
      video.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const attachPreAssets = (data) =>
  apiCallBegan({
    url: '/media/add-pre-asset-reference-media',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearAttachPreAssetsResponse = () =>
  apiCallBegan({
    onLogout: reset.type,
  })
