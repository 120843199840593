import React from 'react'

import './switch.css'

const AppSwitch = ({ loading, checked, onChange }) => {
    return (
        <div className={`custom_switch_container ${checked && "custom_switch_container_checked"}`} onClick={onChange}>
            {loading ?
                <div className="swicth_loader_container">
                    <img src='/images/loader.gif' className='swicth_loader' alt='loader' />
                </div> :
                <div className={`custom_switch_dot ${checked && "custom_switch_dot_checked"}`} />
            }
        </div>
    )
}

export default AppSwitch