import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'addVersion',
  initialState: {
    addVersionInfo: null,
    loading: false,
  },
  reducers: {
    requested: (state, action) => {
      state.loading = true
    },
    success: (state, action) => {
      state.addVersionInfo = action.payload
      state.loading = false
    },
    failed: (state, action) => {
      state.addVersionInfo = action.payload
      state.loading = false
    },
    reset: (state, action) => {
      state.addVersionInfo = action.payload
      state.loading = false
    },
  },
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addVersion = (data) =>
  apiCallBegan({
    url: '/version/add-version',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

  export const changeVersionStatus = (versionId, data) =>
    apiCallBegan({
      url: `/version/enable-disable-version?id=${versionId}`,
    method: 'PATCH',
    data,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })

export const clearAddVersionResponse = () =>
  apiCallBegan({
    onReset: reset.type,
  })
