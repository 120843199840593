import {
  GET_PRETEST_REPORT_REQUEST,
  GET_PRETEST_REPORT_SUCCESS,
  GET_PRETEST_REPORT_FAIL,
  GET_PRETEST_REPORT_RESET,
  GET_USER_INFO_BY_TOKEN_REQUEST,
  GET_USER_INFO_BY_TOKEN_SUCCESS,
  GET_USER_INFO_BY_TOKEN_FAIL,
  GET_USER_INFO_BY_TOKEN_RESET,
  UPDATE_USERS_PROFILE_REQUEST,
  UPDATE_USERS_PROFILE_SUCCESS,
  UPDATE_USERS_PROFILE_FAIL,
  UPDATE_USERS_PROFILE_RESET,
} from '../constants/myProfileConstants'
import { baseUrl } from '../../../middleware/url'
import axios from 'axios'

export const getPretestReport = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PRETEST_REPORT_REQUEST })
    const {
      credentials: { credentials },
      userDetails,
    } = getState()

    const config = {
      headers: {
        token: credentials.data.token,
        'Content-Type': 'application/json',
      },
    }

    const uri = `${baseUrl}/report/get-pre-test-point-report-by-mission-for-chart`

    const { data } = await axios.get(uri, config)

    dispatch({
      type: GET_PRETEST_REPORT_SUCCESS,
      payload: data,
    })
  } catch (err) {
    dispatch({
      type: GET_PRETEST_REPORT_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    })
  }
}

export const getUserByToken = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_INFO_BY_TOKEN_REQUEST })
    const {
      credentials: { credentials },
    } = getState()

    const config = {
      headers: {
        token: credentials.data.token,
        'Content-Type': 'application/json',
      },
    }

    const uri = `${baseUrl}/user/get-user-by-token`

    const { data } = await axios.get(uri, config)

    dispatch({
      type: GET_USER_INFO_BY_TOKEN_SUCCESS,
      payload: data,
    })
  } catch (err) {
    dispatch({
      type: GET_USER_INFO_BY_TOKEN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    })
  }
}

export const updateUserProfile = (info) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USERS_PROFILE_REQUEST })
    const {
      credentials: { credentials },
    } = getState()

    const config = {
      headers: {
        token: credentials.data.token,
        'Content-Type': 'application/json',
      },
    }

    const uri = `${baseUrl}/user/update-user`

    const { data } = await axios.post(uri, info, config)

    dispatch({
      type: UPDATE_USERS_PROFILE_SUCCESS,
      payload: data,
    })
  } catch (err) {
    dispatch({
      type: UPDATE_USERS_PROFILE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    })
  }
}

export const clearUpdateUserProfile = (info) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_USERS_PROFILE_RESET, payload: null })
}
