import { combineReducers } from 'redux'

import sidebar from './localStates/sidebar'
import isLoading from './localStates/appLoader'
import toaster from './localStates/toaster'

import credentials from './login'
import isLoggedIn from './localStates/auth'

import missionCount from './dashboard/missionCount'
import userCount from './dashboard/userCount'
import spaceCountList from './dashboard/spaceCounts'
import propertyCountList from './dashboard/propertyCount'

import userList from './users/userList'
import userStatus from './users/enableDisableUser'
import userSignupResponse from './users/userSignup'
import updateUserResponse from './users/updateUser'
import deleteUser from './users/DeleteUser'
import userKidList from './users/usersKidList'
import addUser from './users/addUser'

//client
import changePassword from './Clients/changePassword'
import clientList from './Clients/clientsList'
import enabledClientList from './Clients/enabledClientsList'
import clientStatus from './Clients/enableDisableClient'
import currentClient from './localStates/selectedClient'
import addClientResponse from './Clients/addClient'
import moduleNames from './Clients/getModuleNames'

import missionList from './missions/missionList'
import missionStatus from './missions/enableDisableMission'
import addMissionResponse from './missions/addMission'
import enabledMissionList from './missions/enabledMissionList'
import deleteMissionResponse from './missions/deleteMission'

import addSpaceResponse from './spaces/addSpaces'
import spaceStatus from './spaces/enableDisableSpaces'
import spaceList from './spaces/SpaceList'
import enabledSpaceList from './spaces/enabledSpaceList'
import deleteSpaceResponse from './spaces/deleteSpace'
import spaceListById from './spaces/getSpaceById'

import propertyList from './properties/propertyList'
import propertiesStatus from './properties/enableDisableProperties'
import addPropertyResponse from './properties/addProperty'
import propertyListById from './properties/getPropertiesById'
import allPropertyList from './properties/getAllProperties'
import deletePropertyResponse from './properties/deleteProperties'

import videoList from './video/videoList'
import addVideoResponse from './video/addVideo'
import enabledVideoList from './video/enabledVideoList'
import deleteVideoResponse from './video/deleteVideo'

import pageTitle from './localStates/pageTitle'
import currentTab from './localStates/currentTab'
import videoPageNumber from './localStates/paginations/videoPagination'

//questions reducers
import addQuestionResponse from './questions/addQuestion'
import questionList from './questions/questionList'
import questionStatus from './questions/enableDisableQuestion'
import deleteQuestionResponse from './questions/deleteQuestion'
import getResponseContent from './questions/responseContentList'
import getFailResponseContent from './questions/failResponseContentList'
import questionResponseContent from './questions/questionResponseContent'

import howToList from './howTo/howToList'
import howToStatus from './howTo/enableDisableHowTo'
import addhowToResponse from './howTo/addHowTo'

import colorList from './colors/colorList'
import changePasswordResponse from './login/changePassword'

import aboutTheAlgorithmResponse from './about/aboutTheAlgorithm'
import aboutTheAlgorithm from './about/getAboutTheAlgo'
import aboutTheBxResponse from './about/aboutTheBx'
import aboutTheBx from './about/getAboutTheBx'

import faqList from './faq/faqList'
import faqStatus from './faq/enableDisableFAQ'
import addFAQResponse from './faq/addFAQ'
import faqDeleteStatus from './faq/deleteFAQ'

import notificationList from './notifications/notificationList'
import notificationStatus from './notifications/enableDisableNotification'
import sendNotificationResponse from './notifications/sendNotification'

import privacyData from './privacy'
import addPrivacyResponse from './privacy/addPrivacy'

import cetegoryList from './cetegory/cetegoryList'
import addCetegoryResponse from './cetegory/addCetegory'
import cetegoryStatus from './cetegory/enableDisableClient'
import enabledCetegoryList from './cetegory/enabledCetegoryList'

import categoryReport from './reports/categoryReport'
import categoryChartReport from './reports/categoryChartReport'
import pointsReport from './reports/pointReport'
import pointsChartReport from './reports/pointChartReport'
import statisticsReport from './reports/statisticsReport'
import statisticsChartReport from './reports/statisticsChartReport'
import processReport from './reports/processCount'
import processChartReport from './reports/processChartReport'
import preTestReport from './reports/preTestReport'
import preTestChartReport from './reports/preTestChartReport'

import avatarBackgroundList from './avatar/avatarBackgroundList'
import addBackgroundResponse from './avatar/addBackground'
import addTagResponse from './avatar/addTag'
import deleteAvatarPropsResponse from './avatar/deleteAvatarProps'
import avatarTagList from './avatar/avatarTagList'
import tagStatus from './avatar/enableDisableTag'
import backgroundStatus from './avatar/enableDisableBackground'

//response videoList

import responseVideoList from './responseVideo/getResponse'
import addResponseVideo from './responseVideo/addResponseVideo'
import deleteResponseVideo from './responseVideo/deleteResponseVideo'

// version

import addVersion from './Versions/AddVersion'
import getVersionList from './Versions/getVersion'
import deleteVersion from './Versions/deleteVersion'
import updateVersion from './Versions/updateVersion'

//audit

import userAudit from './audit/getAudit'

//success video

import getSuccessVideo from './successVideo/getSuccessVideos'
import addSuccessVideo from './successVideo/addSuccessVideo'
import deleteSuccessVideo from './successVideo/deleteSuccessVideo'
import enableSuccessVideo from './successVideo/enableSuccessVideo'

//onboard
import addOnBoarding from './onBoarding/addOnBoarding'
import getOnBoarding from './onBoarding/getOnBoarding'
import updateApproval from './onBoarding/updateApproval'
import addOnBoardingPrimary from './onBoarding/addOnBoardingPrimary'
import getPlans from '../store/onBoarding/getPlans'
import verifyCoupon from './onBoarding/verifyCoupon'
import paymentConfirmation from './onBoarding/paymentConfirmation'

//configuration

import getConfig from '../store/configuration/getConfig'
import patchConfig from './configuration/patchConfig'

import {
  tilesReducer,
  userDetailsByTokenReducer,
  userLoginReducer,
} from '../src2/redux/reducers/userReducers'
import { allMissionsReducer } from '../src2/redux/reducers/missionReducers'
import { allSpacesReducer } from '../src2/redux/reducers/spaceReducers'
import {
  allPropertiesReducer,
  getMediaDetailsReducer,
  getPropertyQuestionsReducer,
  getQuestionProgressReducer,
  getResponseVideosReducer,
} from '../src2/redux/reducers/propertyReducers'
import { addStatisticsReducer } from '../src2/redux/reducers/statisticsReducers'
import {
  getAboutReducer,
  getPrivacyPolicyReducer,
} from '../src2/redux/reducers/aboutReducers'
import {
  myProfileReducer,
  myProfileUserReducer,
  updateUserprofileReducer,
} from '../src2/redux/reducers/myProfileReducer'
import getStudentList from '../store/students/getStudentList'
import changeStudent from './students/changeStudent'
import addStudent from './students/addStudent'
import getGraphIds from './performance/getGraphIds'
import getGraphData from './performance/getGraphData'
import getSkillGraphData from './performance/getSkillGraphData'
import getPlayGraphData from './performance/getPlayGraphData'
import getWeeklyCounts from './leaderboard/getWeeklyCount'
import getProgress from './userDashboard/getProgress'

//settings

import getSubscription from './settings/getSubscription'

//coupons
import getCoupon from './Coupon/getCoupons'
import addCoupon from './Coupon/addCoupons'
import deleteCoupon from './Coupon/deleteCoupon'
import enableCoupon from './Coupon/enableCoupon'

import getAvatarProperties from './avatar/getAvatarProperties'
import updateAvatar from './avatar/updateAvatar'
import purchaseAvatar from './avatar/purchaseAvatar'

import verifyUser from './therapistView/verifyUser'
import changeTherapistView from './therapistView/changeTherapistView'

import getPromocode from './promocode/getPromocode'
import addPromocode from './promocode/addPromocode'
import enablePromocode from './promocode/enablePromocode'

import cancelSubscription from './settings/cancelSubscription'
import reactivateSubsription from './settings/reactivateSubsription'

import getKidAudit from './users/getKidAudit'

import addPreAssets from './preAsset/addPreAssets'
import deletePreAssets from './preAsset/deletePreAssets'
import preAssetsList from './preAsset/preAssetsList'
import attachPreAsset from './preAsset/attachPreAsset'
import getAllPreAssets from './preAsset/getAllPreAssets'

import enableRegistrationDialogue from './settings/enableRegistrationDialogue'
import getRegistrationDialogue from './settings/getRegistrationDialogue'

const reducers = combineReducers({
  userLogin: userLoginReducer,
  userDetails: userDetailsByTokenReducer,
  tiles: tilesReducer,
  missions: allMissionsReducer,
  spaces: allSpacesReducer,
  properties: allPropertiesReducer,
  mediaDetails: getMediaDetailsReducer,
  propertyQuestions: getPropertyQuestionsReducer,
  addStatistics: addStatisticsReducer,
  questionProgress: getQuestionProgressReducer,
  myProfileReport: myProfileReducer,
  userByTokenInfo: myProfileUserReducer,
  updateUserprofile: updateUserprofileReducer,
  responseVideos: getResponseVideosReducer,

  about: getAboutReducer,
  privacyPolicy: getPrivacyPolicyReducer,

  sidebar,
  toaster,
  isLoading,

  credentials,
  isLoggedIn,

  missionCount,
  userCount,
  spaceCountList,
  propertyCountList,

  userList,
  deleteUser,
  userStatus,
  userSignupResponse,
  updateUserResponse,
  userKidList,
  addUser,

  changePassword,
  clientList,
  enabledClientList,
  clientStatus,
  currentClient,
  addClientResponse,
  moduleNames,

  missionList,
  missionStatus,
  addMissionResponse,
  enabledMissionList,
  deleteMissionResponse,

  addSpaceResponse,
  spaceStatus,
  spaceList,
  enabledSpaceList,
  deleteSpaceResponse,
  spaceListById,

  propertyList,
  propertiesStatus,
  addPropertyResponse,
  propertyListById,
  allPropertyList,
  deletePropertyResponse,

  videoList,
  addVideoResponse,
  enabledVideoList,
  deleteVideoResponse,

  pageTitle,
  currentTab,
  videoPageNumber,

  addQuestionResponse,
  questionList,
  questionStatus,
  deleteQuestionResponse,
  getResponseContent,
  getFailResponseContent,
  questionResponseContent,

  howToList,
  howToStatus,
  addhowToResponse,

  colorList,
  changePasswordResponse,

  aboutTheAlgorithmResponse,
  aboutTheAlgorithm,

  aboutTheBxResponse,
  aboutTheBx,
  faqList,
  faqStatus,
  addFAQResponse,
  faqDeleteStatus,

  notificationList,
  notificationStatus,
  sendNotificationResponse,

  privacyData,
  addPrivacyResponse,

  cetegoryList,
  addCetegoryResponse,
  cetegoryStatus,
  enabledCetegoryList,

  categoryReport,
  categoryChartReport,
  pointsReport,
  pointsChartReport,
  statisticsReport,
  statisticsChartReport,
  processReport,
  processChartReport,
  preTestReport,
  preTestChartReport,

  avatarBackgroundList,
  addBackgroundResponse,
  addTagResponse,
  deleteAvatarPropsResponse,
  avatarTagList,
  tagStatus,
  backgroundStatus,

  responseVideoList,
  addResponseVideo,
  deleteResponseVideo,

  addVersion,
  getVersionList,
  deleteVersion,
  updateVersion,

  userAudit,

  getSuccessVideo,
  addSuccessVideo,
  deleteSuccessVideo,
  enableSuccessVideo,

  addOnBoarding,
  getOnBoarding,
  updateApproval,
  addOnBoardingPrimary,
  getPlans,
  verifyCoupon,
  paymentConfirmation,

  getStudentList,
  changeStudent,
  addStudent,

  getGraphIds,
  getGraphData,
  getSkillGraphData,
  getPlayGraphData,

  getWeeklyCounts,

  getConfig,
  patchConfig,

  getProgress,

  getSubscription,
  cancelSubscription,
  reactivateSubsription,

  getCoupon,
  addCoupon,
  deleteCoupon,
  enableCoupon,

  getAvatarProperties,
  updateAvatar,
  purchaseAvatar,

  verifyUser,
  changeTherapistView,
  getPromocode,
  addPromocode,
  enablePromocode,

  getKidAudit,
  addPreAssets,
  deletePreAssets,
  preAssetsList,
  attachPreAsset,
  getAllPreAssets,

  enableRegistrationDialogue,
  getRegistrationDialogue,
})

const rootReducer = (state, action) => {
  if (action.type === 'user-login/logout') {
    state = undefined
  }
  return reducers(state, action)
}
export default rootReducer
