import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enable-disable-faq',
    initialState: {
        faqStatus: null,
        loading: false,
    },
    reducers: {
        requested: (users) => {
            users.loading = true
        },
        success: (users, action) => {
            users.faqStatus = action.payload;
            users.loading = false
        },
        failed: (users, action) => {
            users.faqStatus = action.payload;
            users.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const setEnableDisableFAQ = (data) => apiCallBegan({
    url: '/faq/enable-disable-faq',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})