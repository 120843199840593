import React from 'react';
import HomeScreenStyles from '../../screens/Home/Home.module.css';
import SkeletonStyles from './Skeletons.module.css';

const MissionCardsSkeleton = ({ items = 4 }) => {
	const [ cards, setCards ] = React.useState([]);

	React.useEffect(
		() => {
			const iter = [];
			for (let i = 0; i < items; i++) {
				iter.push(i);
			}
			setCards(iter);
		},
		[ items ]
	);

	return (
		cards &&
		cards.length > 0 &&
		cards.map((card) => (
			<div className={HomeScreenStyles.selectTypes_card} key={card}>
				<div className={HomeScreenStyles.selectTypes_card_content}>
					<div className={[ SkeletonStyles.selectTypes_card_image_skeleton, SkeletonStyles.skeleton ].join(' ')} />
					<div>
						<div className={[ SkeletonStyles.selectTypes_card_name_skeleton, SkeletonStyles.skeleton ].join(' ')} />
						<div
							className={[ SkeletonStyles.selectTypes_card_name_skeleton, SkeletonStyles.skeleton ].join(' ')}
							style={{ height: '1rem', width: '10rem', marginTop: '1rem' }}
						/>
					</div>
				</div>

				<div className={HomeScreenStyles.selectTypes_card_progress}>
					<div
						className={[ SkeletonStyles.selectTypes_card_name_skeleton, SkeletonStyles.skeleton ].join(' ')}
						style={{ height: '1.5rem', width: '5rem' }}
					/>
				</div>
			</div>
		))
	);
};

export default MissionCardsSkeleton;
