import React from 'react'
import AppSwitchSmall from '../../AppSwitchSmall'
import Styles from './VersionCard.module.css'
import { AiFillAndroid, AiFillApple } from 'react-icons/ai'

const VersionCard = ({
  onDelete,
  onChange,
  androidVersion,
  iosVersion,
  description,
  isCompulsory,
  deleteId,
}) => {
  return (
    <div
      className='client_card_container'
      style={{ width: '35rem', height: '20rem' }}>
      <div className='client_card_header'>
        <div className={Styles.boxContainer}>
          <div className={Styles.box}>
            <AiFillAndroid size={16} />
            <div className={Styles.title}>
              {androidVersion ? androidVersion : ''}
            </div>
          </div>
          <div className={Styles.box}>
            <AiFillApple size={16} />
            <div className={Styles.title}>{iosVersion ? iosVersion : ''}</div>
          </div>
        </div>
        <div className='user_card_actions'>
          <div
            className='client_card_action_container_left'
            style={{ marginTop: 0 }}>
            <AppSwitchSmall
              onChange={() => onChange(deleteId, !isCompulsory)}
              checked={isCompulsory}
              //   loading={currentItem === item._id && statusLoading}
            />
            <img
              src='/images/delete.png'
              className='client_card_delete_image'
              onClick={() => onDelete(deleteId)}
            />
          </div>
        </div>
      </div>
      <div className={Styles.description}>{description ? description : ''}</div>
      {/* <div className='user_card_mobile_email'>
        <svg className='user_card_mobile_icon'>
          <use xlinkHref={`/images/sprite.svg#icon-phone`} />
        </svg>
        <p className='user_card_mobile_number'>8696417282</p>
      </div>
      <div className='user_card_mobile_email'>
        <svg className='user_card_email_icon'>
          <use xlinkHref={`/images/sprite.svg#icon-email`} />
        </svg>
        <p className='user_card_email_number'>abhishekpal401@gmail.com</p>
      </div> */}
    </div>
  )
}

export default VersionCard
