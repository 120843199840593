import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppHeader from '../../../../components/AppHeader'
import { getUserAudit, clearUserAudit } from '../../../../store/audit/getAudit'
import styles from './audit.module.css'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import Pagination from '../../../../components/Pagination'

const Users = () => {
  const dispatch = useDispatch()
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState('')

  const pageSize = 15

  const { sidebar } = useSelector((bar) => bar.sidebar)

  const { usersAuditInfo, loading } = useSelector((audit) => audit.userAudit)

  const { credentials } = useSelector((state) => state.credentials)

  useEffect(() => {
    dispatch(
      getUserAudit({
        filter: '',
        pageNumber,
        pageSize,
      })
    )
  }, [credentials])

  const onPageChange = (page) => {
    const selectedPage = page.selected
    dispatch(
      getUserAudit({ pageSize, pageNumber: selectedPage + 1, filterValue: '' })
    )
    setPageNumber(selectedPage + 1)
  }

  const onSearchSpaces = (event) => {
    setSearch(event.target.value)
    dispatch(
      getUserAudit({ pageSize, pageNumber, filterValue: event.target.value })
    )
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Users Audit'
        isSearch
        searchPlaceholder='Search users audit'
        onSearch={onSearchSpaces}
        searchValue={search}
      />
      {loading ? (
        <TableSkeleton />
      ) : (
        <div className='client__container'>
          <div className='missions_main_content'>
            {usersAuditInfo &&
            usersAuditInfo?.status &&
            usersAuditInfo?.data &&
            usersAuditInfo?.data?.result &&
            Array.isArray(usersAuditInfo?.data?.result) ? (
              <div className={styles.table_box}>
                <table className={styles.table_content}>
                  <thead>
                    <tr>
                      <th>SNo.</th>
                      <th>System</th>
                      <th>App Version</th>
                      <th>Brand</th>
                      <th>Brand Model</th>
                      <th>Network</th>
                      <th>Speed(Mbps)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersAuditInfo &&
                      usersAuditInfo.status &&
                      usersAuditInfo.data &&
                      usersAuditInfo.data.result &&
                      Array.isArray(usersAuditInfo.data.result) &&
                      usersAuditInfo.data.result.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item?.systemName ? item?.systemName : 'N/A'}
                            </td>
                            <td>
                              {item?.appVersion ? item?.appVersion : 'N/A'}
                            </td>
                            <td>{item?.brand ? item?.brand : 'N/A'}</td>
                            <td>{item?.model ? item?.model : 'N/A'}</td>
                            <td>
                              {JSON.parse(item?.lastNetwork).cellularGeneration
                                ? JSON.parse(item?.lastNetwork)
                                    .cellularGeneration
                                : 'N/A'}
                            </td>
                            <td>
                              {JSON.parse(item?.lastNetwork).speed
                                ? Number(
                                    JSON.parse(item?.lastNetwork).speed
                                  ).toFixed(2)
                                : 'N/A'}
                            </td>
                            <td></td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className='data_not_found_container'>
                <p className='data_not_found_message'>
                  {usersAuditInfo?.message}
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {usersAuditInfo !== null &&
        usersAuditInfo.data &&
        usersAuditInfo.data.result &&
        usersAuditInfo.data.result.length > 0 &&
        usersAuditInfo.data.totalPageSize > pageSize && (
          <Pagination
            list={usersAuditInfo}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
          />
        )}
    </div>
  )
}

export default Users
