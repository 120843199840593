import React from 'react';
import classes from './Onboard.module.css';
import { Button, CheckBox, TextBox } from './components';
import { inputPhoneMasking } from '../../../utils/validators';

const Step1 = ({ userState, setUserState, handleStep1 }) => {
  return (
    <div className={classes.content}>
      <div className={classes.header_box}>
        <img src='/assets/bx_black_logo.png' alt='BX Logo' className={classes.logo} />
        <h1 className={classes.main_heading}>Bx Builders</h1>
        <p className={classes.main_sub_heading}>Fostering growth. Building connections.</p>
      </div>
      <p className={classes.heading}>Account Details</p>

      <form className={classes.content_box} onSubmit={handleStep1}>
        <TextBox
          placeholder='Name'
          name='name'
          value={userState.step1.name.value}
          onChange={e => setUserState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, name: { value: e.target.value, error: '' } } }))}
          errorMessage={userState.step1.name.error}
        />
        <TextBox
          placeholder='Email'
          name='email'
          value={userState.step1.email.value}
          onChange={e => setUserState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, email: { value: e.target.value, error: '' } } }))}
          errorMessage={userState.step1.email.error}
        />
        <TextBox
          placeholder='Phone Number'
          name='phone_number'
          value={userState.step1.phone.value}
          onChange={e => setUserState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, phone: { value: inputPhoneMasking(e.target.value), error: '' } } }))}
          errorMessage={userState.step1.phone.error}
        />
        <CheckBox
          checked={userState.step1.tnc}
          onClick={() => setUserState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, tnc: !prevVal.step1.tnc } }))}
          label='By registering, I agree to receive text messages in relation to my account login. I understand that standard text and data rates may apply.'
        />

        <Button type='submit' disabled={!userState.step1.tnc}>
          Next
        </Button>
      </form>
    </div>
  );
};

export default Step1;
