import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'mission-list',
    initialState: {
        missionList: [],
        loading: false,
    },
    reducers: {
        requested: (missions) => {
            missions.loading = true
        },
        success: (missions, action) => {
            missions.missionList = action.payload;
            missions.loading = false
        },
        failed: (missions, action) => {
            missions.missionList = action.payload;
            missions.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getMissionList = data => apiCallBegan({
    url: '/mission/get-mission',
    method: 'POST',
    data,
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})