// export const baseUrl = 'http://18.144.33.201/api/v1'

//staging
// export const baseUrl = 'http://72.167.37.148:9999/api/v1'

// Production
// export const baseUrl = 'https://dashboard.bxbuilders.com/api/v1'

//development

// export const baseUrl = 'http://localhost:9999/api/v1'

export const baseUrl = process.env.REACT_APP_URL
export const STRIPE_TEST_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const socketUrl = process.env.REACT_APP_SOCKET_URL
export const DEFAULT_CLIENT_ID = process.env.REACT_APP_DEFAULT_CLIENT_ID
// export const STRIPE_PUBLISHABLE_KEY =
// 'pk_live_51KRQfSEEHMP6rc33N4qSqgIRk4XrKEwX0N99oMoupUQWe8k1f1xlYoPN33rn4kL1Uc9xCJKOqXFvxQbZ5HoH5yq200RmQNc29U'
