import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enable-disable-background',
    initialState: {
        backgroundStatus: null,
        loading: false,
    },
    reducers: {
        requested: (cetegory) => {
            cetegory.loading = true
        },
        success: (cetegory, action) => {
            cetegory.backgroundStatus = action.payload;
            cetegory.loading = false
        },
        failed: (cetegory, action) => {
            cetegory.backgroundStatus = action.payload;
            cetegory.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const setEnableDisableBackground = (item) => apiCallBegan({
    url: '/avatar-properties/enable-disable-avatar-properties',
    method: 'POST',
    data: { _id: item._id, enable: item.enable },
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})