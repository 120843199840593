import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AppHeader from '../../../../components/AppHeader'
import VideoCard from '../../../../components/Cards/VideoCard'
import AppModal from '../../../../components/AppModal'
import AppModal2 from '../../../../components/AppModal2'
import ProgressBar from '../../../../components/forms/ProgressBar'
import ImageUploader from '../../../../components/forms/ImageUploader'
import AppButton from '../../../../components/forms/AppButton'
import AllVideosSkeleton from '../../../../components/Skeletons/AllVideosSkeleton'
import TextInput from '../../../../components/forms/TextInput'
import Pagination from '../../../../components/Pagination'
import AppSwitch from '../../../../components/AppSwitch'
import Select from '../../../../components/forms/Select'
import { validateOnlyChacters } from '../../../../utils/validators'

import { getVideoList } from '../../../../store/video/videoList'
import { getPreAssetList } from '../../../../store/preAsset/preAssetsList'

import {
  addPreAssets,
  clearAddPreAssetsResponse,
} from '../../../../store/preAsset/addPreAssets'

import {
  deletePreAssets,
  clearDeletePreAssetsResponse,
} from '../../../../store/preAsset/deletePreAssets'
import { setVideoPageNumber } from '../../../../store/localStates/paginations/videoPagination'
import AudioUploader from '../../../../components/forms/AudioUploader/index'
import socketIOClient from 'socket.io-client'
import { socketUrl } from '../../../../middleware/url'
import axios from 'axios'

const fileTypeData = [
  {
    id: 1,
    title: 'Video',
    value: 'video',
  },
  {
    id: 4,
    title: 'Image',
    value: 'image',
  },
  {
    id: 2,
    title: 'Pdf',
    value: 'doc',
  },
  {
    id: 3,
    title: 'Audio',
    value: 'audio',
  },
]

const Videos = () => {
  const dispatch = useDispatch()

  const { sidebar } = useSelector((bar) => bar.sidebar)
  const { credentials } = useSelector((bar) => bar.credentials)
  const { addPreVideoResponse } = useSelector((list) => list.addPreAssets)
  const { deletePreAssetsResponse } = useSelector(
    (list) => list.deletePreAssets
  )
  const { preAssetsList, loading } = useSelector((list) => list.preAssetsList)
  const { videoPageNumber } = useSelector((list) => list.videoPageNumber)

  const [search, setSearch] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [progress, setProgress] = useState(null)
  const [isUpadate, setIsUpadate] = useState(null)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [deleteWithReference, setDeleteWithReference] = useState(true)

  const [videoTitle, setVideoTitle] = useState('')
  const [videoTitleError, setVideoTitleError] = useState('')

  const [video, setVideo] = useState('')
  const [videoError, setVideoError] = useState('')
  const [videoURL, setVideoURL] = useState('')

  const [audio, setAudio] = useState('')
  const [audioError, setAudioError] = useState('')
  const [audioURL, setAudioURL] = useState('')

  const [fileType, setFileType] = useState('video')
  const [fileTypeError, setFileTypeError] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileExtension, setFileExtension] = useState(null)
  const [addCancelToken, setAddCancelToken] = useState(null)

  const pageSize = 8

  window.onunload = function () {
    localStorage.setItem('preassetSearch', '')
    setSearch('')
  }

  useEffect(() => {
    const initialAPIs = async () => {
      await dispatch(
        getPreAssetList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: '',
        })
      )
      await dispatch(clearAddPreAssetsResponse())
    }

    if (localStorage.getItem('preassetSearch') === '') {
      initialAPIs()
    }
  }, [dispatch, videoPageNumber, credentials])

  useEffect(() => {
    const initialAPIs = async () => {
      await dispatch(
        getPreAssetList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: '',
        })
      )
      await dispatch(clearAddPreAssetsResponse())
    }

    initialAPIs()
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('preassetSearch')) {
      setSearch(localStorage.getItem('preassetSearch'))
      dispatch(
        getPreAssetList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: localStorage.getItem('preassetSearch'),
        })
      )
    }
  }, [dispatch, videoPageNumber, credentials])

  useEffect(() => {
    if (addPreVideoResponse !== null) {
      if (addPreVideoResponse?.status) {
        toast.success(addPreVideoResponse?.message)
        setVideo('')
        setVideoURL('')
        dispatch(
          getPreAssetList({
            pageSize,
            pageNumber: videoPageNumber,
            filterValue: '',
          })
        )
        dispatch(clearAddPreAssetsResponse())
        setProgress(null)
        onCloseModal()
      } else {
        toast.error(addPreVideoResponse?.message)
        setProgress(null)
        dispatch(clearAddPreAssetsResponse())
      }
    }
  }, [addPreVideoResponse, dispatch])

  useEffect(() => {
    if (deletePreAssetsResponse !== null) {
      if (deletePreAssetsResponse?.status) {
        toast.success(deletePreAssetsResponse?.message)
        closeDeleteModal()
        dispatch(
          getPreAssetList({
            pageSize,
            pageNumber: videoPageNumber,
            filterValue: '',
          })
        )
        dispatch(clearDeletePreAssetsResponse())
      } else {
        toast.error(deletePreAssetsResponse?.message)
        closeDeleteModal()
        dispatch(
          getPreAssetList({
            pageSize,
            pageNumber: videoPageNumber,
            filterValue: '',
          })
        )
        dispatch(clearDeletePreAssetsResponse())
      }
    }
  }, [deletePreAssetsResponse, dispatch])

  const onSearchVideo = (event) => {
    setSearch(event.target.value)
    localStorage.setItem('preassetSearch', event.target.value)
    dispatch(
      getPreAssetList({
        pageSize,
        pageNumber: videoPageNumber,
        filterValue: event.target.value,
      })
    )
  }

  const onAddVideoHandler = () => {
    setShowAddModal(true)
    setAddCancelToken(null)
  }

  const onUpload = (image) => {
    if (image.type === 'application/pdf') {
      const name = image?.name

      setFileName(name)
    } else {
      setFileName('Upload pdf')
    }

    setVideoURL(image)
    setVideoError('')
  }

  const audioUpload = (audio) => {
    setVideoURL(audio)
    setVideoError('')
  }

  const onCloseModal = () => {
    setIsUpadate(null)
    setShowAddModal(false)
    setVideoTitle('')
    setVideo('')
    setVideoURL('')
    setProgress(null)
    setFileTypeError('')
    setVideoError('')
    setVideoTitleError('')
    setFileType('video')
    setFileName(null)
    setFileExtension(null)
    if (addCancelToken) {
      addCancelToken?.cancel()
    }
  }

  const getVideoTitle = (type) => {
    if (type === 'video') {
      return 'Upload video'
    } else if (type === 'image') {
      return 'Upload image'
    } else if (type === 'doc') {
      return 'Upload pdf'
    } else {
      return 'Upload video'
    }
  }

  const onEditHandler = (item) => {
    const ulrSplit = item?.assetURL.split('/')

    const name = ulrSplit[ulrSplit.length - 1]

    const extension = name.split('.')

    if (item.fileType) {
      setFileName(name)
    } else {
      setFileName('Upload pdf')
    }

    setShowAddModal(true)
    setIsUpadate(item)
    setVideoTitle(item.title)
    setVideo(item.assetURL)
    setFileType(item.fileType)
    setAddCancelToken(null)
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault()

    const data = new FormData()

    if (isUpadate) {
      if (videoURL !== '') {
        data.append('file', videoURL, videoURL.name)
      }
      if (isUpadate._id) {
        data.append('id', isUpadate._id)
      }
      data.append('title', videoTitle)
      data.append('fileType', fileType)

      if (videoTitle === '') {
        setVideoTitleError('Please enter asset title')
        return
      } else if (videoTitle.length < 2) {
        setVideoTitleError('asset title must be two character long')
        return
      } else if (fileType === '' || fileType === null) {
        setFileTypeError('Please Select a file type')
        return
      } else if (video === '' && fileType === 'video') {
        setVideoError('Please select video')
        return
      } else if (video === '' && fileType === 'doc') {
        setVideoError('Please select pdf')
      } else if (video === '' && fileType === 'audio') {
        setVideoError('Please select audio file')
        return
      } else if (video === '' && fileType === 'image') {
        setVideoError('Please select image file')
        return
      }
      const cancelTokenForUpdate = axios.CancelToken.source()

      setAddCancelToken(cancelTokenForUpdate)

      await dispatch(
        addPreAssets(
          data,
          fileType,
          (progress) => setProgress(progress),
          cancelTokenForUpdate
        )
      )
    } else {
      if (videoTitle === '') {
        setVideoTitleError('Please enter asset title')
        return
      } else if (videoTitle.length < 2) {
        setVideoTitleError('asset title must be two character long')
        return
      } else if (fileType === '' || fileType === null) {
        setFileTypeError('Please Select a file type')
        return
      } else if (video === '' && fileType === 'video') {
        setVideoError('Please select video')
        return
      } else if (video === '' && fileType === 'doc') {
        setVideoError('Please select pdf')
        return
      } else if (video === '' && fileType === 'audio') {
        setVideoError('Please select audio file')
        return
      } else if (video === '' && fileType === 'image') {
        setVideoError('Please select image file')
        return
      } else {
        data.append('title', videoTitle)
        data.append('file', videoURL, videoURL.name)
        data.append('fileType', fileType)

        const cancelTokenForAdd = axios.CancelToken.source()

        setAddCancelToken(cancelTokenForAdd)

        await dispatch(
          addPreAssets(
            data,
            fileType,
            (progress) => setProgress(progress),
            cancelTokenForAdd
          )
        )
      }
    }
  }

  const onPageChange = async (page) => {
    const selectedPage = page.selected
    dispatch(
      getPreAssetList({
        pageSize,
        pageNumber: selectedPage + 1,
        filterValue: '',
      })
    )
    dispatch(setVideoPageNumber(selectedPage + 1))
  }

  const onDeleteStatusChange = () => {
    setDeleteWithReference(!deleteWithReference)
  }

  const onDeleteHandler = (item) => {
    setShowDeleteModal(true)
    setDeletedItem(item)
  }

  const onDeleteMission = () => {
    if (deletedItem !== null) {
      dispatch(deletePreAssets(deletedItem._id, deleteWithReference))
    }
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setDeletedItem(null)
    setDeleteWithReference(false)
  }

  const onAddSettingsHandler = () => {}

  const onClientChange = (item) => {
    const initialAPIs = async () => {
      await dispatch(
        getPreAssetList({
          pageSize,
          pageNumber: videoPageNumber,
          filterValue: '',
        })
      )
      await dispatch(clearAddPreAssetsResponse())
    }
    initialAPIs()
    setSearch('')
  }

  const onRefresh = () => {
    dispatch(
      getPreAssetList({
        pageSize,
        pageNumber: videoPageNumber,
        filterValue: '',
      })
    )
    dispatch(clearAddPreAssetsResponse())
  }

  const setFileNamehandler = (type) => {
    const title = getVideoTitle(type)
    setFileName(title)
  }

  return (
    <div className={`main_container ${!sidebar && 'main_container_active'}`}>
      <AppHeader
        heading='Pre Assets'
        isAdd
        onAdd={onAddVideoHandler}
        isSearch
        searchPlaceholder='Search Pre Assets'
        onSearch={onSearchVideo}
        searchValue={search}
        onClientChange={onClientChange}
      />
      {loading ? (
        <AllVideosSkeleton />
      ) : (
        <Fragment>
          <div className='client__container'>
            <div className='missions_main_content'>
              {preAssetsList !== null &&
              preAssetsList.data &&
              preAssetsList.data.result &&
              preAssetsList.data.result.length > 0 ? (
                preAssetsList.data.result.map((item, index) => {
                  // const videoURL=item.assetURL;
                  return (
                    <VideoCard
                      key={index}
                      item={{
                        ...item,
                        videoURL: item.assetURL,
                      }}
                      onEditClick={onEditHandler}
                      onDelete={onDeleteHandler}
                      onAddClick={onAddSettingsHandler}
                      showLink={false}
                      showAttach={false}
                    />
                  )
                })
              ) : (
                <div className='data_not_found_container'>
                  <p className='data_not_found_message'>
                    {search !== ''
                      ? 'Search result not found'
                      : "You haven't uploaded any asset yet."}
                  </p>
                </div>
              )}
            </div>
          </div>

          <AppModal show={showAddModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>
                {isUpadate ? 'Edit Pre Asset' : 'Add Pre Asset'}
              </p>
              <form
                className='clinic_form_container'
                onSubmit={onSubmitHandler}>
                <div className='clinic_form_top_container'>
                  <div className='clinic_form_top_left_container'>
                    <TextInput
                      placeholder='Title'
                      src='/images/missions-input.png'
                      onChange={(video) => {
                        setVideoTitleError('')
                        setVideoTitle(video.target.value)
                      }}
                      value={videoTitle}
                      error={videoTitleError}
                    />
                    <Select
                      src='/images/missions-input.png'
                      data={fileTypeData}
                      placeholder='Select file type'
                      onChange={(event) => {
                        setFileType(event.target.value)
                        setFileTypeError('')
                        setVideo('')
                        setVideoURL('')
                        setFileNamehandler(event.target.value)
                        // setFileName('Upload pdf')
                      }}
                      value={fileType}
                      fileExtension={fileExtension}
                    />
                    {fileTypeError !== '' ? (
                      <p className='input_error_text'>{fileTypeError}</p>
                    ) : null}
                    <div className='modal_buttons'>
                      <AppButton
                        title={isUpadate ? 'update' : 'add'}
                        type='submit'
                        icon={
                          <img
                            src='/images/add-clinic.png'
                            className='app_button_image'
                          />
                        }
                        onClick={onSubmitHandler}
                      />
                      <AppButton
                        title='cancel'
                        type='button'
                        buttonContainer={{
                          backgroundColor: 'var(--transparent)',
                          marginLeft: '0.5rem',
                        }}
                        textStyle={{
                          color: 'var(--black)',
                          fontWeight: 'bold',
                        }}
                        onClick={onCloseModal}
                      />
                    </div>
                  </div>

                  <div className='clinic_form_image_upload_container'>
                    {fileType === 'audio' ? (
                      <AudioUploader
                        onUpload={audioUpload}
                        audio={fileType === 'audio' ? video : ''}
                        setURL={(video) => setVideo(video)}
                        audioTitle='Upload Audio'
                        errorMessage={videoError}
                        containerStyles={{ width: '15vw', height: '9vw' }}
                      />
                    ) : (
                      <ImageUploader
                        onUpload={onUpload}
                        video={
                          fileType === 'video' || fileType === 'image'
                            ? video
                            : ''
                        }
                        setURL={(video) => setVideo(video)}
                        errorMessage={videoError}
                        containerStyles={{ width: '15vw', height: '9vw' }}
                        videoTitle={
                          isUpadate ? fileName : getVideoTitle(fileType)
                        }
                        fileType={fileType}
                        isUpadate={isUpadate}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
            <ProgressBar progress={progress * 100} />
          </AppModal>
          <AppModal2 show={showDeleteModal}>
            <div className='clinic_add_modal_container'>
              <p className='form_header_text'>Delete Pre Asset</p>
              <p className='form_header_delete_text'>
                Are you sure you want to delete{' '}
                <span>{deletedItem !== null && deletedItem.title}</span>?
              </p>
              {/* <div className='form_header_delete_ref'>
                <p className='form_header_delete_ref_text'>
                  Delete all it's reference
                </p>
                <AppSwitch
                  checked={deleteWithReference}
                  onChange={onDeleteStatusChange}
                />
              </div> */}

              <div className='modal_buttons'>
                <AppButton
                  title='delete'
                  type='submit'
                  // icon={<img src='/images/add-clinic.png' className='app_button_image' />}
                  onClick={onDeleteMission}
                />
                <AppButton
                  title='cancel'
                  type='button'
                  buttonContainer={{
                    backgroundColor: 'var(--transparent)',
                    marginLeft: '0.5rem',
                  }}
                  textStyle={{ color: 'var(--black)', fontWeight: 'bold' }}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </AppModal2>
        </Fragment>
      )}
      {preAssetsList !== null &&
        preAssetsList.data &&
        preAssetsList.data.result &&
        preAssetsList.data.result.length > 0 &&
        preAssetsList.data.totalPageSize > 8 && (
          <Pagination
            list={preAssetsList}
            onPageChange={onPageChange}
            rowsPerPage={pageSize}
            page={videoPageNumber}
          />
        )}
    </div>
  )
}

export default Videos
