import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import Content from '../../components/Content'
import styles from './EditAvatar.module.css'
import Avatar, { Piece } from 'avataaars'
import { avatarList } from './avatarData'
import { gsap } from 'gsap'
import {
  getAvatarProperties,
  clearAvatarProperties,
} from '../../../store/avatar/getAvatarProperties'
import {
  updateAvatarProperties,
  clearUpdateAvatarResponse,
} from '../../../store/avatar/updateAvatar'
import {
  purchaseAvatarProperties,
  clearPurchaseAvatarProperties,
} from '../../../store/avatar/purchaseAvatar'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import Loader from 'react-js-loader'
import Modal from '../../components/Modal'

const EditAvatar = (props) => {
  const { avatarId } = props.match.params

  const dispatch = useDispatch()
  const history = useHistory()

  const middleButton = useRef(null)
  const [avatarData, setAvatarData] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const [options, setOptions] = useState(null)

  const { avatarList: avatarProperties, loading } = useSelector(
    (state) => state.getAvatarProperties
  )

  const { avatarUpdateResponse } = useSelector((state) => state.updateAvatar)

  const { purchaseReponse } = useSelector((state) => state.purchaseAvatar);


  useEffect(() => {
    if (avatarId) {
      dispatch(getAvatarProperties(avatarId))
    }

    return () => {
      dispatch(clearUpdateAvatarResponse())
    }
  }, [avatarId])

  const editAvatarHandler = (item) => {
    if (options.type) {
      setAvatarData({
        ...avatarData,
        [options.type]: item.value,
      })
    }
  }

  const optionshandler = (item) => {
    setOptions(item)
  }

  useEffect(() => {
    if (
      avatarProperties &&
      avatarProperties.data &&
      Array.isArray(avatarProperties.data)
    ) {
      let avatar = {}

      avatarProperties.data.forEach((item) => {
        avatar = {
          ...avatar,
          [item.type]: item.defaultValue,
        }
      })

      if (!purchaseReponse) {
        setAvatarData(avatar)
      }
      setOptions(avatarProperties.data[0])
      dispatch(clearPurchaseAvatarProperties())
    }
  }, [avatarProperties, avatarId, dispatch])

  useEffect(() => {
    if (avatarUpdateResponse) {
      if (avatarUpdateResponse.status) {
        toast.success(avatarUpdateResponse.message)
        dispatch(clearUpdateAvatarResponse())
        history.push(`/student`)
      } else if (!avatarUpdateResponse.status) {
        toast.error(avatarUpdateResponse.message)

        dispatch(clearUpdateAvatarResponse())
      }
    }
  }, [avatarUpdateResponse])

  const saveHandler = () => {
    if (avatarId && avatarData) {
      const data = {
        _id: avatarId,
        kidAvatar: avatarData,
      }

      dispatch(updateAvatarProperties(data))
    }
  }

  const purchaseModalHandler = (item) => {
    setShowModal(true)
    setCurrentItem(item)
  }
  const purchaseHandler = () => {
    if (currentItem && avatarId) {
      const data = {
        kid_id: avatarId,
        avatarProperties: currentItem.type,
        point: currentItem.charges,
      }

      dispatch(purchaseAvatarProperties(data))
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setCurrentItem(null)
  }

  useEffect(() => {
    if (purchaseReponse) {
      if (purchaseReponse.status) {
        toast.success(purchaseReponse.message)
        if (avatarId) {
          dispatch(getAvatarProperties(avatarId))
        }
        closeModal()
      } else {
        toast.error(purchaseReponse.message)
        dispatch(clearPurchaseAvatarProperties())
      }
    }
  }, [purchaseReponse])

  return (
    <Content currentMenu='student' headerTitle='Edit Avatar'>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          {avatarData ? (
            <>
              {avatarData?.background && avatarData?.background !== '' && (
                <div className={styles.backgroundImgContainer}>
                  <img
                    src={avatarData.background}
                    alt='generic'
                    className={styles.background}
                    style={{ borderRadius: '3rem' }}
                  />
                </div>
              )}
              {avatarData?.tag && avatarData?.tag !== '' && (
                <div className={styles.tagImgContainer}>
                  <img
                    src={avatarData.tag}
                    alt='generic 2'
                    className={styles.background}
                  />
                </div>
              )}
              <div className={styles.avatarStyle}>
                <Avatar
                  style={{ width: '18rem', height: '18rem' }}
                  avatarStyle={avatarData.avatarStyle}
                  topType={avatarData.topType}
                  accessoriesType={avatarData.accessoriesType}
                  hairColor={avatarData.hairColor}
                  facialHairType={avatarData.facialHairType}
                  clotheType={avatarData.clotheType}
                  clotheColor={avatarData.clotheColor}
                  eyeType={avatarData.eyeType}
                  eyebrowType={avatarData.eyebrowType}
                  mouthType={avatarData.mouthType}
                  skinColor={avatarData.skinColor}
                />
              </div>

              <button
                className='modalAnswerNext_next_btn  avatarBtn buttonAvatar '
                onClick={saveHandler}>
                Save <span />
              </button>
            </>
          ) : (
            <Loader
              type='bubble-top'
              bgColor={'#FFFFFF'}
              color={'#FFFFFF'}
              size={20}
            />
          )}
        </div>
        <div className={styles.middleContainer}>
          {avatarProperties &&
            avatarProperties.data &&
            Array.isArray(avatarProperties.data) &&
            avatarProperties.data.map((item, index) => {
              return (
                <div key={index}>
                  {item.isPurchase ? (
                    <button
                      ref={middleButton}
                      className={styles.middleCard}
                      onClick={optionshandler.bind(this, item)}
                      key={index}>
                      {item.category}
                    </button>
                  ) : (
                    <button
                      className={styles.middleCard2}
                      onClick={purchaseModalHandler.bind(this, item)}
                      key={index}>
                      {item.category}
                      <div class={styles.coinBag}>
                        <div>{item.charges}</div>
                        <img
                          src='/images/BagOfCoins.png'
                          className={styles.coin}
                        />
                      </div>

                      <svg className={styles.icon}>
                        <use xlinkHref={`/images/sprite.svg#icon-lock`} />
                      </svg>
                    </button>
                  )}
                </div>
              )
            })}
        </div>
        <div className={styles.rightContainer}>
          {options &&
            options?.options?.map((item, i) => {
              return (
                <div
                  className={styles.rightCard}
                  key={i}
                  onClick={editAvatarHandler.bind(this, item)}>
                  {options.type === 'background' || options.type === 'tag' ? (
                    <div className={styles.backgroundCard}>
                      {item.value ? (
                        <img
                          src={item.value}
                          alt={item.title}
                          className={styles.background}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <Avatar
                      style={{ width: '8rem', height: '8rem' }}
                      avatarStyle={
                        options.type === 'avatarStyle'
                          ? item.value
                          : 'Transparent'
                      }
                      topType={
                        options.type === 'topType'
                          ? item.value
                          : avatarProperties?.kid?.kidGender === 'male'
                            ? 'ShortHairDreads01'
                            : 'LongHairCurly'
                      }
                      accessoriesType={
                        options.type === 'accessoriesType'
                          ? item.value
                          : 'Blank'
                      }
                      hairColor={
                        options.type === 'hairColor' ? item.value : 'BrownDark'
                      }
                      facialHairType={
                        options.type === 'facialHairType' ? item.value : 'Blank'
                      }
                      facialHairColor={
                        options.type === 'facialHairColor'
                          ? item.value
                          : 'Blonde'
                      }
                      clotheType={
                        options.type === 'clotheType'
                          ? item.value
                          : 'BlazerSweater'
                      }
                      clotheColor={
                        options.type === 'clotheColor' ? item.value : 'Black'
                      }
                      eyeType={
                        options.type === 'eyeType' ? item.value : 'Default'
                      }
                      eyebrowType={
                        options.type === 'eyebrowType' ? item.value : 'Default'
                      }
                      mouthType={
                        options.type === 'mouthType' ? item.value : 'Default'
                      }
                      skinColor={
                        options.type === 'skinColor' ? item.value : 'Light'
                      }
                    />
                  )}
                  <div style={{ marginTop: '0.5rem' }}>{item.title}</div>
                </div>
              )
            })}
        </div>
      </div>
      <Modal show={showModal} style={{ width: '25vw' }}>
        <p className='modalQuestion' style={{ textAlign: 'center' }}>
          Are you sure you want to unlock?
        </p>

        <div className='logout_btn_container'>
          <button className='modalAnswerNext_next_btn2' onClick={closeModal}>
            Cancel <span />
          </button>
          <button
            className='modalAnswerNext_next_btn3'
            onClick={purchaseHandler}>
            Purchase <span />
          </button>
        </div>
      </Modal>
    </Content>
  )
}

export default EditAvatar
