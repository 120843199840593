import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
  name: 'get-weekly-counts',
  initialState: {
    weeklyCounts: null,
    loading: false,
  },
  reducers: {
    requested: (tile) => {
      tile.loading = true
    },
    success: (tile, action) => {
      tile.weeklyCounts = action.payload
      tile.loading = false
    },
    failed: (tile, action) => {
      tile.weeklyCounts = action.payload
      tile.loading = false
    },
  },
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const getWeeklyCounts = () =>
  apiCallBegan({
    url: '/point/get-weekly-report-by-client',
    method: 'GET',
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type,
  })
