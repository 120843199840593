import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'add-how-to',
    initialState: {
        addhowToResponse: null,
        loading: false,
    },
    reducers: {
        requested: (howTo) => {
            howTo.loading = true
        },
        success: (howTo, action) => {
            howTo.addhowToResponse = action.payload
            howTo.loading = false
        },
        failed: (howTo, action) => {
            howTo.addhowToResponse = action.payload;
            howTo.loading = false
        },
        reset: (howTo, action) => {
            howTo.addhowToResponse = action.payload;
            howTo.loading = false
        }
    }
})

const { requested, success, failed, reset } = slice.actions
export default slice.reducer

export const addHowTo = (data, onUploadProgress) => apiCallBegan({
    url: '/generic/create-and-update-generic-tiles',
    method: 'POST',
    data,
    onUploadProgress,
    onStart: requested.type,
    onSuccess: success.type,
    onFailed: failed.type
})

export const clearAddHowToResponse = () => apiCallBegan({
    onReset: reset.type,
})